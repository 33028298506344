import React, { Component } from "react";
// import { DropDownList } from '@progress/kendo-react-dropdowns';
// import { Button } from "@progress/kendo-react-buttons";
import axios from 'axios';
// import chBlank from './components/images/chBlank.png'
import homeDocs from './components/images/homeDocs.png'
import homeAccessDocs from './components/images/homeAccessDocs.png'
import homeWeb from './components/images/homeWeb.png'
import homeMkt from './components/images/homeMkt.png'
// import homeContact from './components/images/homeContact.png'
// import chPlaceholder from './components/images/chPlaceholder.png'
// import chbg from './components/images/chbg.jpg'
// import ReactLoading from 'react-loading';
import Snapshot from "../Snapshot/Snapshot";





class Template2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            associatedHousehold: this.props.CHassociatedHousehold != null ? this.props.CHassociatedHousehold : null,
            csTeamLoaded: false,
            members: [],
            test: [],
            loading: true,
            imageLoading: true,
            styleSettings: {
                themePrimaryColor: null,
                themeFontFamily: null,
            },
            docAccess: null,
            settings: {
                headerText: null,
                headerFontStyle: null,
                headerFontSize: null,
                headerFontColor: null,
                backgroundImage: null,
                leftSubheaderText: null,
                leftSubheaderColor: null,
                documentsLinkText: null,
                documentsLinkColor: null,
                accountsLinkText: null,
                accountsLinkColor: null,
                rightSubheaderText: null,
                rightSubheaderColor: null,
                websiteLinkText: null,
                websiteLinkUrl: null,
                websiteLinkColor: null,
                additionalLinkText: null,
                additionalLinkUrl: null,
                additionalLinkColor: null,
                bottomImage: null,
                bottomImageHeaderText: null,
                bottomImageHeaderColor: null, 
                showAdditionalLink: null,
                showWebsiteLink: null,
                backgroundImageFade: null,
            }
        }

        this.formatPhoneNumber = this.formatPhoneNumber.bind(this);

    }


    componentDidMount() {
        //this.getCsTeamDedicated();  
        this.useEffect();
        this.getDocAccessStatus(); 
        this.getStyleSettings();     
        this.getTemplate2Images(() => {
            this.getTemplateSettings();
        });
    }

    getDocAccessStatus() {
        var accessArray = this.props.user.access;

        var docAccess = accessArray.find(a =>a.includes("doc"));
        
        this.setState({docAccess: docAccess}) 
    }

    getStyleSettings() {
        var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    
        var styleNames = ['themePrimaryColor', 'RIAFirmName', 'themeFontFamily']
    
        axios(`api/getStyleSettings`, {
          method: 'GET',
          params: { company: this.props.user.company, styleNames: styleNames },
          headers: headers
        })
        .then(response => {
          this.setState({styleSettings: response.data.styleSettings})
        })
        .catch(err => {
            console.error(err);
        })
      }

    getTemplateSettings() {

        //this.setState({ refreshText: 'Loading...', employeeSelected: false, employeeSelect: '', });
        // var members = [];
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        //axios
        //.post('api/ssrsGetReports', payload, { headers })

        axios(`api/getHomePageSettings`, {
            method: 'GET',
            params: { company: this.props.user.company, template: 'template2' },
            headers: headers,
        })
        .then(response => {
            this.setState({settings: response.data.settings, loading: false})
        })
        .catch(err => {
            this.setState({loading: false})
            console.error(err);
            //this.setState({ refreshText: '', csTeamLoaded: true });
        })
        
    }

    getTemplate2Images(cb) {

        //this.setState({ refreshText: 'Loading...', employeeSelected: false, employeeSelect: '', });
        // var members = [];
        var backgroundImage = null
        var bottomImage = null
        // var test = []
        // var tt = {}
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        //axios
        //.post('api/ssrsGetReports', payload, { headers })

        axios
            .get("api/download?company=" + this.props.user.company + "&path=customlanding/template2/backgroundImage.png&name=backgroundImage.png", { headers })
            .then((response) => {
                if (response.data) {
                    backgroundImage = response.data
                }
                axios
                .get("api/download?company=" + this.props.user.company + "&path=customlanding/template2/bottomImage.png&name=bottomImage.png", { headers })
                .then((response) => {
                    if (response.data) {
                        bottomImage = response.data
                    } 
                    this.setState({backgroundImage: backgroundImage, bottomImage: bottomImage, imageLoading: false}, () =>{
                        cb()
                    })
                })
                .catch((e) => {
                    console.error(e);
                    cb()
                });
            })
            .catch((e) => {
                console.error(e);
                cb()
            });
    }

    getTemplateImages(cb) {

        //this.setState({ refreshText: 'Loading...', employeeSelected: false, employeeSelect: '', });
        // var members = [];
        var backgroundImage = null
        var bottomImage = null
        var test = []
        var tt = {}
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        //axios
        //.post('api/ssrsGetReports', payload, { headers })

        axios(`api/getHomePageImages`, {
            method: 'GET',
            params: { company: this.props.user.company, template: 'template1' },
            headers: headers,
        })
        .then(response => {
            console.log(response)
            response.data.data.forEach(element => {
                if (element.pictureName === 'backgroundImage'){
                    console.log(element)
                    backgroundImage = this.createImageObject(element.data)
                }
                else if (element.pictureName === 'bottomImage'){
                    tt.test = this.createImageObject(element.data)
                    
                    test.push(tt)
                    bottomImage = this.createImageObject(element.data)
                }
            });
            console.log(backgroundImage)
            this.setState({test: test, backgroundImage: backgroundImage, bottomImage: bottomImage, imageLoading: false}, () =>{
                cb()
            })
        })
        .catch(err => {
            this.setState({imageLoading: false})
            cb()
            console.error(err);
            //this.setState({ refreshText: '', csTeamLoaded: true });
        })
        
    }

    getCsTeamDedicated() {
        //this.setState({ refreshText: 'Loading...', employeeSelected: false, employeeSelect: '', });
        var members = [];
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        //axios
        //.post('api/ssrsGetReports', payload, { headers })

        axios(`api/CsTeamHousehold`, {
            method: 'GET',
            params: { company: this.props.user.company, user: this.props.user.user },
            headers: headers,
        })
            .then(response => {
                // if (1 === 1) {

                    if (response.data.data != null && response.data.data.length === 1 && this.props.user.role === 'user') {
                        this.setState({ associatedHousehold: {
                            household: response.data.data[0].household, 
                            account: response.data.data[0].account,
                            displayName: response.data.data[0].displayName,
                            accountAnnotation: response.data.data[0].accountAnnotation
                        } })
                        this.props.setCHassociatedHousehold({
                            household: response.data.data[0].household, 
                            account: response.data.data[0].account,
                            displayName: response.data.data[0].displayName,
                            accountAnnotation: response.data.data[0].accountAnnotation
                        });
                    }

                    axios(`api/CsTeamDedicated`, {
                        method: 'GET',
                        params: { company: this.props.user.company, household: (response.data.data != null && response.data.data.length > 0 && response.data.data[0].household != null) ? response.data.data[0].household : '3Sisters85Inc-011_marysue83' },
                        headers: headers,
                    })
                        .then(response => {
                            if (response.data.data[0]) {

                                members.push(this.createTeamMemberObject(response.data.data[0]))
                            }
                            if (response.data.data[1]) {

                                members.push(this.createTeamMemberObject(response.data.data[1]))
                            }
                            if (response.data.data[2]) {

                                members.push(this.createTeamMemberObject(response.data.data[2]))
                            }

                            this.setState({ members: members, csTeamLoaded: true })


                            //var sort=[{ field: 'type', dir: 'asc' }, { field: 'reportName', dir: 'asc' }]
                            //this.setState({ data: members.map(cloneProduct), members: members, refreshText: '' })

                        })
                        .catch(err => {
                            console.error(err);
                            this.setState({ refreshText: '', csTeamLoaded: true });
                        })
                // }
                // else {
                //     this.setState({ refreshText: '', csTeamLoaded: true });
                // }

            })
            .catch(err => {
                console.error(err);
                this.setState({ refreshText: '', csTeamLoaded: true });
            })


    }

    formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          var intlCode = (match[1] ? '+1 ' : '');
          return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
      }

    createTeamMemberObject(memberData) {
        var fileURL = null

        if (memberData.data) {
            var arrayBufferView = new Uint8Array(memberData.data.data);

            const file = new Blob(
                [arrayBufferView],
                { type: 'image/png' }
            );
            fileURL = URL.createObjectURL(file);//Build a URL from the file
        }


        var member = {
            HouseholdID: memberData.HouseholdID,
            Relation: memberData.Relation,
            FullName: memberData.FullName,
            Title: memberData.Title,
            Phone: memberData.Phone,
            Email: memberData.Email,
            PictureName: memberData.PictureName,
            Image: fileURL
        }

        return member
    }

    

    fadeColor(rgb) {
        if (!rgb){
            rgb = "rgb(255,255,255,0)"
        }
        const [red, green, blue] = rgb.match(/\d+/g).map(Number);
        
        return "rgb(" + red + ", " + green + ", " + blue + ", 0)";
    }

    createImageObject(imageData) {
        var arrayBufferView = new Uint8Array(imageData.data);

        const file = new Blob(
            [arrayBufferView],
            { type: 'image/png' }
        );

        var url = URL.createObjectURL(file);//Build a URL from the file

        console.log(url)
        return url
    }

    useEffect(){
        window.addEventListener('resize', this.handleResize);
    }
    
    handleResize = (e) => {this.setState({pageWidth: e.target.innerWidth})}

    getHeaderVariables(text){
        if (!text){
            text = ''
        }

        text = text.replace(/{{name}}/g, this.props.user.name)
        text = text.replace(/{{riafirmname}}/g, this.state.styleSettings.RIAFirmName)
        text = text.replace(/{{email}}/g, this.props.user.email)

        return text
    }

    getFontWeight(){
        var fontAttributes = {}
        let fontStyle = this.state.settings.headerFontStyle || ''
        switch(fontStyle.toLowerCase().includes('bold')){
            case true:
                fontAttributes.fontWeight = 'bold'
                break
            default:
                fontAttributes.fontWeight = 'normal'
                break
        }  

        switch(fontStyle.toLowerCase().includes('underline')){
            case true:
                fontAttributes.textDecoration = 'underline'
                break
            default:
                fontAttributes.textDecoration = ''
                break
        }  

        switch(fontStyle.toLowerCase().includes('italic')){
            case true:
                fontAttributes.fontStyle = 'italic'
                break
            default:
                fontAttributes.fontStyle = ''
                break
        } 


        return fontAttributes
    }



    render() {
        var bgColor = this.state.styleSettings.themePrimaryColor
        return (
            <>
                {(!this.state.loading && !this.state.imageLoading) && 
                    <div style={{
                        backgroundImage: (this.state.settings.backgroundImageFade 
                            ? `linear-gradient(to left, ${this.fadeColor(bgColor).replace("rgb(","rgba(")}, ${bgColor.replace("rgb(","rgba(")}),url(${this.state.backgroundImage})` 
                            : `url(${this.state.backgroundImage})`),
                        backgroundSize: 'cover', 
                        backgroundRepeat: 'no-repeat', 
                        height: '92vh', 
                        padding: 0, 
                        margin: 0, 
                        display: 'flex', 
                        flexDirection: 'column',  // Added to stack children vertically
                        width: '100%'
                    }}>
                        <label className='template2Header' style={{
                            paddingTop: '1vw', 
                            paddingBottom: '2vw', 
                            fontSize: (this.state.settings.headerFontSize || '5') + 'vw', 
                            fontFamily: 'Muli', 
                            ...this.getFontWeight(), 
                            color: this.state.settings.headerFontColor
                        }}>
                            {this.getHeaderVariables(this.state.settings.headerText)}
                        </label>
                        
                        <div className="templateTable" style={{
                            display: 'flex', 
                            flexWrap: 'wrap', 
                            fontFamily: 'Muli'
                        }}>
                            <div style={{display: 'flex', flexWrap: 'wrap', flex: 1}}>
                                <div style={{
                                    flex: 1, 
                                    borderRadius: '10px',
                                    minWidth: '300px', 
                                    maxWidth: '300px', 
                                    margin: '5px', 
                                    backgroundColor: 'rgba(0,0,0,0.061)'
                                }}>
                                    <div style={{
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        cursor: 'pointer', 
                                        paddingRight: '5px'
                                    }} onClick={this.props.onCHDocClick}>
                                        <img style={{verticalAlign: 'middle'}} src={homeDocs} width="50" height="50" alt="Document Icon" />
                                        <span style={{color: this.state.settings.documentsLinkColor, marginLeft: '10px'}}>
                                            {this.state.settings.documentsLinkText}
                                        </span>
                                    </div>
                                </div>
                                <div style={{
                                    flex: 1, 
                                    borderRadius: '10px',
                                    minWidth: '300px', 
                                    maxWidth: '300px', 
                                    margin: '5px', 
                                    backgroundColor: 'rgba(0,0,0,0.061)'
                                }}>
                                    <div style={{
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        cursor: 'pointer', 
                                        paddingRight: '5px'
                                    }} onClick={(e) => this.props.onCHAccountClick(e, this.state.associatedHousehold)}>
                                        <img style={{verticalAlign: 'middle'}} src={homeAccessDocs} width="50" height="50" alt="Access Documents Icon" />
                                        <span style={{color: this.state.settings.accountsLinkColor, marginLeft: '10px'}}>
                                            {this.state.settings.accountsLinkText}
                                        </span>
                                    </div>
                                </div>
                                <div style={{
                                    flex: 1, 
                                    borderRadius: '10px',
                                    minWidth: '300px', 
                                    maxWidth: '300px', 
                                    margin: '5px', 
                                    backgroundColor: 'rgba(0,0,0,0.061)'
                                }}>
                                    <a target="_blank" rel="noopener noreferrer" style={{
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        position: 'relative', 
                                        top: '50%', 
                                        textDecoration: 'none', 
                                        transform: 'translateY(-50%)', 
                                        paddingRight: '5px'
                                    }} href={(this.state.settings.websiteLinkUrl && !this.state.settings.websiteLinkUrl.includes('https://')) 
                                        ? (`https://${this.state.settings.websiteLinkUrl}`) 
                                        : this.state.settings.websiteLinkUrl
                                    }>
                                        <div style={{
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            height: '100%', 
                                            color: this.state.settings.websiteLinkColor
                                        }}>
                                            <img style={{verticalAlign: 'middle'}} src={homeWeb} width="50" height="50" alt="Website Icon" />
                                            <span style={{marginLeft: '10px'}}>{this.state.settings.websiteLinkText}</span>
                                        </div>
                                    </a>
                                </div>
                                <div style={{
                                    flex: 1, 
                                    borderRadius: '10px',
                                    minWidth: '300px', 
                                    maxWidth: '300px', 
                                    margin: '5px', 
                                    backgroundColor: 'rgba(0,0,0,0.061)'
                                }}>
                                    <a target="_blank" rel="noopener noreferrer" style={{
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        position: 'relative', 
                                        top: '50%', 
                                        transform: 'translateY(-50%)', 
                                        textDecoration: 'none', 
                                        paddingRight: '5px'
                                    }} href={(this.state.settings.additionalLinkUrl && !this.state.settings.additionalLinkUrl.includes('https://')) 
                                        ? (`https://${this.state.settings.additionalLinkUrl}`) 
                                        : this.state.settings.additionalLinkUrl
                                    }>
                                        <div style={{
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            height: '100%', 
                                            color: this.state.settings.additionalLinkColor
                                        }}>
                                            <img style={{verticalAlign: 'middle'}} src={homeMkt} width="50" height="50" alt="Additional Link Icon" />
                                            <span style={{marginLeft: '10px'}}>{this.state.settings.additionalLinkText}</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        
                        <Snapshot type={'lite'} pageWidth={this.state.pageWidth} onSnapshotBodyClick={(selectedHousehold, visualizationName) => this.props.onSnapshotBodyClick(selectedHousehold, visualizationName)} onBack={(e) => this.props.goBack()} compSettings={this.props.compSettings} styleSettings={this.state.styleSettings} user={this.props.user} loggedInUser={this.props.loggedInUser} idToken={this.props.idToken} />
                    </div>
                }
            {this.state.loading && <div></div>}
            </>
        );
    }

}

export default Template2;