import React, { Component } from "react";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import EquityOverview from './dashboards/EquityOverview';
import PerformanceOverview from './dashboards/PerformanceOverview';
import HoldingsDashboard from './dashboards/HoldingsDashboard';
import FixedIncomeHoldingsDashboard from './dashboards/FixedIncomeHoldingsDashboard';
import AccountSummaryDashboard from './dashboards/AccountSummaryDashboard';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import axios from 'axios';
import MarketValueDashbard from "./dashboards/MarketValueDashboard";
import GroupAppraisalDashboard from "./dashboards/GroupAppraisalDashboard";
import HistoricalMVDashboard from './dashboards/HistoricalMVDashboard';
import TransactionSummary from "./dashboards/TransactionSummary";
import GrowthDashboard from "./dashboards/GrowthDashboard";
import AUMDashboard from "./dashboards/AUMDashboard";
import HoldingsSummaryDashboard from "./dashboards/HoldingsSummaryDashboard";
import RGainDashboard from "./dashboards/RGainDashboard";
import { Button } from '@progress/kendo-react-buttons';


class Portfolio extends Component {

  constructor(props) {
    super(props);

    var allTabs = ["acctSumm", "equityOver", "perfOver", "holds", "fiHolds", "holdSumm", "markVal", "grpApp", "histMarkVal", "tranSumm", "growth", "aum", "rgain"];
    var enabledTabs = [];

    if(props.user.access.indexOf('defaultTabs')<0){
      for(let i=0; i<allTabs.length; i++){      
        var index = props.user.access.indexOf(allTabs[i]);

        if(index>=0){
          enabledTabs.push(allTabs[i]);
        }
      }
    }
    else{
      enabledTabs = props.compSettings.defaultPortTabs;
    }
    //June 2022, this fixes a bug meant for this item: April 2021 - we want to hide some visualizations altogether
    enabledTabs = enabledTabs.filter(tab =>  
        tab !== 'holdSumm' && 
        tab !== 'aum' && 
        tab !== 'growth' && 
        tab !== 'histMarkVal' && 
        tab !== 'grpApp' && 
        tab !== 'markVal')

    var portTabOrder = [];
    
    if(this.props.compSettings.portTabOrder)
      portTabOrder = this.props.compSettings.portTabOrder.split('|');

    enabledTabs = enabledTabs.sort((a,b)=>{
      return portTabOrder.indexOf(a)-portTabOrder.indexOf(b);
    });

    var disabled = false;
    if(props.compSettings.portHomeTab==='grpApp')
      disabled = true;

    var homeIndex = enabledTabs.indexOf(props.compSettings.portHomeTab);
    if(homeIndex<0)
      homeIndex=0;

    var goToIndex = enabledTabs.indexOf(props.user.goToVisualization || '');
    if (goToIndex>=0)
      homeIndex=goToIndex;

    var selectedPort = {displayName:"Select Portfolio", account:'', default:true, accountAnnotation:''};
    // var defaultAcctLS = localStorage.getItem('defaultAcct');
    
    if(this.props.user.defaultAcct)
      selectedPort = this.props.user.defaultAcct  //!= null ? this.props.user.defaultAcct : (defaultAcctLS != null ? JSON.parse(defaultAcctLS) : {account: ''});

      if (String(selectedPort.displayName).indexOf(' - '+this.props.user.defaultAcct.account) === -1 && (this.props.user.role === 'advisor' || this.props.user.role === 'admin')) selectedPort.displayName += (' - '+this.props.user.defaultAcct.account);

    this.state = {
      tabSelected:homeIndex,
      portfolios:[],
      enabledTabs:enabledTabs,
      portDropDisabled:disabled,
      currentUser:this.props.user,
      selectedPort:selectedPort,
      initialLoad:true,
      accountAnnotation:selectedPort.accountAnnotation,
      includeConsolidated: false,
    }

    this.getPortfolios = this.getPortfolios.bind(this);
    this.getAnnotationType = this.getAnnotationType.bind(this);
    this.getClassificationGroupingString = this.getClassificationGroupingString.bind(this);

  }

  getStyleSettings(cb) {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      'userDef1',
      'userDef2',
      'userDef3',
      'userDef4',
      'userDef5',
      'showConsolidated',
      'acctOverviewClassificationGrouping',
      'acctOverviewPieBorderColor',
      'acctOverviewPieBorderWidth',
      'acctOverviewPieGradient',
      'acctPeriod1',
      'acctPeriod2',
      'acctPeriod3',
      'acctPeriodMult',
      'acctDropPeriods',
      'showAcctSummIrr',
      'showAcctSummTwr',
      'showIncome',
      'showInvstGL',
      'showFeesPaid',
      'accountSummaryshowNetCW',
      'accountSummaryShowBenefit', 
      'accountSummaryShowTransfers',
      'acctsummDisplayConsolidatedPerformance',
      'chartShowAsOf',
      'chartEnableFeeSelect',
      'chartDefaultFees',
      'showConsolidated',
      'perfHistIrr',//perf overview settings
      'enablePerfReturnSelect',
      'showPerfStart',
      'showPerfBench',
      'showPerfMTD',
      'showPerfQTD',
      'showPerfPrevQtr',
      'showPerf3Month',
      'showPerf6Month',
      'showPerfYTD',
      'showPerfPrevYear',
      'showPerfYear',
      'showPerf3Year',
      'showPerf5Year',
      'showPerf7Year',
      'showPerf10Year',
      'showPerf15Year',
      'showPerf20Year',
      'showPerf25Year',
      'showPerfITD',
      'showTwrBench',
      'showTwrMTD',
      'showTwrQTD',
      'showTwrPrevQtr',
      'showTwr3Month',
      'showTwr6Month',
      'showTwrYTD',
      'showTwrPrevYear',
      'showTwrYear',
      'showTwr3Year',
      'showTwr5Year',
      'showTwr7Year',
      'showTwr10Year',
      'showTwr15Year',
      'showTwr20Year',
      'showTwr25Year',
      'showTwrITD',
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
    .then(response => {

      if (response.data.styleSettings.acctOverviewPieGradient) {
        switch (response.data.styleSettings.acctOverviewPieGradient){
          case 'Round':
            response.data.styleSettings.acctOverviewPieGradient = 'roundedBevel'
            break
          case 'Sharp':
            response.data.styleSettings.acctOverviewPieGradient = 'sharpBevel'
            break
          case 'None':
            response.data.styleSettings.acctOverviewPieGradient = 'none'
            break
          default:
            response.data.styleSettings.acctOverviewPieGradient = 'none'
        }
      }
      response.data.styleSettings.acctOverviewClassificationGrouping = this.getClassificationGroupingString(response.data.styleSettings.acctOverviewClassificationGrouping)
      
      response.data.styleSettings.acctPeriod1 = this.getDateCode(response.data.styleSettings.acctPeriod1)
      response.data.styleSettings.acctPeriod2 = this.getDateCode(response.data.styleSettings.acctPeriod2)
      response.data.styleSettings.acctPeriod3 = this.getDateCode(response.data.styleSettings.acctPeriod3)
      response.data.styleSettings.acctPeriodMult = this.getDateCode(response.data.styleSettings.acctPeriodMult)

      var newArray = []
      response.data.styleSettings.acctDropPeriods.split('|').forEach(p => {
        newArray.push(this.getDateCode(p))
      })
      response.data.styleSettings.acctDropPeriods = newArray
      //response.data.styleSettings.perfHistIrr = false
      //response.data.styleSettings.enablePerfReturnSelect = true


      this.setState({styleSettings: response.data.styleSettings}, () => {
        cb()
      })
    })
    .catch(err => {
        console.error(err);
    })
}

getDateCode (dateString){
  switch (dateString){
    case 'Month to Date':
      return 'mtd';
    case 'Quarter to Date':
      return 'qtd';
    case 'Previous Quarter':
      return 'pqtr'
    case 'Previous 3 Months':
      return '3mo'
    case 'Previous 6 Months':
      return '6mo'
    case 'Year to Date':
      return 'ytd'
    case 'Previous Year':
      return 'pyr'
    case 'Last 1 Year':
      return 'l1y'
    case 'Last 3 Years':
      return 'l3y'
    case 'Last 5 Years':
      return 'l5y'
    case 'Last 7 Years':
      return 'l7y'
    case 'Last 10 Years':
      return 'l10y'
    case 'Last 15 Years':
      return 'l15y'
    case 'Last 20 Years':
      return 'l20y'
    case 'Last 25 Years':
      return 'l25y'
    case 'Inception to Date':
      return 'itd'
    case 'Hidden':
      return ''
    default:
      return ''
  }

}

getClassificationGroupingString(stringIn){
  stringIn = stringIn.replace(/Asset Class/g, 'assetAllocation')
  stringIn = stringIn.replace(/Sector/g, 'sector')
  stringIn = stringIn.replace(/Securities/g, 'securityName')
  stringIn = stringIn.replace(/ > /g, ',')

  return stringIn
}

  handleSelect = (e) => {
    var currTab = this.state.enabledTabs[e.selected];
    var selectedPort = this.state.selectedPort;
    var ports = this.state.portfolios.slice();
    var index = ports.findIndex(port=>{
      return port.consolidated===true;
    });

    if(index>=0 && currTab!=='equityOver' && currTab!=='tranSumm' && currTab!=='rgain' && currTab!=='acctSumm' && currTab!=='perfOver' && currTab!=='markVal' && currTab!=='growth' && currTab!=='aum' && currTab!=='holdSumm' && currTab!=='holds' && currTab!=='fiHolds'){
      ports.splice(0,1);
      if(ports.length>0 && this.state.selectedPort.consolidated)
        selectedPort = ports[0];
      else
        selectedPort = this.state.selectedPort
    }
    else if(index<0 && (currTab==='equityOver' || currTab==='tranSumm' || currTab==='rgain' || currTab==='acctSumm' || currTab==='perfOver' || currTab==='markVal' || currTab==='growth' || currTab==='aum' || currTab==='holdSumm' || currTab==='holds' || currTab==='fiHolds')
                    && this.state.includeConsolidated === true){
      ports.splice(0, 0, {displayName:
        (this.state.currentUser != null && this.state.currentUser.defaultAcct != null 
            && this.state.currentUser.defaultAcct.household != null && (this.props.user.role === 'advisor' || this.props.user.role === 'admin') ? this.state.currentUser.defaultAcct.household + ' - ' : '')
            +"Consolidated", consolidated:true, account:''});
      if(this.state.styleSettings.showConsolidated && this.state.initialLoad)
        selectedPort = ports[0];
    }
    else if(this.state.initialLoad && this.props.user.defaultAcct)
      selectedPort=this.props.user.defaultAcct;

    if(currTab==='grpApp')
      this.setState({portDropDisabled:true});
    else
      this.setState({portDropDisabled:false});

    var accountAnnotation = this.getAnnotationType(selectedPort)
    this.setState({ tabSelected: e.selected, portfolios:ports, selectedPort:selectedPort, accountAnnotation:accountAnnotation });
  }

  onPortChange = (e) =>{
    var selected = e.target.value

    this.getAnnotations((selected), (annotations) => {
      selected.annotation = annotations.annotation
      selected.allAccountsAnnotation = annotations.allAccountsAnnotation
      selected.allGroupsAnnotation = annotations.allGroupsAnnotation
      selected.allEntitiesAnnotation = annotations.allEntitiesAnnotation

      var accountAnnotation = this.getAnnotationType(selected)

      this.setState({selectedPort:selected,accountAnnotation:accountAnnotation});
    })
  }

  componentDidMount(){
    this.getStyleSettings(() => {
      this.getPortfolios();
    });
  }

  componentDidUpdate(){
    if(this.props.user!==this.state.currentUser){
      this.setState({currentUser:this.props.user}, ()=>{this.getPortfolios()});
    }
  }

  getPortfolios(){
    var house = '';
    if(this.props.user.defaultAcct && this.props.user.defaultAcct.household){
      house = this.props.user.defaultAcct.household;
    }
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var payload ={
      company:this.props.user.company,
      household:house,
      user:this.props.user.user
    }
    axios.post('api/portfolios', payload, {headers}).then(response=>{
      if(response.data.code===200){
        var ports = response.data.portfolios;
        var selected = {displayName:"Select Portfolio", account:'', default:true, accountAnnotation:'' }
        if(ports.length>0){

          ports.forEach(port => {
            port.allEntitiesAnnotation = this.state.currentUser.defaultAcct.allEntitiesAnnotation
            port.allGroupsAnnotation = this.state.currentUser.defaultAcct.allGroupsAnnotation
            port.allAccountsAnnotation = this.state.currentUser.defaultAcct.allAccountsAnnotation
          })
          selected=ports[0];
        }
          

        var currTab = this.state.enabledTabs[this.state.tabSelected];

        //if((currTab==='acctSumm' || currTab==='perfOver' || currTab==='markVal' || currTab==='growth' || currTab==='aum') && house==='' && ports.length>0){
        if((currTab==='equityOver' || currTab==='tranSumm' || currTab==='rgain' || currTab==='acctSumm' || currTab==='perfOver' || currTab==='markVal' || currTab==='growth' || currTab==='aum' || currTab==='holdSumm' || currTab==='holds' || currTab==='fiHolds') && ports.length>1){
          ports.splice(0, 0, {displayName:(this.state.currentUser != null && this.state.currentUser.defaultAcct != null 
            && this.state.currentUser.defaultAcct.household != null && (this.props.user.role === 'advisor' || this.props.user.role === 'admin') ? this.state.currentUser.defaultAcct.household + ' - ' : '')
            +"Consolidated", consolidated:true, account:'', accountAnnotation:this.state.currentUser.defaultAcct.householdAnnotation, allEntitiesAnnotation:this.state.currentUser.defaultAcct.allEntitiesAnnotation,
            allGroupsAnnotation:this.state.currentUser.defaultAcct.allGroupsAnnotation, allAccountsAnnotation:this.state.currentUser.defaultAcct.allAccountsAnnotation
          });
          this.setState({includeConsolidated: true})
        }

        //if(this.state.initialLoad && this.props.compSettings.showConsolidated && !this.props.user.defaultAcct && ports.length>0)
        if(this.state.initialLoad && (this.state.styleSettings.showConsolidated || this.props.user.clickedHousehold)  && ports.length>1)
          selected=ports[0];
        else if(this.state.initialLoad && this.props.user.clickedHousehold && this.props.user.defaultAcct)
          selected=ports[0]; 
        else if(this.state.initialLoad && this.props.user.defaultAcct)
          selected=this.props.user.defaultAcct;
        else if(!this.state.initialLoad)
          selected = this.state.selectedPort; 

        this.getAnnotations((selected), (annotations) => {
          selected.annotation = annotations.annotation
          selected.allAccountsAnnotation = annotations.allAccountsAnnotation
          selected.allGroupsAnnotation = annotations.allGroupsAnnotation
          selected.allEntitiesAnnotation = annotations.allEntitiesAnnotation

          var accountAnnotation = this.getAnnotationType(selected)
  
          this.setState({portfolios:ports, selectedPort:selected, initialLoad:false,accountAnnotation:accountAnnotation });
        })
        
        

        
      }
      else{
        alert("Could not get portfolios for account.");
      }
    }).catch(err=>{
      console.log(err);
    });
  }

  getAnnotations(selected, callback){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios.get('api/getAnnotations?company='+this.props.user.company +'&entity='+selected.account, {headers}).then(response=>{
      if(response.data.code===200 && response.data.annotations.length > 0){
        callback(response.data.annotations[0])        
      }
      else{
        callback({entity: null, annotation: null, allEntitiesAnnotation: null, allGroupsAnnotation: null, allAccountsAnnotation: null})        
      }
    }).catch(err=>{
      console.log(err);
    });
  }

  getAnnotationType(selected) {
    var accountAnnotation
    if(selected.allEntitiesAnnotation && !selected.annotation){
      accountAnnotation = selected.allEntitiesAnnotation
    }
    else if(selected.consolidated && selected.allGroupsAnnotation && !selected.annotation){
      accountAnnotation = selected.allGroupsAnnotation
    }
    else if (!selected.annotation && !selected.consolidated) {
      accountAnnotation = selected.allAccountsAnnotation
    }
    else {
      accountAnnotation = selected.annotation
    }

    return accountAnnotation
  }

  getTab = (tab, key)=>{
    if(tab==='acctSumm'){
      return (
        <TabStripTab title='Account Summary' key={key}>
          <AccountSummaryDashboard user={this.props.user} compSettings={this.props.compSettings} styleSettings={this.state.styleSettings} portfolio={this.state.selectedPort} idToken={this.props.idToken}/>
        </TabStripTab>
      );
    }
    else if(tab==='equityOver'){
      return(
      <TabStripTab title="Account Overview" key={key}>
          <EquityOverview user={this.props.user} compSettings={this.props.compSettings} styleSettings={this.state.styleSettings} portfolio={this.state.selectedPort} household={this.props.user.defaultAcct.household}  idToken={this.props.idToken}/>
      </TabStripTab>
      );
    }
    else if(tab==='perfOver'){
      return(
      <TabStripTab title="Performance Overview" scrollable={true} key={key}>
        <PerformanceOverview user={this.props.user} compSettings={this.props.compSettings} styleSettings={this.state.styleSettings} portfolio={this.state.selectedPort} idToken={this.props.idToken}/>
      </TabStripTab>
      );
    }
    else if(tab==='holds'){
      return(
      <TabStripTab title="Holdings" scrollable={true} key={key}>
        <HoldingsDashboard user={this.props.user} compSettings={this.props.compSettings} styleSettings={this.state.styleSettings} portfolio={this.state.selectedPort} household={this.props.user.defaultAcct.household} idToken={this.props.idToken}/>
      </TabStripTab>
      );
    }
    else if(tab==='fiHolds'){
      return(
      <TabStripTab title="Fixed Income Holdings" scrollable={true} key={key}>
        <FixedIncomeHoldingsDashboard user={this.props.user} compSettings={this.props.compSettings} styleSettings={this.state.styleSettings} portfolio={this.state.selectedPort} household={this.props.user.defaultAcct.household} idToken={this.props.idToken}/>
      </TabStripTab>
      );
    }
    else if(tab==='markVal'){
      return(
      <TabStripTab title="Market Value" scrollable={true} key={key}>
        <MarketValueDashbard user={this.props.user} compSettings={this.props.compSettings} portfolio={this.state.selectedPort} idToken={this.props.idToken}/>
      </TabStripTab>
      );
    }
    else if(tab==='grpApp'){
      return(
      <TabStripTab title="Group Appraisal" scrollable={true} key={key}>
        <GroupAppraisalDashboard user={this.props.user} portfolios={this.state.portfolios} compSettings={this.props.compSettings} portfolio={this.state.selectedPort} idToken={this.props.idToken}/>
      </TabStripTab>
      );
    }
    else if(tab==='histMarkVal'){
      return(
      <TabStripTab title="Historical Market Value" scrollable={true} key={key}>
        <HistoricalMVDashboard user={this.props.user} compSettings={this.props.compSettings} portfolio={this.state.selectedPort} idToken={this.props.idToken}/>
      </TabStripTab>
      );
    }
    else if(tab==='tranSumm'){
      return(
      <TabStripTab title="Transaction Summary" scrollable={true} key={key}>
        <TransactionSummary user={this.props.user} compSettings={this.props.compSettings} portfolio={this.state.selectedPort} household={this.props.user.defaultAcct.household} idToken={this.props.idToken}/>
      </TabStripTab>
      );
    }
    else if(tab==='growth'){
      return(
      <TabStripTab title="Performance Growth" scrollable={true} key={key}>
        <GrowthDashboard user={this.props.user} compSettings={this.props.compSettings} portfolio={this.state.selectedPort} idToken={this.props.idToken}/>
      </TabStripTab>
      );
    }
    else if(tab==='aum'){
      return(
      <TabStripTab title="Account AUM" scrollable={true} key={key}>
        <AUMDashboard user={this.props.user} compSettings={this.props.compSettings} portfolio={this.state.selectedPort} idToken={this.props.idToken}/>
      </TabStripTab>
      );
    }
    else if(tab==='holdSumm'){
      return(
      <TabStripTab title="Holdings Summary" scrollable={true} key={key}>
        <HoldingsSummaryDashboard user={this.props.user} compSettings={this.props.compSettings} styleSettings={this.state.styleSettings} portfolio={this.state.selectedPort} household={this.props.user.defaultAcct.household} idToken={this.props.idToken}/>
      </TabStripTab>
      );
    }
    else if(tab==='rgain'){
      return(
      <TabStripTab title="Realized Gains" scrollable={true} key={key}>
        <RGainDashboard user={this.props.user} compSettings={this.props.compSettings} portfolio={this.state.selectedPort} household={this.props.user.defaultAcct.household} idToken={this.props.idToken}/>
      </TabStripTab>
      );
    }

  }


  render() {


    var equityOverIndex = this.state.enabledTabs.indexOf('equityOver');
    // var tranSummIndex = this.state.enabledTabs.indexOf('tranSumm');
    var tabStyle = {}
    if (this.state.tabSelected === equityOverIndex)
      tabStyle = {minWidth: '1400px'}
    return(<>
        {!this.state.initialLoad && <div className={this.state.tabSelected === this.state.enabledTabs.indexOf('perfOver') ? 'portfolioPerfOver' : 'portfolio'}>
          {this.props.user.user!==this.props.loggedInUser.user && <div><strong className='bgText' style={{textAlign:'center'}}>Viewing {this.props.user.user}'s portfolio</strong></div>}
          <Button style={{marginRight:'10px'}} onClick={this.props.onGotoHouseholdList}>Back</Button>
          <label className='bgText'>Account: </label>
          <DropDownList
            disabled={this.state.portDropDisabled}
            data={(this.props.user.role === 'advisor' || this.props.user.role === 'admin') ? [...this.state.portfolios].map(item => { 
                let obj = {...item}; 
                obj.displayName = item.displayName + (item.account !== '' ? ' - ' +item.account : ''); 
                return obj;
            }) : this.state.portfolios}
            textField="displayName"
            style={{width:'40vw'}}
            value={this.state.selectedPort}     
            onChange={this.onPortChange}
          />
          
          {this.state.accountAnnotation !=='' && (
          <blockquote style={{fontSize: '12px', marginLeft: '128px', marginTop:'6px', marginBottom:'6px' }} className='bgText'   >{this.state.accountAnnotation}</blockquote>
          )}
          
          <TabStrip style={tabStyle} selected={this.state.tabSelected} onSelect={this.handleSelect}>
              {this.state.enabledTabs.map((tab, key)=>{
                // if (//April 2021 - we want to hide some visualizations altogether
                //   tab !== 'holdSumm' && 
                //   tab !== 'aum' && 
                //   tab !== 'growth' && 
                //   tab !== 'histMarkVal' && 
                //   tab !== 'grpApp' && 
                //   tab !== 'markVal'
                // ){
                  return this.getTab(tab, key);
                // }
              })}
          </TabStrip>
        </div>}
        </>
    );
  }

  exportPDFWithComponent = () => {
    this.pdfExportComponent.save();
  }

}

export default Portfolio;