import React, { Component } from "react";
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import axios from 'axios';
import { process } from '@progress/kendo-data-query';
import GridLoading from '../../Loaders/GridLoading';
import MapAccountPopUp from './MapAccountPopUp';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Button } from '@progress/kendo-react-buttons';

class UnmappedAccounts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      unmapped: [],
      result: { data: [], total: 0 },
      resultExport: { data: [], total: 0 },
      households: [],
      loading: true,
      dataState: { skip: 0, take: 25, sort: [{ field: 'portfolio_Id', dir: 'asc' }] }
    }
  }

  componentDidMount() {
    this.getUnmappedAccts();
    this.getHouseholds();
  }

  refresh = () => {
    this.getUnmappedAccts();
  }

  getUnmappedAccts = () => {
    this.setState({ loading: true });
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios.get('api/unmappedAccts?company=' + this.props.user.company, { headers }).then(response => {
      this.setState({
        unmapped: response.data.unmapped,
        result: process(response.data.unmapped, this.state.dataState),
        resultExport: process(response.data.unmapped, { ...this.state.dataState, skip: 0, take: response.data.unmapped.length }),
        loading: false
      });
    }).catch(err => {
      console.log(err);
      this.setState({ unmapped: [], loading: false });
    });
  }

  deleteUnmappedData = (portCode) => {
    var conf = window.confirm("Are you sure you want to permanently remove " + portCode + " and it's data?");

    if (conf) {
      var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

      var payload = {
        company: this.props.user.company,
        port: portCode
      }

      axios.post("api/deleteUnmapped", payload, { headers }).then(response => {
        if (response.data.code === 200) {
          this.getUnmappedAccts();
        }
        else {
          alert("An error has occurred not all data has been deleted from this account. Please try again.");
        }
      }).catch(err => console.log(err));
    }
  }

  getHouseholds = () => {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios.get("api/households?company=" + this.props.user.company, { headers }).then(response => {
      if (response.data.code === 200) {
        this.setState({ households: response.data.households });
      }
      else {
        alert("An error occured while fetching the Household list.");
        this.setState({ households: [] });
      }
    }).catch(error => console.log(error));

  }

  changeDataState = (e) => {
    this.setState({
      dataState: e.dataState,
      result: process(this.state.unmapped, e.dataState),
      resultExport: process(this.state.unmapped, { ...e.dataState, skip: 0, take: this.state.unmapped.length })
    });
  }

  createMapCell = (cell) => {
    return (
      <td>
        <MapAccountPopUp households={this.state.households} refresh={this.refresh} idToken={this.props.idToken} company={this.props.user.company} account={cell.dataItem.portfolio_Id} displayName={cell.dataItem.displayName} button={<Button className='docButton'>Add Mapping</Button>} />
        <Button className='docButton' onClick={(e) => this.props.previewUnmapped(cell.dataItem.portfolio_Id)}>Preview Data</Button>
        <Button className='docButton' icon='delete' onClick={(e) => this.deleteUnmappedData(cell.dataItem.portfolio_Id)}>Delete Account Data</Button>
      </td>
    );
  }

  _export;
  export = () => {
    this._export.save();
  }

  render() {
    return (

      <div>
        <br />
        {!this.state.loading && <strong style={{ color: 'red', marginLeft: '5px' }}>{this.state.unmapped.length} Unmapped Accounts (Most Recent Position File)</strong>}
        <br />
        <span className='acctMappingList'>
          <ExcelExport data={this.state.resultExport.data} fileName={'Unmapped_Acccounts_Export.xlsx'} ref={(exporter) => { this._export = exporter; }}>
            <ExcelExportColumn field='portfolio_Id' title='Portfolio Code' width={150} />
            <ExcelExportColumn field='displayName' title='Portfolio Name' width={300} />
          </ExcelExport>
          <Grid data={this.state.result}
            filterable={true}
            sortable={true}
            pageable={true}
            onDataStateChange={this.changeDataState}
            {...this.state.dataState}>
            <GridColumn headerClassName='gridHeader' field='portfolio_Id' title='Portfolio Code' />
            <GridColumn headerClassName='gridHeader' field='displayName' title='Portfolio Name' />
            <GridColumn headerClassName='gridHeader' filterCell={(props) => { return (<></>) }} cell={(props) => this.createMapCell(props)} />
            <GridToolbar>
              <div>
                <Button icon='refresh' title='Refresh' onClick={(e) => this.getUnmappedAccts()}>{this.state.refreshText}</Button>
                <Button icon="excel" title='Export to Excel' onClick={this.export}>Export</Button>
              </div>
            </GridToolbar>
          </Grid>
          {this.state.loading && <GridLoading />}
        </span>
      </div>
    );
  }

}

export default UnmappedAccounts;