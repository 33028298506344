import React, { Component } from "react";
import { Grid, GridColumn, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import GridLoading from '../../Loaders/GridLoading';
import { parseDate } from '@telerik/kendo-intl';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Button } from "@progress/kendo-react-buttons";
import axios from 'axios';

const ADJUST_PADDING = 4;
const COLUMN_MIN = 4;

class HoldingsSummary extends Component {

  constructor(props) {
    super(props);

    this.minGridWidth = 0;

    var columns = [];

    // columns.splice(1,0,{field:'percentage' , title: '% of Portfolio', format:"{0:p2}", headerClass:'gridHeader', minWidth:150});
    // columns.splice(1,0,{field:'unrealized' , title: 'Unrealized Gain/Loss', format:"{0:c}", headerClass:'gridHeader', minWidth:150});
    // columns.splice(1,0,{field:'estAnnIncome' , title: 'Annual Income', format:"{0:c}", headerClass:'gridHeader', minWidth:150});
    // columns.splice(1,0,{field:'marketValue' , title: 'Market Value', format:"{0:c}", headerClass:'gridHeader', minWidth:150});
    // columns.splice(1,0,{field:'MaturityDate' , title: 'Maturity Date', format:"{0:d}", headerClass:'gridHeader', minWidth:150});
    // columns.splice(1,0,{field:'InterestDividendRate' , title: 'Coupon', format:"{0:p2}", headerClass:'gridHeader', minWidth:100});  
    // columns.splice(0,0,{field:'quantity' , title: 'Par', headerClass:'gridHeader', minWidth:100});
    // columns.splice(0,0,{field:'SPRating' , title: 'S&P Rating', headerClass:'gridHeader', minWidth:100});
    // columns.splice(0,0,{field:'securitySymbol' , title: 'Cusip', headerClass:'gridHeader', minWidth:150});

    //columns.splice(0,0,{field:'securityName' , title: 'Security Name', headerClass:'gridHeader', minWidth:150});
    //columns.splice(1, 0, { field: 'percentage', title: 'Total %', format: "{0:p2}", headerClass: 'gridHeader', minWidth: 150 });
    if (props.compSettings.ShowHoldingsSummUser5)
      columns.splice(0, 0, { field: 'userDef5', title: this.props.styleSettings.userDef5, headerClass: 'gridHeader', minWidth: 150 });
    if (props.compSettings.ShowHoldingsSummUser4)
      columns.splice(0, 0, { field: 'userDef4', title: this.props.styleSettings.userDef4, headerClass: 'gridHeader', minWidth: 150 });
    if (props.compSettings.ShowHoldingsSummUser3)
      columns.splice(0, 0, { field: 'userDef3', title: this.props.styleSettings.userDef3, headerClass: 'gridHeader', minWidth: 150 });
    if (props.compSettings.ShowHoldingsSummUser2)
      columns.splice(0, 0, { field: 'userDef2', title: this.props.styleSettings.userDef2, headerClass: 'gridHeader', minWidth: 150 });
    if (props.compSettings.ShowHoldingsSummUser1)
      columns.splice(0, 0, { field: 'userDef1', title: this.props.styleSettings.userDef1, headerClass: 'gridHeader', minWidth: 150 });
    if (props.compSettings.ShowHoldingsSummSector)
      columns.splice(0, 0, { field: 'sector', title: 'Sector', headerClass: 'gridHeader', minWidth: 150 });
    if (props.compSettings.ShowHoldingsSummIndustry)
      columns.splice(0, 0, { field: 'industry', title: 'Industry', headerClass: 'gridHeader', minWidth: 150 });
    if (props.compSettings.ShowHoldingsSummAssetAllocation)
      columns.splice(0, 0, { field: 'assetAllocation', title: 'Asset Allocation', headerClass: 'gridHeader', minWidth: 150 });
    if (props.compSettings.ShowHoldingsSummPercentage)
      columns.splice(0, 0, { field: 'percentage', title: 'Total %', format: "{0:p2}", headerClass: 'gridHeader', minWidth: 75 });
    if (props.compSettings.ShowHoldingsSummPortfolio)
      columns.splice(0, 0, { field: 'Portfolio', title: 'Portfolio', headerClass: 'gridHeader', minWidth: 300 });
    if (props.compSettings.ShowHoldingsSummMarketValue)
      columns.splice(0, 0, { field: 'marketValue', title: 'Market Value', format: "{0:c}", headerClass: 'gridHeader', minWidth: 150 });
    if (props.compSettings.ShowHoldingsSummUnrealized)
      columns.splice(0, 0, { field: 'unrealized', title: 'Unrealized G/L', format: "{0:c}", headerClass: 'gridHeader', minWidth: 150 });
    if (props.compSettings.ShowHoldingsSummTotalCost)
      columns.splice(0, 0, { field: 'totalCost', title: 'Total Cost', format: "{0:c}", headerClass: 'gridHeader', minWidth: 150 });
    if (props.compSettings.ShowHoldingsSummQuantity)
      columns.splice(0, 0, { field: 'quantity', title: 'Quantity', format:"{0:n2}", headerClass: 'gridHeader', minWidth: 120 });
    if (props.compSettings.ShowHoldingsSummSecuritySymbol)
      columns.splice(0, 0, { field: 'securitySymbol', title: 'Symbol', headerClass: 'gridHeader', minWidth: 135 });
    if (props.compSettings.ShowHoldingsSummSecurityName)
      columns.splice(0, 0, { field: 'securityName', title: 'Security Name', headerClass: 'gridHeader', minWidth: 350 });
    //columns.splice(0, 0, { field: 'Portfolio', title: 'Account', headerClass: 'gridHeader', minWidth: 150 });




    var defaultAggs = [{ field: 'quantity', aggregate: 'sum' },
    { field: 'marketValue', aggregate: 'sum' },
    { field: 'totalCost', aggregate: 'sum' },
    { field: 'percentage', aggregate: 'sum' }];

    //var defaultGroup = [{ field: "Portfolio", aggregates: defaultAggs }];
    var defaultGroup = [];
    //defaultGroup.push({ field: "assetAllocation", aggregates: defaultAggs });
    if (props.compSettings.holdingsSummGrp1)
      defaultGroup.push({ field: props.compSettings.holdingsSummGrp1, aggregates: defaultAggs });
    if (props.compSettings.holdingsSummGrp2)
      defaultGroup.push({ field: props.compSettings.holdingsSummGrp2, aggregates: defaultAggs });
    if (props.compSettings.holdingsSummGrp3)
      defaultGroup.push({ field: props.compSettings.holdingsSummGrp3, aggregates: defaultAggs });
    if (props.compSettings.holdingsSummGrp1 || props.compSettings.holdingsSummGrp2 || props.compSettings.holdingsSummGrp3)
      defaultGroup.push({ field: "securitySymbol", aggregates: defaultAggs });


    this.state = {
      accountHoldings: [],
      result: [],
      dataState: { sort: [{ field: 'Portfolio', dir: 'desc' }], group: defaultGroup },
      aggregates: defaultAggs,
      portfolio: this.props.portfolio,
      dates: [],
      selectedDate: '',
      columns: columns,
      loading: true,
      IndvTaxLots: 0,
      styleSettings: {
        chartShowAsOf: null
      }
    }

    this.getAccountHoldings = this.getAccountHoldings.bind(this);
    this.getHoldDates = this.getHoldDates.bind(this);
    this.changeDataState = this.changeDataState.bind(this);
    this.formatCurrency = this.formatCurrency.bind(this);
    this.formatPercent = this.formatPercent.bind(this);
    this.cellRender = this.cellRender.bind(this);
    this.headerCellRender = this.headerCellRender.bind(this);
    this.getDate = this.getDate.bind(this);
    this.getSpaceChar = this.getSpaceChar.bind(this);
  }

  componentDidMount() {
    this.getStyleSettings(() =>{
      this.getAccountHoldings(null, () => {
        this.setMinGridWidth();
      });
      this.getHoldDates();
    })
    
  }

  componentDidUpdate() {
    if (this.props.portfolio !== this.state.portfolio) {
      this.setState({ portfolio: this.props.portfolio }, () => {
        this.getAccountHoldings(null, () => {
          this.setMinGridWidth();
        })
      });
    }
  }

  getStyleSettings(cb) {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      "chartShowAsOf"
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
      .then(response => {
        this.setState({ styleSettings: response.data.styleSettings }, () => {
          cb()
        });
      })
      .catch(err => {
        console.error(err);
      })


  }

  setMinGridWidth = () => {
    this.grid = document.querySelector('.k-grid');
    window.addEventListener('resize', this.handleResize);
    this.minGridWidth = 0;
    this.state.columns.map(item => this.minGridWidth += item.minWidth);
    if (this.state.dataState.group)
      this.minGridWidth += 32 * this.state.dataState.group.length;
    this.setState({
      gridCurrent: this.grid.offsetWidth,
      setMinWidth: this.grid.offsetWidth < this.minGridWidth
    });
  }

  handleResize = () => {
    if (this.grid.offsetWidth < this.minGridWidth && !this.state.setMinWidth) {
      this.setState({
        setMinWidth: true
      });
    } else if (this.grid.offsetWidth > this.minGridWidth) {
      this.setState({
        gridCurrent: this.grid.offsetWidth,
        setMinWidth: false
      });
    }
  }

  setWidth = (minWidth) => {
    let width = this.state.setMinWidth ? minWidth : minWidth + (this.state.gridCurrent - this.minGridWidth) / this.state.columns.length;
    if (width >= COLUMN_MIN)
      width = width - ADJUST_PADDING;
    return width;
  }

  getAccountHoldings(date, cb) {
    this.setState({ loading: true });
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var unmapped = false;
    if (this.props.user.unmapped) {
      unmapped = true
    }


    if (date === null)
      date = '';

    var port = this.state.portfolio.account
    if (this.state.portfolio.consolidated) {
      port = this.props.household
    }

    axios.get(`api/holdingsSummary`, {
      method: 'GET',
      params: {
        company: this.props.user.company,
        role: this.props.user.role,
        port: port,
        consolidated: this.state.portfolio.consolidated,
        date: date,
        unmapped: unmapped
      },
      headers: headers,
    })
      .then(response => {
        if (response.data.code === 200) {
          var IndvTaxLots = 0;
          for (let i = 0; i < response.data.data.length; i++) {
            IndvTaxLots = response.data.data[i].IndvTaxLots;
            response.data.data[i]["Portfolio"] = response.data.data[i].displayName;
            response.data.data[i]["Type"] = response.data.data[i].securityType;
            response.data.data[i]["Asset Class"] = response.data.data[i].assetAllocation;
            response.data.data[i]["Sector"] = response.data.data[i].sector;
            response.data.data[i]["Industry"] = response.data.data[i].industry;
          }

          //Change the "dataState"
          var defaultAggs = [{ field: 'quantity', aggregate: 'sum' },
          { field: 'marketValue', aggregate: 'sum' },
          { field: 'totalCost', aggregate: 'sum' },
          { field: 'percentage', aggregate: 'sum' },
          { field: 'SecurityCount', aggregate: 'min' },
          { field: 'SecurityCount', aggregate: 'max' },
          { field: 'IndvTaxLots', aggregate: 'max' }
          ];

          //var defaultGroup = [{ field: "Portfolio", aggregates: defaultAggs }];
          var defaultGroup = [];
          //defaultGroup.push({ field: "assetAllocation", aggregates: defaultAggs });
          if (this.props.compSettings.holdingsSummGrp1)
            defaultGroup.push({ field: this.props.compSettings.holdingsSummGrp1, aggregates: defaultAggs });
          if (this.props.compSettings.holdingsSummGrp2)
            defaultGroup.push({ field: this.props.compSettings.holdingsSummGrp2, aggregates: defaultAggs });
          if (this.props.compSettings.holdingsSummGrp3)
            defaultGroup.push({ field: this.props.compSettings.holdingsSummGrp3, aggregates: defaultAggs });
          if (this.props.compSettings.holdingsSummGrp1 || this.props.compSettings.holdingsSummGrp2 || this.props.compSettings.holdingsSummGrp3)
            defaultGroup.push({ field: "securitySymbol", aggregates: defaultAggs });
          // if (IndvTaxLots.toString()==="1")
          // {
          //   defaultGroup.push({field:"securityName", aggregates:defaultAggs});
          // }

          //setup the table grouping/totals
          this.setState({ dataState: { sort: [{ field: 'Portfolio', dir: 'desc' }], group: defaultGroup }, aggregates: defaultAggs });



          this.setState({ accountHoldings: response.data.data, result: process(response.data.data, this.state.dataState), loading: false, IndvTaxLots: IndvTaxLots }, () => {
            this.state.result.data.forEach(dataItem => dataItem.expanded = false);
            if (cb) {
              cb();
            }
          });
        }
      }).catch(err => {
        console.log(err);
      });
  }

  getDate(sqlDate, isUTC) {
    var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
    if (d) {
      var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
      if (isUTC)
        return utc;
      else
        return d;
    }
    else
      return null;
  }

  getHoldDates() {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company: this.props.user.company,
      port: this.props.portfolio.account,
      user: this.props.user.user
    }
    axios.post('api/posDates', payload, { headers }).then(response => {
      if (response.data.code === 200) {
        if (response.data.data.length > 0)
          this.setState({ dates: response.data.data, selectedDate: response.data.data[0].fDate });
        else
          this.setState({ dates: [] });
      }
    }).catch(err => {
      console.log(err);
    });
  }

  _export;
  export = () => {
    this._export.save();
  }

  expand = () => {
    this.state.result.data.forEach(row => {
      row.expanded = true;
      this.expandChild(row);
    });
    this.forceUpdate();
  }

  expandChild = (child) => {
    if (child.items) {
      child.items.forEach(item => {
        item.expanded = true;
        this.expandChild(item);
      })
    }
  }

  collapse = () => {
    this.state.result.data.forEach(row => {
      row.expanded = false;
      this.collapseChild(row);
    });
    this.forceUpdate();
  }

  collapseChild = (child) => {
    if (child.items) {
      child.items.forEach(item => {
        item.expanded = false;
        this.collapseChild(item);
      })
    }
  }


  render() {
    return (
      <div className='accountHoldings'>
        <ExcelExport data={this.state.accountHoldings} fileName={'HoldingsSummary.xlsx'} ref={(exporter) => { this._export = exporter; }}>
          <Grid data={this.state.result}
            groupable={{ footer: 'always' }}
            className='noGroupGridLines'
            resizable={true}
            reorderable={true}
            filterable={false}
            sortable={true}
            onDataStateChange={this.changeDataState}
            onExpandChange={this.expandChange}
            expandField="expanded"
            cellRender={this.cellRender}

            headerCellRender={this.headerCellRender}
            {...this.state.dataState}>
            <GridNoRecords>
              {this.state.loading && 'Loading...'}
              {!this.state.loading && 'No records available'}
            </GridNoRecords>
            <GridToolbar>
              {this.state.styleSettings.chartShowAsOf && <><label>As Of: </label><select onChange={(e) => { this.setState({ selectedDate: e.target.value }); this.getAccountHoldings(e.target.value, () => this.setMinGridWidth()) }}>
                {this.state.dates.map((date, i) => (
                  <option key={i} value={date.fDate}>{date.fDate}</option>
                ))}
              </select></>}
              {!this.state.styleSettings.chartShowAsOf && <label>As Of: {this.state.selectedDate}</label>}
              <Button icon="excel" title='Export to Excel' onClick={this.export}>Export</Button>
              <Button title='Expand' onClick={this.expand}>Expand All</Button>
              <Button title='Collapse' onClick={this.collapse}>Collapse All</Button>
            </GridToolbar>
            {
              this.state.columns.map((column, key) => {
                return <GridColumn field={column.field} title={column.title} key={key} format={column.format} headerClassName={column.headerClass} width={this.setWidth(column.minWidth)} />
              })
            }
          </Grid>
        </ExcelExport>
        {this.state.loading && <GridLoading />}
      </div>
    );
  }

  changeDataState = (e) => {
    //this.setState(this.createAppState(e.data));

    const groups = e.dataState.group;
    if (groups) {
      groups.map(group => group.aggregates = this.state.aggregates);
    }
    var newData = process(this.state.accountHoldings, e.dataState);
    this.setState({ dataState: e.dataState, result: newData });
  }



  formatCurrency(val) {
    var formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val);

    return formatted;
  }

  formatQuantity(val) {
    var nfObject = new Intl.NumberFormat('en-US');
    var formatted = nfObject.format(val);
    return formatted;
  }

  formatPercent(val) {
    return (val * 100).toFixed(2) + "%";
  }

  getSpaceChar(lengthOfInputString) {
    let spaceString = ''
    var amountOfSpaces = 75 - lengthOfInputString
    for (let i = 0; i < amountOfSpaces; i++) {
      spaceString += String.fromCharCode(160)
    }
    return spaceString
  }

  getLengthOfStringInPixels(string) {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext("2d");
    ctx.font = "bold 10.7px arial";
    //ctx.fontWeight = 'bold'
    var pixelLength = ctx.measureText(string).width;

    return pixelLength
  }


  cellRender(tdElement, cellProps) {

    //console.log(cellProps)
    if (tdElement && tdElement.props.children && cellProps.rowType === "groupHeader") {

      var label = tdElement.props.children.props.children
      var children
      children = <>{label}<span style={{ paddingLeft: ((300.00 - this.getLengthOfStringInPixels(label[1])) + 'px') }}>
        {(this.props.compSettings.ShowHoldingsSummTotalCost) && <>Total Cost: {this.formatCurrency(cellProps.dataItem.aggregates.totalCost.sum)} | </>}
        {(this.props.compSettings.ShowHoldingsSummMarketValue) && <>Total Market Value: {this.formatCurrency(cellProps.dataItem.aggregates.marketValue.sum)} | </>}
        {(this.props.compSettings.ShowHoldingsSummPercentage) && <>Total Percentage: {((cellProps.dataItem.aggregates.percentage.sum.toFixed(4)) * 100).toFixed(2)} %</>}
      </span></>

      // let children = <span>{tdElement.props.children.props.children} count: {cellProps.dataItem.items.length}</span>
      return React.cloneElement(tdElement, tdElement.props, children);
    }

    if (cellProps.rowType === 'groupFooter') {
      return null
    }


    if (tdElement !== null) {
      var style = {};
      if (cellProps.field === 'acqDate') {
        var acqDate = new Date(cellProps.dataItem[cellProps.field])
        if (acqDate.getUTCFullYear() === 1901) {
          return (
            <td {...tdElement.props} style={style}> </td>
          );
        }
      }
      else if (cellProps.field === 'quantity' && cellProps.dataItem[cellProps.field] === 0) {
        return (
          <td {...tdElement.props} style={style}> </td>
        );
      }
      if (cellProps.field === 'quantity' || cellProps.field === 'marketValue' || cellProps.field === 'percentage'
        || cellProps.field === 'totalCost' || cellProps.field === 'unrealized' || cellProps.field === 'yield'
        || cellProps.field === 'estAnnIncome' || cellProps.field === 'appMarketPrice' || cellProps.field === 'unitCost')
        style = { textAlign: 'right' };

      return (
        <td {...tdElement.props} style={style} />
      );
    }
    else {
      return tdElement;
    }
  }

  headerCellRender(thElement, cellProps) {
    if (thElement !== null) {
      var style = {};
      if (cellProps.field === 'quantity' || cellProps.field === 'marketValue' || cellProps.field === 'percentage'
        || cellProps.field === 'totalCost' || cellProps.field === 'unrealized' || cellProps.field === 'yield'
        || cellProps.field === 'estAnnIncome' || cellProps.field === 'appMarketPrice' || cellProps.field === 'unitCost')
        style = { justifyContent: 'right' };

      return (
        <div {...thElement.props} style={style} />
      );
    }
    else {
      return thElement;
    }
  }

  expandChange = (event) => {
    event.dataItem[event.target.props.expandField] = event.value;
    event.dataItem.items.forEach(item => item.expanded = false);//set child items expanded = false
    this.forceUpdate();
  }

}

export default HoldingsSummary;