import React, { Component } from "react";
import { Button } from '@progress/kendo-react-buttons';
import {Grid, GridColumn, GridToolbar} from '@progress/kendo-react-grid';
import axios from 'axios';
import { Upload } from '@progress/kendo-react-upload';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { process } from '@progress/kendo-data-query';

class HouseholdMapping extends Component {

  constructor(props) {
    super(props);
    this.state = {
        files:[],
        previewMappings:[],
        mappings:[],
        result:[],
        dataState:{ sort:[{field:'household', dir:'asc'}], skip: 0, take: 25 },
        loading:false,
        append: false,
    }

    this.getHouseholdMappings=this.getHouseholdMappings.bind(this);
    this.clearState=this.clearState.bind(this);
  }

  componentDidMount(){
    this.getHouseholdMappings();
  }

  clearState(){
    this.setState({
        files:[],
        previewMappings:[]
    });
  };


  onAdd = (e)=>{
      this.setState({files:e.newState});
  }

  onRemove = (e)=>{
    this.setState({files:e.newState});
  }

  onStatusChange = (e)=>{
      if(e.response.response.code===200){
        e.newState[0].progress=100;
        e.newState[0].status=4;
        this.setState({previewMappings:e.response.response.mappings});
      }
      else{
        e.newState[0].progress=0;
        e.newState[0].status=0;
        e.newState[0].error = e.response.response.error;
      }
      this.setState({files:e.newState});
  }

  onBeforeUpload = (e)=>{
    e.additionalData.company=this.props.user.company;
  }

  getHouseholdMappings(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    this.setState({loading:true});
    
    var payload ={
      company:this.props.user.company,
      household:null
    }

    //axios.post('api/householdMappings?company='+this.props.user.company, {headers}).then(response=>{
    axios.post('api/householdMappingsGet' , payload , {headers}).then(response=>{
      var result = process(response.data.mappings, this.state.dataState);
      this.setState({mappings:response.data.mappings,result:result, loading:false});
    }).catch(err=>{
      console.log(err);
      this.setState({mappings:[], loading:false});
    });
  }

  uploadHouseholdMappings(e){
    
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var formData = new FormData();

    formData.append('company', this.props.user.company);
    formData.append('upload', true);
    formData.append('file' , this.state.files[0].getRawFile());

    var appendMode = false
    if (this.state.append){
      appendMode = true
      formData.append('append', true);
    }

    axios.post('api/householdMappings?company='+this.props.user.company, formData, {headers}).then(response=>{
        if(response.data.code===200){
            if (appendMode){
              var existingString = ''
              if (response.data.mappingsCount.existing > 0)
                existingString = "\n" + response.data.mappingsCount.existing + " mappings already existed"

              alert("Household mappings have been successfully uploaded.\n\n" 
                + response.data.mappingsCount.new + " mappings were added"
                + existingString)  
            }
            else{
              alert("Household mappings have been successfully uploaded.");
            }
            this.getHouseholdMappings();
            this.clearState();
        }
        else{
            alert("An error has occurred while uploading. Please try again.");
            this.getHouseholdMappings();
            this.clearState();
        }
    }).catch(err=>{
        alert("An error has occurred while uploading. Please try again.");
        this.getHouseholdMappings();
        this.clearState();
    });

  }

  renderFile = (props)=>{
    const { files } = props;

      return (
      <ul>
          {
            files.map(file =>{
            return file.error ?
            <>
            <div style={{color:'red'}}key={file.name}>
                <div>{file.name}</div>
                <div>Error: {file.error}</div>
            </div>
            </>
            :
            <div style={{color:'green'}}key={file.name}>
                <div>{file.name}</div>
                <div>{this.state.previewMappings.length} mappings found.</div>
                <Button style={{textAlign:'right', marginLeft:'10px'}} onClick={e=>this.uploadHouseholdMappings(e)}>Confirm Upload</Button>
                </div>
            })          
          }
      </ul>);
  }

  _export;
  export = () =>{
    this._export.save();
  }

  

  changeDataState = (e)=>{
    var newData = process(this.state.mappings, e.dataState);
    this.setState({dataState:e.dataState, result:newData});
  }

  render() {
    return(
        <div>
          <ExcelExport data={this.state.mappings} fileName={'HouseholdMapping_Export.xlsx'} ref={(exporter)=>{this._export = exporter;}}>
            <ExcelExportColumn field='household' title='Household'/>          
            <ExcelExportColumn field='account' title='Account'/>
            <ExcelExportColumn field='displayName' title='Account Name'/>
          </ExcelExport>



            <table className='fullWidth'>
                <tbody>
                    <tr>
                        <td><h3>Upload Tab-Delimited Household Mapping:</h3>
                        <label style={{paddingLeft:'20px'}} ><input type='checkbox' checked={this.state.append} onChange={(e)=>this.setState({append:!this.state.append})}/>Append Only</label>
                          <br/><br/>
                          <div>Format should be household [tab] account/portfolio</div>
                          {!this.state.append && <div><strong>Note:</strong> This will overwrite the current household mappings.</div>}
                          {this.state.append && <br/>}
                        </td>
                        <td style={{textAlign: 'right'}}><Upload autoUpload={true} 
                                    multiple={false} 
                                    files={this.state.files}
                                    listItemUI={this.renderFile}
                                    onAdd={this.onAdd}
                                    onRemove={this.onRemove}
                                    onStatusChange={this.onStatusChange}
                                    onBeforeUpload={this.onBeforeUpload}
                                    saveUrl={'api/householdMappings?company='+this.props.user.company}
                                    saveHeaders={{'authorization': 'Bearer '.concat(this.props.idToken)}}/></td>
                    </tr>
                </tbody>
            </table>
            <br/>
            
            
            <div className='houseAccountsMappingList'>
                <Grid data={this.state.result}
                 sortable={true}
                 filterable={true}
                 pageable={{ pageSizes: [10, 25, 50, 100, 250, 500] }}
                 onDataStateChange={this.changeDataState}
                   {...this.state.dataState}>
                

                <GridToolbar>
              <Button icon="excel" title='Export to Excel' onClick={this.export}>Export</Button>
            </GridToolbar>
                    <GridColumn headerClassName='gridHeader' field='household' title='Household'/>
                    <GridColumn headerClassName='gridHeader' field='account' title='Account'/>
                    <GridColumn headerClassName='gridHeader' field='displayName' title='Account Name'/>
                </Grid>
            </div>
        </div>
    );
  }

}

export default HouseholdMapping;