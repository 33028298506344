import React, { Component } from "react";
// import { Button } from '@progress/kendo-react-buttons';
// import ColorButton from "./components/ColorButton";
// import FontDropDown from "./components/FontDropDown";
// import TabDropDown from "./components/TabDropDown";
// import LogoUpload from "./components/LogoUpload";
// import ChartColors from './components/ChartColors';
// import ViewsList from '../UserList/components/ViewsList';
// import MultiDropDwon from './components/MultiDropDown';
import StyleSetting from './components/StyleSetting';
import axios from 'axios';
// import { Sortable } from '@progress/kendo-react-sortable';
import * as utils from '../utilities';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

class StyleCenter extends Component {

  constructor(props) {
    super(props);

    var companyViews = [{ name: this.props.compSettings.docTab1, page: "docTab1" },
    { name: this.props.compSettings.docTab2, page: "docTab2" },
    { name: this.props.compSettings.docTab3, page: "docTab3" },
    { name: this.props.compSettings.docTab4, page: "docTab4" },
    { name: this.props.compSettings.docTab5, page: "docTab5" },
    { name: this.props.compSettings.docTab7, page: "docTab7" },
    { name: 'Portfolio', page: "portfolio" }];

    var portTabViews = [{ text: "Account Summary", name: "Account Summary", page: "acctSumm", value: "acctSumm" },
    { text: "Account Overview", name: "Account Overview", page: "equityOver", value: "equityOver" },
    { text: "Performance Overview", name: "Performance Overview", page: "perfOver", value: "perfOver" },
    { text: "Holdings", name: "Holdings", page: "holds", value: "holds" },
    { text: "Fixed Income Holdings", name: "Fixed Income Holdings", page: "fiHolds", value: "fiHolds" },
    //{ text: "Holdings Summary", name: "Holdings Summary", page: "holdSumm", value: "holdSumm" },
    //{ text: "Market Value", name: "Market Value", page: "markVal", value: "markVal" },
    //{ text: 'Group Appraisal', name: "Group Appraisal", page: "grpApp", value: "grpApp" },
    //{ text: 'Historical Market Value', name: "Historical Market Value", page: "histMarkVal", value: "histMarkVal" },
    { text: 'Transaction Summary', name: "Transaction Summary", page: "tranSumm", value: "tranSumm" },
    //{ text: 'Performance Growth', name: "Performance Growth", page: "growth", value: "growth" },
    //{ text: 'Account AUM', name: "Account AUM", page: "aum", value: "aum" },
    { text: 'Realized Gains', name: "Realized Gains", page: "rgain", value: "rgain" }];

    var portTabOrder = [];

    if (this.props.compSettings.portTabOrder)
      portTabOrder = this.props.compSettings.portTabOrder.split(',');

    // var newPortTabOrder = []

    // if ()

    var orderedPortTabs = portTabViews.sort((a, b) => {
      return portTabOrder.indexOf(a.value) - portTabOrder.indexOf(b.value);
    });

    var holdGroups = [{ text: "Asset Class", value: "Asset Class" },
    { text: "Security Type", value: "Type" },
    { text: "Sector", value: "Sector" },
    { text: "Industry", value: "Industry" },
    { text: props.compSettings.userDef1 || "User Def 1", value: props.compSettings.userDef1 },
    { text: props.compSettings.userDef2 || "User Def 2", value: props.compSettings.userDef2 },
    { text: props.compSettings.userDef3 || "User Def 3", value: props.compSettings.userDef3 },
    { text: props.compSettings.userDef4 || "User Def 4", value: props.compSettings.userDef4 },
    { text: props.compSettings.userDef5 || "User Def 5", value: props.compSettings.userDef5 }];

    var aumGroups = [{ text: "Asset Class", value: "assetAllocation" },
    { text: "Security Type", value: "securityType" },
    { text: "Sector", value: "sector" },
    { text: "Industry", value: "industry" },
    { text: props.compSettings.userDef1 || "User Def 1", value: 'userDef1' },
    { text: props.compSettings.userDef2 || "User Def 2", value: 'userDef2' },
    { text: props.compSettings.userDef3 || "User Def 3", value: 'userDef3' },
    { text: props.compSettings.userDef4 || "User Def 4", value: 'userDef4' },
    { text: props.compSettings.userDef5 || "User Def 5", value: 'userDef5' }];

    var periods = [{ text: "Month to Date", value: "mtd" },
    { text: "Quarter to Date", value: "qtd" },
    { text: "Year to Date", value: "ytd" },
    { text: "6 Month", value: "6m" },
    { text: "12 Month", value: "12m" }];

    var fullPeriods = [{ text: "Month to Date", value: "mtd" },
    { text: "Quarter to Date", value: "qtd" },
    { text: "Previous Quarter", value: "pqtr" },
    { text: "Previous 3 Months", value: "3mo" },
    { text: "Previous 6 Months", value: "6mo" },
    { text: "Year to Date", value: "ytd" },
    { text: "Previous Year", value: "pyr" },
    { text: "Last 1 Year", value: "l1y" },
    { text: "Last 3 Years", value: "l3y" },
    { text: "Last 5 Years", value: "l5y" },
    { text: "Last 7 Years", value: "l7y" },
    { text: "Last 10 Years", value: "l10y" },
    { text: "Last 15 Years", value: "l15y" },
    { text: "Last 20 Years", value: "l20y" },
    { text: "Last 25 Years", value: "l25y" },
    { text: "Inception to Date", value: "itd" },];

    var landItems = [
      { text: "Market Value", value: "mv" },
      { text: "Realized YTD", value: "rYTD" },
      { text: "Est Ann Income", value: "estAnn" },
      { text: "Month to Date IRR", value: "mtdIrr" },
      { text: "Quarter to Date IRR", value: "qtdIrr" },
      { text: "Year to Date IRR", value: "ytdIrr" },
      { text: "Month to Date TWR", value: "mtd" },
      { text: "Quarter to Date TWR", value: "qtd" },
      { text: "Previous Quarter TWR", value: "pqtr" },
      { text: "Previous 3 Months TWR", value: "3mo" },
      { text: "Previous 6 Months TWR", value: "6mo" },
      { text: "Year to Date TWR", value: "ytd" },
      { text: "Previous Year TWR", value: "pyr" },
      { text: "Last 1 Year TWR", value: "l1y" },
      { text: "Last 3 Years TWR", value: "l3y" },
      { text: "Last 5 Years TWR", value: "l5y" },
      { text: "Last 7 Years TWR", value: "l7y" },
      { text: "Last 10 Years TWR", value: "l10y" },
      { text: "Last 15 Years TWR", value: "l15y" },
      { text: "Last 20 Years TWR", value: "l20y" },
      { text: "Last 25 Years TWR", value: "l25y" },
      { text: "Inception to Date TWR", value: "itd" },];

    var chartTypes = [
      { text: 'Line', value: 'line' },
      { text: 'Column', value: 'column' },
      { text: 'Area', value: 'area' }
    ];

    var gradients = [{ text: "Round", value: "roundedBevel" }, { text: "Sharp", value: "sharpBevel" }];
    var classificationGrouping1 = [
        { text: "Asset Class > Sector > Securities", value: "assetAllocation,sector,securityName" }, 
        { text: "Asset Class > Sector", value:"assetAllocation,sector"},
        { text: "Asset Class > Securities", value: "assetAllocation,securityName" }, 
        { text: "Asset Class", value:"assetAllocation"},
        { text: "Sector > Securities", value:"sector,securityName"},
        { text: "Sector", value:"sector"},
        { text: "Securities", value:"securityName"},
    ];

    if (props.company === 'cssi')
      companyViews.push({ name: this.props.compSettings.docTab6, page: "docTab6" });

    this.state = {
      bgColor: this.props.style["--theme-primary-color"],
      chartColors: [],
      pieColors: [],
      colorsChanged: false,
      folderOrder: this.props.compSettings.folderOrder,
      selectedFont: this.props.style['--theme-font-family'],
      chartFont: this.props.style['--theme-chart-font'],
      docTab1: this.props.compSettings.docTab1,
      docTab2: this.props.compSettings.docTab2,
      docTab3: this.props.compSettings.docTab3,
      docTab4: this.props.compSettings.docTab4,
      docTab5: this.props.compSettings.docTab5,
      docTab6: this.props.compSettings.docTab6,
      docTab7: this.props.compSettings.docTab7,
      advTab: this.props.compSettings.advisorTab,
      portTab: this.props.compSettings.portfolioTab,
      views: companyViews,
      portTabs: portTabViews,
      portTabOrder: orderedPortTabs,
      portHomeTab: this.props.compSettings.portHomeTab,
      defaultPortTabs: this.props.compSettings.defaultPortTabs,
      defaultViews: this.props.compSettings.defaultViews,
      userUpload: this.props.compSettings.userUpload,
      docGrid: this.props.compSettings.docGrid,
      showConsolidated: this.props.compSettings.showConsolidated,
      showAsOf: this.props.compSettings.showAsOf,
      showMonthEnds: this.props.compSettings.showMonthEnds,
      showFilters: this.props.compSettings.showFilters,
      monthsBack: this.props.compSettings.monthsBack,
      contactLabel: this.props.compSettings.contactLabel,
      docsLabel: this.props.compSettings.docsLabel,
      usersLabel: this.props.compSettings.usersLabel,
      // advAllUsers: this.props.compSettings.advAllUsers,
      showNetCW: this.props.compSettings.showNetCW,
      showIncome: this.props.compSettings.showIncome,
      showInvstGL: this.props.compSettings.showInvstGL,
      showFeesPaid: this.props.compSettings.showFeesPaid,
      showPerfStart: this.props.compSettings.showPerfStart,
      showPerfBench: this.props.compSettings.showPerfBench,
      showPerfMTD: this.props.compSettings.showPerfMTD,
      showPerfQTD: this.props.compSettings.showPerfQTD,
      showPerfPrevQtr: this.props.compSettings.showPerfPrevQtr,
      showPerf3Month: this.props.compSettings.showPerf3Month,
      showPerf6Month: this.props.compSettings.showPerf6Month,
      showPerfYTD: this.props.compSettings.showPerfYTD,
      showPerfPrevYear: this.props.compSettings.showPerfPrevYear,
      showPerfYear: this.props.compSettings.showPerfYear,
      showPerf3Year: this.props.compSettings.showPerf3Year,
      showPerf5Year: this.props.compSettings.showPerf5Year,
      showPerf7Year: this.props.compSettings.showPerf7Year,
      showPerf10Year: this.props.compSettings.showPerf10Year,
      showPerf15Year: this.props.compSettings.showPerf15Year,
      showPerf20Year: this.props.compSettings.showPerf20Year,
      showPerf25Year: this.props.compSettings.showPerf25Year,
      showPerfITD: this.props.compSettings.showPerfITD,
      showTwrBench: this.props.compSettings.showTwrBench,
      showTwrMTD: this.props.compSettings.showTwrMTD,
      showTwrQTD: this.props.compSettings.showTwrQTD,
      showTwrPrevQtr: this.props.compSettings.showTwrPrevQtr,
      showTwr3Month: this.props.compSettings.showTwr3Month,
      showTwr6Month: this.props.compSettings.showTwr6Month,
      showTwrYTD: this.props.compSettings.showTwrYTD,
      showTwrPrevYear: this.props.compSettings.showTwrPrevYear,
      showTwrYear: this.props.compSettings.showTwrYear,
      showTwr3Year: this.props.compSettings.showTwr3Year,
      showTwr5Year: this.props.compSettings.showTwr5Year,
      showTwr7Year: this.props.compSettings.showTwr7Year,
      showTwr10Year: this.props.compSettings.showTwr10Year,
      showTwr15Year: this.props.compSettings.showTwr15Year,
      showTwr20Year: this.props.compSettings.showTwr20Year,
      showTwr25Year: this.props.compSettings.showTwr25Year,
      showTwrITD: this.props.compSettings.showTwrITD,
      showHoldAsset: this.props.compSettings.showHoldAsset,
      showHoldSecType: this.props.compSettings.showHoldSecType,
      showHoldSector: this.props.compSettings.showHoldSector,
      showHoldInd: this.props.compSettings.showHoldInd,
      showHoldUser1: this.props.compSettings.showHoldUser1,
      showHoldUser2: this.props.compSettings.showHoldUser2,
      showHoldUser3: this.props.compSettings.showHoldUser3,
      showHoldUser4: this.props.compSettings.showHoldUser4,
      showHoldUser5: this.props.compSettings.showHoldUser5,
      userDef1: this.props.compSettings.userDef1,
      userDef2: this.props.compSettings.userDef2,
      userDef3: this.props.compSettings.userDef3,
      userDef4: this.props.compSettings.userDef4,
      userDef5: this.props.compSettings.userDef5,
      holdGroups: holdGroups,
      holdGrp1: this.props.compSettings.holdGrp1,
      holdGrp2: this.props.compSettings.holdGrp2,
      holdGrp3: this.props.compSettings.holdGrp3,
      fixedHoldGrp1: this.props.compSettings.fixedHoldGrp1,
      fixedHoldGrp2: this.props.compSettings.fixedHoldGrp2,
      fixedHoldGrp3: this.props.compSettings.fixedHoldGrp3,
      holdingsSummGrp1: this.props.compSettings.holdingsSummGrp1,
      holdingsSummGrp2: this.props.compSettings.holdingsSummGrp2,
      holdingsSummGrp3: this.props.compSettings.holdingsSummGrp3,
      ShowHoldingsAcqDate: this.props.compSettings.ShowHoldingsAcqDate,
      ShowHoldingsQuantity: this.props.compSettings.ShowHoldingsQuantity,
      ShowHoldingsUnitCost: this.props.compSettings.ShowHoldingsUnitCost,
      ShowHoldingsTotalCost: this.props.compSettings.ShowHoldingsTotalCost,
      ShowHoldingsPrice: this.props.compSettings.ShowHoldingsPrice,
      ShowHoldingsMarketValue: this.props.compSettings.ShowHoldingsMarketValue,
      ShowHoldingsPctOfAccount: this.props.compSettings.ShowHoldingsPctOfAccount,
      ShowHoldingsUGain: this.props.compSettings.ShowHoldingsUGain,
      ShowHoldingsEstAnnIncome: this.props.compSettings.ShowHoldingsEstAnnIncome,
      ShowHoldingsYield: this.props.compSettings.ShowHoldingsYield,
      ShowFixedHoldCusip: this.props.compSettings.ShowFixedHoldCusip,
      ShowFixedHoldSPRating: this.props.compSettings.ShowFixedHoldSPRating,
      ShowFixedHoldMoodyRating: this.props.compSettings.ShowFixedHoldMoodyRating,
      ShowFixedHoldQuantity: this.props.compSettings.ShowFixedHoldQuantity,
      ShowFixedHoldInterestDividendRate: this.props.compSettings.ShowFixedHoldInterestDividendRate,
      ShowFixedHoldNextCallDate: this.props.compSettings.ShowFixedHoldNextCallDate,
      ShowFixedHoldMaturityDate: this.props.compSettings.ShowFixedHoldMaturityDate,
      ShowFixedHoldTotalCost: this.props.compSettings.ShowFixedHoldTotalCost,
      ShowFixedHoldMarketValue: this.props.compSettings.ShowFixedHoldMarketValue,
      ShowFixedHoldEstAnnIncome: this.props.compSettings.ShowFixedHoldEstAnnIncome,
      ShowFixedHoldUnrealized: this.props.compSettings.ShowFixedHoldUnrealized,
      ShowFixedHoldPercentage: this.props.compSettings.ShowFixedHoldPercentage,
      ShowFixedHoldUser5: this.props.compSettings.ShowFixedHoldUser5,
      ShowFixedHoldUser4: this.props.compSettings.ShowFixedHoldUser4,
      ShowFixedHoldUser3: this.props.compSettings.ShowFixedHoldUser3,
      ShowFixedHoldUser2: this.props.compSettings.ShowFixedHoldUser2,
      ShowFixedHoldUser1: this.props.compSettings.ShowFixedHoldUser1,
      ShowFixedHoldInd: this.props.compSettings.ShowFixedHoldInd,
      ShowFixedHoldSector: this.props.compSettings.ShowFixedHoldSector,
      ShowFixedHoldSecType: this.props.compSettings.ShowFixedHoldSecType,
      ShowFixedHoldAsset: this.props.compSettings.ShowFixedHoldAsset,
      FixedHoldIncludeCash: this.props.compSettings.FixedHoldIncludeCash,
      ShowHoldingsSummUser5: this.props.compSettings.ShowHoldingsSummUser5,
      ShowHoldingsSummUser4: this.props.compSettings.ShowHoldingsSummUser4,
      ShowHoldingsSummUser3: this.props.compSettings.ShowHoldingsSummUser3,
      ShowHoldingsSummUser2: this.props.compSettings.ShowHoldingsSummUser2,
      ShowHoldingsSummUser1: this.props.compSettings.ShowHoldingsSummUser1,
      ShowHoldingsSummSector: this.props.compSettings.ShowHoldingsSummSector,
      ShowHoldingsSummIndustry: this.props.compSettings.ShowHoldingsSummIndustry,
      ShowHoldingsSummAssetAllocation: this.props.compSettings.ShowHoldingsSummAssetAllocation,
      ShowHoldingsSummPercentage: this.props.compSettings.ShowHoldingsSummPercentage,
      ShowHoldingsSummPortfolio: this.props.compSettings.ShowHoldingsSummPortfolio,
      ShowHoldingsSummMarketValue: this.props.compSettings.ShowHoldingsSummMarketValue,
      ShowHoldingsSummTotalCost: this.props.compSettings.ShowHoldingsSummTotalCost,
      ShowHoldingsSummUnrealized: this.props.compSettings.ShowHoldingsSummUnrealized,
      ShowHoldingsSummQuantity: this.props.compSettings.ShowHoldingsSummQuantity,
      ShowHoldingsSummSecuritySymbol: this.props.compSettings.ShowHoldingsSummSecuritySymbol,
      ShowHoldingsSummSecurityName: this.props.compSettings.ShowHoldingsSummSecurityName,
      AlwaysLumpUserHoldings: this.props.compSettings.AlwaysLumpUserHoldings,
      pieBorderColor: this.props.compSettings.pieBorderColor,
      pieBorderWidth: this.props.compSettings.pieBorderWidth,
      gradients: gradients,
      classificationGrouping1options: classificationGrouping1,
      classificationGrouping1: this.props.compSettings.classificationGrouping1,
      pieGradient: this.props.compSettings.pieGradient,
      periods: periods,
      tranPeriod: this.props.compSettings.tranPeriod,
      growthPeriod: this.props.compSettings.growthPeriod,
      showSettle: this.props.compSettings.showSettle,
      defaultFees: this.props.compSettings.defaultFees,
      perfHistIrr: this.props.compSettings.perfHistIrr,
      growthIrr: this.props.compSettings.growthIrr,
      fullPeriods: fullPeriods,
      acctPeriod1: this.props.compSettings.acctPeriod1,
      acctPeriod2: this.props.compSettings.acctPeriod2,
      acctPeriod3: this.props.compSettings.acctPeriod3,
      acctPeriodMult: this.props.compSettings.acctPeriodMult,
      acctDropPeriods: this.props.compSettings.acctDropPeriods,
      aumGroups: aumGroups,
      aumGrp: this.props.compSettings.aumGrp,
      aumDropGrps: this.props.compSettings.aumDropGrps,
      landItems: landItems,
      landCols: this.props.compSettings.landCols,
      showAcctSummIrr: this.props.compSettings.showAcctSummIrr,
      showAcctSummTwr: this.props.compSettings.showAcctSummTwr,
      enableFeeSelect: this.props.compSettings.enableFeeSelect,
      enablePerfReturnSelect: this.props.compSettings.enablePerfReturnSelect,
      fontSizes: {
        acctSumm: this.getFontSize('--acctSumm-font-size'),
        perfOver: this.getFontSize('--perfOver-font-size'),
        holdings: this.getFontSize('--holdings-font-size'),
        fiHoldings: this.getFontSize('--fiHoldings-font-size'),
        mktVal: this.getFontSize('--mktVal-font-size'),
        grpApp: this.getFontSize('--grpApp-font-size'),
        histMktVal: this.getFontSize('--histMktVal-font-size'),
        tran: this.getFontSize('--tran-font-size'),
        aum: this.getFontSize('--aum-font-size'),
        rgain: this.getFontSize('--rgain-font-size')
      },
      cornerLogo: null,
      cornerPreview: this.getPreviewLogo(),
      expand: {},
      rgainPeriod: this.props.compSettings.rgainPeriod,
      fontColor: this.props.style["--theme-font-color"],
      hhPerPage: this.props.compSettings.hhPerPage,
      chartTypes: chartTypes,
      mvChart: this.props.compSettings.mvChart,
      tabSelected:0,
      styleGroups: [],
      unsavedChanges: false,
    }

    this.onPortViewsChange = this.onPortViewsChange.bind(this);
    this.onViewsChange = this.onViewsChange.bind(this);
    this.onBGColorChange = this.onBGColorChange.bind(this);
    this.onFontFamilyChange = this.onFontFamilyChange.bind(this);
    this.onChartFontChange = this.onChartFontChange.bind(this);
    this.onChangeFolderOrder = this.onChangeFolderOrder.bind(this);
    this.onCornerLogoChange = this.onCornerLogoChange.bind(this);
    this.applyChanges = this.applyChanges.bind(this);
    this.uploadLogo = this.uploadLogo.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.getChartColors = this.getChartColors.bind(this);
    this.uploadChartColors = this.uploadChartColors.bind(this);
    this.onFontSizeChange = this.onFontSizeChange.bind(this);
    this.getFontSize = this.getFontSize.bind(this);
    this.getPreviewLogo = this.getPreviewLogo.bind(this);
    this.expandIcon = this.expandIcon.bind(this);
    this.changeExpand = this.changeExpand.bind(this);
    this.GetAllStyleSettings = this.GetAllStyleSettings.bind(this);
    this.onTabContentChange = this.onTabContentChange.bind(this);
    this.onTabContentSave = this.onTabContentSave.bind(this);
  }

  componentDidMount() {
    this.getSuperAdminSettings(() =>{
      this.getChartColors();
      this.GetAllStyleSettings();
    })
  }

  getSuperAdminSettings(cb) {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios(`api/superAdminSettings`, {
      method: 'GET',
      params: { company: this.props.company },
      headers: headers
    })
      .then(response => {
        response.data.data.forEach(setting => {
          if (setting.settingName === 'enableMFA') {
            this.setState({ enableMFA: setting.settingValue.toLowerCase() === 'true' ? true : false }, () => {
              cb()
            })
          }
        })

      })
      .catch(err => {console.log(err); cb()});
  }

  getChartColors() {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios.get('api/colors?company=' + this.props.company, { headers }).then(response => {
      if (response.data.code === 200) {
        var chartColors = [];
        var pieColors = [];
        for (let i = 0; i < response.data.colors.length; i++) {
          if (!response.data.colors[i].type)
            chartColors.push(response.data.colors[i]);
          else if (response.data.colors[i].type === 'pie')
            pieColors.push(response.data.colors[i]);
        }
        this.setState({ chartColors: chartColors, pieColors: pieColors });
      }
    }).catch(err => {
      console.log(err);
    });
  }



  onBGColorChange = (e) => {
    this.setState({ bgColor: e.hex });
  }

  onFontColorChange = (e) => {
    this.setState({ fontColor: e.hex });
  }


  onChartColorsChange = (colors) => {
    this.setState({ chartColors: colors, colorsChanged: true });
  }

  onPieColorsChange = (colors) => {
    this.setState({ pieColors: colors, colorsChanged: true });
  }

  onChangeFolderOrder = (e) => {
    this.setState({ folderOrder: e.target.value });
  }

  onFontFamilyChange = (e) => {
    this.setState({ selectedFont: e.target.value.text });
  }

  onChartFontChange = (e) => {
    this.setState({ chartFont: e.target.value.text });
  }

  getPreviewLogo() {
    var prev = this.props.style['--theme-corner-logo'];

    if (prev && prev.length > 4)
      prev = this.props.style['--theme-corner-logo'].substring(4, this.props.style['--theme-corner-logo'].length - 1);
    else
      prev = null;

    return prev;
  }

  getFontSize(cssVar) {
    var size = this.props.style[cssVar];
    if (size && size.length > 2)
      size = size.substring(0, size.length - 2);
    else
      size = null;

    return size;
  }

  onViewsChange(view, e) {
    var newViews = this.state.defaultViews.slice();
    var indView = newViews.indexOf(view.page);

    if (indView === -1)
      newViews.push(view.page);
    else
      newViews.splice(indView, 1);

    this.setState({ defaultViews: newViews });
  }

  onPortViewsChange(view, e) {
    var newViews = this.state.defaultPortTabs.slice();
    var indView = newViews.indexOf(view.page);

    if (indView === -1)
      newViews.push(view.page);
    else
      newViews.splice(indView, 1);

    this.setState({ defaultPortTabs: newViews });
  }

  onInputChange(e, attr, isCheckbox) {
    if (isCheckbox)
      this.setState({ [attr]: !this.state[attr] });
    else if (attr === 'monthsBack') {
      if (e.target.value < 1 && e.target.value !== '')
        alert('Months back must be a number greater than 0 or left blank');
      else
        this.setState({ [attr]: e.target.value });
    }
    else if (attr === 'pieBorderWidth') {
      if (e.target.value < 0 && e.target.value !== '')
        alert('Pie border thickness must be 0 or a positive number');
      else
        this.setState({ [attr]: e.target.value });
    }
    else if (attr === 'portHomeTab' || attr === 'holdGrp1' || attr === 'holdGrp2' || attr === 'acctPeriod1'
      || attr === 'fixedHoldGrp1' || attr === 'fixedHoldGrp2' || attr === 'fixedHoldGrp3'
      || attr === 'holdingsSummGrp1' || attr === 'holdingsSummGrp2' || attr === 'holdingsSummGrp3'
      || attr === 'holdGrp3' || attr === 'classificationGrouping1'
      || attr === 'pieGradient' || attr === 'tranPeriod' || attr === 'growthPeriod'
      || attr === 'acctPeriod2' || attr === 'acctPeriod3' || attr === 'acctPeriodMult' || attr === 'aumGrp'
      || attr === 'rgainPeriod' || attr === 'mvChart') {
      this.setState({ [attr]: e.target.value.value });
    }
    else if (attr === 'pieBorderColor') {
      this.setState({ [attr]: e.hex });
    }
    else if (attr === 'acctDropPeriods' || attr === 'aumDropGrps' || attr === 'landCols') {
      this.setState({ [attr]: e });
    }
    else if (attr === 'hhPerPage') {
      var val = parseInt(e.target.value);
      if (isNaN(val) || val < 0)
        val = 0;
      else
        this.setState({ [attr]: val });
    }
    else {
      this.setState({ [attr]: e.target.value });
    }
  }

  onFontSizeChange(e, attr) {
    var fSize = this.state.fontSizes;
    if (e.target.value < 1 || e.target.value === '')
      alert("Font size must be a positive number");
    else {
      fSize[attr] = e.target.value;
      this.setState({ fontSizes: fSize });
    }
  }

  onCornerLogoChange = (e) => {
    this.setState({ cornerLogo: e.target.files[0], cornerPreview: URL.createObjectURL(e.target.files[0]) })
  }

  applyChanges = (e) => {
    if (this.state.userDef1 === '' || this.state.userDef2 === '' || this.state.userDef3 === '' || this.state.userDef4 === '' || this.state.userDef5 === '') {
      alert("User Def labels cannot be left blank.");
    }
    else {
      var copyStyle = { ...this.props.style };

      copyStyle['--theme-primary-color'] = this.state.bgColor;
      this.props.onStyleChange('--theme-primary-color', this.state.bgColor);
      copyStyle['--acctSumm-font-size'] = this.state.fontSizes.acctSumm + 'pt';
      this.props.onStyleChange('--acctSumm-font-size', this.state.fontSizes.acctSumm + 'pt');
      copyStyle['--perfOver-font-size'] = this.state.fontSizes.perfOver + 'pt';
      this.props.onStyleChange('--perfOver-font-size', this.state.fontSizes.perfOver + 'pt');
      copyStyle['--holdings-font-size'] = this.state.fontSizes.holdings + 'pt';
      this.props.onStyleChange('--holdings-font-size', this.state.fontSizes.holdings + 'pt');
      copyStyle['--fiHoldings-font-size'] = this.state.fontSizes.fiHoldings + 'pt';
      this.props.onStyleChange('--fiHoldings-font-size', this.state.fontSizes.fiHoldings + 'pt');
      copyStyle['--mktVal-font-size'] = this.state.fontSizes.mktVal + 'pt';
      this.props.onStyleChange('--mktVal-font-size', this.state.fontSizes.mktVal + 'pt');
      copyStyle['--grpApp-font-size'] = this.state.fontSizes.grpApp + 'pt';
      this.props.onStyleChange('--grpApp-font-size', this.state.fontSizes.grpApp + 'pt');
      copyStyle['--histMktVal-font-size'] = this.state.fontSizes.histMktVal + 'pt';
      this.props.onStyleChange('--histMktVal-font-size', this.state.fontSizes.histMktVal + 'pt');
      copyStyle['--tran-font-size'] = this.state.fontSizes.tran + 'pt';
      this.props.onStyleChange('--tran-font-size', this.state.fontSizes.tran + 'pt');
      copyStyle['--aum-font-size'] = this.state.fontSizes.aum + 'pt';
      this.props.onStyleChange('--aum-font-size', this.state.fontSizes.aum + 'pt');
      copyStyle['--rgain-font-size'] = this.state.fontSizes.rgain + 'pt';
      this.props.onStyleChange('--rgain-font-size', this.state.fontSizes.rgain + 'pt');
      copyStyle['--theme-font-color'] = this.state.fontColor;
      this.props.onStyleChange('--theme-font-color', this.state.fontColor);

      this.props.onCompSettingsChange('folderOrder', this.state.folderOrder);
      this.props.onCompSettingsChange('docTab1', this.state.docTab1);
      this.props.onCompSettingsChange('docTab2', this.state.docTab2);
      this.props.onCompSettingsChange('docTab3', this.state.docTab3);
      this.props.onCompSettingsChange('docTab4', this.state.docTab4);
      this.props.onCompSettingsChange('docTab5', this.state.docTab5);
      this.props.onCompSettingsChange('docTab6', this.state.docTab6);
      this.props.onCompSettingsChange('docTab7', this.state.docTab7);
      this.props.onCompSettingsChange('contactLabel', this.state.contactLabel);
      this.props.onCompSettingsChange('docsLabel', this.state.docsLabel);
      this.props.onCompSettingsChange('usersLabel', this.state.usersLabel);
      this.props.onCompSettingsChange('userUpload', this.state.userUpload);
      this.props.onCompSettingsChange('showAsOf', this.state.showAsOf);
      this.props.onCompSettingsChange('showMonthEnds', this.state.showMonthEnds);
      this.props.onCompSettingsChange('monthsBack', this.state.monthsBack);
      this.props.onCompSettingsChange('defaultViews', this.state.defaultViews, true);
      this.props.onCompSettingsChange('defaultPortTabs', this.state.defaultPortTabs, true);
      this.props.onCompSettingsChange('advisorTab', this.state.advTab);
      this.props.onCompSettingsChange('portfolioTab', this.state.portTab);
      this.props.onCompSettingsChange('docGrid', this.state.docGrid);
      // this.props.onCompSettingsChange('advAllUsers', this.state.advAllUsers);
      this.props.onCompSettingsChange('portHomeTab', this.state.portHomeTab);
      this.props.onCompSettingsChange('showConsolidated', this.state.showConsolidated);
      this.props.onCompSettingsChange('showFilters', this.state.showFilters);
      this.props.onCompSettingsChange('showNetCW', this.state.showNetCW);
      this.props.onCompSettingsChange('showIncome', this.state.showIncome);
      this.props.onCompSettingsChange('showInvstGL', this.state.showInvstGL);
      this.props.onCompSettingsChange('showFeesPaid', this.state.showFeesPaid);
      this.props.onCompSettingsChange('showPerfStart', this.state.showPerfStart);
      this.props.onCompSettingsChange('showPerfBench', this.state.showPerfBench);
      this.props.onCompSettingsChange('showPerfMTD', this.state.showPerfMTD);
      this.props.onCompSettingsChange('showPerfQTD', this.state.showPerfQTD);
      this.props.onCompSettingsChange('showPerfPrevQtr', this.state.showPerfPrevQtr);
      this.props.onCompSettingsChange('showPerf3Month', this.state.showPerf3Month);
      this.props.onCompSettingsChange('showPerf6Month', this.state.showPerf6Month);
      this.props.onCompSettingsChange('showPerfYTD', this.state.showPerfYTD);
      this.props.onCompSettingsChange('showPerfPrevYear', this.state.showPerfPrevYear);
      this.props.onCompSettingsChange('showPerfYear', this.state.showPerfYear);
      this.props.onCompSettingsChange('showPerf3Year', this.state.showPerf3Year);
      this.props.onCompSettingsChange('showPerf5Year', this.state.showPerf5Year);
      this.props.onCompSettingsChange('showPerf7Year', this.state.showPerf7Year);
      this.props.onCompSettingsChange('showPerf10Year', this.state.showPerf10Year);
      this.props.onCompSettingsChange('showPerf15Year', this.state.showPerf15Year);
      this.props.onCompSettingsChange('showPerf20Year', this.state.showPerf20Year);
      this.props.onCompSettingsChange('showPerf25Year', this.state.showPerf25Year);
      this.props.onCompSettingsChange('showPerfITD', this.state.showPerfITD);
      this.props.onCompSettingsChange('showTwrBench', this.state.showTwrBench);
      this.props.onCompSettingsChange('showTwrMTD', this.state.showTwrMTD);
      this.props.onCompSettingsChange('showTwrQTD', this.state.showTwrQTD);
      this.props.onCompSettingsChange('showTwrPrevQtr', this.state.showTwrPrevQtr);
      this.props.onCompSettingsChange('showTwr3Month', this.state.showTwr3Month);
      this.props.onCompSettingsChange('showTwr6Month', this.state.showTwr6Month);
      this.props.onCompSettingsChange('showTwrYTD', this.state.showTwrYTD);
      this.props.onCompSettingsChange('showTwrPrevYear', this.state.showTwrPrevYear);
      this.props.onCompSettingsChange('showTwrYear', this.state.showTwrYear);
      this.props.onCompSettingsChange('showTwr3Year', this.state.showTwr3Year);
      this.props.onCompSettingsChange('showTwr5Year', this.state.showTwr5Year);
      this.props.onCompSettingsChange('showTwr7Year', this.state.showTwr7Year);
      this.props.onCompSettingsChange('showTwr10Year', this.state.showTwr10Year);
      this.props.onCompSettingsChange('showTwr15Year', this.state.showTwr15Year);
      this.props.onCompSettingsChange('showTwr20Year', this.state.showTwr20Year);
      this.props.onCompSettingsChange('showTwr25Year', this.state.showTwr25Year);
      this.props.onCompSettingsChange('showTwrITD', this.state.showTwrITD);
      this.props.onCompSettingsChange('showHoldAsset', this.state.showHoldAsset);
      this.props.onCompSettingsChange('showHoldSecType', this.state.showHoldSecType);
      this.props.onCompSettingsChange('showHoldSector', this.state.showHoldSector);
      this.props.onCompSettingsChange('showHoldInd', this.state.showHoldInd);
      this.props.onCompSettingsChange('showHoldUser1', this.state.showHoldUser1);
      this.props.onCompSettingsChange('showHoldUser2', this.state.showHoldUser2);
      this.props.onCompSettingsChange('showHoldUser3', this.state.showHoldUser3);
      this.props.onCompSettingsChange('showHoldUser4', this.state.showHoldUser4);
      this.props.onCompSettingsChange('showHoldUser5', this.state.showHoldUser5);
      this.props.onCompSettingsChange('userDef1', this.state.userDef1);
      this.props.onCompSettingsChange('userDef2', this.state.userDef2);
      this.props.onCompSettingsChange('userDef3', this.state.userDef3);
      this.props.onCompSettingsChange('userDef4', this.state.userDef4);
      this.props.onCompSettingsChange('userDef5', this.state.userDef5);
      this.props.onCompSettingsChange('holdGrp1', this.state.holdGrp1);
      this.props.onCompSettingsChange('holdGrp2', this.state.holdGrp2);
      this.props.onCompSettingsChange('holdGrp3', this.state.holdGrp3);
      this.props.onCompSettingsChange('fixedHoldGrp1', this.state.fixedHoldGrp1);
      this.props.onCompSettingsChange('fixedHoldGrp2', this.state.fixedHoldGrp2);
      this.props.onCompSettingsChange('fixedHoldGrp3', this.state.fixedHoldGrp3);
      this.props.onCompSettingsChange('holdingsSummGrp1', this.state.holdingsSummGrp1);
      this.props.onCompSettingsChange('holdingsSummGrp2', this.state.holdingsSummGrp2);
      this.props.onCompSettingsChange('holdingsSummGrp3', this.state.holdingsSummGrp3);
      this.props.onCompSettingsChange('ShowHoldingsAcqDate', this.state.ShowHoldingsAcqDate);
      this.props.onCompSettingsChange('ShowHoldingsQuantity', this.state.ShowHoldingsQuantity);
      this.props.onCompSettingsChange('ShowHoldingsUnitCost', this.state.ShowHoldingsUnitCost);
      this.props.onCompSettingsChange('ShowHoldingsTotalCost', this.state.ShowHoldingsTotalCost);
      this.props.onCompSettingsChange('ShowHoldingsPrice', this.state.ShowHoldingsPrice);
      this.props.onCompSettingsChange('ShowHoldingsMarketValue', this.state.ShowHoldingsMarketValue);
      this.props.onCompSettingsChange('ShowHoldingsPctOfAccount', this.state.ShowHoldingsPctOfAccount);
      this.props.onCompSettingsChange('ShowHoldingsUGain', this.state.ShowHoldingsUGain);
      this.props.onCompSettingsChange('ShowHoldingsEstAnnIncome', this.state.ShowHoldingsEstAnnIncome);
      this.props.onCompSettingsChange('ShowHoldingsYield', this.state.ShowHoldingsYield);
      this.props.onCompSettingsChange('AlwaysLumpUserHoldings', this.state.AlwaysLumpUserHoldings);
      this.props.onCompSettingsChange('classificationGrouping1', this.state.classificationGrouping1);
      this.props.onCompSettingsChange('pieBorderColor', this.state.pieBorderColor);
      this.props.onCompSettingsChange('pieBorderWidth', this.state.pieBorderWidth);
      this.props.onCompSettingsChange('pieGradient', this.state.pieGradient);
      this.props.onCompSettingsChange('tranPeriod', this.state.tranPeriod);
      this.props.onCompSettingsChange('showSettle', this.state.showSettle);
      this.props.onCompSettingsChange('growthPeriod', this.state.growthPeriod);
      this.props.onCompSettingsChange('defaultFees', this.state.defaultFees);
      this.props.onCompSettingsChange('perfHistIrr', this.state.perfHistIrr);
      this.props.onCompSettingsChange('growthIrr', this.state.growthIrr);
      this.props.onCompSettingsChange('acctPeriod1', this.state.acctPeriod1);
      this.props.onCompSettingsChange('acctPeriod2', this.state.acctPeriod2);
      this.props.onCompSettingsChange('acctPeriod3', this.state.acctPeriod3);
      this.props.onCompSettingsChange('acctPeriodMult', this.state.acctPeriodMult);
      this.props.onCompSettingsChange('acctDropPeriods', this.state.acctDropPeriods);
      this.props.onCompSettingsChange('aumGrp', this.state.aumGrp);
      this.props.onCompSettingsChange('aumDropGrps', this.state.aumDropGrps);
      this.props.onCompSettingsChange('showAcctSummIrr', this.state.showAcctSummIrr);
      this.props.onCompSettingsChange('showAcctSummTwr', this.state.showAcctSummTwr);
      this.props.onCompSettingsChange('rgainPeriod', this.state.rgainPeriod);
      this.props.onCompSettingsChange('enableFeeSelect', this.state.enableFeeSelect);
      this.props.onCompSettingsChange('landCols', this.state.landCols);
      this.props.onCompSettingsChange('enablePerfReturnSelect', this.state.enablePerfReturnSelect);
      this.props.onCompSettingsChange('hhPerPage', this.state.hhPerPage);
      this.props.onCompSettingsChange('mvChart', this.state.mvChart);
      this.props.onCompSettingsChange('ShowFixedHoldCusip', this.state.ShowFixedHoldCusip);
      this.props.onCompSettingsChange('ShowFixedHoldSPRating', this.state.ShowFixedHoldSPRating);
      this.props.onCompSettingsChange('ShowFixedHoldMoodyRating', this.state.ShowFixedHoldMoodyRating);
      this.props.onCompSettingsChange('ShowFixedHoldQuantity', this.state.ShowFixedHoldQuantity);
      this.props.onCompSettingsChange('ShowFixedHoldInterestDividendRate', this.state.ShowFixedHoldInterestDividendRate);
      this.props.onCompSettingsChange('ShowFixedHoldNextCallDate', this.state.ShowFixedHoldNextCallDate);
      this.props.onCompSettingsChange('ShowFixedHoldMaturityDate', this.state.ShowFixedHoldMaturityDate);
      this.props.onCompSettingsChange('ShowFixedHoldTotalCost', this.state.ShowFixedHoldTotalCost);
      this.props.onCompSettingsChange('ShowFixedHoldMarketValue', this.state.ShowFixedHoldMarketValue);
      this.props.onCompSettingsChange('ShowFixedHoldEstAnnIncome', this.state.ShowFixedHoldEstAnnIncome);
      this.props.onCompSettingsChange('ShowFixedHoldUnrealized', this.state.ShowFixedHoldUnrealized);
      this.props.onCompSettingsChange('ShowFixedHoldPercentage', this.state.ShowFixedHoldPercentage);
      this.props.onCompSettingsChange('ShowFixedHoldUser5', this.state.ShowFixedHoldUser5);
      this.props.onCompSettingsChange('ShowFixedHoldUser4', this.state.ShowFixedHoldUser4);
      this.props.onCompSettingsChange('ShowFixedHoldUser3', this.state.ShowFixedHoldUser3);
      this.props.onCompSettingsChange('ShowFixedHoldUser2', this.state.ShowFixedHoldUser2);
      this.props.onCompSettingsChange('ShowFixedHoldUser1', this.state.ShowFixedHoldUser1);
      this.props.onCompSettingsChange('ShowFixedHoldInd', this.state.ShowFixedHoldInd);
      this.props.onCompSettingsChange('ShowFixedHoldSector', this.state.ShowFixedHoldSector);
      this.props.onCompSettingsChange('ShowFixedHoldSecType', this.state.ShowFixedHoldSecType);
      this.props.onCompSettingsChange('ShowFixedHoldAsset', this.state.ShowFixedHoldAsset);
      this.props.onCompSettingsChange('FixedHoldIncludeCash', this.state.FixedHoldIncludeCash);
      this.props.onCompSettingsChange('ShowHoldingsSummUser5', this.state.ShowHoldingsSummUser5);
      this.props.onCompSettingsChange('ShowHoldingsSummUser4', this.state.ShowHoldingsSummUser4);
      this.props.onCompSettingsChange('ShowHoldingsSummUser3', this.state.ShowHoldingsSummUser3);
      this.props.onCompSettingsChange('ShowHoldingsSummUser2', this.state.ShowHoldingsSummUser2);
      this.props.onCompSettingsChange('ShowHoldingsSummUser1', this.state.ShowHoldingsSummUser1);
      this.props.onCompSettingsChange('ShowHoldingsSummSector', this.state.ShowHoldingsSummSector);
      this.props.onCompSettingsChange('ShowHoldingsSummIndustry', this.state.ShowHoldingsSummIndustry);
      this.props.onCompSettingsChange('ShowHoldingsSummAssetAllocation', this.state.ShowHoldingsSummAssetAllocation);
      this.props.onCompSettingsChange('ShowHoldingsSummPercentage', this.state.ShowHoldingsSummPercentage);
      this.props.onCompSettingsChange('ShowHoldingsSummPortfolio', this.state.ShowHoldingsSummPortfolio);
      this.props.onCompSettingsChange('ShowHoldingsSummMarketValue', this.state.ShowHoldingsSummMarketValue);
      this.props.onCompSettingsChange('ShowHoldingsSummUnrealized', this.state.ShowHoldingsSummUnrealized);
      this.props.onCompSettingsChange('ShowHoldingsSummTotalCost', this.state.ShowHoldingsSummTotalCost);
      this.props.onCompSettingsChange('ShowHoldingsSummQuantity', this.state.ShowHoldingsSummQuantity);
      this.props.onCompSettingsChange('ShowHoldingsSummSecuritySymbol', this.state.ShowHoldingsSummSecuritySymbol);
      this.props.onCompSettingsChange('ShowHoldingsSummSecurityName', this.state.ShowHoldingsSummSecurityName);

      var tabOrderDB = '';
      for (let i = 0; i < this.state.portTabOrder.length; i++) {
        if (i !== this.state.portTabOrder.length - 1)
          tabOrderDB = tabOrderDB + this.state.portTabOrder[i].value + ',';
        else
          tabOrderDB = tabOrderDB + this.state.portTabOrder[i].value;
      }

      this.props.onCompSettingsChange('portTabOrder', tabOrderDB);

      if (this.state.colorsChanged)
        this.uploadChartColors();

      if (this.state.selectedFont !== '-Select a font-') {
        copyStyle['--theme-font-family'] = this.state.selectedFont;
        this.props.onStyleChange('--theme-font-family', this.state.selectedFont);
      }
      if (this.state.chartFont !== '-Select a font-') {
        copyStyle['--theme-chart-font'] = this.state.chartFont;
        this.props.onStyleChange('--theme-chart-font', this.state.chartFont);
      }
      if (this.state.cornerLogo !== null && this.state.cornerLogo !== undefined) {
        this.uploadLogo(copyStyle);
      }
      else {
        this.props.setStyle(copyStyle);
        this.props.refresh('style');
        alert("Style Settings have been updated.");
      }
    }
  }

  expandIcon(group) {
    if (this.state.expand[group])
      return 'minus';
    else
      return 'plus';
  }

  changeExpand(group) {
    var newExpand = this.state.expand;
    if (newExpand[group] !== undefined)
      newExpand[group] = !newExpand[group];
    else
      newExpand[group] = true;
    this.setState({ expand: newExpand });
  }

  uploadChartColors() {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var colors = this.state.chartColors.slice();
    var pieColors = this.state.pieColors.slice();
    var allColors = colors.concat(pieColors);

    var payload = {
      colors: allColors,
      company: this.props.company
    }

    axios.post('api/colors', payload, { headers }).then(response => {
      if (response.data.code !== 200) {
        alert("An error occured while updating chart colors.");
      }
    }).catch(err => console.log(err));
  }

  uploadLogo(copyStyle) {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    const formData = new FormData();
    formData.append('company', 'logos');
    formData.append('logoCompany', this.props.company);
    formData.append('type', 'logo');
    formData.append('prevLogo', this.props.compSettings.cornerLogo);
    formData.append(this.props.company + this.state.cornerLogo.name.substring(this.state.cornerLogo.name.indexOf('.')), this.state.cornerLogo);


    axios.post('api/logo?company='+this.props.company, formData, { headers }).then(response => {
      if (response.data.code === 200) {
        this.props.setStyle(copyStyle);
        this.props.onCompSettingsChange('cornerLogo', response.data.newlogo);
        this.props.refresh('style');
        alert("Style Settings have been updated.");
      }
    }).catch(err => console.log(err));
  }

  onDragOver = (e) => {
    this.setState({ portTabOrder: e.newState });
  }

  onNavigate = (e) => {
    this.setState({ portTabOrder: e.newState });
  }

  getBaseItemStyle = (isActive) => ({
    fontSize: '16px',
    textAlign: 'center',
    outline: 'none',
    border: '1px solid',
    cursor: 'move',
    borderColor: isActive ? '#27aceb' : 'black',
    background: 'white',
    marginBottom: '5px'
  });

  sortItemUI = (props) => {
    const { isActive, style, attributes, dataItem, forwardRef } = props;
    return (
      <div ref={forwardRef}
        {...attributes}
        style={{
          ...this.getBaseItemStyle(isActive),
          ...style
        }}
      >
        {dataItem.name}
      </div>
    )
  }

  //new stuff here
  handleSelect = (e) => {
    if (this.state.unsavedChanges){
      if (window.confirm('Leave this tab without saving changes?')) {
        this.setState({ tabSelected: e.selected, unsavedChanges: false });
      }
    }
    else{
      this.setState({ tabSelected: e.selected});
    }    
  }

  GetAllStyleSettings(cb) {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios(`api/getAllStyleSettings`, {
      method: 'GET',
      params: { company: this.props.company, },
      headers: headers
    })
    .then(response => {
      var styleSettings = {}
      response.data.styles.forEach(styleGroup => {
        styleGroup.contents.forEach(style => {
          styleSettings[style.name] = style.value
        })        
      });
      var userProfileIndex = response.data.styles.findIndex(r2 => r2.name === 'UserProfileSettings');
      if (!utils.checkPermission(this.props.user,'Enable My Profile')){
        response.data.styles.splice(userProfileIndex, 1)
      }

        if (cb){
        this.setState({styleGroups: response.data.styles, styleSettings: styleSettings}, () => {
          cb()
        })
        }
        else{
          this.setState({styleGroups: response.data.styles, styleSettings: styleSettings})
        }
    })
    .catch(err => {
        console.error(err);
    })
  }

  onTabContentChange() {
    this.setState({unsavedChanges: true})
  }

  onTabContentSave() {
    this.setState({unsavedChanges: false})
    this.GetAllStyleSettings(() => {
      this.props.onCompSettingsChange('advisorTab', this.state.styleSettings.advisorTab);
      this.props.onCompSettingsChange('portTabOrder', this.state.styleSettings.portTabOrder);
      this.props.onCompSettingsChange('portfolioTab', this.state.styleSettings.portfolioTab);

      var homeTabTranslated = ''
      switch(this.state.styleSettings.portHomeTab){
        case 'Account Overview':
          homeTabTranslated = 'equityOver'
          break
        case 'Account Summary':
          homeTabTranslated = 'acctSumm'
          break
        case 'Holdings':
          homeTabTranslated = 'holds'
          break
        case 'Fixed Income Holdings':
          homeTabTranslated = 'fiHolds'
          break
        case 'Realized Gains':
          homeTabTranslated = 'rgain'
          break
        case 'Transaction Summary':
          homeTabTranslated = 'tranSumm'
          break
        case 'Performance Overview':
          homeTabTranslated = 'perfOver'
          break
        default:
          break
      }
      
      this.props.onCompSettingsChange('portHomeTab', homeTabTranslated);


      var defaultViews = [];
      var defaultPortTabs = [];

      if (this.state.styleSettings.defaultViewYourDocs){
        defaultViews.push('docTab1')
      }
      if (this.state.styleSettings.defaultViewSharedDocs){
        defaultViews.push('docTab2')
      }
      if (this.state.styleSettings.defaultViewSharedDocs2){
        defaultViews.push('docTab3')
      }
      if (this.state.styleSettings.defaultViewSharedDocs3){
        defaultViews.push('docTab4')
      }
      if (this.state.styleSettings.defaultViewAdvisorSharedDocs){
        defaultViews.push('docTab5')
      }
      if (this.state.styleSettings.defaultViewWebReports){
        defaultViews.push('docTab6')
      }
      if (this.state.styleSettings.defaultViewHouseholdDocs){
        defaultViews.push('docTab7')
      }
      if (this.state.styleSettings.defaultViewPortfolio){
        defaultViews.push('portfolio')
      }


      if (this.state.styleSettings.portTabAcctOverview){
        defaultPortTabs.push('equityOver')
      }
      if (this.state.styleSettings.portTabAcctSumm){
        defaultPortTabs.push('acctSumm')
      }
      if (this.state.styleSettings.portTabHoldings){
        defaultPortTabs.push('holds')
      }
      if (this.state.styleSettings.portTabFixedIncomeHoldings){
        defaultPortTabs.push('fiHolds')
      }
      if (this.state.styleSettings.portTabRGains){
        defaultPortTabs.push('rgain')
      }
      if (this.state.styleSettings.portTabTranSumm){
        defaultPortTabs.push('tranSumm')
      }
      if (this.state.styleSettings.portTabPerfOver){
        defaultPortTabs.push('perfOver')
      }
  
      this.props.onCompSettingsChange("defaultViews", defaultViews);
      this.props.onCompSettingsChange("defaultPortTabs", defaultPortTabs);
    });

  }

  //end new stuff here



  render() {
    return (
      <div>
        <><TabStrip selected={this.state.tabSelected} onSelect={this.handleSelect}>
            {this.state.styleGroups.length === 0 &&
              <TabStripTab title='' key={9999}></TabStripTab>
            }
            {this.state.styleGroups.map((group, i) => {     
              return ( 
                <TabStripTab title={group.description} name={group.name} key={i}>
                  <StyleSetting idToken={this.props.idToken} compSettings={this.props.compSettings} enableMFA={this.state.enableMFA} onCompSettingsChange={this.props.onCompSettingsChange} company={this.props.company} changed={this.onTabContentChange} saved={this.onTabContentSave} contents={group.contents} chartColors={this.state.chartColors}/>
                </TabStripTab>
              ) 
            })}
          </TabStrip>
        </>
        {/* <table className="styleTable">
          <tbody>
             <tr>
              <td className='settingHeader'>Logos:</td><td><Button icon={this.expandIcon('logos')} onClick={(e) => this.changeExpand('logos')} /></td>
            </tr>
            {this.state.expand.logos && <>
              <tr>
                <td className='settingLabel'><strong>Corner Logo (200x50):</strong><img className="cornerUpload" src={this.state.cornerPreview} alt="Corner Logo" /></td>
                <td><LogoUpload previewImage={this.state.cornerPreview} onChangeLogo={(event) => this.onCornerLogoChange(event)} /></td>
              </tr>
            </>} 
             <tr>
              <td className='settingHeader'>Colors:</td><td><Button icon={this.expandIcon('colors')} onClick={(e) => this.changeExpand('colors')} /></td>
            </tr>
            {this.state.expand.colors && <>
              <tr>
                <td className='settingLabel'><strong>Background Color:</strong></td>
                <td><ColorButton defaultColor={this.props.style["--theme-primary-color"]} onChange={this.onBGColorChange} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Font Color (on background):</strong><div className='settingDescription'>This color will be applied to any text that is on top of the background, such as the menu links in the top right.</div></td>
                <td><ColorButton defaultColor={this.props.style["--theme-font-color"]} onChange={this.onFontColorChange} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Chart Colors:</strong><div className='settingDescription'>Colors used for charts and graphs. Colors will be applied to data from top to bottom and will restart at the top if there are not enough colors.</div></td>
                <td><ChartColors colors={this.state.chartColors} onChange={this.onChartColorsChange} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Pie Asset Class/Sector Colors:</strong><div className='settingDescription'>Note: It is recommended if using specifc colors for Asset Class/Sectors to make sure to setup a color for every possible Asset Class/Sector, so there are no conflicts with general chart colors.</div></td>
                <td><ChartColors colors={this.state.pieColors} onChange={this.onPieColorsChange} type='pie' /></td>
              </tr>
            </>} 
             <tr>
              <td className='settingHeader'>Labels:</td><td><Button icon={this.expandIcon('labels')} onClick={(e) => this.changeExpand('labels')} /></td>
            </tr>
            {this.state.expand.labels && <>
              <tr>
                <td className='settingLabel'><strong>"Contact" Label:</strong></td>
                <td ><input value={this.state.contactLabel} onChange={(e) => this.onInputChange(e, 'contactLabel')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>"Documents" Label:</strong></td>
                <td><input value={this.state.docsLabel} onChange={(e) => this.onInputChange(e, 'docsLabel')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>"Users" Label:</strong></td>
                <td><input value={this.state.usersLabel} onChange={(e) => this.onInputChange(e, 'usersLabel')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>"Your Docs" Tab Label:</strong></td>
                <td><input value={this.state.docTab1} onChange={(e) => this.onInputChange(e, 'docTab1')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>"Household Docs" Tab Label:</strong></td>
                <td><input value={this.state.docTab7} onChange={(e) => this.onInputChange(e, 'docTab7')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>"Shared Docs" Tab Label:</strong></td>
                <td><input value={this.state.docTab2} onChange={(e) => this.onInputChange(e, 'docTab2')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>"Shared Docs 2" Tab Label:</strong></td>
                <td><input value={this.state.docTab3} onChange={(e) => this.onInputChange(e, 'docTab3')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>"Shared Docs 3" Tab Label:</strong></td>
                <td><input value={this.state.docTab4} onChange={(e) => this.onInputChange(e, 'docTab4')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>"Advisor Shared Docs" Tab Label:</strong></td>
                <td><input value={this.state.docTab5} onChange={(e) => this.onInputChange(e, 'docTab5')} /></td>
              </tr>
              {this.props.company === 'cssi' && <tr>
                <td className='settingLabel'><strong>"Web Reports" Tab Label:</strong></td>
                <td><input value={this.state.docTab6} onChange={(e) => this.onInputChange(e, 'docTab6')} /></td>
              </tr>}
              <tr>
                <td className='settingLabel'><strong>"User Def 1" Label:</strong><div className='settingDescription'>Note: Cannot be any of the following reserved names for column headers: securityName, secType, Type, assetAllocation, Asset Class, Sector, Industry, acqDate, quantity, marketValue, percentage, totalCost, yield, unrealized, estAnnIncome.</div></td>
                <td><input value={this.state.userDef1} onChange={(e) => this.onInputChange(e, 'userDef1')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>"User Def 2" Label:</strong><div className='settingDescription'>Note: Cannot be any of the following reserved names for column headers: securityName, secType, Type, assetAllocation, Asset Class, Sector, Industry, acqDate, quantity, marketValue, percentage, totalCost, yield, unrealized, estAnnIncome.</div></td>
                <td><input value={this.state.userDef2} onChange={(e) => this.onInputChange(e, 'userDef2')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>"User Def 3" Label:</strong><div className='settingDescription'>Note: Cannot be any of the following reserved names for column headers: securityName, secType, Type, assetAllocation, Asset Class, Sector, Industry, acqDate, quantity, marketValue, percentage, totalCost, yield, unrealized, estAnnIncome.</div></td>
                <td><input value={this.state.userDef3} onChange={(e) => this.onInputChange(e, 'userDef3')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>"User Def 4" Label:</strong><div className='settingDescription'>Note: Cannot be any of the following reserved names for column headers: securityName, secType, Type, assetAllocation, Asset Class, Sector, Industry, acqDate, quantity, marketValue, percentage, totalCost, yield, unrealized, estAnnIncome.</div></td>
                <td><input value={this.state.userDef4} onChange={(e) => this.onInputChange(e, 'userDef4')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>"User Def 5" Label:</strong><div className='settingDescription'>Note: Cannot be any of the following reserved names for column headers: securityName, secType, Type, assetAllocation, Asset Class, Sector, Industry, acqDate, quantity, marketValue, percentage, totalCost, yield, unrealized, estAnnIncome.</div></td>
                <td><input value={this.state.userDef5} onChange={(e) => this.onInputChange(e, 'userDef5')} /></td>
              </tr>
            </>} 
             <tr>
              <td className='settingHeader'>Advisor Settings:</td><td><Button icon={this.expandIcon('advisor')} onClick={(e) => this.changeExpand('advisor')} /></td>
            </tr>
            {this.state.expand.advisor && <>
              <tr>
                <td className='settingLabel'><strong>Advisors can access all users:</strong><div className='settingDescription'>If unchecked, advisors will only have access to their clients.</div></td>
                <td><input className='bigger' type="checkbox" checked={this.state.advAllUsers} onChange={(e) => this.onInputChange(e, 'advAllUsers', true)} /></td>
              </tr>
            </>} 
            <tr>
              <td className='settingHeader'>Views:</td><td><Button icon={this.expandIcon('views')} onClick={(e) => this.changeExpand('views')} /></td>
            </tr>
            {this.state.expand.views && <>
              <tr>
                <td className='settingLabel'><strong>Default Views:</strong><div className='settingDescription'>Pages that the user will have access to by default when invited to the company.</div></td>
                <td><ViewsList data={this.state.views} views={this.state.defaultViews} onChange={this.onViewsChange} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Portfolio Tabs:</strong><div className='settingDescription'>Enable or disable tabs in the portfolio section for the entire company.</div></td>
                <td><ViewsList data={this.state.portTabs} views={this.state.defaultPortTabs} onChange={this.onPortViewsChange} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Portfolio Tab Order:</strong><div className='settingDescription'>Drag the tabs in the desired order you want them to appear.</div></td>
                <td><Sortable data={this.state.portTabOrder} idField={'value'} onDragOver={this.onDragOver} onNavigate={this.onNavigate} itemUI={this.sortItemUI} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Enable "Portfolio" Tab:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.portTab} onChange={(e) => this.onInputChange(e, 'portTab', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Default Portolio Tab:</strong></td>
                <td><TabDropDown items={this.state.portTabOrder} value={this.state.portHomeTab} default={{ text: "First Available Tab", value: '' }} onChange={(e) => this.onInputChange(e, 'portHomeTab')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Enable "Advisor" Tab:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.advTab} onChange={(e) => this.onInputChange(e, 'advTab', true)} /></td>
              </tr>
            </>}
             <tr>
              <td className='settingHeader'>Fonts:</td><td><Button icon={this.expandIcon('fonts')} onClick={(e) => this.changeExpand('fonts')} /></td>
            </tr>
            {this.state.expand.fonts && <>
              <tr>
                <td className='settingLabel'><strong>Website Font:</strong><div className='settingDescription'>Font used for all pages of website</div></td>
                <td><FontDropDown onChange={this.onFontFamilyChange} default={this.props.style['--theme-font-family'] || "-Select a font-"} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Charts Font:</strong><div className='settingDescription'>Font used specifically for items in graphs and charts</div></td>
                <td><FontDropDown onChange={this.onChartFontChange} default={this.props.style['--theme-chart-font'] || "-Select a font-"} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Account Summary Font Size (pt):</strong><div className='settingDescription'>Font size used on account summary page</div></td>
                <td><input type="number" min="1" value={this.state.fontSizes.acctSumm} onChange={(e) => this.onFontSizeChange(e, 'acctSumm')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Performance Overview Font Size (pt):</strong><div className='settingDescription'>Font size used on performance overview page</div></td>
                <td><input type="number" min="1" value={this.state.fontSizes.perfOver} onChange={(e) => this.onFontSizeChange(e, 'perfOver')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Holdings Font Size (pt):</strong><div className='settingDescription'>Font size used on holdings page</div></td>
                <td><input type="number" min="1" value={this.state.fontSizes.holdings} onChange={(e) => this.onFontSizeChange(e, 'holdings')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Fixed Income Holdings Font Size (pt):</strong><div className='settingDescription'>Font size used on fixed income holdings page</div></td>
                <td><input type="number" min="1" value={this.state.fontSizes.fiHoldings} onChange={(e) => this.onFontSizeChange(e, 'fiHoldings')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Transaction Summary Font Size (pt):</strong><div className='settingDescription'>Font size used on transaction summary page</div></td>
                <td><input type="number" min="1" value={this.state.fontSizes.tran} onChange={(e) => this.onFontSizeChange(e, 'tran')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Realized Gains Font Size (pt):</strong><div className='settingDescription'>Font size used on realized gains page</div></td>
                <td><input type="number" min="1" value={this.state.fontSizes.rgain} onChange={(e) => this.onFontSizeChange(e, 'rgain')} /></td>
              </tr>
            </>} 
             <tr>
              <td className='settingHeader'>Document Center:</td><td><Button icon={this.expandIcon('doc')} onClick={(e) => this.changeExpand('doc')} /></td>
            </tr>
            {this.state.expand.doc && <>
              <tr>
                <td className='settingLabel'><strong>Users can upload documents:</strong><div className='settingDescription'>Note: Users can still be indivually setup with upload settings in the users list. This is the default setting for the entire company.</div></td>
                <td><input className='bigger' type="checkbox" checked={this.state.userUpload} onChange={(e) => this.onInputChange(e, 'userUpload', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Folder Order (Comma-Separated):</strong></td>
                <td><input value={this.state.folderOrder} onChange={(event) => this.onChangeFolderOrder(event)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show Documents as grid:</strong><div className='settingDescription'>If not checked, documents will be shown in a tree layout by default.</div></td>
                <td><input className='bigger' type="checkbox" checked={this.state.docGrid} onChange={(e) => this.onInputChange(e, 'docGrid', true)} /></td>
              </tr>
            </>} 
             <tr>
              <td className='settingHeader'>Chart Settings:</td><td><Button icon={this.expandIcon('chart')} onClick={(e) => this.changeExpand('chart')} /></td>
            </tr>
            {this.state.expand.chart && <>
              <tr>
                <td className='settingLabel'><strong>Enable "As Of" date drop-down on charts:</strong><div className='settingDescription'>If checked users will be able to select a drop-down date on charts where applicable.</div></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showAsOf} onChange={(e) => this.onInputChange(e, 'showAsOf', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show only Month-Ends on Performance:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showMonthEnds} onChange={(e) => this.onInputChange(e, 'showMonthEnds', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>How many months of past data users can see:</strong><div className='settingDescription'>Leave blank to include all uploaded data.</div></td>
                <td><input type="number" min="1" value={this.state.monthsBack} onChange={(e) => this.onInputChange(e, 'monthsBack')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Enable Net/Gross fee drop-down:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.enableFeeSelect} onChange={(e) => this.onInputChange(e, 'enableFeeSelect', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Default performance fees option:</strong></td>
                <td>
                  <input type="radio" name="perfFees" value='net' defaultChecked={this.state.defaultFees === 'net'} onChange={(e) => this.onInputChange(e, 'defaultFees')} /><label style={{ marginRight: '10px' }}>Net</label>
                  <input type="radio" name="perfFees" value='gross' defaultChecked={this.state.defaultFees === 'gross'} onChange={(e) => this.onInputChange(e, 'defaultFees')} /><label>Gross</label>
                </td>
              </tr>
            </>} 
             <tr>
              <td className='settingHeader'>Account Landing Settings:</td><td><Button icon={this.expandIcon('acctLand')} onClick={(e) => this.changeExpand('acctLand')} /></td>
            </tr>
            {this.state.expand.acctLand && <>
              <tr>
                <td className='settingLabel'><strong>Columns to show:</strong></td>
                <td><MultiDropDwon placeholder='Select a column' items={this.state.landItems} value={this.state.landCols} onChange={(e) => this.onInputChange(e, 'landCols')} /></td>
              </tr>
            </>} 
             <tr>
              <td className='settingHeader'>Account Summary Settings:</td><td><Button icon={this.expandIcon('acctSumm')} onClick={(e) => this.changeExpand('acctSumm')} /></td>
            </tr>
            {this.state.expand.acctSumm && <>
              <tr>
                <td className='settingLabel'><strong>Default Period One:</strong></td>
                <td><TabDropDown items={this.state.fullPeriods} value={this.state.acctPeriod1} default={{ text: "Hidden", value: '' }} onChange={(e) => this.onInputChange(e, 'acctPeriod1')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Default Period Two:</strong></td>
                <td><TabDropDown items={this.state.fullPeriods} value={this.state.acctPeriod2} default={{ text: "Hidden", value: '' }} onChange={(e) => this.onInputChange(e, 'acctPeriod2')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Default Period Three:</strong></td>
                <td><TabDropDown items={this.state.fullPeriods} value={this.state.acctPeriod3} default={{ text: "Hidden", value: '' }} onChange={(e) => this.onInputChange(e, 'acctPeriod3')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Default Period (Consolidated Version):</strong></td>
                <td><TabDropDown items={this.state.fullPeriods} value={this.state.acctPeriodMult} onChange={(e) => this.onInputChange(e, 'acctPeriodMult')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Available Periods:</strong><div className='settingDescription'>Default periods must be in available periods list to function correctly.</div></td>
                <td><MultiDropDwon placeholder='Select a period' items={this.state.fullPeriods} value={this.state.acctDropPeriods} onChange={(e) => this.onInputChange(e, 'acctDropPeriods')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show IRR:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showAcctSummIrr} onChange={(e) => this.onInputChange(e, 'showAcctSummIrr', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show TWR:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showAcctSummTwr} onChange={(e) => this.onInputChange(e, 'showAcctSummTwr', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Income" total:</strong><div className='settingDescription'>Displays "Income" instead of "Interest", "Dividends", and "Change in Accrued Interest".</div></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showIncome} onChange={(e) => this.onInputChange(e, 'showIncome', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Investment Gain/Loss" total:</strong><div className='settingDescription'>Displays "Investment Gain/Loss" instead of "Realized Gain" and "Unrealized Gain".</div></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showInvstGL} onChange={(e) => this.onInputChange(e, 'showInvstGL', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show management/portfolio fees paid by client:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showFeesPaid} onChange={(e) => this.onInputChange(e, 'showFeesPaid', true)} /></td>
              </tr>
            </>} 
             <tr>
              <td className='settingHeader'>Account Overview Settings:</td><td><Button icon={this.expandIcon('acctOver')} onClick={(e) => this.changeExpand('acctOver')} /></td>
            </tr>
            {this.state.expand.acctOver && <>
              {this.state.classificationGrouping1 != null && <tr>
                <td className='settingLabel'><strong>Classification Groupings:</strong></td>
                <td><TabDropDown width={300} items={this.state.classificationGrouping1options} value={this.state.classificationGrouping1} onChange={(e) => this.onInputChange(e, 'classificationGrouping1')} /></td>
              </tr>}
              <tr>
                <td className='settingLabel'><strong>Pie Chart Border Color:</strong></td>
                <td><ColorButton defaultColor={this.state.pieBorderColor} onChange={(e) => this.onInputChange(e, 'pieBorderColor')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Pie Chart Border Thickness (pixels):</strong></td>
                <td><input type="number" min="0" step=".1" value={this.state.pieBorderWidth} onChange={(e) => this.onInputChange(e, 'pieBorderWidth')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Pie Chart Gradient:</strong></td>
                <td><TabDropDown items={this.state.gradients} value={this.state.pieGradient} default={{ text: "None", value: 'none' }} onChange={(e) => this.onInputChange(e, 'pieGradient')} /></td>
              </tr>
            </>} 
             <tr>
              <td className='settingHeader'>Performance Overview Settings:</td><td><Button icon={this.expandIcon('perfOver')} onClick={(e) => this.changeExpand('perfOver')} /></td>
            </tr>
            {this.state.expand.perfOver && <>
              <tr>
                <td className='settingLabel'><strong>Use IRR instead of TWR:</strong><div className='settingDescription'>Note: This setting will also impact the performance overview column chart.</div></td>
                <td><input className='bigger' type="checkbox" checked={this.state.perfHistIrr} onChange={(e) => this.onInputChange(e, 'perfHistIrr', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Enable IRR/TWR dropdown:</strong><div className='settingDescription'>Note: This setting will also impact the performance overview column chart.</div></td>
                <td><input className='bigger' type="checkbox" checked={this.state.enablePerfReturnSelect} onChange={(e) => this.onInputChange(e, 'enablePerfReturnSelect', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Start Date" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showPerfStart} onChange={(e) => this.onInputChange(e, 'showPerfStart', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show Benchmark rows associated with accounts:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showPerfBench} onChange={(e) => this.onInputChange(e, 'showPerfBench', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "MTD" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showPerfMTD} onChange={(e) => this.onInputChange(e, 'showPerfMTD', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "QTD" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showPerfQTD} onChange={(e) => this.onInputChange(e, 'showPerfQTD', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Prev Qtr" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showPerfPrevQtr} onChange={(e) => this.onInputChange(e, 'showPerfPrevQtr', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "3 Months" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showPerf3Month} onChange={(e) => this.onInputChange(e, 'showPerf3Month', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "6 Months" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showPerf6Month} onChange={(e) => this.onInputChange(e, 'showPerf6Month', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "YTD" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showPerfYTD} onChange={(e) => this.onInputChange(e, 'showPerfYTD', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Prev Year" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showPerfPrevYear} onChange={(e) => this.onInputChange(e, 'showPerfPrevYear', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "1 Year" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showPerfYear} onChange={(e) => this.onInputChange(e, 'showPerfYear', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "3 Years" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showPerf3Year} onChange={(e) => this.onInputChange(e, 'showPerf3Year', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "5 Years" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showPerf5Year} onChange={(e) => this.onInputChange(e, 'showPerf5Year', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "7 Years" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showPerf7Year} onChange={(e) => this.onInputChange(e, 'showPerf7Year', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "10 Years" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showPerf10Year} onChange={(e) => this.onInputChange(e, 'showPerf10Year', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "15 Years" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showPerf15Year} onChange={(e) => this.onInputChange(e, 'showPerf15Year', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "20 Years" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showPerf20Year} onChange={(e) => this.onInputChange(e, 'showPerf20Year', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "25 Years" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showPerf25Year} onChange={(e) => this.onInputChange(e, 'showPerf25Year', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "ITD" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showPerfITD} onChange={(e) => this.onInputChange(e, 'showPerfITD', true)} /></td>
              </tr>
            </>} 
             <tr>
              <td className='settingHeader'>Performance Overview Column Chart Settings:</td><td><Button icon={this.expandIcon('perfOverCol')} onClick={(e) => this.changeExpand('perfOverCol')} /></td>
            </tr>
            {this.state.expand.perfOverCol && <>
              <tr>
                <td className='settingLabel'><strong>Show Benchmark columns associated with account (single account view only):</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showTwrBench} onChange={(e) => this.onInputChange(e, 'showTwrBench', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "MTD" columns:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showTwrMTD} onChange={(e) => this.onInputChange(e, 'showTwrMTD', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "QTD" columns:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showTwrQTD} onChange={(e) => this.onInputChange(e, 'showTwrQTD', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Prev Qtr" columns:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showTwrPrevQtr} onChange={(e) => this.onInputChange(e, 'showTwrPrevQtr', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "3 Months" columns:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showTwr3Month} onChange={(e) => this.onInputChange(e, 'showTwr3Month', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "6 Months" columns:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showTwr6Month} onChange={(e) => this.onInputChange(e, 'showTwr6Month', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "YTD" columns:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showTwrYTD} onChange={(e) => this.onInputChange(e, 'showTwrYTD', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Prev Year" columns:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showTwrPrevYear} onChange={(e) => this.onInputChange(e, 'showTwrPrevYear', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "1 Year" columns:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showTwrYear} onChange={(e) => this.onInputChange(e, 'showTwrYear', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "3 Years" columns:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showTwr3Year} onChange={(e) => this.onInputChange(e, 'showTwr3Year', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "5 Years" columns:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showTwr5Year} onChange={(e) => this.onInputChange(e, 'showTwr5Year', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "7 Years" columns:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showTwr7Year} onChange={(e) => this.onInputChange(e, 'showTwr7Year', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "10 Years" columns:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showTwr10Year} onChange={(e) => this.onInputChange(e, 'showTwr10Year', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "15 Years" columns:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showTwr15Year} onChange={(e) => this.onInputChange(e, 'showTwr15Year', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "20 Years" columns:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showTwr20Year} onChange={(e) => this.onInputChange(e, 'showTwr20Year', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "25 Years" columns:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showTwr25Year} onChange={(e) => this.onInputChange(e, 'showTwr25Year', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "ITD" columns:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showTwrITD} onChange={(e) => this.onInputChange(e, 'showTwrITD', true)} /></td>
              </tr>
            </>} 

             <tr>
              <td className='settingHeader'>Holdings Summary Settings:</td><td><Button icon={this.expandIcon('holdSumm')} onClick={(e) => this.changeExpand('holdSumm')} /></td>
            </tr> 
            {this.state.expand.holdSumm && <>

              <tr>
                <td className='settingLabel'><strong>Show "Security Name" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsSummSecurityName} onChange={(e) => this.onInputChange(e, 'ShowHoldingsSummSecurityName', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Security Symbol" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsSummSecuritySymbol} onChange={(e) => this.onInputChange(e, 'ShowHoldingsSummSecuritySymbol', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Quantity" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsSummQuantity} onChange={(e) => this.onInputChange(e, 'ShowHoldingsSummQuantity', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Unrealized G/L" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsSummUnrealized} onChange={(e) => this.onInputChange(e, 'ShowHoldingsSummUnrealized', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Total Cost" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsSummTotalCost} onChange={(e) => this.onInputChange(e, 'ShowHoldingsSummTotalCost', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Market Value" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsSummMarketValue} onChange={(e) => this.onInputChange(e, 'ShowHoldingsSummMarketValue', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Portfolio" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsSummPortfolio} onChange={(e) => this.onInputChange(e, 'ShowHoldingsSummPortfolio', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Percentage" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsSummPercentage} onChange={(e) => this.onInputChange(e, 'ShowHoldingsSummPercentage', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Asset Allocation" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsSummAssetAllocation} onChange={(e) => this.onInputChange(e, 'ShowHoldingsSummAssetAllocation', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Industry" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsSummIndustry} onChange={(e) => this.onInputChange(e, 'ShowHoldingsSummIndustry', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Sector" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsSummSector} onChange={(e) => this.onInputChange(e, 'ShowHoldingsSummSector', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "UserDef1" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsSummUser1} onChange={(e) => this.onInputChange(e, 'ShowHoldingsSummUser1', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "UserDef2" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsSummUser2} onChange={(e) => this.onInputChange(e, 'ShowHoldingsSummUser2', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "UserDef3" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsSummUser3} onChange={(e) => this.onInputChange(e, 'ShowHoldingsSummUser3', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "UserDef4" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsSummUser4} onChange={(e) => this.onInputChange(e, 'ShowHoldingsSummUser4', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "UserDef5" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsSummUser5} onChange={(e) => this.onInputChange(e, 'ShowHoldingsSummUser5', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Classification Grouping 1:</strong><div className='settingDescription'>If grouping by a user-defined field, this setting must be updated each time the corresponding user def label is changed.</div></td>
                <td><TabDropDown items={this.state.holdGroups} value={this.state.holdingsSummGrp1} default={{ text: "None", value: '' }} onChange={(e) => this.onInputChange(e, 'holdingsSummGrp1')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Classification Grouping 2:</strong><div className='settingDescription'>If grouping by a user-defined field, this setting must be updated each time the corresponding user def label is changed.</div></td>
                <td><TabDropDown items={this.state.holdGroups} value={this.state.holdingsSummGrp2} default={{ text: "None", value: '' }} onChange={(e) => this.onInputChange(e, 'holdingsSummGrp2')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Classification Grouping 3:</strong><div className='settingDescription'>If grouping by a user-defined field, this setting must be updated each time the corresponding user def label is changed.</div></td>
                <td><TabDropDown items={this.state.holdGroups} value={this.state.holdingsSummGrp3} default={{ text: "None", value: '' }} onChange={(e) => this.onInputChange(e, 'holdingsSummGrp3')} /></td>
              </tr>

            </>}


             <tr>
              <td className='settingHeader'>Holdings Settings:</td><td><Button icon={this.expandIcon('holds')} onClick={(e) => this.changeExpand('holds')} /></td>
            </tr>
            {this.state.expand.holds && <>
              <tr>
                <td className='settingLabel'><strong>Show "Asset Class" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showHoldAsset} onChange={(e) => this.onInputChange(e, 'showHoldAsset', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Security Type" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showHoldSecType} onChange={(e) => this.onInputChange(e, 'showHoldSecType', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Sector" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showHoldSector} onChange={(e) => this.onInputChange(e, 'showHoldSector', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Industry" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showHoldInd} onChange={(e) => this.onInputChange(e, 'showHoldInd', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "User Def 1" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showHoldUser1} onChange={(e) => this.onInputChange(e, 'showHoldUser1', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "User Def 2" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showHoldUser2} onChange={(e) => this.onInputChange(e, 'showHoldUser2', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "User Def 3" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showHoldUser3} onChange={(e) => this.onInputChange(e, 'showHoldUser3', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "User Def 4" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showHoldUser4} onChange={(e) => this.onInputChange(e, 'showHoldUser4', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "User Def 5" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showHoldUser5} onChange={(e) => this.onInputChange(e, 'showHoldUser5', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Acq Date" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsAcqDate} onChange={(e) => this.onInputChange(e, 'ShowHoldingsAcqDate', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Quantity" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsQuantity} onChange={(e) => this.onInputChange(e, 'ShowHoldingsQuantity', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Unit Cost" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsUnitCost} onChange={(e) => this.onInputChange(e, 'ShowHoldingsUnitCost', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Total Cost" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsTotalCost} onChange={(e) => this.onInputChange(e, 'ShowHoldingsTotalCost', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Unit Price" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsPrice} onChange={(e) => this.onInputChange(e, 'ShowHoldingsPrice', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Market Value" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsMarketValue} onChange={(e) => this.onInputChange(e, 'ShowHoldingsMarketValue', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "% of Account" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsPctOfAccount} onChange={(e) => this.onInputChange(e, 'ShowHoldingsPctOfAccount', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Est Annual Income" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsEstAnnIncome} onChange={(e) => this.onInputChange(e, 'ShowHoldingsEstAnnIncome', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Yield" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowHoldingsYield} onChange={(e) => this.onInputChange(e, 'ShowHoldingsYield', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Always Show Lumped Positions to "User":</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.AlwaysLumpUserHoldings} onChange={(e) => this.onInputChange(e, 'AlwaysLumpUserHoldings', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Classification Grouping 1:</strong><div className='settingDescription'>If grouping by a user-defined field, this setting must be updated each time the corresponding user def label is changed.</div></td>
                <td><TabDropDown items={this.state.holdGroups} value={this.state.holdGrp1} default={{ text: "None", value: '' }} onChange={(e) => this.onInputChange(e, 'holdGrp1')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Classification Grouping 2:</strong><div className='settingDescription'>If grouping by a user-defined field, this setting must be updated each time the corresponding user def label is changed.</div></td>
                <td><TabDropDown items={this.state.holdGroups} value={this.state.holdGrp2} default={{ text: "None", value: '' }} onChange={(e) => this.onInputChange(e, 'holdGrp2')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Classification Grouping 3:</strong><div className='settingDescription'>If grouping by a user-defined field, this setting must be updated each time the corresponding user def label is changed.</div></td>
                <td><TabDropDown items={this.state.holdGroups} value={this.state.holdGrp3} default={{ text: "None", value: '' }} onChange={(e) => this.onInputChange(e, 'holdGrp3')} /></td>
              </tr>
            </>} 
             <tr>
              <td className='settingHeader'>Fixed Income Holdings Settings:</td><td><Button icon={this.expandIcon('fiHolds')} onClick={(e) => this.changeExpand('fiHolds')} /></td>
            </tr>
            {this.state.expand.fiHolds && <>

              <tr>
                <td className='settingLabel'><strong>Include Cash</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.FixedHoldIncludeCash} onChange={(e) => this.onInputChange(e, 'FixedHoldIncludeCash', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Asset Class" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowFixedHoldAsset} onChange={(e) => this.onInputChange(e, 'ShowFixedHoldAsset', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Security Type" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowFixedHoldSecType} onChange={(e) => this.onInputChange(e, 'ShowFixedHoldSecType', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Industry Group" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowFixedHoldInd} onChange={(e) => this.onInputChange(e, 'ShowFixedHoldInd', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Sector" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowFixedHoldSector} onChange={(e) => this.onInputChange(e, 'ShowFixedHoldSector', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "CUSIP" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowFixedHoldCusip} onChange={(e) => this.onInputChange(e, 'ShowFixedHoldCusip', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "S&P Rating" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowFixedHoldSPRating} onChange={(e) => this.onInputChange(e, 'ShowFixedHoldSPRating', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Moodys Rating" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowFixedHoldMoodyRating} onChange={(e) => this.onInputChange(e, 'ShowFixedHoldMoodyRating', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Par" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowFixedHoldQuantity} onChange={(e) => this.onInputChange(e, 'ShowFixedHoldQuantity', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Coupon" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowFixedHoldInterestDividendRate} onChange={(e) => this.onInputChange(e, 'ShowFixedHoldInterestDividendRate', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Next Call Date" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowFixedHoldNextCallDate} onChange={(e) => this.onInputChange(e, 'ShowFixedHoldNextCallDate', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Maturity Date" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowFixedHoldMaturityDate} onChange={(e) => this.onInputChange(e, 'ShowFixedHoldMaturityDate', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Total Cost" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowFixedHoldTotalCost} onChange={(e) => this.onInputChange(e, 'ShowFixedHoldTotalCost', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Market Value" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowFixedHoldMarketValue} onChange={(e) => this.onInputChange(e, 'ShowFixedHoldMarketValue', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Annual Income" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowFixedHoldEstAnnIncome} onChange={(e) => this.onInputChange(e, 'ShowFixedHoldEstAnnIncome', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Unrealized G/L" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowFixedHoldUnrealized} onChange={(e) => this.onInputChange(e, 'ShowFixedHoldUnrealized', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "Percent of Portfolio" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowFixedHoldPercentage} onChange={(e) => this.onInputChange(e, 'ShowFixedHoldPercentage', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "UserDef1" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowFixedHoldUser1} onChange={(e) => this.onInputChange(e, 'ShowFixedHoldUser1', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "UserDef2" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowFixedHoldUser2} onChange={(e) => this.onInputChange(e, 'ShowFixedHoldUser2', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "UserDef3" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowFixedHoldUser3} onChange={(e) => this.onInputChange(e, 'ShowFixedHoldUser3', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "UserDef4" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowFixedHoldUser4} onChange={(e) => this.onInputChange(e, 'ShowFixedHoldUser4', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show "UserDef5" column:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.ShowFixedHoldUser5} onChange={(e) => this.onInputChange(e, 'ShowFixedHoldUser5', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Classification Grouping 1:</strong><div className='settingDescription'>If grouping by a user-defined field, this setting must be updated each time the corresponding user def label is changed.</div></td>
                <td><TabDropDown items={this.state.holdGroups} value={this.state.fixedHoldGrp1} default={{ text: "None", value: '' }} onChange={(e) => this.onInputChange(e, 'fixedHoldGrp1')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Classification Grouping 2:</strong><div className='settingDescription'>If grouping by a user-defined field, this setting must be updated each time the corresponding user def label is changed.</div></td>
                <td><TabDropDown items={this.state.holdGroups} value={this.state.fixedHoldGrp2} default={{ text: "None", value: '' }} onChange={(e) => this.onInputChange(e, 'fixedHoldGrp2')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Classification Grouping 3:</strong><div className='settingDescription'>If grouping by a user-defined field, this setting must be updated each time the corresponding user def label is changed.</div></td>
                <td><TabDropDown items={this.state.holdGroups} value={this.state.fixedHoldGrp3} default={{ text: "None", value: '' }} onChange={(e) => this.onInputChange(e, 'fixedHoldGrp3')} /></td>
              </tr>

            </>} 


             <tr>
              <td className='settingHeader'>Market Value Tab Settings:</td><td><Button icon={this.expandIcon('markVal')} onClick={(e) => this.changeExpand('markVal')} /></td>
            </tr> 
            {this.state.expand.markVal && <>
              <tr>
                <td className='settingLabel'><strong>Default Chart Type:</strong></td>
                <td><TabDropDown items={this.state.chartTypes} value={this.state.mvChart} onChange={(e) => this.onInputChange(e, 'mvChart')} /></td>
              </tr>
            </>}
             <tr>
              <td className='settingHeader'>Transaction Tab Settings:</td><td><Button icon={this.expandIcon('tran')} onClick={(e) => this.changeExpand('tran')} /></td>
            </tr>
            {this.state.expand.tran && <>
              <tr>
                <td className='settingLabel'><strong>Show "Settlement Date":</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showSettle} onChange={(e) => this.onInputChange(e, 'showSettle', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Default Transaction Period:</strong></td>
                <td><TabDropDown items={this.state.periods} value={this.state.tranPeriod} default={{ text: "Earliest date in last upload", value: '' }} onChange={(e) => this.onInputChange(e, 'tranPeriod')} /></td>
              </tr>
            </>} 
             <tr>
              <td className='settingHeader'>Performance Growth Settings:</td><td><Button icon={this.expandIcon('perfGrow')} onClick={(e) => this.changeExpand('perfGrow')} /></td>
            </tr> 
            {this.state.expand.perfGrow && <>
              <tr>
                <td className='settingLabel'><strong>Use IRR instead of TWR:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.growthIrr} onChange={(e) => this.onInputChange(e, 'growthIrr', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Default Performance Period:</strong></td>
                <td><TabDropDown items={this.state.periods} value={this.state.growthPeriod} default={{ text: "Earliest date in last upload", value: '' }} onChange={(e) => this.onInputChange(e, 'growthPeriod')} /></td>
              </tr>
            </>}
             <tr>
              <td className='settingHeader'>Account AUM Settings:</td><td><Button icon={this.expandIcon('aum')} onClick={(e) => this.changeExpand('aum')} /></td>
            </tr> 
            {this.state.expand.aum && <>
              <tr>
                <td className='settingLabel'><strong>Default Classification Grouping:</strong></td>
                <td><TabDropDown items={this.state.aumGroups} value={this.state.aumGrp} onChange={(e) => this.onInputChange(e, 'aumGrp')} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Available Classification Groups:</strong><div className='settingDescription'>Default group must be in available groups list to function correctly.</div></td>
                <td><MultiDropDwon placeholder='Select a group' items={this.state.aumGroups} value={this.state.aumDropGrps} onChange={(e) => this.onInputChange(e, 'aumDropGrps')} /></td>
              </tr>
            </>}
             <tr>
              <td className='settingHeader'>Realized Gains Settings:</td><td><Button icon={this.expandIcon('rgain')} onClick={(e) => this.changeExpand('rgain')} /></td>
            </tr>
            {this.state.expand.rgain && <>
              <tr>
                <td className='settingLabel'><strong>Default Period:</strong></td>
                <td><TabDropDown items={this.state.periods} value={this.state.rgainPeriod} default={{ text: "Earliest date in last upload", value: '' }} onChange={(e) => this.onInputChange(e, 'rgainPeriod')} /></td>
              </tr>
            </>} 
             <tr>
              <td className='settingHeader'>Misc Settings:</td><td><Button icon={this.expandIcon('misc')} onClick={(e) => this.changeExpand('misc')} /></td>
            </tr>
            {this.state.expand.misc && <>
              <tr>
                <td className='settingLabel'><strong>Show consolidated versions by default when available:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showConsolidated} onChange={(e) => this.onInputChange(e, 'showConsolidated', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Show filters by default:</strong></td>
                <td><input className='bigger' type="checkbox" checked={this.state.showFilters} onChange={(e) => this.onInputChange(e, 'showFilters', true)} /></td>
              </tr>
              <tr>
                <td className='settingLabel'><strong>Default households per page:</strong><div className='settingDescription'>Used on the household list</div></td>
                <td><input type="number" min="1" step="1" value={this.state.hhPerPage} onChange={(e) => this.onInputChange(e, 'hhPerPage')} /></td>
              </tr>
            </>} 
            <tr>
              <td className='settingLabel'></td>
              <td><Button onClick={this.applyChanges}>Apply Changes</Button></td>
            </tr>
          </tbody>
        </table> */}
      </div>
    );
  }

}

export default StyleCenter;