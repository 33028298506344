import React from "react";
// import axios from 'axios';
import {Grid, GridColumn, GridDetailRow} from '@progress/kendo-react-grid';
// import GridLoading from "../../Loaders/GridLoading";


class HouseholdDetail extends GridDetailRow{

  constructor(props) {
    super(props);
    
    this.state = {
    }
  }


  formatCurrency = (val) =>{
    var formatted = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(val);
    return formatted;
  }

  headerCellRender = (thElement, cellProps) => {
    if(thElement!==null){
      var style={cursor:'default'};

      if(cellProps.field==='marketValue'){
        style={cursor:'pointer', justifyContent:'right'};
        return(<div {...thElement.props} style={style}>
          <span class="k-link !k-cursor-default">
            <span class="k-column-title" style={style}>
              Market Value As Of: {this.props.dataItem.date}
            </span>
          </span>
        </div>)
      }

      return(
        <div {...thElement.props} style={style}/>
      );
    }

    return thElement;
  }

  CellRender = (tdElement, cellProps) => {
    var val;
    var style;

    if(tdElement!==null){
      style={cursor:'pointer'};

      if(cellProps.field==='marketValue'){
          style={cursor:'pointer', textAlign:'right'};
          if(cellProps.dataItem['closeDate']){
            return(<td {...tdElement.props} style={style}>{'closed ' + cellProps.dataItem['closeDate']}</td>);
          }
          val = cellProps.dataItem[cellProps.field];
          if(val===null)
            val = '-';
          else
            val = this.formatCurrency(val);

        return(<td {...tdElement.props} style={style}>{val}</td>);
      }

      return(
        <td {...tdElement.props} style={style}/>
      );
    }

    return tdElement;
  }

  render() {
    const data = this.props.dataItem.details;
    var includeMV = false	
    if(data && data.length > 0)	
      includeMV = this.props.dataItem.includeMV

    return(
        <div>
            <Grid data={data}
                style={{maxHeight:'30vh'}} 
                headerCellRender={this.headerCellRender}
                cellRender={this.CellRender}
                onRowClick={(e)=>e.dataItem.onAcctClick(e)}
                >
                <GridColumn headerClassName='gridHeader' field='account' title='Account' />
                <GridColumn headerClassName='gridHeader' field='displayName' title='Display Name' />
                {includeMV && <GridColumn headerClassName='gridHeader' field='marketValue' title='Market Value' format="{0:c2}" />}
            </Grid>
        </div>
    );
  }

}
export default HouseholdDetail;

