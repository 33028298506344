import React, { Component } from "react";
import { Button } from '@progress/kendo-react-buttons';
import {Grid, GridColumn, GridToolbar} from '@progress/kendo-react-grid';
import axios from 'axios';
import { process } from '@progress/kendo-data-query';

class WebRepWhiteList extends Component {

  constructor(props) {
    super(props);
    this.state = {
        result:[],
        whitelist:[],
        edit:false,
        dataState:{skip:0, take:25}
    }

    this.getWhiteList=this.getWhiteList.bind(this);
    this.clearState=this.clearState.bind(this);
  }

  componentDidMount(){
    this.getWhiteList();
  }

  clearState(){
    this.setState({
        edit:false
    });
  };

  uploadWhiteList(e, list){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      list:list,
      company:this.props.user.company
    }
    axios.post('api/whitelist', payload, {headers}).then(response=>{
      if(response.data.code===200){
        alert("White List been successfully updated.");
        this.clearState();
        this.getWhiteList();
      }
      else if(response.data.code===304){
        alert(response.data.error);
      }
      else{
        alert("An error has occurred while updating. Please try again.");
        this.clearState();
        this.getWhiteList();
      }
    }).catch(err=>{
      alert("An error has occurred while updating. Please try again.");
      this.clearState();
      this.getWhiteList();
      console.log(err);
    });
  }

  getWhiteList(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios.get('api/whitelist?company='+this.props.user.company, {headers}).then(response=>{
        if(response.data.code===200){
            var result = process(response.data.data, this.state.dataState);
            this.setState({whitelist:response.data.data, result:result});
        }
        else
            alert("Error getting whitelist");
    }).catch(err=>{
      console.log(err);
      this.setState({whitelist:[], result:[]});
    });
  }

  changeDataState = (e)=>{
    var newData = process(this.state.whitelist, e.dataState);
    this.setState({dataState:e.dataState, result:newData});
  }

  gridItemChange = (e) => {
    e.dataItem[e.field] = e.value;
      this.setState({
          whitelist: [ ...this.state.whitelist ]
      });
  }

  editGrid = (e) =>{
    var isEdit = !this.state.edit;
    var editList = this.state.whitelist.slice();
    for(let i=0; i<editList.length; i++){
        editList[i].inEdit = isEdit;
    }
    var result = process(editList, this.state.dataState);
    this.setState({whitelist:editList, result:result, edit:isEdit});
  }

  addListItem = (e) =>{
    var editList = this.state.whitelist.slice();
    var id = 1;
    if(editList.length>0){
      id = editList[editList.length-1].id+1
    }
    editList.splice(0,0,{id:id, domain:"", company:"", inEdit:true});
    var result = process(editList, this.state.dataState);
    this.setState({whitelist:editList, result:result});
  }

  deleteListItem(e, cell){
    var editList = this.state.whitelist.slice();
    var index = editList.findIndex(x=>{
      return (x.id===cell.dataItem.id);
    });
    if(index>=0){
      editList.splice(index, 1);
      var result = process(editList, this.state.dataState);
      this.setState({whitelist:editList, result:result});
    }
  }

  createCell(cell){
    return (<td><Button icon="trash" onClick = {(event) => this.deleteListItem(event, cell)}>Delete?</Button></td>);
  }


  render() {
    return(
        <div>
            <span className='acctMappingList'>
              <Grid data={this.state.result}
                    pageable={true}
                    filterable={true}
                    sortable={true}
                    editField="inEdit"
                    dataState={this.state.dataState}
                    onDataStateChange={this.changeDataState}
                    onItemChange={this.gridItemChange}
                    {...this.state.dataState}>
                  <GridToolbar>
                    <div><strong>Current White List</strong></div>
                    <Button className='docButton' icon='edit' onClick={this.editGrid}>Edit</Button>
                    <Button className='docButton' icon='plus' disabled={!this.state.edit} onClick={this.addListItem}>Add Domain</Button>
                    <Button className='docButton' icon='save' disabled={!this.state.edit} onClick={e=>this.uploadWhiteList(e, this.state.whitelist)}>Save</Button>
                  </GridToolbar>
                  <GridColumn field='domain' headerClassName='gridHeader' title='Domain' />
                  <GridColumn field='company' headerClassName='gridHeader' title='Company' />
                  {this.state.edit && <GridColumn editable={false} cell={(props) => this.createCell(props)}/>}
              </Grid>
            </span>
        </div>
    );
  }

}

export default WebRepWhiteList;