import React, { Component } from "react";
import { Button } from '@progress/kendo-react-buttons';
import UploadForm from './UploadForm';
import {Grid, GridColumn, GridToolbar} from '@progress/kendo-react-grid';
import {filterBy, orderBy} from '@progress/kendo-data-query';
import AddFolder from './AddFolder';
import axios from 'axios';
import FileTree from './FileTree';
import {parseDate, formatDate} from '@telerik/kendo-intl';

class SharedTab extends Component {

  constructor(props) {
    super(props);

    // var CompLayoutText = "Grid Layout";
    // if(props.compSettings.docGrid)
    //     CompLayoutText = "Folder Layout";

    this.state = {
      uploadType:'admin',
      categories:[],
      fileDownload: { text: "", path: ""},
      fileSelected: false,
      layoutText:'',
      gridLayout:false,
      gridSharedFiles:[],
      gridFilter: undefined,
      sharedFiles:[],
      sharedFolders:[],
      filterable:props.styleSettings.showFilters,
      refreshText:'',
      sharedFilesLoading:true,
      sort:[{field:'uploadDate', dir:'desc'}],
      styleSettings:{
        documentFolderOrder: null,
        docGrid: null,
      }
    }

    this.onUploadPublicDoc = this.onUploadPublicDoc.bind(this);
    this.onPublicDelete = this.onPublicDelete.bind(this);
    this.getSharedFiles = this.getSharedFiles.bind(this);
    this.createNewFolder = this.createNewFolder.bind(this);
    this.clickTimeout = null;
    this.getStyleSettings=this.getStyleSettings.bind(this);

  }

  componentDidMount() {
    this.getSharedFiles();
    this.getStyleSettings();
  }

  getStyleSettings() {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = ['documentFolderOrder', 'docGrid', 'showFilters']

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
    .then(response => {
        var CompLayoutText = "Grid Layout";
            console.log(response.data.styleSettings)
            if(response.data.styleSettings.docGrid)
            CompLayoutText = "Folder Layout";

      this.setState({styleSettings: response.data.styleSettings, /*filterable:response.data.styleSettings.showFilters,*/ layoutText: CompLayoutText ,gridLayout: response.data.styleSettings.docGrid})
    })
    .catch(err => {
        console.error(err);
    })
}

  showUpload() {
    if (this.props.uploadUser.role === 'admin')
        return true;

    return false;
  }

  onClick = (event) => {
    this.download();
  }

  download() {  
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {"files":[], "company":this.props.user.company};

    if(Array.isArray(this.state.fileDownload)){
        if(this.state.fileDownload.length>1){
            for(let i=0; i<this.state.fileDownload.length; i++){
                payload.files.push({
                    "path": this.state.fileDownload[i].path,
                    "name":this.state.fileDownload[i].text
                });
            }
                axios
                    .post("api/downloadzip", payload, {headers})
                    .then((response) => {
                        window.open("api/downloadzip?user="+this.props.uploadUser.user);
                    })
                    .catch((e) => {
                        //console.error("ERROR");
                    });
        }
        else{
            
            var payload2 = {
                company:this.props.user.company,
                path:this.state.fileDownload[0].path,
                name:this.state.fileDownload[0].text,
                type:'download'
                }

            //.get("api/download?company="+this.props.user.company+"&path="+this.state.fileDownload[0].path+"&name="+this.state.fileDownload[0].text+"&type=download", {headers})
            axios
            .post("api/download",payload2, {headers})
            .then((response) => {
                window.open(response.data);
            })
            .catch((e) => {
                return false;
            });
        }
    }
    else if(!this.state.fileDownload.path.endsWith('/')){
        
            var payload3 = {
                company:this.props.user.company,
                path:this.state.fileDownload.path,
                name:this.state.fileDownload.text,
                type:'download'
                }
            //.get("api/download?company="+this.props.user.company+"&path="+this.state.fileDownload.path+"&name="+this.state.fileDownload.text+"&type=download", {headers})
            axios
            .post("api/download",payload3, {headers})

            .then((response) => {
                window.open(response.data);
            })
            .catch((e) => {
                return false;
            });
    }

  }

  getDownButtonText(files){
    if(Array.isArray(files) && files.length>1){
        return files.length+' files';
    }
    else if(Array.isArray(files) && files.length===1){
        if(!files[0].text.endsWith('/'))
            return files[0].text;
        else 
            return "";
    }
    else if(Array.isArray(files) && files.length===0){
        return "";
    }
    else if (files!==undefined && files!==null){
        if(!files.text.endsWith('/'))
            return files.text;
        else 
            return "";
    }
    else{
        return "";
    }
  }

  onPublicDelete = (e) => {
    const confirm = window.confirm("Are you sure you want to delete this file?");

    if (confirm === true) {
        var apiBaseUrl = "/api/";
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var payload = {
            "files": this.state.fileDownload,
            "company": this.props.user.company,
            "user": ""
        }
        axios
            .post(apiBaseUrl + "/deleteFile", payload, {headers})
            .then((response) => {
                if (response.data.code === 400) {
                    alert("Error deleting file.");
                }
                this.setState({fileSelected:false});
                this.setState({fileDownload:{text:"", path:""}})
                this.getSharedFiles();
                this.forceUpdate();
            })
            .catch((e) => {
                alert("Error deleting file.");
            });

    }
  }

  createNewFolder(e, directory, addFolder, groups) {
    if(addFolder!=="" && addFolder!==undefined)
    {
        var newFolder = { text: addFolder + "/", path: directory + "/" + addFolder + "/", items: [] };
        var folds;
        var files;

        folds = this.state.sharedFolders.slice();
        files = this.state.sharedFiles.slice();


        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var payload = {
            "folder": newFolder.path,
            "company": this.props.user.company,
            "groups":groups
        };

        axios
            .post("api/createFolder", payload, {headers})
            .then((response) => {
                if (response.data.code === 200) {
                    folds.push(newFolder);
                    files.push(newFolder);
                    this.setState({ sharedFolders: folds });
                    this.setState({ categories: folds })
                    this.setState({ sharedFiles: files });
                    this.setState({newCategory:''});
                }
                else if (response.data.code === 400) {
                    alert("Folder already exists.");
                }
                else if (response.data.code === 300){
                    alert("Folder was created but an error occured when setting permissions");
                    folds.push(newFolder);
                    files.push(newFolder);
                    this.setState({ sharedFolders: folds });
                    this.setState({ categories: folds })
                    this.setState({ sharedFiles: files });
                    this.setState({newCategory:''});                  
                }
            })
            .catch((e) => {
                //console.error("ERROR");
            });
    }
  }

  onGridLayoutClick = (e) => {
    var clearedShared;

    if(this.state.gridLayout===false){
        clearedShared = this.state.sharedFiles.slice();
        this.clearSelected(clearedShared);

        this.setState({sharedFiles:clearedShared});
        this.setState({gridLayout:true});
        this.setState({fileDownload:{ text: "", path: ""}});
        this.setState({fileSelected: false});
        this.setState({layoutText:'Folder Layout'})
    }
    else{
        clearedShared = this.state.gridSharedFiles.slice();
        this.clearSelected(clearedShared);

        this.setState({gridSharedFiles:clearedShared});
        this.setState({gridLayout:false});
        this.setState({gridFilter:undefined});
        this.setState({fileDownload:{ text: "", path: ""}});
        this.setState({fileSelected: false});
        this.setState({layoutText:'Grid Layout'});
    }
    this.getSharedFiles();
  }

  clearSelected(arr) {
    for (var i = 0; i < arr.length; i++) {
        if (arr[i].items !== undefined) {
            for (var j = 0; j < arr[i].items.length; j++) {
                arr[i].items[j].selected = false;
            }
            arr[i].selected = false;                       
        }
        else {
            arr[i].selected = false;
        }
    }
  }

  onItemClick = (event, itemArray, folderArray, gridArray) =>{
    if (event.item != null && event.item.expanded != null) {
        event.item.expanded = !event.item.expanded;
    }
    if (this.clickTimeout !== null) {
        this.onDoubleItemClick(event, itemArray);
        clearTimeout(this.clickTimeout)
        this.clickTimeout = null
      } else {
        this.onSingleItemClick(event, itemArray, folderArray, gridArray);  
        this.clickTimeout = setTimeout(()=>{
        clearTimeout(this.clickTimeout)
          this.clickTimeout = null
        }, 200)
      }
  }

  onSingleItemClick = (event, itemArray, folderArray, gridArray) => {
    var copyFiles = itemArray.slice();
    var copyFolders = [];

    if(folderArray!==null){
    copyFolders = folderArray.slice();
    }

    if(event.dataItem===undefined){
        if (event.item.selected === undefined || event.item.selected === false) {
            this.clearSelected(copyFiles);
            this.clearSelected(copyFolders);
        }
        this.setState(copyFiles);

        //not a folder
        if (event.item.items === undefined) {
            this.setState({ fileDownload: event.item });
            if (event.item.selected === true) {
                event.item.selected = false;
                this.setState({ fileDownload: { text: "", path: "" } })
                this.setState({fileSelected: false});
            }
            else {
                event.item.selected = true;
                this.setState({ fileDownload: event.item });
                this.setState({fileSelected: true});
            }
        }
        //folder without any contents
        else if(event.item.items.length===0){
            if (event.item.selected === true) {
                event.item.selected = false;
                this.setState({ fileDownload: { text: "", path: "" } });
                this.setState({fileSelected: false});
            }
            else {
                event.item.selected = true;
                this.setState({ fileDownload: event.item});
                this.setState({fileSelected: false});
            }
        }
        //folder with contents
        else if(event.item.items.length > 0){
            event.item.selected = false;
            this.setState({ fileDownload: { text: "", path: "" } });
            this.setState({fileSelected: false});
        }
    }
    else{
        if (event.dataItem.selected === undefined || event.dataItem.selected === false) {
            this.clearSelected(copyFiles);
            this.clearSelected(copyFolders);
            this.clearSelected(gridArray);
        }
        this.setState(copyFiles);

        //not a folder
        if (event.dataItem.items === undefined) {
            this.setState({ fileDownload: event.dataItem });
            if (event.dataItem.selected === true) {
                event.dataItem.selected = false;
                this.setState({ fileDownload: { text: "", path: "" } });
                this.setState({fileSelected: false});
            }
            else {
                event.dataItem.selected = true;
                this.setState({ fileDownload: event.dataItem });
                this.setState({fileSelected: true});
            }
        }
        //folder without any contents
        else if(event.dataItem.items.length===0){
            if (event.dataItem.selected === true) {
                event.dataItem.selected = false;
                this.setState({ fileDownload: { text: "", path: "" } });
                this.setState({fileSelected: false});
            }
            else {
                event.dataItem.selected = true;
                this.setState({ fileDownload: event.dataItem});
                this.setState({fileSelected: false});
            }
        }
        //folder with contents
        else if(event.dataItem.items.length > 0){
            event.dataItem.selected = false;
            this.setState({ fileDownload: { text: "", path: "" } });
            this.setState({fileSelected: false});
        }
    }
  }

  onDoubleItemClick = (event, itemArray, gridArray) =>{
    var copyFiles = itemArray.slice();

    if(event.dataItem===undefined){
        if (event.item.selected === undefined || event.item.selected === false) {
            this.clearSelected(copyFiles);
        }
        this.setState(copyFiles);

        if (!Array.isArray(event.item.items) && event.item.items === undefined) {
            this.setState({ fileDownload: event.item });
            if (event.item.selected === true) {
                event.item.selected = false;
                this.setState({ fileDownload: { text: "", path: "" } });
                this.setState({fileSelected: false});

            }
            else {
                event.item.selected = true;
                this.setState({fileSelected: true});
            }

        }
    }
    else{
        if (event.dataItem.selected === undefined || event.dataItem.selected === false) {
            this.clearSelected(copyFiles);
        }
        this.setState(copyFiles);

        if (!Array.isArray(event.dataItem.items) && event.dataItem.items === undefined) {
            this.setState({ fileDownload: event.dataItem });
            if (event.dataItem.selected === true) {
                event.dataItem.selected = false;
                this.setState({ fileDownload: { text: "", path: "" } });
                this.setState({fileSelected: false});
            }
            else {
                event.dataItem.selected = true;
                this.setState({fileSelected: true});
            }

        }
    }
    this.download();
    this.clearSelected(copyFiles);
    this.setState(copyFiles);
    this.setState({ fileDownload: { text: "", path: "" } });
    this.setState({fileSelected: false});
  }

  onExpandChange = (e, itemArray, folderArray,) => {
    const data = [...this.state.sharedFiles];
    const itemIndex = data.findIndex(item => item.path === e.item.path);
    data[itemIndex] = {...e.item};
    if (data[itemIndex].expanded != null) data[itemIndex].expanded = !data[itemIndex].expanded;
    this.setState({ sharedFiles: data });
    this.onItemClick(e, itemArray, folderArray,);
  }

  headerSelectionChange(event, gFiles){
    const checked = event.syntheticEvent.target.checked;
    gFiles.forEach(item => item.selected = checked);
    var selectedFiles = [];
    for(let i=0; i<gFiles.length; i++){
        if(gFiles[i].selected){
            selectedFiles.push(gFiles[i]);
        }
    }
    this.setState({fileDownload:selectedFiles});
    if(selectedFiles.length>0){
        this.setState({fileSelected: true});
    }
    else{
        this.setState({fileSelected: false});
    }
    this.forceUpdate();
  }

  selectionChange(event, gFiles){
      event.dataItem.selected = !event.dataItem.selected;
      var selectedFiles = [];
      for(let i=0; i<gFiles.length; i++){
          if(gFiles[i].path === event.dataItem.path){
              gFiles[i].selected=event.dataItem.selected;
          }
          if(gFiles[i].selected){
              selectedFiles.push(gFiles[i]);
          }
      }
      this.setState({fileDownload:selectedFiles});
      if(selectedFiles.length>0){
          this.setState({fileSelected: true});
      }
      else{
          this.setState({fileSelected: false});
      }
      this.forceUpdate();
  }

  getFolders(fileTree) {
    var folders = [];

    for (var i = 0; i < fileTree.length; i++) {
        if (fileTree[i].items !== undefined) {
            folders.push(fileTree[i]);
        }
    }

    return folders;
  }

  sortFolders(arr){
    var order;

    if(this.state.styleSettings.documentFolderOrder!==null && this.state.styleSettings.documentFolderOrder!==undefined && this.state.styleSettings.documentFolderOrder!==''){
        order=this.state.styleSettings.documentFolderOrder.split(',');
    }

    var sorted = arr;
    if(order!==undefined && order!==null){
        sorted = arr.sort(function(a,b){
            var inA = order.indexOf(a.text.substring(0,a.text.length-1));
            var inB = order.indexOf(b.text.substring(0,b.text.length-1));
    
            if(inA === -1)
            return 1;
            
            return inA-inB;
        });
    }

    return sorted;
  }

  convertFilesToGrid(files){
    var grid = [];
    var folder = "";
    for(let i=0; i<files.length; i++){
        if(files[i].items===undefined || files[i].items===null){
            grid.push({category:"",text:files[i].text, path:files[i].path, description:files[i].description, uploadedBy:files[i].uploadedBy, uploadDate:this.getDate(files[i].uploadDate),   makePrivate:files[i].makePrivate, selected:files[i].selected});
        }
        else{
            folder = files[i].text;
            for(let j=0; j<files[i].items.length; j++){
                grid.push({category:folder,text:files[i].items[j].text, path:files[i].items[j].path, description:files[i].items[j].description, uploadedBy:files[i].items[j].uploadedBy, uploadDate:this.getDate(files[i].items[j].uploadDate),  makePrivate:files[i].items[j].makePrivate, selected:files[i].items[j].selected})
            }
        }
    }
    return grid;
  }

  getDate(mysqlDate){
    var d = parseDate(mysqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
    return d;
  }

  getSharedFiles() {
    this.setState({refreshText:'Loading...'});
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios
        .get("api/publicfiletree?company="+this.props.user.company+"&tab="+this.props.sharedTab+"&loggedInUser="+this.props.uploadUser.user, {headers})
        .then((response) => {
          var orderedFiles = this.sortFolders(response.data);
          this.setState({ sharedFiles: orderedFiles });
          var folds = this.getFolders(response.data);
          this.setState({ sharedFolders: folds });
          this.setState({gridSharedFiles:this.convertFilesToGrid(orderedFiles)});
          this.setState({sharedFilesLoading:false});
          this.setState({refreshText:''});
          return response.data;
        })
        .catch((e) => {
          console.log(e);
          //console.error("ERROR");
        });
  }

  onLinkClick = (event, file, idToken) => {      
    const headers = { 'authorization': 'Bearer '.concat(idToken) };
    
        var payload = {
            company:this.props.user.company,
            path:file.path,
            name:file.text
            }
        //.get("api/download?company="+this.props.user.company+"&path="+file.path+"&name="+file.text, {headers})
        axios
        .post("api/download",payload, {headers})
        .then((response) => {
            window.open(response.data, '_blank');
        })
        .catch((e) => {
            return false;
        });
    return false;
  }

  onUploadPublicDoc(e, files, category, description) {
    this.getSharedFiles();
  }

  FileCell = props => (
    <>
       <td className="fileItem">
         <span className={this.iconClassName(props.dataItem.text)}></span>
         {props.dataItem.text.toLowerCase().endsWith('pdf') && <button className='link-button' onClick={(event) => this.onLinkClick(event, props.dataItem, this.props.idToken)}>{props.dataItem.text}</button>}
         {!props.dataItem.text.toLowerCase().endsWith('pdf') && props.dataItem.text}
         <br/>
         {props.dataItem.makePrivate &&
                                <div className="description" >PRIVATE COMPANY DOCUMENT</div>
                                }
       <div className="description" >{props.dataItem.description}</div>
       <div className="uploadBy" >{this.uploadedByLine(props.dataItem.uploadedBy, props.dataItem.uploadDate)}</div>
       </td>
       
    </>
  );

  uploadedByLine = (uploadedBy, uploadDate ) => {
    var returnString = null

    if (uploadedBy !== undefined) {
        returnString = "Uploaded by: " + uploadedBy;
        if (uploadDate) {
            returnString = returnString.concat(" on " +formatDate(parseDate(uploadDate), "d"))
        }
    }
    

    return returnString;
}

  is = (fileName, ext) => new RegExp(`.${ext}`).test(fileName.toLowerCase());

    iconClassName = (text) => {
        
        if (this.is(text, 'pdf')) {
            return 'k-icon k-font-icon k-i-file-pdf colored-pdf';
        } else if (this.is(text, 'html')) {
            return 'k-icon k-font-icon k-i-html';
        } else if (this.is(text, 'jpg|png')) {
            return 'k-icon k-font-icon k-i-image';
        }
        else if (this.is(text, 'txt')) {
            return 'k-icon k-font-icon k-i-file-txt';
        }
        else if (this.is(text, 'xlsx|xls|xlsm')) {
            return 'k-icon k-font-icon k-i-excel colored-excel';
        }
        else if (this.is(text, 'doc|docx')) {
            return 'k-icon k-font-icon k-i-word colored-word';
        } 
        else if (this.is(text, 'csv')){
            return 'k-icon k-font-icon k-i-csv';
        }
        else if (this.is(text, 'ppt|pptx')){
            return 'k-icon k-font-icon k-i-ppt colored-ppt';
        }
        else if (this.is(text, 'ini|inf')){
            return 'k-icon k-font-icon k-i-config';
        }
        else if(this.is(text, 'zip')){
            return 'k-icon k-font-icon k-i-zip';
        }
        else {
            return 'k-icon k-font-icon k-i-file';
        }
    }

    processSharedData = () => {
        const { gridSharedFiles, sort, gridFilter } = this.state;

        //filterBy(orderBy(this.state.gridHouseholdFiles, this.state.sort), this.state.gridFilter)
        var trimmedData = []
        gridSharedFiles.forEach(row => {
         row.category = row.category.replace('/','')
         trimmedData.push(row)
        })
         return filterBy(orderBy(trimmedData, sort), gridFilter);
     } 

  render() {
      //<GridColumn title="Document Details" field="text" width='600px' headerClassName='gridHeader'cell={this.FileCell} />
    //   console.log(this.state.gridSharedFiles)
    return(
        <div>
            {this.showUpload() && !this.state.sharedFilesLoading && <UploadForm idToken={this.props.idToken} sharedTab={this.props.sharedTab} company={this.props.user.company} user={this.props.user.user} uploadUser={this.props.uploadUser} uploadMethod={this.onUploadPublicDoc} type={this.state.uploadType} files={this.state.sharedFiles} folders={this.state.sharedFolders} descriptions={this.props.descriptions} title={this.props.title} button={<Button className="docButton" icon="upload"> Upload Document</Button>} />}   
            <Button className="docButton" disabled={!this.state.fileSelected} icon="download" onClick={(event) => this.onClick(event)}>Download {this.getDownButtonText(this.state.fileDownload)}</Button>                                  
            {this.showUpload() && <Button className="docButton" disabled={this.state.fileDownload.path===""} onClick={this.onPublicDelete} icon='delete'>Delete</Button>}           
            {this.props.user.role === 'admin' && <span>
            <AddFolder addFolder={this.createNewFolder} idToken={this.props.idToken} user={this.props.uploadUser} directory={'shared/Tab'+this.props.sharedTab} button={<Button className="docButton" icon="folder-add">Add Folder</Button>} />
            </span>
            }
            <Button className="docButton" onClick={this.onGridLayoutClick}>{this.state.layoutText}</Button>
            {this.state.gridLayout && <span className="fileList">
                <Grid data={this.processSharedData()} selectedField="selected"
                onRowClick={(event) => this.onItemClick(event, this.state.sharedFiles, this.state.sharedFolders, this.state.gridSharedFiles)}
                onSelectionChange={(e) => this.selectionChange(e, this.state.gridSharedFiles)}
                onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.gridSharedFiles)}
                filterable={this.state.filterable}
                filter={this.state.gridFilter}
                onFilterChange={(e)=>{this.setState({gridFilter:e.filter})}}
                sortable={true}
                sort={this.state.sort}
                onSortChange={(e) => {
                    this.setState({
                        sort: e.sort
                    });
                }}
                >
                <GridToolbar>
                <Button icon='refresh' title='Refresh' onClick={this.getSharedFiles}>{this.state.refreshText}</Button>
                <Button icon='filter' title='Filter' onClick={(e)=>this.setState({filterable:!this.state.filterable})}>Filter</Button>
                </GridToolbar>
                <GridColumn field="selected" filterable={false} headerClassName='gridHeader' headerSelectionValue={this.state.gridSharedFiles.findIndex(dataItem => dataItem.selected === false) === -1} />
                <GridColumn field="category" width='200px' headerClassName='gridHeader' title="Folder" />
                <GridColumn title="Document Details" field="text" width='600px' headerClassName='gridHeader'cell={this.FileCell} />
            </Grid></span>}
            {!this.state.gridLayout && this.state.sharedFiles.length > 0 && 
                <FileTree 
                    data={this.state.sharedFiles} 
                    type='shared' 
                    showDelete={this.props.uploadUser.role === 'admin'} 
                    loading={this.state.sharedFilesLoading} 
                    user={this.props.uploadUser} 
                    idToken={this.props.idToken} 
                    onItemClick={(event) => this.onItemClick(event, this.state.sharedFiles, this.state.sharedFolders)} 
                    onDelete={(event) => this.onPublicDelete(event)}
                    onExpandChange={(event) => this.onExpandChange(event, this.state.sharedFiles, this.state.sharedFolders)}
                     />}
        </div>
    );
  }

}

export default SharedTab;