import React, { Component } from "react";
import HistoricalMV from '../components/HistoricalMV';
import axios from 'axios';

class HistoricalMVDashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
        dates:[],
        selectedDate:'',
        perfType:'net',
        styleSettings: {
          chartShowAsOf: null,
          chartEnableFeeSelect: null,
          chartDefaultFees: null
        }
    }

  }

  componentDidMount(){
    this.getStyleSettings();
    this.getPerfDates();
  }

  getStyleSettings(cb) {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      'chartShowAsOf',
      'chartEnableFeeSelect',
      'chartDefaultFees'
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
      .then(response => {
        this.setState({ styleSettings: response.data.styleSettings, perfType: (response.data.styleSettings.chartDefaultFees === 'Gross' ? 'gross' : 'net') }, () => {
          cb()
        });
      })
      .catch(err => {
        console.error(err);
      })


  }

  getPerfDates(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company:this.props.user.company ,
      email:this.props.user.user,
      port:this.props.portfolio.account,
      household:this.props.user.defaultAcct.household,
      perfType:this.state.perfType
    }
    axios.post('api/perfDates', payload, {headers}).then(response=>{
      if(response.data.code===200){
          if(response.data.data.length>0)
            this.setState({dates:response.data.data, selectedDate:response.data.data[0].fDate});
          else
            this.setState({dates:[]});
      }
    }).catch(err=>{
      console.log(err);
    });
  }

  firstUpper(s){
    return s.charAt(0).toUpperCase()+s.slice(1);
  }

  render() {
    return(
        <div className='histMVDash'>
          {this.state.styleSettings.chartEnableFeeSelect && <><label>Type: </label><select onChange={(e)=>this.setState({perfType:e.target.value})} value={this.state.perfType} style={{marginRight:'10px'}}>
            <option value='net'>Net</option>
            <option value='gross'>Gross</option>
          </select></>}
          {!this.state.styleSettings.chartEnableFeeSelect && <label style={{marginRight:'10px'}}>Type: {this.firstUpper(this.state.perfType)}</label>}
          {this.state.styleSettings.chartShowAsOf && <><label>As Of: </label><select onChange={(e)=>this.setState({selectedDate:e.target.value})}>
            {this.state.dates.map((date, i)=>(
              <option key={i} value={date.fDate}>{date.fDate}</option>
            ))}
          </select>
          <br/></>}
          {!this.state.styleSettings.chartShowAsOf && <label>As Of: {this.state.selectedDate}</label>}
          <HistoricalMV user={this.props.user} date={this.state.selectedDate} portfolio={this.props.portfolio} idToken={this.props.idToken}/>
        </div>
    );
  }

}

export default HistoricalMVDashboard;