import React from 'react';
import { Route, Router, Redirect, Switch  } from 'react-router-dom';
import App from './App';
import Login from './PortalHome/Login';
import Callback from './Callback/Callback';
import Auth from './Auth/Auth';
import history from './history';


class Routes extends React.Component {

  handleAuthentication = ({ location }) => {
    if (/access_token|id_token|error/.test(location.hash)) {
      this.auth.handleAuthentication();
    }
  }

  constructor(props) {
    super(props);

    this.stateVars = {
      loggedInUser: {},
      isSuperAdmin: null,
      customLanding: null,
      banner: null,
      termsAccepted: null,
      portalUser: {},
      style: {},
      companySettings: {},
      menuItems: [],
      userSettings: {},
      CHassociatedHousehold: null,
      isPortalEntered: false
    }

    this.state = {
      ...this.stateVars
    }
    this.auth = new Auth();
  }

  setPortalEntered = (status, _callback) => {
    this.setState({
      isPortalEntered: status
    }, () => {
      if (_callback)
        _callback();
    })
  }

  setLoggedInUser = (user, _callback) => {
    this.setState({
      loggedInUser: user
    }, () => {
      if (_callback)
        _callback();
    })
  }

  setSuperAdminStatus = (status, _callback) => {
    this.setState({
      isSuperAdmin: status
    }, () => {
      if (_callback)
        _callback();
    })
  }

  setBanner = (status, _callback) => {
    this.setState({
      banner: status
    }, () => {
      if (_callback)
        _callback();
    })
  }

  setTermsAccepted = (status, _callback) => {
    this.setState({
      termsAccepted: status
    }, () => {
      if (_callback)
        _callback();
    })
  }

  setCustomLandingPage = (status, _callback) => {
    this.setState({
      customLanding: status
    }, () => {
      if (_callback)
        _callback();
    })
  }

  setPortalUser = (status, _callback) => {
    if (!status.defaultAcct && localStorage.getItem('defaultAcct') != null) {
      status.defaultAcct = JSON.parse(localStorage.getItem('defaultAcct'));
    }
    console.log('setPortalUser',status)
    this.setState({
      portalUser: status
    }, () => {
      if (status.defaultAcct) {
        localStorage.setItem('defaultAcct',JSON.stringify(status.defaultAcct));
      }
      if (_callback)
        _callback();
    })
  }

  setStyle = (status, _callback) => {
    this.setState({
      style: status
    }, () => {
      if (_callback)
        _callback();
    })
  }

  setCompanySettings = (attr, val, _callback) => {
    this.setState(prevState => {
      let obj = { ...prevState }
      obj.companySettings[attr] = val
      return obj;
    }, () => {
      if (_callback)
        _callback();
    })
  }

  setMenuItems = (val, _callback) => {
    this.setState(prevState => {
      let obj = { ...prevState }
      obj.menuItems = val
      return obj;
    }, () => {
      if (_callback)
        _callback();
    })
  }

  setUserSetting = (attr, val, _callback) => {
    this.setState(prevState => {
      let obj = { ...prevState }
      obj.userSettings[attr] = val
      return obj;
    }, () => {
      if (_callback)
        _callback();
    })
  }

  setCHassociatedHousehold = (status, _callback) => {
    this.setState({
      CHassociatedHousehold: status
    }, () => {
      if (_callback)
        _callback();
    })
  }

  changeCompany = () => {
    localStorage.removeItem('appCompany');
    localStorage.removeItem('appGoTo');
    this.setState({
      ...this.stateVars
    })
  }

  render() {
    const inputParams = {
      ...this.state,
      setLoggedInUser: this.setLoggedInUser,
      setSuperAdminStatus: this.setSuperAdminStatus,
      setBanner: this.setBanner,
      setTermsAccepted: this.setTermsAccepted,
      setCustomLandingPage: this.setCustomLandingPage,
      setPortalUser: this.setPortalUser,
      setStyle: this.setStyle,
      setCompanySettings: this.setCompanySettings,
      setMenuItems: this.setMenuItems,
      setUserSetting: this.setUserSetting,
      setCHassociatedHousehold: this.setCHassociatedHousehold,
      changeCompany: this.changeCompany,
      setPortalEntered: this.setPortalEntered
    }
    
    return (
      <Router history={history}>
        <div>
          <Switch>
            {/* <Route exact path="/" render={(props) => <Redirect to='/login' {...props} />} /> */}
            <Route path="/home" key='home' render={(props) => <App auth={this.auth} route={'home'} {...props} {...inputParams} />} />
            <Route path="/docs" key='docs' render={(props) => <App auth={this.auth} route={'docs'} {...props} {...inputParams} />} />
            <Route path="/HouseholdDocCenter" key='HouseholdDocCenter' render={(props) => <App auth={this.auth} route={'HouseholdDocCenter'} {...props} {...inputParams} />} />
            <Route path="/users" key='users' render={(props) => <App auth={this.auth} route={'users'} {...props} {...inputParams} />} />
            <Route path="/style" key='style' render={(props) => <App auth={this.auth} route={'style'} {...props} {...inputParams} />} />
            <Route path="/homePageSettings" key='homePageSettings' render={(props) => <App auth={this.auth} route={'homePageSettings'} {...props} {...inputParams} />} />
            <Route path="/permissions" key='permissions' render={(props) => <App auth={this.auth} route={'permissions'} {...props} {...inputParams} />} /> 
            <Route path="/roles" key='roles' render={(props) => <App auth={this.auth} route={'roles'}  {...props} {...inputParams} />} />
            <Route path="/settings" key='settings' render={(props) => <App auth={this.auth} route={'settings'} {...props} {...inputParams} />} />
            <Route path="/mailSettings" key='mail' render={(props) => <App auth={this.auth} route={'mailSettings'} {...props} {...inputParams} />} />
            <Route path="/superAdminSettings" key='superAdmin' render={(props) => <App auth={this.auth} route={'superAdminSettings'} {...props} {...inputParams} />} />
            <Route path="/reportLogs" key='reportLogs' render={(props) => <App auth={this.auth} route={'reportLogs'} {...props} {...inputParams} />} />
            <Route path="/customMapping" key='customMapping' render={(props) => <App auth={this.auth} route={'customMapping'} {...props} {...inputParams} />} />
            <Route path="/contact" key='contact' render={(props) => <App auth={this.auth} route={'contact'} {...props} {...inputParams} />} />
            <Route path="/locationSettings" key='loc' render={(props) => <App auth={this.auth} route={'locationSettings'} {...props} {...inputParams} />} />
            <Route path="/webreportSignUp" key='webrepsignup' render={(props) => <App auth={this.auth} route={'webreportSignUp'} {...props} {...inputParams} />} />
            <Route path="/dataMappings" key='data' render={(props) => <App auth={this.auth} route={'dataMappings'} {...props} {...inputParams} />} />
            <Route path="/dataManagement" key='data' render={(props) => <App auth={this.auth} route={'dataManagement'} {...props} {...inputParams} />} />
            <Route path="/BulkDocumentManagement" key='bulkdocumentmanagement' render={(props) => <App auth={this.auth} route={'BulkDocumentManagement'} {...props} {...inputParams} />} />
            <Route path="/BulkEmailManagement" key='bulkemailmanagement' render={(props) => <App auth={this.auth} route={'BulkEmailManagement'} {...props} {...inputParams} />} />
            <Route path="/dataUpload" key='dataupload' render={(props) => <App auth={this.auth} route={'dataUpload'} {...props} {...inputParams} />} />
            <Route path="/landing" key='landing' render={(props) => <App auth={this.auth} route={'landing'} {...props} {...inputParams} />} />
            <Route path="/portfolio" key='portfolio' render={(props) => <App auth={this.auth} route={'portfolio'} {...props} {...inputParams} />} />
            <Route path="/snapshot" key='snapshot' render={(props) => <App auth={this.auth} route={'snapshot'} {...props} {...inputParams} />} />
            <Route path="/widgets" key='widgets' render={(props) => <App auth={this.auth} route={'widgets'} {...props} {...inputParams} />} />
            <Route path="/userGroups" key='userGroups' render={(props) => <App auth={this.auth} route={'userGroups'} {...props} {...inputParams} />} />
            <Route path="/whitelist" key='whitelist' render={(props) => <App auth={this.auth} route={'whitelist'} {...props} {...inputParams} />} />
            <Route path="/advisorRelations" key='advRelations' render={(props) => <App auth={this.auth} route={'advisorRelations'} {...props} {...inputParams} />} />
            <Route path="/Households" key='Households' render={(props) => <App auth={this.auth} route={'Households'} {...props} {...inputParams} />} />
            <Route path="/advisor" key='advisor' render={(props) => <App auth={this.auth} route={'advisor'} {...props} {...inputParams} />} />
            <Route path="/timesheet" key='timesheet' render={(props) => <App auth={this.auth} route={'timesheet'} {...props} {...inputParams} />} />
            <Route path="/vacation" key='vacation' render={(props) => <App auth={this.auth} route={'vacation'} {...props} {...inputParams} />} />
            <Route path="/sqleditor" key='sqleditor' render={(props) => <App auth={this.auth} route={'sqleditor'} {...props} {...inputParams} />} />
            <Route path="/standardReporting" key='standardReporting' render={(props) => <App auth={this.auth} route={'standardReporting'} {...props} {...inputParams} />} />
            <Route path="/reporting" key='reporting' render={(props) => <App auth={this.auth} route={'reporting'} {...props} {...inputParams} />} />
            <Route path="/standardReportingNew" key='standardReportingNew' render={(props) => <App auth={this.auth} route={'standardReportingNew'} {...props} {...inputParams} />} />
            <Route path="/customReporting" key='customReporting' render={(props) => <App auth={this.auth} route={'customReporting'} {...props} {...inputParams} />} />
            {/* <Route path="/reportList" key='reportList' render={(props) => <App auth={this.auth} route={'reportList'} {...props} setStyle={setStyle} etCompanySettings={setCompanySetting} setUserSettings={setUserSetting} setLoggedInUser={setLoggedInUser} loggedInUser={loggedInUser}  setSuperAdminStatus={setSuperAdminStatus} isSuperAdmin={isSuperAdmin} setTermsAccepted={setTermsAccepted} termsAccepted={termsAccepted} setBanner={setBanner} banner={banner} setCustomLandingPage={setCustomLandingPage} customLanding={customLanding} setPortalUser={setPortalUser}  portalUser={portalUser} setCHassociatedHousehold={setCHassociatedHousehold}/>} />                        */}
            <Route path="/dataExports" key='dataExports' render={(props) => <App auth={this.auth} route={'dataExports'} {...props} {...inputParams} />} />
            <Route path="/runExport" key='runExport' render={(props) => <App auth={this.auth} route={'runExport'} {...props} {...inputParams} />} />
            <Route path="/reportManagement" key='reportManagement' render={(props) => <App auth={this.auth} route={'reportManagement'} {...props} {...inputParams} />} />
            <Route path="/bannerManagement" key='bannerManagement' render={(props) => <App auth={this.auth} route={'bannerManagement'} {...props} {...inputParams} />} />
            <Route path="/banner" key='banner' render={(props) => <App auth={this.auth} route={'banner'} {...props} {...inputParams} />} />
            <Route path="/ssrsPackager" key='ssrsPackager' render={(props) => <App auth={this.auth} route={'ssrsPackager'} {...props} {...inputParams} />} />
            <Route path="/ssrsPackagerReview" key='ssrsPackagerReview' render={(props) => <App auth={this.auth} route={'ssrsPackagerReview'} {...props} {...inputParams} />} />
            <Route path="/advAccountList" key='advAccountList' render={(props) => <App auth={this.auth} route={'advAccountList'} {...props} {...inputParams} />} />
            <Route path="/householdList" key='householdList' render={(props) => <App auth={this.auth} route={'householdList'} {...props} {...inputParams} />} />
            <Route path="/reports" key='reports' render={(props) => <App auth={this.auth} route={'reports'} {...props} {...inputParams} />} />
            <Route path="/login" key='login' render={(props) => <Login auth={this.auth} route={'login'} {...props} {...inputParams} />} />
            {/* CUSTOM LANDING PAGES START */}
            <Route path="/Churchill" key='Churchill' render={(props) => <App auth={this.auth} route={'Churchill'} {...props} {...inputParams} />} />
            <Route path={"/" + (inputParams.loggedInUser.company)} key={inputParams.loggedInUser.company} render={(props) => <App auth={this.auth} route={inputParams.loggedInUser.company} {...props} {...inputParams} />} />
            {/* CUSTOM LANDING PAGES END */}
            <Route path="/callback" key='callback' render={(props) => {
              this.handleAuthentication(props);
              return <Callback {...props} />
            }} />
            <Route render={(props) => <Redirect to='/home' {...props} />} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default Routes;