import React, { Component } from "react";

// import WidthSelector from './WidthSelector';
import '../Snapshot.css'
import AccountOverview from '../library/AccountOverview'
import AccountSummary from '../library/AccountSummary'
import FixedIncomeHoldings from '../library/FixedIncomeHoldings'
import Holdings from '../library/Holdings'
import PerformanceOverview from '../library/PerformanceOverview'
import RealizedGains from '../library/RealizedGains'
import TransactionSummary from '../library/TransactionSummary'

class SnapshotWidget extends Component {

  constructor(props) {
    super(props);
    this.state = {
      width: this.props.width,
      height: 1,
      callback: null
    };
  }

  getWidthName = (width) => {
    switch (width) {
      case 4: return "Small";
      case 6: return "Medium";
      case 12: return "Large";
      default: return "";
    }
  }

  getModule = (module) => {
    switch (module) {
      case "AccountOverview":
        return <AccountOverview {...this.props} callback={this.callback} />
      case "AccountSummary":
        return <AccountSummary {...this.props} callback={this.callback} />
      case "FixedIncomeHoldings":
        return <FixedIncomeHoldings {...this.props} callback={this.callback} />
      case "Holdings":
        return <Holdings {...this.props} callback={this.callback} />
      case "PerformanceOverview":
        return <PerformanceOverview {...this.props} callback={this.callback} />
      case "RealizedGains":
        return <RealizedGains {...this.props} callback={this.callback} />
      case "TransactionSummary":
        return <TransactionSummary {...this.props} callback={this.callback} />
      default:
        return <></>
    }
  }

  getDataVisualizationTabName = (module) => {
    switch (module) {//reference "allTabs" array in Portfolio.js for these return values
      case "AccountOverview":
        return 'equityOver'
      case "AccountSummary":
        return 'acctSumm'
      case "FixedIncomeHoldings":
        return 'fiHolds'
      case "Holdings":
        return 'holds'
      case "PerformanceOverview":
        return 'perfOver'
      case "RealizedGains":
        return 'rgain'
      case "TransactionSummary":
        return 'tranSumm'
      default:
        return ''
    }
  }

  callback = (cb) => {
    this.setState({
      callback: cb
    });
  }

  render() {
    return (
      <div className={"widgetCardColumn widgetCardColumn" + this.props.width + " widget p-3" + (this.props.active ? "" : " d-none")}>
        <div className={"widgetCard widgetHeight" + this.state.height}>
          <div className="widgetHeader dragDiv">
            <div className="widgetRow justify-content-center">
              {/* <div className="widgetColumn widgetWidthSelector d-flex flex-shrink-1 flex-grow-0 align-right p-0">
                <WidthSelector
                  widths={this.props.widths}
                  width={this.state.width}
                  callback={(width) => { if (this.state.callback) this.state.callback(width); this.props.handleChange(this.props.id, 'width', width); }}
                />
              </div> */}
              <div className="widgetTitle px-1">
                <div>{this.props.name}<div className="dragIcon absoluteDragIcon px-2"><span className="k-icon k-font-icon k-icon-md k-i-move"></span></div></div>
              </div>
            </div>
          </div>
          <div onClick={() => {this.props._handleClick(this.getDataVisualizationTabName(this.props.widget.module))}} className="widgetBody">
            {this.getModule(this.props.widget.module)}
          </div>
        </div>
      </div>
    );
  }

}

export default SnapshotWidget;