import React, { Component } from "react";
import Popup from "reactjs-popup";
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import axios from 'axios';
import { process } from '@progress/kendo-data-query';
import GridLoading from '../../Loaders/GridLoading';
import { Button } from "@progress/kendo-react-buttons";


class AddHouseholdAccount extends Component {

  constructor(props) {
    super(props);
    this.state = {
        accounts:[],
        result:[],
        loading:true,
        dataState:{skip:0, take:10},

        household:this.props.household,
        company:this.props.company,
        account:""
    }
    
    this.clearState = this.clearState.bind(this);
    this.getAccounts=this.getAccounts.bind(this);
    this.createCell=this.createCell.bind(this);
    this.addHouseholdMapping=this.addHouseholdMapping.bind(this);
  }

  componentDidMount(){
    this.getAccounts();
  }

  getAccounts(){
    this.setState({loading:true});
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) }; 
    
    var payload ={
      company:this.props.company,
      household:this.props.household
    }

    axios.post('api/accountHHList', payload, {headers}).then(response=>{
      var result = process(response.data.accounts, this.state.dataState);
      this.setState({accounts:response.data.accounts, result:result, loading:false});
    }).catch(err=>{
      console.log(err);
      this.setState({accounts:[], loading:false});
    });
  }

  changeDataState = (e)=>{
    var newData = process(this.state.accounts, e.dataState);
    this.setState({dataState:e.dataState, result:newData});
  }


  
  addHouseholdMapping(e, cell, callback){
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var payload = {
      company:this.props.company,
      household:this.state.household,
      account: cell.dataItem.account
    }
    axios.post("api/createHouseholdAccountMapping", payload, {headers}).then(response => {
      if(response.data.code===200){
        alert('Household/Account Mapping created successfully');
        //callback();
      }
      else{
        alert(response.data.error);
        //callback("ERROR");
      }
    }).catch(err=>{
        alert("An error has occured"); 
        console.log(err); 
        //callback("ERROR");
      });    
  }





  createCell(cell){
    if(cell.rowType==='data'){
      return (
          <div>          
          <Button className='docButton' onClick = {(event) => this.addHouseholdMapping(event, cell)}>Add Account Mapping</Button>
          </div>
      );
    }
    else{
      return (
        <></>
      );
    }
  }




  clearState(){
    this.setState({
      account:''
    });
}

  render() {
    return(
      <Popup size="lg" trigger={this.props.button}  onClose={(e)=>{this.clearState()}} overlayStyle={{ zIndex: 5 }} modal>
        {close => (

      <div className='modal'>
              <Button className="close"
                 icon="close"
                  onClick={() => {
                    this.props.refresh();
                    close();
                  }}
                  />

              <div className="header" align="left">
                Select Account to Map
              </div>


        <Grid data={this.state.result}
              filterable={true}
              sortable={true}
              pageable={{pageSizes:[10,25,50,100,250,500]}}
              onDataStateChange={this.changeDataState}
              {...this.state.dataState}>
            <GridColumn width='200' headerClassName='gridHeader' field='account' title='Account' />
            <GridColumn width='300' headerClassName='gridHeader' field='accountName' title='Account Name' />
            <GridColumn headerClassName='gridHeader' width='200'  editable={false} cell={(props) => this.createCell(props)}/>
        </Grid>
        {this.state.loading && <GridLoading />}

                <div className="actions">

        <Button
          className="button"
          onClick={() => {
            this.props.refresh();
            close();
          }}
        >
          Close
        </Button>

        

        </div>



      </div>
                )}
                </Popup>
        
    );
  }

}

export default AddHouseholdAccount;