import React, { Component } from "react";
import {Grid, GridColumn, GridToolbar, GridNoRecords} from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import axios from 'axios';
import {parseDate} from '@telerik/kendo-intl';
import {DatePicker} from '@progress/kendo-react-dateinputs';
import GridLoading from '../../Loaders/GridLoading';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Button } from "@progress/kendo-react-buttons";

const ADJUST_PADDING = 4;
const COLUMN_MIN = 4;

class Transactions extends Component {

  constructor(props) {
    super(props);

    this.minGridWidth = 0;

    var columns = []

    // var columns = [{field:'tranName' , title: 'Transaction Name', headerClass:'gridHeader', minWidth:200, maxWidth: 300},
    // {field:'secSymbol' , title: 'Symbol', headerClass:'gridHeader', minWidth:100, maxWidth: 135},
    // {field:'security' , title: 'Security Name', headerClass:'gridHeader', minWidth:200, maxWidth: 350},
    // {field:'tradeDate' , title: 'Trade Date', headerClass:'gridHeader' , format:'{0:d}', minWidth:125, maxWidth: 135},
    // {field:'quantity' , title: 'Quantity', headerClass:'gridHeader' , format:'{0:n2}', minWidth:100, maxWidth: 120},
    // {field:'UnitPrice' , title: 'Price Per Share', headerClass:'gridHeader' , format:'{0:n2}', minWidth:150, maxWidth: 150},
    // {field:'tradeAmount' , title: 'Trade Amount', headerClass:'gridHeader' , format:'{0:c2}', minWidth:150, maxWidth: 150}];

    // if(props.compSettings.showSettle)
    //   columns.splice(2,0,{field:'settleDate' , title: 'Settle Date', headerClass:'gridHeader' , format:'{0:d}', minWidth:125, maxWidth: 135});

    this.state = {
      fromDate: new Date(),
      toDate: new Date(),
      transactions:[],
      result:[],
      loading:false,
      dataState:{sort:[{field:'tradeDate', dir:'desc'}]},
      aggregates:[{field:'tradeAmount', aggregate:'sum'}],
      portfolio:this.props.portfolio,
      setMinWidth: false,
      gridCurrent: 0,
      columns:columns,
      styleSettings: {
        tranSummaryShowSymbol: null,
        tranSummaryShowPricePerShare: null,
        tranSummaryShowSettle: null,
        tranSummaryPeriod: null
      }
    }

    this.getTransactions = this.getTransactions.bind(this);
    this.changeDataState = this.changeDataState.bind(this);
    this.formatCurrency = this.formatCurrency.bind(this);
    this.cellRender = this.cellRender.bind(this);
    this.headerCellRender = this.headerCellRender.bind(this);
    this.getDate = this.getDate.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.getPeriod = this.getPeriod.bind(this);
    this.setColumns = this.setColumns.bind(this);
    this.getStyleSettings=this.getStyleSettings.bind(this);
    this.refresh=this.refresh.bind(this);
  }

  componentDidMount(){
    this.getStyleSettings(() =>{
      this.setState({fromDate: this.getPeriod()}, () => {
        this.setColumns(() => {
          this.getTransactions(()=>{
            this.setMinGridWidth();
          });
        })
      })
    });
    
  }

  componentDidUpdate(){
    if(this.props.portfolio!==this.state.portfolio){
      this.setState({portfolio:this.props.portfolio}, ()=>{this.getTransactions(()=>{
        this.setMinGridWidth();
      })});
    }
  }

  refresh(){
    this.getTransactions(()=>{
      this.setMinGridWidth();
    });
  }

  getStyleSettings(cb) {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      'tranSummaryShowSymbol',
      'tranSummaryShowPricePerShare',
      'tranSummaryShowSettle',
      'tranSummaryPeriod'
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
    .then(response => {
      this.setState({styleSettings: response.data.styleSettings}, () => {
        cb()
      })
    })
    .catch(err => {
        console.error(err);
    })
  }

  onFieldChange(e, field){
    this.setState({[field]:e.target.value})
  }

  setMinGridWidth = ()=>{
    this.grid = document.querySelector('.k-grid');
          window.addEventListener('resize', this.handleResize);
          this.minGridWidth = 0;
          this.state.columns.map(item => this.minGridWidth += item.minWidth);
          if(this.state.dataState.group)
            this.minGridWidth += 32*this.state.dataState.group.length;
          this.setState({
              gridCurrent: this.grid.offsetWidth,
              setMinWidth: this.grid.offsetWidth < this.minGridWidth
      });
  }

  setColumns(cb) {

    var columns = [{field:'tranName' , title: 'Transaction Name', headerClass:'gridHeader', minWidth:200, maxWidth: 300},
    {field:'secSymbol' , title: 'Symbol', headerClass:'gridHeader', minWidth:100, maxWidth: 135},
    {field:'security' , title: 'Security Name', headerClass:'gridHeader', minWidth:200, maxWidth: 350},
    {field:'tradeDate' , title: 'Trade Date', headerClass:'gridHeader' , format:'{0:d}', minWidth:125, maxWidth: 135},
    {field:'quantity' , title: 'Quantity', headerClass:'gridHeader' , format:'{0:n2}', minWidth:100, maxWidth: 120},
    {field:'UnitPrice' , title: 'Price Per Share', headerClass:'gridHeader' , format:'{0:n2}', minWidth:150, maxWidth: 150},
    {field:'tradeAmount' , title: 'Trade Amount', headerClass:'gridHeader' , format:'{0:c2}', minWidth:150, maxWidth: 150}];

    if(this.state.styleSettings.tranSummaryShowSettle)
      columns.splice(2,0,{field:'settleDate' , title: 'Settle Date', headerClass:'gridHeader' , format:'{0:d}', minWidth:125, maxWidth: 135});

    


    this.setState({ columns: columns }, () => {
      cb()
    });
    
  }

  handleResize = () => {
    if (this.grid.offsetWidth < this.minGridWidth && !this.state.setMinWidth) {
        this.setState({
            setMinWidth: true
        });
    } else if (this.grid.offsetWidth > this.minGridWidth) {
        this.setState({
            gridCurrent: this.grid.offsetWidth,
            setMinWidth: false
        });
    }
  }

  setWidth = (minWidth, maxWidth) => {
    let width = this.state.setMinWidth ? minWidth : minWidth + (this.state.gridCurrent - this.minGridWidth) / this.state.columns.length;
    if(width >= COLUMN_MIN)
      width = width-ADJUST_PADDING;

    if(width > maxWidth)
      return maxWidth
    else
      return width;
  }

  getPeriod(){
    var period = this.state.styleSettings.tranSummaryPeriod;
    var date = new Date();

    if(period==="Month to Date")
      date = new Date(date.getFullYear(), date.getMonth(), 1);
    else if(period==='Quarter to Date'){
      var quarter = Math.floor((date.getMonth() / 3));
      date = new Date(date.getFullYear(), quarter * 3, 1);
    }
    else if(period==='Year to Date')
      date = new Date(date.getFullYear(), 0, 1);
    else if(period==='6 Month')
      date = new Date(date.getFullYear(), date.getMonth()-6, date.getDate());
    else if(period==='12 Month')
      date = new Date(date.getFullYear()-1, date.getMonth(), date.getDate());
    else date = null;

    return date;
  }

  getTransactions(cb){
    this.setState({loading:true});
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    // var unmapped = '';

    // if(this.props.user.unmapped)
    //   unmapped = '&unmapped=true';

    var port = this.state.portfolio.account
    if (this.state.portfolio.consolidated) {
      port = this.props.household
    }

    //axios.get('api/transactions?company='+this.props.user.company+'&port='+this.state.portfolio.account+'&from='+this.state.fromDate+'&to='+this.state.toDate+''+unmapped, {headers})

    axios.get(`api/transactions`, {
      method: 'GET',
      params: {
        company: this.props.user.company,
        port: port,
        consolidated: this.state.portfolio.consolidated,
        from: this.state.fromDate,
        to: this.state.toDate,
      },
      headers: headers,
    })
    .then(response=>{
      if(response.data.code===200){
        var fDate = this.state.fromDate;
        var tDate = this.state.toDate;
        var getNewDate = false
        if (!fDate){
          getNewDate = true
        }

        for(let i=0; i<response.data.data.length; i++){
            response.data.data[i].tradeDate = this.getDate(response.data.data[i].tradeDate, true);
            response.data.data[i].settleDate = this.getDate(response.data.data[i].settleDate, true);
            if(!response.data.data[i].displayName)
              response.data.data[i].displayName = response.data.data[i].portfolio;

            //get earliest date
            if(getNewDate && (!fDate || new Date(fDate) > new Date(response.data.data[i].tradeDate)))
              fDate = response.data.data[i].tradeDate;
            
        }

        this.setState({transactions:response.data.data, loading:false, toDate:tDate, fromDate:fDate, result:this.addPropertyToItems(process(response.data.data, this.state.dataState))},()=>cb());
        this.setState({transactions:response.data.data, loading:false, toDate:tDate, fromDate:fDate, result:this.addPropertyToItems(process(response.data.data, this.state.dataState))},()=>cb());
      }
    }).catch(err=>{
      this.setState({transactions:[], loading:false},()=>cb());
      console.log(err);
    });
  }

  getDate(sqlDate, isUTC){
    var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
    if(d){
      var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
      if(isUTC)
        return utc;
      else
        return d;
    }
    else
      return null;
  }

  _export;
  export = () =>{
    this._export.save();
  }

  render() {
    return(
        <div className='transactions'>
          <ExcelExport data={this.state.transactions} fileName={'Transactions.xlsx'} ref={(exporter)=>{this._export = exporter;}}>         
          <Grid data={this.state.result}
                groupable={{footer:'always'}}       
                className='noGroupGridLines'         
                resizable={true}
                reorderable={true}
                filterable={false}
                sortable={true}
                onDataStateChange={this.changeDataState}
                onExpandChange={this.expandChange}
                expandField="expanded"
                cellRender={this.cellRender}
                headerCellRender={this.headerCellRender}
                {...this.state.dataState}>
              <GridNoRecords>
                {this.state.loading && 'Loading...'}
                {!this.state.loading && 'No records available'}
              </GridNoRecords>
              <GridToolbar>
                <label style={{paddingLeft:'10px'}}><strong>From: </strong></label><DatePicker value={this.state.fromDate} max={new Date()} onChange={(e)=>this.onFieldChange(e, 'fromDate')}/>
                <label style={{paddingLeft:'5px'}}><strong>To: </strong></label><DatePicker value={this.state.toDate} max={new Date()} onChange={(e)=>this.onFieldChange(e, 'toDate')}/>
                <Button icon="refresh" title='Refresh' onClick={this.refresh}>Refresh</Button>
                <Button icon="excel" title='Export to Excel' onClick={this.export}>Export</Button>
              </GridToolbar>
              {
                this.state.columns.map((column, key)=>{
                  if (column.field === 'secSymbol' && !this.state.styleSettings.tranSummaryShowSymbol)
                    return <></>
                  if (column.field === 'UnitPrice' && !this.state.styleSettings.tranSummaryShowPricePerShare)
                    return <></>
                  else
                    return <GridColumn field={column.field} title={column.title} key={key} format={column.format} headerClassName={column.headerClass} width={this.setWidth(column.minWidth, column.maxWidth)} />
                })
              }
              </Grid>
          </ExcelExport>
          {this.state.loading && <GridLoading/>}
        </div>
    );
  }

  changeDataState = (e)=>{
    const groups = e.dataState.group;
    if(groups){
      groups.map(group=>group.aggregates = this.state.aggregates);
    }
    var newData = process(this.state.transactions, e.dataState);
    this.setState({dataState:e.dataState, result:this.addPropertyToItems(newData)});
  }

  formatCurrency(val){
    var formatted = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(val);
    return formatted;
  }

  formatQuantity(val) {
    var nfObject = new Intl.NumberFormat('en-US');
    var formatted = nfObject.format(val);
    return formatted;
  }

cellRender(tdElement, cellProps) {
    if (cellProps.rowType === 'groupFooter') {
      if (cellProps.field!=='value' && cellProps.field!=='displayName' && cellProps.field!=='security' && cellProps.field!=='secSymbol' && cellProps.field!=='tranName' && cellProps.field!=='tradeDate' && cellProps.field!=='settleDate' && cellProps.field!=='quantity' && cellProps.field!=='UnitPrice') { 
        var val = '';
        if(cellProps.dataItem.aggregates[cellProps.field]){
          val = cellProps.dataItem.aggregates[cellProps.field].sum
          val = this.formatCurrency(val);
        }
        return (
            <td style={{textAlign: 'right'}}>
                <strong>{val}</strong>
            </td>
        );
      }
      else if(cellProps.field==='displayName'){
        return(<></>);
      }
      else if(cellProps.field==='security'){
        return (
          <td colSpan='1'>
              <strong>{cellProps.dataItem?.value?.toString()} Totals:</strong>
          </td>
        );
      }
    }
    if(tdElement!==null){
      var style = {};
      if(cellProps.field==='quantity' || cellProps.field==='UnitPrice' || cellProps.field==='tradeAmount')
        style = {textAlign: 'right'};
      
      if(cellProps.field==='quantity' || cellProps.field==='UnitPrice'){
        var quant = cellProps.dataItem[cellProps.field];
        if(quant===0 || !quant)
          quant = null;
        else
          quant = quant.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return(
          <td {...tdElement.props} style={style}>{quant}</td>
        );
      }

      return(
        <td {...tdElement.props} style={style}/>
      );
    }
    else{
      return tdElement;
    }
}

headerCellRender(thElement, cellProps) {
  if(thElement!==null){
    var style = {};
    if(cellProps.field==='quantity' || cellProps.field==='tradeAmount' || cellProps.field==='UnitPrice')
      style = {justifyContent: 'right'};

    return(
      <div {...thElement.props} style={style}/>
    );
  }
  else{
    return thElement;
  }
}

  // expandChange = (event) => {
  //   event.dataItem[event.target.props.expandField] = event.value;
  //   this.forceUpdate();
  // }

expandChange = (event) => {
  const expandField = event.target.props.expandField;
  const itemId = event.dataItem.id;

  const updateItemsRecursive = (items) => {
    return items.map(item => {
      let updatedItem = item;
      if (item.id === itemId) {
        updatedItem = {
          ...item,
          [expandField]: !item[expandField],
        };
      }
      if (Array.isArray(item.items)) updatedItem.items = updateItemsRecursive(item.items);
      return updatedItem;
    });
  };

  const updatedData = updateItemsRecursive(this.state.result.data);

  this.setState((prevState) => ({
    ...prevState,
    result: {
      ...prevState.result,
      data: updatedData
    }
  }));
}

addPropertyToItems = (obj) => {
  // Usage: (call in both initial data set and prior to any datagrid result state change)
  // result = this.addPropertyToItems(result);

  const addPropertyToNestedItems = (item) => {
    item.id = Math.random();
    item.expanded = true;

    // Recursively call this function for each item in the 'items' array
    if (Array.isArray(item.items)) item.items.forEach(subItem => addPropertyToNestedItems(subItem));
    return item;
  }

  // Traverse the array and add properties
  if (Array.isArray(obj.data)) obj.data.forEach(item => addPropertyToNestedItems(item));
  return obj;
}

expand = () => {
  const newData = this.state.result.data.map(row => {
    const newRow = { ...row };
    newRow.expanded = true;
    newRow.items = this.expandItems(newRow.items);
    return newRow;
  });
  this.setState(prevState => ({
    result: {
      ...prevState.result,
      data: newData
    }
  }));
};

expandItems = (items) => {
  if (!items) return [];
  return items.map(item => {
    const newItem = { ...item };
    newItem.expanded = true;
    newItem.items = this.expandItems(newItem.items);
    return newItem;
  });
};

expandCollapse = (field) => {
  const newData = this.state.result.data.map(row =>
    this.expandCollapseItems(row, field)
  );
  this.setState(prevState => ({
    result: {
      ...prevState.result,
      data: newData
    }
  }));
};

expandCollapseItems = (item, field) => {
  const newItem = { ...item };
  if (!field || field === newItem.field) {
    newItem.expanded = !newItem.expanded;
  }
  if (newItem.items) {
    newItem.items = newItem.items.map(child => this.expandCollapseItems(child, field));
  }
  return newItem;
};

}

export default Transactions;