import React, { Component } from "react";
import { Grid, GridColumn, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import { aggregateBy, process } from '@progress/kendo-data-query';
import axios from 'axios';
import { parseDate } from '@telerik/kendo-intl';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { isDate } from "util";
import GridLoading from '../../Loaders/GridLoading';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Button } from "@progress/kendo-react-buttons";

const columns = [
  { field: 'AccountID', title: 'Account ID', headerClass: 'gridHeader', minWidth: 200, excelWidth: 200,show: true },
  { field: 'Name1', title: 'Account ID', headerClass: 'gridHeader', minWidth: 200, excelWidth: 200,show: true },
  { field: 'Name2', title: 'Account ID', headerClass: 'gridHeader', minWidth: 200, excelWidth: 200,show: true },
  { field: 'Name3', title: 'Account ID', headerClass: 'gridHeader', minWidth: 200, excelWidth: 200,show: true },
  { field: 'openDate', title: 'Open Date', headerClass: 'gridHeader', format: '{0:MM/dd/yyyy}', excelFormat: "mm/dd/yyyy", minWidth: 125, excelWidth: 125, show: true },
  { field: 'closeDate', title: 'Close Date', headerClass: 'gridHeader', format: '{0:MM/dd/yyyy}', excelFormat: "mm/dd/yyyy", minWidth: 125, excelWidth: 125, show: true },
  { field: 'quantity', title: 'Quantity', headerClass: 'gridHeader', format: '{0:n2}', excelFormat: "#,##0.00", minWidth: 120, excelWidth: 120, show: true },
  { field: 'secSymbol', title: 'Symbol', headerClass: 'gridHeader', minWidth: 135, excelWidth: 135, show: true },
  { field: 'secName', title: 'Security Name', headerClass: 'gridHeader', minWidth: 350,excelWidth: 350, show: true },
  { field: 'costBasis', title: 'Cost Basis', headerClass: 'gridHeader', format: '{0:c2}', excelFormat: "#,##0.00", minWidth: 120, excelWidth: 125,show: true },
  { field: 'proceeds', title: 'Proceeds', headerClass: 'gridHeader', format: '{0:c2}', excelFormat: "#,##0.00", minWidth: 120, excelWidth: 125,show: true },
  { field: 'shortTerm', title: 'Short Term', headerClass: 'gridHeader', format: '{0:c2}', excelFormat: "#,##0.00", minWidth: 120, excelWidth: 125,show: true },
  { field: 'longTerm', title: 'Long Term', headerClass: 'gridHeader', format: '{0:c2}', excelFormat: "#,##0.00", minWidth: 120, excelWidth: 125,show: true },
  { field: 'realizedCap', title: 'Realized Cap', headerClass: 'gridHeader',  width: 0, minWidth: 0, excelWidth: 125,show: false },
];

const ADJUST_PADDING = 5;
const COLUMN_MIN = 4;

class RGains extends Component {

  constructor(props) {
    super(props);

    this.minGridWidth = 0;

    var aggregates = [{ field: 'costBasis', aggregate: 'sum' },
    { field: 'proceeds', aggregate: 'sum' },
    { field: 'shortTerm', aggregate: 'sum' },
    { field: 'longTerm', aggregate: 'sum' },
    { field: 'gains', aggregate: 'sum'}]

    this.state = {
      fromDate: new Date(),
      toDate: new Date(),
      rgains: [],
      result: [],
      totalGains: null,
      loading: false,
      dataState: { sort: [{ field: 'closeDate', dir: 'desc' }], group:[] },
      aggregates: aggregates,
      origAggregates: aggregates,
      portfolio: this.props.portfolio,
      setMinWidth: false,
      gridCurrent: 0,
      styleSettings: {
        rgainDefaultPeriod: null,
        rgainShowAccountID: null,
        rgainShowName1: null,
        rgainShowName2: null,
        rgainShowName3: null,
      },
      showAccountBreakdown: false,
      includeCapGains: true,
    }

    this.getRGains = this.getRGains.bind(this);
    this.changeDataState = this.changeDataState.bind(this);
    this.formatCurrency = this.formatCurrency.bind(this);
    this.cellRender = this.cellRender.bind(this);
    this.headerCellRender = this.headerCellRender.bind(this);
    this.getDate = this.getDate.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.getPeriod = this.getPeriod.bind(this);
    this.refresh = this.refresh.bind(this);
    this.getStyleSettings = this.getStyleSettings.bind(this);
    this.sortClassification = this.sortClassification.bind(this)
    this.sortClassificationChild = this.sortClassificationChild.bind(this)
    this.excelTotal = this.excelTotal.bind(this);
  }

  componentDidMount() {
    this.getStyleSettings(() => {
      this.setState({ fromDate: this.getPeriod() }, () => {
        this.getRGains(() => {
          this.setMinGridWidth();
        });
      });
    });
    // this.getRGains();
    // this.setMinGridWidth();
  }

  componentDidUpdate() {
    if (this.props.portfolio !== this.state.portfolio) {
      this.setState({ portfolio: this.props.portfolio }, () => {
        this.getRGains(() => {
          this.setMinGridWidth();
        })
      });
    }
  }

  refresh() {
    this.getRGains(() => {
      this.setMinGridWidth();
    });
  }

  setMinGridWidth = () => {
    this.grid = document.querySelector('.k-grid');
    window.addEventListener('resize', this.handleResize);
    this.minGridWidth = 0;
    columns.map(item => this.minGridWidth += item.minWidth);
    if (this.state.dataState.group)
      this.minGridWidth += 32 * this.state.dataState.group.length;
    this.setState({
      gridCurrent: this.grid.offsetWidth,
      setMinWidth: this.grid.offsetWidth < this.minGridWidth
    });
  }

  handleResize = () => {
    if (this.grid.offsetWidth < this.minGridWidth && !this.state.setMinWidth) {
      this.setState({
        setMinWidth: true
      });
    } else if (this.grid.offsetWidth > this.minGridWidth) {
      this.setState({
        gridCurrent: this.grid.offsetWidth,
        setMinWidth: false
      });
    }
  }

  setWidth = (minWidth) => {
    let width = this.state.setMinWidth ? minWidth : minWidth + (this.state.gridCurrent - this.minGridWidth) / columns.length;
    if (width >= COLUMN_MIN)
      width = width - ADJUST_PADDING;
    return width;
  }

  getStyleSettings(cb) {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      'holdingsHouseholdName',
      'rgainDefaultPeriod',
      'rgainShowAccountID',
      'rgainShowName1',
      'rgainShowName2',
      'rgainShowName3',
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
      .then(response => {
        this.setState({ styleSettings: response.data.styleSettings }, () => {
          cb()
        });
      })
      .catch(err => {
        console.error(err);
      })


  }

  setHouseholdOrConsolidated = (data) => {
    if (this.state.styleSettings.holdingsHouseholdName != null && this.state.styleSettings.holdingsHouseholdName === 'Consolidated'
        && data.data != null && data.data[0] != null && data.data[0].value != null) {
            data.data[0].value = 'Consolidated';
    }
    return data;
  }

  onFieldChange(e, field) {
    this.setState({ [field]: e.target.value })
  }

  getPeriod() {
    var period = this.state.styleSettings.rgainDefaultPeriod;
    var date = new Date();

    if (period === "Month to Date")
      date = new Date(date.getFullYear(), date.getMonth(), 1);
    else if (period === 'Quarter to Date') {
      var quarter = Math.floor((date.getMonth() / 3));
      date = new Date(date.getFullYear(), quarter * 3, 1);
    }
    else if (period === 'Year to Date')
      date = new Date(date.getFullYear(), 0, 1);
    else if (period === '6 Month')
      date = new Date(date.getFullYear(), date.getMonth() - 6, date.getDate());
    else if (period === '12 Month')
      date = new Date(date.getFullYear() - 1, date.getMonth(), date.getDate());
    else date = null;

    return date;
  }

  getRGains(cb) {
    this.setState({ loading: true });
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    // var unmapped = '';
    var rgains = []
    var totalGains = 0

    var port = this.state.portfolio.account
    if (this.state.portfolio.consolidated) {
      port = this.props.household
    }

    // var breakOutTaxLotAccounts = false;
    if ((this.state.styleSettings.rgainShowAccountID ||
      this.state.styleSettings.rgainShowName1 ||
      this.state.styleSettings.rgainShowName2 ||
      this.state.styleSettings.rgainShowName3) &&
      this.state.showAccountBreakdown) {
      // breakOutTaxLotAccounts = true;
    }

    var params = {
      company: this.props.user.company,
      port: port,
      consolidated: this.state.portfolio.consolidated,
      from: this.state.fromDate,
      to: this.state.toDate,
      //breakOutConsolidatedPorts: breakOutTaxLotAccounts,
      unmapped: this.props.user.unmapped ? true : null
    }

    // if(this.props.user.unmapped)
    //   unmapped = '&unmapped=true';

    //axios.get('api/rgains?company='+this.props.user.company+'&port='+this.state.portfolio.account+'&from='+this.state.fromDate+'&to='+this.state.toDate+''+unmapped, {headers})
    axios.get(`api/rgains`, {
      method: 'GET',
      params: params,
      headers: headers,
    })
      .then(response => {
        if (response.data.code === 200) {
          var fDate = this.state.fromDate;
          var tDate = this.state.toDate;
          // var hasRealized = false
          // var hasCapital = false
          var getNewDate = false
          if (!fDate) {
            getNewDate = true
          }

          for (let i = 0; i < response.data.data.length; i++) {
            if (this.state.portfolio.consolidated) {
              response.data.data[i].household = this.props.household
            }

            response.data.data[i].openDate = this.getDate(response.data.data[i].openDate, true);
            response.data.data[i].closeDate = this.getDate(response.data.data[i].closeDate, true);
            if (!response.data.data[i].displayName)
              response.data.data[i].displayName = response.data.data[i].portCode;

            response.data.data[i].Portfolio = response.data.data[i].displayName

            response.data.data[i].gains = (response.data.data[i].shortTerm || 0) + (response.data.data[i].longTerm || 0)
            totalGains = totalGains + response.data.data[i].gains

            //get earliest date
            if (getNewDate && (!fDate || new Date(fDate) > new Date(response.data.data[i].closeDate)))
              fDate = response.data.data[i].closeDate;


              //only include capital gains (realizedCap === 'C') if includeCapGains is true
            if (this.state.includeCapGains || response.data.data[i].realizedCap === 'R') {
              // if (response.data.data[i].realizedCap === 'R')
              //   hasRealized = true
              // if (response.data.data[i].realizedCap === 'C')
              //   hasCapital = true

              rgains.push(response.data.data[i])
            }
          }

          var defaultGroup = []

          if (this.state.portfolio.consolidated) {
            defaultGroup.push({ field: "household", aggregates: this.state.aggregates });
          }
          else {
            defaultGroup.push({ field: "Portfolio", aggregates: this.state.aggregates });

          }
          if (this.state.showAccountBreakdown) {
            if (this.state.styleSettings.rgainShowName1) {
              defaultGroup.push({ field: "Name1", aggregates: this.state.aggregates });
            }
            if (this.state.styleSettings.rgainShowName2) {
              defaultGroup.push({ field: "Name2", aggregates: this.state.aggregates });
            }
            if (this.state.styleSettings.rgainShowName3) {
              defaultGroup.push({ field: "Name3", aggregates: this.state.aggregates });
            }
            if (this.state.styleSettings.rgainShowAccountID) {
              defaultGroup.push({ field: "AccountID", aggregates: this.state.aggregates });
            }
          }

          // if (hasRealized && hasCapital)
            defaultGroup.push({ field: "realizedCap", aggregates: this.state.aggregates });

          // var dState = this.state.dataState;
          //dState.group = [{field:"Portfolio", aggregates:this.state.aggregates}]

          this.setState({ dataState: { sort: this.state.dataState.sort, group: defaultGroup, aggregates: this.state.aggregates }, totalGains: totalGains }, () => {
            this.setState({ rgains: rgains, loading: false, toDate: tDate, fromDate: fDate, result: this.addPropertyToItems(this.setHouseholdOrConsolidated(process(rgains, this.state.dataState))) }, () => {
              this.sortClassification();
              cb();
            });
          })
        }
      }).catch(err => {
        this.setState({ rgains: [], loading: false });
        console.log(err);
      });
  }


  sortClassification = () => {
    this.state.result.data.forEach(row => {

      if (row.items & row.items.length > 0) {
        if (row.items[0].field === 'realizedCap') {
          row.items.sort().reverse()
        }
      }


      this.sortClassificationChild(row);
    });
    this.forceUpdate();
  }

  sortClassificationChild = (child) => {
    if (child.items) {
      if (child.items.length > 0) {
        if (child.items[0].field === 'realizedCap') {
          child.items.sort().reverse()
        }
      }

      child.items.forEach(item => {
        this.sortClassificationChild(item);
      })
    }
  }

  getDate(sqlDate, isUTC) {
    var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
    if (d) {
      var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
      if (isUTC)
        return utc;
      else
        return d;
    }
    else
      return null;
  }

  _export;
  export = () => {
    this._export.save();
  }

  CustomGroupHeader = (props) => {
    return (props.value.replace(/&/g,'+'))
}
  
  CustomGroupFooter = (props) => {
    var val;
    if (props.column.field === 'closeDate') {
        return (props.group.value.replace(/&/g,'+') + " Totals:")
    }
    if (props.column.field === 'closeDate'){
      if (props.group.field === 'realizedCap'){
        return ((props.group.value === 'R' ? 'Realized Gain/Loss' : 'Capital Gain Distributions') + " Totals:")  
      }
      return (props.group.value + " Totals:")
    }
    else if (props.column.field === 'secSymbol'){
        val = ''
        val = props.aggregates.gains.sum

        val = this.formatCurrency(val);
        return (val)  
    }
    else if ((props.column.field === 'costBasis' || props.column.field === 'proceeds' || props.column.field === 'shortTerm' || props.column.field === 'longTerm') && props.aggregates[props.column.field] && props.aggregates[props.column.field].sum !== undefined){
      return props.aggregates[props.column.field].sum
    }
    else if ((props.column.field === 'costBasis' || props.column.field === 'proceeds' || props.column.field === 'shortTerm' || props.column.field === 'longTerm') && props.aggregates[props.column.field] && props.aggregates[props.column.field].min !== undefined){
      return props.aggregates[props.column.field].min
    }
    else if ((props.column.field === 'costBasis' || props.column.field === 'proceeds' || props.column.field === 'shortTerm' || props.column.field === 'longTerm') && props.aggregates[props.column.field] && props.aggregates[props.column.field].max !== undefined){
      return props.aggregates[props.column.field].max
    }
    else{
      return " "
    }
  }

  excelTotal = () => {return aggregateBy(this.state.accountHoldings, this.state.aggregates)}
    
  CustomFooter = (props) => {return ('Total ' + props.column.field + ': ' + this.excelTotal()[props.column.field].sum)}

  processForExcel = (data) => {
    // console.log(data)
    if (data != null && data.length > 0) {
        data.filter(d => d.items != null).forEach(d => {
            if (d.items) {
                if (d.field === 'realizedCap' && d.value === 'R') { d.field = ''; d.value = 'Realized Gain/Loss' }
                if (d.field === 'realizedCap' && d.value === 'C') { d.field = ''; d.value = 'Capital Gain Distributions' }
                // if (d.field === 'AccountID') { d.value = '='+d.value }
                d = this.processForExcel(d.items)
            } 
        })
        data.filter(d => d.items == null).forEach(d => {
            if (d.openDate != null) d.openDate = String(d.openDate).substring(5,7)+'/'+ String(d.openDate).substring(8,10)+'/'+ String(d.openDate).substring(0,4);
            if (d.closeDate != null) d.closeDate =  String(d.closeDate).substring(5,7)+'/'+ String(d.closeDate).substring(8,10)+'/'+ String(d.closeDate).substring(0,4);
        })
    }
    return data;
  }

  render() {
    return (
      <div className='rgains'>
        <ExcelExport
          data={this.processForExcel(JSON.parse(JSON.stringify(this.state.result)).data)}
          collapsible={true}
          group={this.state.dataState.group}
          paddingCellOptions={{ background: '#ffffff' }}
          fileName={'RealizedGains.xlsx'}
          ref={(exporter) => { this._export = exporter; }}
        >

          
          {
            this.state.dataState.group.map((group, key) => {
              if (columns.findIndex(column=>{
                return column.field === group.field;
              }) < 0){
                return( 
                    // accountID top header
                  <ExcelExportColumn 
                  field={group.field} 
                  title={group.title} 
                  key={key} 
                  hidden={true}
                  //cellOptions={{ format: column.excelFormat }}
                  footerCellOptions={{ background:'#ffffff' }}
                  groupHeader={(e) => 'Account ID: '+this.CustomGroupHeader(e)}
                  groupHeaderCellOptions={{background:'#ffffff'}}
                  //groupFooterCellOptions={{ background:'#ffffff' }}
                  //width={this.setWidth(column.minWidth)} 
                />)
              } else {
                return (<></>)
              }

            
            })
          }
          {
            columns.map((column, key) => {
              if (column.field === 'AccountID' && !this.state.styleSettings.rgainShowAccountID)
                return <></>
              if (column.field === 'Name1' && !this.state.styleSettings.rgainShowName1)
                return <></>
              if (column.field === 'Name2' && !this.state.styleSettings.rgainShowName2)
                return <></>
              if (column.field === 'Name3' && !this.state.styleSettings.rgainShowName3)
                return <></>
              if(column.field === 'closeDate'){
                // closeDate
                return column.show && 
                  <ExcelExportColumn 
                    field={column.field} 
                    title={column.title} 
                    key={key} 
                    cellOptions={{ format: column.excelFormat, background:'#ffffff' }}
                    //footerCellOptions={{ wrap: true, textAlign: 'center' }}
                    groupHeader={(e) => this.CustomGroupHeader(e)}
                    groupHeaderCellOptions={{background:'#ffffff'}}
                    groupFooterCellOptions={{ textAlign: 'left',}}
                    groupFooter={(e) => this.CustomGroupFooter(e)}
                    footerCellOptions={{ background:'#ffffff' }}
                    //footer={(e) => this.CustomFooter(e)} 
                    width={column.excelWidth}
                  />
              }

              var aggIndex = this.state.origAggregates.findIndex(agg=>{
                return agg.field === column.field;
              });

              

              if(aggIndex>=0){
                // costBasis, proceeds, shortTerm, longTerm
                return column.show && 
                  <ExcelExportColumn 
                    field={column.field} 
                    title={column.title} 
                    key={key} 
                    cellOptions={{ format: column.excelFormat, background:'#ffffff' }}
                    //footerCellOptions={{ wrap: true, textAlign: 'center' }}
                    groupHeaderCellOptions={{ textAlign: 'left', format: 'text', background: '#ffffff'}}
                    groupFooterCellOptions={{ textAlign: 'right', format: column.excelFormat, }}
                    groupFooter={(e) => this.CustomGroupFooter(e)}
                    footerCellOptions={{ background:'#ffffff' }}
                    //footer={(e) => this.CustomFooter(e)} 
                    width={column.excelWidth}
                  />
              }
              // else if (column.field=== 'secSymbol'){
              //   return column.show && 
              //     <ExcelExportColumn 
              //       field={column.field} 
              //       title={column.title} 
              //       key={key} 
              //       cellOptions={{ format: column.excelFormat, background:'#ffffff' }}
              //       //footerCellOptions={{ wrap: true, textAlign: 'center' }}
              //       groupFooterCellOptions={{ textAlign: 'right', format: column.excelFormat, }}
              //       groupFooter={(e) => this.CustomGroupFooter(e)}
              //       footerCellOptions={{ background:'#ffffff' }}
              //       //footer={(e) => this.CustomFooter(e)} 
              //       width={this.setWidth(column.minWidth)} 
              //     />
              // }

              var groupIndex = this.state.dataState.group.findIndex(group=>{
                return group.field === column.field;
              });

              if(groupIndex>=0){
                // accountID & header
                return (column.show && 
                  <ExcelExportColumn 
                    field={column.field} 
                    title={column.title} 
                    key={key} 
                    cellOptions={{ format: column.excelFormat, background:'#ffffff' }}
                    groupHeader={(e) => 'Account ID: '+this.CustomGroupHeader(e)}
                    groupHeaderCellOptions={{background:'#ffffff'}}
                    footerCellOptions={{ background:'#ffffff' }}
                    //groupFooterCellOptions={{ background:'#ffffff' }}
                    width={column.excelWidth}
                  />)
              }

              
                
                // this.state.aggregates.forEach(thisAgg =>{
                //   if (column.field === thisAgg.field){
                //     return column.show && 
                //       <ExcelExportColumn 
                //         field={column.field} 
                //         title={column.title} 
                //         key={key} 
                //         //cellOptions={{ format: column.format }}
                //         footerCellOptions={{ wrap: true, textAlign: 'center' }}
                //         groupFooterCellOptions={{ textAlign: 'right' }}
                //         groupFooter={(e) => this.CustomGroupFooter(e)}
                //         footer={(e) => this.CustomFooter(e)} 
                //         width={this.setWidth(column.minWidth)} 
                //       />
                //   }
                // })

                
                // openDate, symbol, securityName
                if (column.show && column.field !== 'secSymbol') {
                    return (
                  <ExcelExportColumn 
                    field={column.field} 
                    title={column.title} 
                    key={key} 
                    cellOptions={{ format: column.excelFormat, background: '#ffffff' }}
                    footerCellOptions={{ background:'#ffffff' }}
                    width={column.excelWidth}
                    //groupFooterCellOptions={{ background:'#ffffff' }}
                    // footerCellOptions={{ wrap: true, textAlign: 'center' }}
                    // groupFooterCellOptions={{ textAlign: 'right' }}
                    // groupFooter={(e) => this.CustomGroupFooter(e)}
                    // footer={CustomFooter} 
                    // width={this.setWidth(column.minWidth)} 
                  />)
                }
               
                // quantity
                if (column.show && column.field === 'secSymbol') {
                    return (
                  <ExcelExportColumn 
                    field={column.field} 
                    title={column.title} 
                    key={key} 
                    cellOptions={{ format: column.excelFormat, background: '#ffffff' }}
                    footerCellOptions={{ background:'#ffffff' }}
                    width={column.excelWidth}
                    //groupFooterCellOptions={{ background:'#ffffff' }}
                    // footerCellOptions={{ wrap: true, textAlign: 'center' }}
                    groupFooterCellOptions={{ textAlign: 'right' }}
                    groupFooter={(e) => this.CustomGroupFooter(e)}
                    // footer={CustomFooter} 
                    // width={this.setWidth(column.minWidth)} 
                  />)
                }
               
                return (<></>)
                
            })
          }
        </ExcelExport>
        {/* <ExcelExport data={this.state.rgains} fileName={'RealizedGains.xlsx'} ref={(exporter) => { this._export = exporter; }}> */}
          <Grid data={this.state.result}
            groupable={{ enabled: false, footer: 'always' }}
            className='noGroupGridLines'
            resizable={true}
            reorderable={true}
            filterable={false}
            sortable={true}
            onDataStateChange={this.changeDataState}
            onExpandChange={this.expandChange}
            expandField="expanded"
            cellRender={this.cellRender}
            headerCellRender={this.headerCellRender}
            {...this.state.dataState}>
            <GridNoRecords>
              {this.state.loading && 'Loading...'}
              {!this.state.loading && 'No records available'}
            </GridNoRecords>
            <GridToolbar>
              <label style={{ paddingLeft: '10px' }}><strong>From: </strong></label><DatePicker value={this.state.fromDate} max={new Date()} onChange={(e) => this.onFieldChange(e, 'fromDate')} />
              <label style={{ paddingLeft: '5px' }}><strong>To: </strong></label><DatePicker value={this.state.toDate} max={new Date()} onChange={(e) => this.onFieldChange(e, 'toDate')} />
              <Button icon="refresh" title='Refresh' onClick={this.refresh}>Refresh</Button>
              <Button icon="excel" title='Export to Excel' onClick={this.export}>Export</Button>
              {/* <label><input className='bigger' type="checkbox" name="includeCapGains" value="value" checked={this.state.includeCapGains} onChange={(e) => { this.setState({ includeCapGains: !this.state.includeCapGains }, () => { this.getRGains(() => this.setMinGridWidth()) }); }} />Include Capital Gains</label> */}
              {(this.state.styleSettings.rgainShowAccountID ||
                this.state.styleSettings.rgainShowName1 ||
                this.state.styleSettings.rgainShowName2 ||
                this.state.styleSettings.rgainShowName3) &&
                <label><input className='bigger' type="checkbox" name="checkbox" value="value" checked={this.state.showAccountBreakdown} onChange={(e) => { this.setState({ showAccountBreakdown: !this.state.showAccountBreakdown }, () => { this.getRGains(() => this.setMinGridWidth()) }); }} />Show Account Breakdown</label>
              }


            </GridToolbar>
            {
              columns.map((column, key) => {
                if (column.field === 'AccountID' && (!this.state.styleSettings.rgainShowAccountID || !this.state.showAccountBreakdown))
                  return <></>
                if (column.field === 'Name1' && (!this.state.styleSettings.rgainShowName1 || !this.state.showAccountBreakdown))
                  return <></>
                if (column.field === 'Name2' && (!this.state.styleSettings.rgainShowName2 || !this.state.showAccountBreakdown))
                  return <></>
                if (column.field === 'Name3' && (!this.state.styleSettings.rgainShowName3 || !this.state.showAccountBreakdown))
                  return <></>
                else
                  return <GridColumn field={column.field} title={column.title} key={key} format={column.format} headerClassName={column.headerClass} width={this.setWidth(column.minWidth)} />
              })
            }
          </Grid>
        {/* </ExcelExport> */}
        {this.state.loading && <GridLoading />}
      </div>
    );
  }

  changeDataState = (e) => {
    const groups = e.dataState.group;
    if (groups) {
      groups.map(group => group.aggregates = this.state.aggregates);
    }
    var newData = process(this.state.rgains, e.dataState);
    this.setState({ dataState: e.dataState, result: this.addPropertyToItems(this.setHouseholdOrConsolidated(newData)) }, () => this.setMinGridWidth());
  }

  formatCurrency(val) {
    var formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val);
    return formatted;
  }

  cellRender(tdElement, cellProps) {
    var gainType;
    var totVal;
    if (tdElement && tdElement.props.children && cellProps.rowType === "groupHeader") {
      if (cellProps.dataItem.field === 'realizedCap') {
        gainType = cellProps.dataItem.value;
        let children = (
          <span>
            <div tabIndex="-1" className="k-i-collapse k-font-icon k-icon"></div>
            {gainType.toLowerCase() === 'r' ? 'Realized Gain/Loss' : 'Capital Gain Distributions'}
          </span>
        );
        return React.cloneElement(tdElement, tdElement.props, children);
      }
    }
    if (cellProps.rowType === 'groupFooter') {
      if (cellProps.columnIndex === this.state.dataState.group.length + 2){
        var val = ''
        val = cellProps.dataItem.aggregates.gains.sum

        val = this.formatCurrency(val);

        if (cellProps.dataItem.field === 'realizedCap' && this.state.totalGains.toFixed(2) === cellProps.dataItem.aggregates.gains.sum.toFixed(2)){
          return (
            <td style={{ textAlign: 'right' }}>
            </td>
          );
        }
        else {
          return (
            <td style={{ textAlign: 'right', display:'table-cell', verticalAlign: 'bottom' }}>
              <strong style={{ color: 'black' }} >{val}</strong>
            </td>
          );
        }
        
      }
      if (cellProps.field !== 'value' && cellProps.field !== 'displayName' && cellProps.field !== 'secSymbol' && cellProps.field !== 'secName' && cellProps.field !== 'openDate' && cellProps.field !== 'closeDate' && cellProps.field !== 'quantity' && cellProps.field !== 'AccountID' && cellProps.field !== 'Name1' && cellProps.field !== 'Name2' && cellProps.field !== 'Name3' && cellProps.field !== 'realizedCap') {
        val = '';
        if (cellProps.dataItem.aggregates[cellProps.field])
          val = cellProps.dataItem.aggregates[cellProps.field].sum

        val = this.formatCurrency(val);
        if (cellProps.dataItem.field === 'realizedCap' && cellProps.dataItem.value.toLowerCase() === 'c' && (cellProps.field === 'costBasis' || cellProps.field === 'proceeds')){
          return (
            <td style={{ textAlign: 'right' }}/>
          );
        }
        if (cellProps.dataItem.field === 'realizedCap' && this.state.totalGains.toFixed(2) === cellProps.dataItem.aggregates.gains.sum.toFixed(2)){
          return (
            <td style={{ textAlign: 'right' }}>
            </td>
          );
        }
        else {
          return (
            <td style={{ textAlign: 'right', display:'table-cell', verticalAlign: 'bottom' }}>
              <strong>{val}</strong>
            </td>
          );
        }
      }
      else if (cellProps.field === 'displayName') {
        return (<></>);
      }
      else if (!this.state.showAccountBreakdown && cellProps.field === 'closeDate') {
        totVal = cellProps.dataItem.value;
        if (isDate(totVal))
          totVal = totVal.getUTCMonth() + '/' + totVal.getUTCDate() + '/' + totVal.getUTCFullYear();
        if (cellProps.dataItem.field === 'realizedCap') {
          gainType = cellProps.dataItem.value;
          if (this.state.totalGains.toFixed(2) === cellProps.dataItem.aggregates.gains.sum.toFixed(2)){
            return (
              <td colSpan='2'>
              </td>
            );
          }
          else {
            return (
              <td colSpan='2'>
                <strong style={{ color: 'black' }}>{gainType.toLowerCase() === 'r' ? 'Realized Gain/Loss' : 'Capital Gain Distributions'} Totals:</strong>
              </td>
            );
          }
        }
        return (
          <td colSpan='2'>
            <strong style={{ color: 'black' }}>{totVal} Totals:</strong>
          </td>
        );
      }
      else if (cellProps.columnIndex === this.state.dataState.group.length + 3) {
        return (
          <></>
        );
      }
      else if (this.state.showAccountBreakdown && (cellProps.field === 'AccountID' || cellProps.field === 'Name1' || cellProps.field === 'Name2' || cellProps.field === 'Name3')) {
        totVal = cellProps.dataItem.value;
        if (isDate(totVal))
          totVal = totVal.getUTCMonth() + '/' + totVal.getUTCDate() + '/' + totVal.getUTCFullYear();
        if (cellProps.columnIndex === this.state.dataState.group.length + 3) {
          return (
            <></>
          );
        }
        if (cellProps.dataItem.field === 'realizedCap') {
          gainType = cellProps.dataItem.value;
          if (this.state.totalGains.toFixed(2) === cellProps.dataItem.aggregates.gains.sum.toFixed(2)){
            return (
              <td colSpan='2'>
              </td>
            );
          }
          else {
            return (
              <td colSpan='2'>
                <strong style={{ color: 'black' }}>{gainType.toLowerCase() === 'r' ? 'Realized Gain/Loss' : 'Capital Gain Distributions'} Totals:</strong>
              </td>
            );
          }
        }
        return (
          <td colSpan='2'>
            <strong style={{ color: 'black' }}>{totVal} Totals:</strong>
          </td>
        );
      }
    }
    if (tdElement !== null) {
      var style = {};
      if (cellProps.field === 'quantity' || cellProps.field === 'costBasis' || cellProps.field === 'proceeds'
        || cellProps.field === 'shortTerm' || cellProps.field === 'longTerm')
        style = { textAlign: 'right' };

      if (cellProps.field === 'quantity') {
        var quant = cellProps.dataItem[cellProps.field];
        if (quant === 0 || quant === undefined || quant === null)
          quant = '';
        else
          quant = quant.toFixed(2);
        return (
          <td {...tdElement.props} style={style}>{quant}</td>
        );
      }

      return (
        <td {...tdElement.props} style={style} />
      );
    }
    else {
      return tdElement;
    }
  }

  headerCellRender(thElement, cellProps) {
    if (thElement !== null) {
      var style = {};
      if (cellProps.field === 'quantity' || cellProps.field === 'costBasis' || cellProps.field === 'proceeds'
        || cellProps.field === 'shortTerm' || cellProps.field === 'longTerm')
        style = { justifyContent: 'right' };

      return (
        <div {...thElement.props} style={style} />
      );
    }
    else {
      return thElement;
    }
  }

  expandChange = (event) => {
    const expandField = event.target.props.expandField;
    const itemId = event.dataItem.id;

    const updateItemsRecursive = (items) => {
      return items.map(item => {
        let updatedItem = item;
        if (item.id === itemId) {
          updatedItem = {
            ...item,
            [expandField]: !item[expandField],
          };
        }
        if (Array.isArray(item.items)) updatedItem.items = updateItemsRecursive(item.items);
        return updatedItem;
      });
    };

    const updatedData = updateItemsRecursive(this.state.result.data);

    this.setState((prevState) => ({
      ...prevState,
      result: {
        ...prevState.result,
        data: updatedData
      }
    }));
  }

  addPropertyToItems = (obj) => {
    // Usage: (call in both initial data set and prior to any datagrid result state change)
    // result = this.addPropertyToItems(result);

    const addPropertyToNestedItems = (item) => {
      item.id = Math.random();
      item.expanded = true;

      // Recursively call this function for each item in the 'items' array
      if (Array.isArray(item.items)) item.items.forEach(subItem => addPropertyToNestedItems(subItem));
      return item;
    }

    // Traverse the array and add properties
    if (Array.isArray(obj.data)) obj.data.forEach(item => addPropertyToNestedItems(item));
    return obj;
  }

  expand = () => {
    const newData = this.state.result.data.map(row => {
      const newRow = { ...row };
      newRow.expanded = true;
      newRow.items = this.expandItems(newRow.items);
      return newRow;
    });
    this.setState(prevState => ({
      result: {
        ...prevState.result,
        data: newData
      }
    }));
  };

  expandItems = (items) => {
    if (!items) return [];
    return items.map(item => {
      const newItem = { ...item };
      newItem.expanded = true;
      newItem.items = this.expandItems(newItem.items);
      return newItem;
    });
  };

  expandCollapse = (field) => {
    const newData = this.state.result.data.map(row =>
      this.expandCollapseItems(row, field)
    );
    this.setState(prevState => ({
      result: {
        ...prevState.result,
        data: newData
      }
    }));
  };

  expandCollapseItems = (item, field) => {
    const newItem = { ...item };
    if (!field || field === newItem.field) {
      newItem.expanded = !newItem.expanded;
    }
    if (newItem.items) {
      newItem.items = newItem.items.map(child => this.expandCollapseItems(child, field));
    }
    return newItem;
  };

}

export default RGains;