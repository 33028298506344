import React, { Component } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
//import {filterBy, orderBy} from '@progress/kendo-data-query';
import { parseDate } from '@telerik/kendo-intl';
import { process } from '@progress/kendo-data-query';
import axios from 'axios';
import GridLoading from '../Loaders/GridLoading';


class ReviewExports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            packages: [],
            categories: [],
            result:[],
            platforms: [],
            reportsLoading: true,
            fileDownload: '',
            fileSelected: false,
            gridFilter: undefined,
            dataState:{skip:0, take:500, group:undefined, filter:undefined, sort:[{ field: 'RequestedDateTime', dir: 'desc' }]},
            filterable: false,//props.styleSettings.showFilters,
            sort: undefined,
            loading: true,
            preppingZip: false
        }

        this.download = this.download.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.refreshPackages = this.refreshPackages.bind(this);
        this.getReviewExportsList = this.getReviewExportsList.bind(this);
    }

    componentDidMount() {
        this.getReviewExportsList();
    }

    getReviewExportsList(parameterList) {
        var reports = [];
        // var reportNames = [];
        // var splitName;
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            
        axios(`api/reviewExportsList`, {
          method: 'GET',
          params: { company: this.props.user.company },
          headers: headers,
        })
          .then(response => {
            console.log(response)
            // var folder
            response.data.data.forEach(exportInResponse => {
                if (exportInResponse.RequestedDateTime){
                    exportInResponse.RequestedDateTime = this.getDate(exportInResponse.RequestedDateTime)
                }
                if (exportInResponse.ProcessedDateTime){
                    exportInResponse.ProcessedDateTime = this.getDate(exportInResponse.ProcessedDateTime)
                }
              reports.push(exportInResponse)
            })
            var data = process(reports, this.state.dataState)
            this.setState({ packages: reports, result: data, loading: false })
    
          })
          .catch(err => {
            this.setState({loading: false})
            console.error(err);
          })
    
      }

    changeDataState = (e)=>{
        var newData = process(this.state.packages, e.data);
        this.setState({dataState:e.data, result:newData});
      }

    getDate(sqlDate, isUTC) {
        var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
        var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds());
        if (isUTC)
            return utc;
        else
            return d;
    }

    onLinkClick = (key) => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        axios(`api/ssrsGetS3File`, {
            method: 'GET',
            params: { key: key, name: key.split('/')[1], company: this.props.user.company },
            headers: headers,
        })
            .then(response => {
                window.location.assign(response.data)
            })
            .catch(err => {
                console.error(err);
            })

        return false;
    }

    selectionChange(event, gFiles) {
        event.dataItem.selected = !event.dataItem.selected;
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ fileDownload: selectedFiles });
        if (selectedFiles.length > 0) {
            this.setState({ fileSelected: true });
        }
        else {
            this.setState({ fileSelected: false });
        }
        this.forceUpdate();
    }

    headerSelectionChange(event, gFiles) {
        const checked = event.syntheticEvent.target.checked;
        gFiles.forEach(item => item.selected = checked);
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ fileDownload: selectedFiles });
        if (selectedFiles.length > 0) {
            this.setState({ fileSelected: true });
        }
        else {
            this.setState({ fileSelected: false });
        }
        this.forceUpdate();
    }

    getDownButtonText(files) {
        if (!files || files.length === 0) {
            return 'Select Package(s) to Download'
            //this.setState({downButtonText: 'Select Package(s) to Download'})
        }
        else if (Array.isArray(files) && files.length > 1) {
            return 'Download ' + files.length + ' packages';
            //this.setState({downButtonText: 'Download ' + files.length + ' packages'})
        }
        else if (Array.isArray(files) && files.length === 1) {
            return 'Download ' + files[0].name;
            //this.setState({downButtonText: 'Download ' + files[0].name})
        }
        else {
            return 'Download ' + files.name;
            //this.setState({downButtonText: 'Download ' + files.name})
        }
    }

    download() {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var payload = { "files": [], "company": this.props.user.company };
        if (Array.isArray(this.state.fileDownload) && this.state.fileDownload.length > 1) {
            this.setState({preppingZip: true })
            for (let i = 0; i < this.state.fileDownload.length; i++) {
                console.log(this.state.fileDownload[i])
                payload.files.push({
                    "path": this.state.fileDownload[i].Key,
                    "name": this.state.fileDownload[i].name
                });
            }
            console.log(payload)
            axios
                .post("api/downloadzip", payload, { headers })
                .then((response) => {
                    console.log(response)
                    window.open("api/downloadzip?user=" + this.props.user.name);
                    this.setState({preppingZip: false })
                })
                .catch((e) => {
                    console.error(e);
                    this.setState({preppingZip: false })
                });
        }
        else if (Array.isArray(this.state.fileDownload)) {
            axios(`api/ssrsGetS3File`, {
                method: 'GET',
                params: { key: this.state.fileDownload[0].Key, company: this.props.user.company, name: this.state.fileDownload[0].name},
                headers: headers,
            })
                .then(response => {
                    window.location.assign(response.data)
                })
                .catch(err => {
                    console.error(err);
                    return false;
                })
        }
        else {
            axios(`api/ssrsGetS3File`, {
                method: 'GET',
                params: { key: this.state.fileDownload[0].Key, company: this.props.user.company, name: this.state.fileDownload[0].name},
                headers: headers,
            })
                .then(response => {
                    console.log(response.data)
                    window.location.assign(response.data)
                })
                .catch(err => {
                    console.error(err);
                    return false;
                })
        }

    }

    onDelete = (e) => {
        var files = [];
        var packageToDelete;
        var addFile = {};
        for (let i = 0; i < this.state.fileDownload.length; i++) {
            packageToDelete = this.state.fileDownload[i];
            addFile.path = packageToDelete.ResultsS3Path;
            addFile.text = packageToDelete.ResultsS3Path;
            files.push(JSON.parse(JSON.stringify(addFile)));
            if (packageToDelete.StatusLog){
                addFile.path = packageToDelete.StatusLog;
                addFile.text = packageToDelete.StatusLog;
                files.push(JSON.parse(JSON.stringify(addFile)));
            }
            addFile = {};
        }

        var confirm = window.confirm("Are you sure you want to delete these file(s)?");

        if (confirm) {
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            var payload = {
                files: files,
                isDataExportOutput: true,
                company: this.props.user.company,
            };
            axios.post('api/deleteFile', payload, { headers }).then(response => {
                if (response.data.code === 200) {
                    this.setState({ fileDownload: '' });
                    this.setState({ fileSelected: false });
                    this.refreshPackages();
                }
                else {
                    alert("Error: Data Export(s) could not be deleted.");
                    this.refreshPackages();
                }
            }).catch(e => console.log(e));
        }
    }

    onDownloadClick = (e) => {
        this.download();
    }

    onFilterChange = (e) => {
        this.setState({ gridFilter: e.filter });
    }

    refreshPackages() {
        this.setState({ fileDownload: '' });
        this.setState({ fileSelected: false, loading: true });
        this.getReviewExportsList();
    }

    render() {
        return (
            <>
                {//this.props.uploadUser.approved !== false &&
                    <div className="packageReview">
                        <Grid data={this.state.result}
                            selectedField='selected'
                            pageable={true}
                            onSelectionChange={(e) => this.selectionChange(e, this.state.packages)}
                            onRowClick={(e) => this.selectionChange(e, this.state.packages)}
                            onDataStateChange={this.changeDataState}
                            onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.packages)}
                            filterable={this.state.filterable}
                            resizable
                            {...this.state.dataState}
                        >
                            <GridToolbar>
                                <Button icon='refresh' title='Refresh' onClick={this.refreshPackages}>{this.state.refreshText}</Button>
                                <Button icon='filter' title='Filter' onClick={(e) => this.setState({ filterable: !this.state.filterable })}>Filter</Button>
                                {/* {this.state.preppingZip 
                                    ? <Button className="docButton" disabled={true} icon="download" >{'Preparing Files...'}</Button>
                                    : <Button className="docButton" disabled={!this.state.fileSelected} icon="download" onClick={this.onDownloadClick}>{this.getDownButtonText(this.state.fileDownload)}</Button>
                                } */}
                                <Button className="docButton" disabled={!this.state.fileSelected} onClick={this.onDelete} icon='delete'>Delete</Button>
                            </GridToolbar>
                            <GridColumn width='50px' field="selected" filterable={false} headerClassName='gridHeader' headerSelectionValue={this.state.packages.findIndex(dataItem => dataItem.selected === false) === -1} />
                            <GridColumn width='107px' field="RequestID" headerClassName='gridHeader' title="Request ID" />
                            <GridColumn width='250px' field="ExportID" headerClassName='gridHeader' title="Export ID" />
                            <GridColumn width='283px' field="RequestingUserEmail" headerClassName='gridHeader' title="User" />
                            <GridColumn width='181px' field="RequestedDateTime" headerClassName='gridHeader' filter="date" format="{0:g}" title="Date" />
                            <GridColumn width='105px' field="Status" headerClassName='gridHeader' title="Status" />
                            <GridColumn
                                // width='350px'
                                // field="Key"
                                title="Download Link"
                                headerClassName='gridHeader'
                                cell={(props) =>
                                    (props.dataItem.Status && props.dataItem.Status.includes("Completed"))  && <td>
                                        <Button disabled={!props.dataItem.ResultsS3Path} title='Download' onClick={(event) => this.onLinkClick(props.dataItem.ResultsS3Path)}>Download</Button>
                                        <span style={{paddingLeft:'10px'}}></span>
                                        <Button disabled={!props.dataItem.StatusLog} title='Log' onClick={(event) => this.onLinkClick(props.dataItem.StatusLog)}>Log</Button>
                                    </td>
                                } />
                        </Grid>
                        {this.state.loading && <GridLoading gridClass='packageReview' />}
                    </div>
                }

            </>
        );
    }
}

export default ReviewExports;