import React, { Component } from "react";
import {Grid, GridColumn, GridToolbar} from '@progress/kendo-react-grid';
import axios from 'axios';
import {parseDate} from '@telerik/kendo-intl';
import LogDetail from './components/LogDetail';
import {process} from '@progress/kendo-data-query';
import { Button } from "@progress/kendo-react-buttons";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import {DatePicker} from '@progress/kendo-react-dateinputs';
import GridLoading from '../Loaders/GridLoading';

class LogList extends Component {

  constructor(props) {
    super(props);

    var fromDate = new Date();
    fromDate.setMonth(fromDate.getMonth() -1);

    this.state = {
        logs:[],
        result:[],
        refreshText:'',
        // filterable:this.props.compSettings.showFilters,
        filterable: props.filterable,
        fromDate:fromDate,
        toDate:new Date(),
        dataState:{skip:0, take:25, sort:[{field:'time', dir:'desc'}]}
    }

    this.getLogs = this.getLogs.bind(this);
    this.onFieldChange =this.onFieldChange.bind(this);
  }

  componentDidMount(){
    this.getLogs();
  }

  getLogs(){
    this.setState({refreshText:'Loading...'});

    var headers = {
      'Authorization': 'Bearer '.concat(this.props.idToken),
      };
    axios.get('api/logs?company='+this.props.company+'&from='+this.state.fromDate+'&to='+this.state.toDate, {headers}).then(response=>{
        var logs = this.modifyLogs(response.data);
        this.setState({logs:logs, result:process(logs, this.state.dataState) ,refreshText:''});
    }).catch(err=>console.log(err));
  }

  modifyLogs(logs){
    for(let i=0; i<logs.length; i++){
        logs[i].time=this.getDate(logs[i].time);
    }
    return logs;
  }

  onFieldChange(e, field){
    this.setState({[field]:e.target.value}, ()=>this.getLogs());
  }

  getDate(mysqlDate){
    var d = parseDate(mysqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
    return d;
  }

  expandChange = (event) => {
    const data = this.state.result.data.map(item =>
      item.id === event.dataItem.id
        ? {
          ...item,
          expanded: !item.expanded,
        }
        : {
          ...item,
        }
    );
    this.setState((prevState) => {
      let obj = { ...prevState };
      obj.result.data = data;
      return obj;
    })
  }

  _export;

  export = () =>{
    this._export.save();
  }

  changeDataState = (e)=>{
    var newData = process(this.state.logs, e.dataState);
    this.setState({dataState:e.dataState, result:newData});
  }

  render() {
      return(
          <div>
            <div className="logList">
              <ExcelExport data={this.state.result.data} fileName='Logs_Export.xlsx' ref={(exporter)=>{this._export = exporter;}}>
                <ExcelExportColumn field="username" title="User" width={300}/>
                <ExcelExportColumn field="action" title="Action" width={200}/>
                <ExcelExportColumn field="item" title="Item" width={500}/>
                <ExcelExportColumn field="detail" title="Detail" width={500}/>
                <ExcelExportColumn field="time" title="Time" width={150} cellOptions={{format:'m/d/yyyy h:mm AM/PM'}}/>
                <ExcelExportColumn field="ipAddress" title="IP Address" width={125}/>
              </ExcelExport>
              <Grid data={this.state.result}
                    detail={LogDetail}
                    resizable={true}
                    reorderable={true}
                    pageable={{pageSizes:[10,25,50,100,250,500]}}
                    sortable={true}
                    onDataStateChange={this.changeDataState}
                    expandField="expanded"
                    onExpandChange={this.expandChange}
                    filterable={this.state.filterable}
                    {...this.state.dataState}>
                <GridToolbar>
                  <div><strong>Logs</strong></div>
                  {this.props.showBack && <Button title='Users' onClick={this.props.onBack}>Back to Users</Button>}
                  <Button icon='refresh' title='Refresh' onClick={this.getLogs}>{this.state.refreshText}</Button>
                  <Button icon='filter' title='Filter' onClick={(e)=>this.setState({filterable:!this.state.filterable})}>Filter</Button>
                  <Button icon="excel" title='Export to Excel' onClick={this.export}>Export</Button>
                  <label style={{paddingLeft:'10px'}}><strong>From: </strong></label><DatePicker value={this.state.fromDate} max={new Date()} onChange={(e)=>this.onFieldChange(e, 'fromDate')}/>
                  <label style={{paddingLeft:'5px'}}><strong>To: </strong></label><DatePicker value={this.state.toDate} max={new Date()} onChange={(e)=>this.onFieldChange(e, 'toDate')}/>
                </GridToolbar>
                <GridColumn headerClassName='gridHeader' field="username" title="User" width="250"/>
                <GridColumn headerClassName='gridHeader' field="action" title="Action" width="250"/>
                <GridColumn headerClassName='gridHeader' field="detail" title="Detail" />
                <GridColumn headerClassName='gridHeader' field="time" title="Time" format="{0:g}" width="200"/>
                <GridColumn headerClassName='gridHeader' field="ipAddress" title="IP Address" width="150"/>
              </Grid>
              {this.state.refreshText && <GridLoading gridClass='logList'/>}
            </div>
          </div>
      );
  }

}

export default LogList;