import React, { Component } from "react";
import {Grid, GridColumn, GridToolbar} from '@progress/kendo-react-grid';
import axios from 'axios';
import { process } from '@progress/kendo-data-query';
import GridLoading from '../Loaders/GridLoading';
import { Button } from '@progress/kendo-react-buttons';

class Timesheets extends Component {

  constructor(props) {
    super(props);

    var years = [];
    var currYear = 2007;
    while(currYear<=2029){
      years.push(currYear);
      currYear++;
    }

    this.state = {
        data:[],
        result:[],
        loading:true,
        dataState:{group:[{field:"type", aggregates:[{field:'monday_hours', aggregate:'sum'},
                          {field:'tuesday_hours', aggregate:'sum'},
                          {field:'wednesday_hours', aggregate:'sum'},
                          {field:'thursday_hours', aggregate:'sum'},
                          {field:'friday_hours', aggregate:'sum'},
                          {field:'saturday_hours', aggregate:'sum'},
                          {field:'sunday_hours', aggregate:'sum'},]}]},
        sundays:[],
        weekBegin:'',
        years:years,
        timesheets_id:'',
        week_begin:new Date(),
        full_name:'',
        initials:'',
        archive:false,
        prefill:false,
        createTimesheet:false,
        buttonDisabled:false,
        yearSelected:new Date().getFullYear()
    }

    this.getLabel = this.getLabel.bind(this);
    this.getSundaysInYear = this.getSundaysInYear.bind(this);
    this.getTimesheet = this.getTimesheet.bind(this);
    this.cellRender = this.cellRender.bind(this);
    this.onGroupExpandClick = this.onGroupExpandClick.bind(this);
  }

  componentDidMount(){
    this.getSundaysInYear(new Date().getFullYear());
  }


  getTimesheet(){
    this.setState({loading:true});
    this.setState({buttonDisabled:true});


    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var archive = false;
    axios.get('api/timesheets?company=cssi&date='+this.state.weekBegin, {headers}).then(response=>{
      if(response.data.code===200){
        var data = response.data.data;
        var id = '';
        var fullName = '';
        var initials = '';
        var week_begin = new Date();

        if(data.length>0){
          id=data[0].timesheets_id;
          archive=data[0].archive;
          fullName = data[0].full_name;
          initials = data[0].initials;
          week_begin = data[0].week_begin;


          var index = data.findIndex(x=>{
            return x.type==='nonbillable';
          });
          if(index<0)
            data.splice(0,0,{type:'nonbillable', name:'', monday_hours:0, tuesday_hours:0, wednesday_hours:0, thursday_hours:0, friday_hours:0, saturday_hours:0, sunday_hours:0, timesheets_id:id, full_name:fullName, week_begin:week_begin, initials:initials, edit:true})
          
          index = data.findIndex(x=>{
            return x.type==='preliminary';
          });
          if(index<0)
            data.splice(0,0,{type:'preliminary', name:'', monday_hours:0, tuesday_hours:0, wednesday_hours:0, thursday_hours:0, friday_hours:0, saturday_hours:0, sunday_hours:0, timesheets_id:id, full_name:fullName, week_begin:week_begin, initials:initials, edit:true})
          

          index = data.findIndex(x=>{
            return x.type==='internal';
          });
          if(index<0)
            data.splice(0,0,{type:'internal', name:'', monday_hours:0, tuesday_hours:0, wednesday_hours:0, thursday_hours:0, friday_hours:0, saturday_hours:0, sunday_hours:0, timesheets_id:id, full_name:fullName, week_begin:week_begin, initials:initials, edit:true})
         
          index = data.findIndex(x=>{
            return x.type==='billable';
          });
          if(index<0)
            data.splice(0,0,{type:'billable', name:'', monday_hours:0, tuesday_hours:0, wednesday_hours:0, thursday_hours:0, friday_hours:0, saturday_hours:0, sunday_hours:0, timesheets_id:id, full_name:fullName, week_begin:week_begin, initials:initials, edit:true})
              
          for(let i=0; i<data.length; i++){
            data[i].edit=true;
          }

          data.splice(data.length-1,0,{type:'total', name:'', monday_hours:0, tuesday_hours:0, wednesday_hours:0, thursday_hours:0, friday_hours:0, saturday_hours:0, sunday_hours:0, full_name:fullName, week_begin:week_begin, initials:initials, timesheets_id:id});

          var result = process(data, this.state.dataState);

          result.data[result.data.length-1].expanded = false;

          this.setState({data:data, archive:archive, result:result, loading:false, timesheets_id:id, full_name:fullName, week_begin:week_begin, initials:initials, createTimesheet:false});
          this.setState({buttonDisabled:false});

        }
        else{
          this.setState({data:[], archive:false, result:[], createTimesheet:true, loading:false});
          this.setState({buttonDisabled:false});
        }
      }
    }).catch(err=>{
      console.log(err);
      alert("There was an error loading this timesheet, please try again.");
      this.setState({data:[], archive:false, result:[], loading:false, timesheets_id:'', week_begin:new Date(), full_name:'', initials:''});
      this.setState({buttonDisabled:false});
    });

    
    

  }

  saveTimesheet = (e)=>{
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var payload={
      timesheet:this.state.data,
      archive:this.state.archive,
      company: 'cssi'
    }

    this.setState({buttonDisabled: true});

    axios.post('api/timesheets', payload, {headers}).then(response=>{
      if(response.data.code===200){
        this.getTimesheet();   
        alert("Timesheet has been saved successfully!");
      }
      else if(response.data.code===201){
        this.getTimesheet();
        alert("Timesheet has been sent and archived successfully");
      }
      else{
        this.getTimesheet();
        alert("An error occured while saving timesheet.");
      }
      this.setState({buttonDisabled: false});
    }).catch(err =>{
      console.log(err);
      this.setState({buttonDisabled: false});
    });

    
  }

  createTimesheet = (e)=>{
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var payload={
      weekBegin: this.state.weekBegin,
      prefill: this.state.prefill,
      company: 'cssi'
    }

    this.setState({buttonDisabled:true});

    axios.post('api/createTimesheet', payload, {headers}).then(response=>{
      if(response.data.code===200){
        this.getTimesheet();
        alert("Timesheet has been created successfully!");
      }
      else{
        this.getTimesheet();
        if(response.data.code===300){
          alert("Error: The timesheet you are trying to create already exists. If you are using Internet Explorer, please try another browser.");
        }
        else{
          alert("An error occured while creating timesheet.");
        }
      }
      this.setState({buttonDisabled: false});
    }).catch(err =>{
      console.log(err);
      this.setState({buttonDisabled: false});
    });

    

  }

  getSundaysInYear(year, callback){
    const monthNames = ["January", "February", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November", "December"];
    var sundays = [];
    var date = new Date(year, 0, 1);
    //compare needs to be same datatype apparently
    var compareyear = date.getFullYear();
    var selected;
    var index = 0;

    while(date.getDay()!==0){
      date.setDate(date.getDate()+1);
    }

    sundays.push({value:(date.getMonth()+1)+'-'+date.getDate()+'-'+date.getFullYear(), name:monthNames[date.getMonth()]+' '+date.getDate(), date:new Date(date.getTime())})
    selected = sundays[0];
    while(date.getFullYear() === (compareyear)){
      date.setDate(date.getDate() + 7);
      sundays.push({value:(date.getMonth()+1)+'-'+date.getDate()+'-'+date.getFullYear(), name:monthNames[date.getMonth()]+' '+date.getDate(), date:new Date(date.getTime())});
      
      if(date<=new Date()){
        index++;
        selected = sundays[index];
      }
    }

    if(index<sundays.length-1 && sundays[index].date<new Date()){
      selected = sundays[index+1];
    }

    this.setState({sundays:sundays, weekBegin:selected.value}, ()=>this.getTimesheet());
  }

  changeDataState = (e)=>{
    var newData = process(this.state.data, e.dataState);
    this.setState({dataState:e.dataState, result:newData});
  }

  // expandChange = (event) => {
  //   event.dataItem[event.target.props.expandField] = event.value;
  //   this.forceUpdate();
  // }

  expandChange = (event) => {
    const data = this.state.result.data.map(item => 
      item.id.join('-') === event.dataItem.id.join('-')
          ? {
               ...item, 
               [event.target.props.expandField]: !item[event.target.props.expandField],
              } 
          : item
  );
    this.setState((prevState) => {
      let obj = {...prevState};
      obj.result.data = data;
      return obj;
    })
  };

  changeWeek = (e)=>{
    this.setState({weekBegin:e.target.value}, ()=>this.getTimesheet());
  }

  addRow = (type)=>{
    var newData = this.state.data.slice();
    newData.push({type:type, name:'', monday_hours:0, tuesday_hours:0, wednesday_hours:0, thursday_hours:0, friday_hours:0, saturday_hours:0, sunday_hours:0, timesheets_id:this.state.timesheets_id, full_name:this.state.full_name, week_begin:this.state.week_begin, initials:this.state.initials, edit:true});
    
    var result = process(newData, this.state.dataState);
    result.data[result.data.length-1].expanded = false;
    this.setState({data:newData, result:result});
  }

  gridItemChange = (e) => {
    var val = e.value;
    var matches = val.match(/^\d*\.?\d*$/);
    if(e.field!=='name' && matches!==null &&  matches.length>0){
      if(val.charAt(val.length-1)!=='.' && !isNaN(parseFloat(matches[0])))
        val = parseFloat(matches[0]);
      if(val==='')
        val = 0;
    }
    else if(e.field!=='name'){
      val = 0;
    }
    else if(e.field==='name' && e.dataItem.type==='standard')
      val = e.dataItem[e.field];

    // if(!isNaN(parseFloat(val)) && val.indexOf('.')!==0 && val.lastIndexOf('.')!==val.length-1)
    //   val = parseFloat(val);
    // else if(e.field!=='name' && isNaN(parseFloat(val)))
    //   val = 0;

    e.dataItem[e.field] = val;

    var result = process(this.state.data, this.state.dataState);
    result.data[result.data.length-1].expanded = false;
    this.setState({result:result});
  }

  onGroupExpandClick(e){
    if(e.dataItem.expanded===undefined){
      e.dataItem.expanded = true;
    }
    e.dataItem.expanded = !e.dataItem.expanded;
    this.forceUpdate();
  }

  getButtonText(){
    var value;
    if(this.state.loading===true)
      value = 'Loading...';
    else if(!this.state.createTimesheet)
      value = 'Saving...';
    else if(this.state.createTimesheet)
      value = 'Creating...';
    return value;
  }

  getLabel(value){
    var label = value;
        if(label==='billable')
          label='Billable';
        else if(label==='internal')
          label='Internal';
        else if(label==='nonbillable')
          label='Non-Billable';
        else if(label==='preliminary')
          label = 'Preliminary';
        else if(label==='standard')
          label = 'Standard';
        else if(label==='total')
          label = '';
    
    return label;
  }

  cellRender(tdElement, cellProps) {
    if(cellProps.rowType==='groupHeader' && cellProps.dataItem.value!=='total'){
      if(cellProps.field==='value'){
        var label = this.getLabel(cellProps.dataItem.value);
        return(
          <td colSpan="2" style={{position: 'sticky', left: '0px', right: '0px', zIndex: '1', background: 'rgb(246, 246, 246)'}}>
            {// eslint-disable-next-line
            <p className="k-reset"><a tabIndex="-1" className={cellProps.dataItem.expanded ? "k-i-collapse k-icon" : "k-i-expand k-icon"} onClick={(e)=>this.onGroupExpandClick(cellProps)}></a>{label}</p>
            }
          </td>
        );
      }
      if(cellProps.field==='name'){
        if(cellProps.dataItem.value!=='standard'){
          return(
            <td colSpan="1" style={{background: 'rgb(246, 246, 246)', border:'none'}}>
              <Button icon="add" onClick={(e)=>this.addRow(cellProps.dataItem.value)}>Add Row</Button>
            </td>
          );
        }
        else{
          return(
            <td colSpan="1" style={{background: 'rgb(246, 246, 246)', border:'none'}}></td>
          );
        }
      }
      else if(cellProps.field==='monday_hours'){
        return(
          <td colSpan="7" style={{background: 'rgb(246, 246, 246)', border:'none'}}></td>
        );
      }
    }
    else if(cellProps.rowType==='groupHeader' && cellProps.dataItem.value==='total')
      return(null);
    else if(cellProps.rowType==='groupFooter'){
      if(cellProps.field==='name'){
        return (
          <td colSpan='1' style={{borderLeft:'none'}}>
              <strong>{this.getLabel(cellProps.dataItem.value)} Totals:</strong>
          </td>
        );
      }
      else if(cellProps.field!=='value'){
        var val = '';
        if(cellProps.dataItem.aggregates[cellProps.field])
          val = cellProps.dataItem.aggregates[cellProps.field].sum;

        if(cellProps.dataItem.value==='total'){
          val = 0;
          for(let i=0; i<this.state.result.data.length; i++){
            val = val+this.state.result.data[i].aggregates[cellProps.field].sum;
          }
        }
        return (
          <td><strong>{val}</strong></td>
        );
      }
    }
    if(tdElement!==null){
      var style={};

      return(
        <td {...tdElement.props} style={style}/>
      );
    }
    else{
      return tdElement;
    }
  }

  totalCell = (cellProps)=>{
    var total = cellProps.dataItem.monday_hours+cellProps.dataItem.tuesday_hours+cellProps.dataItem.wednesday_hours
      +cellProps.dataItem.thursday_hours+cellProps.dataItem.friday_hours+cellProps.dataItem.saturday_hours+cellProps.dataItem.sunday_hours;
    if(cellProps.rowType==='groupHeader'){
      return null;
    }
    else if(cellProps.rowType==='groupFooter'){
      if(cellProps.dataItem.value!=='total'){
        total = 0;
        for(var key in cellProps.dataItem.aggregates){
          total = total + cellProps.dataItem.aggregates[key].sum;
        }
      }
      else{
        total= 0;
        for(let i=0; i<this.state.data.length; i++){
          total = total + this.state.data[i].monday_hours+this.state.data[i].tuesday_hours+this.state.data[i].wednesday_hours
          +this.state.data[i].thursday_hours+this.state.data[i].friday_hours+this.state.data[i].saturday_hours+this.state.data[i].sunday_hours;
        }
      }
    }
    if(isNaN(total))
      total=0;
    return(
      <td>
        {total}
      </td>
    );
  }

  render() {
    return(
      <div className='timesheets'>
        <Grid data={this.state.result}
              onExpandChange={this.expandChange}
              expandField="expanded"
              editField='edit'
              groupable={{enabled:false, footer:'always'}}
              className='timesheetGrid'
              onDataStateChange={this.changeDataState}
              onItemChange={this.gridItemChange}
              cellRender={this.cellRender}
              headerCellRender={this.headerCellRender}
              {...this.state.dataState}>
            <GridToolbar>
              <label>Year: </label>
              <select value={this.state.yearSelected} onChange={(e)=>{this.setState({yearSelected:e.target.value}); this.getSundaysInYear(e.target.value)}}>
                      {this.state.years.map((y, i)=>(
                      <option key={i} value={y}>{y}</option>
                    ))}
              </select>
              <label>Week Ending: </label>
              <select value={this.state.weekBegin} onChange={this.changeWeek}>
                      {this.state.sundays.map((date, i)=>(
                      <option key={i} value={date.value}>{date.name}</option>
                    ))}
              </select>
              {!this.state.createTimesheet && <><Button disabled={this.state.buttonDisabled} icon='save' onClick={this.saveTimesheet}>  {this.state.buttonDisabled ?    this.getButtonText()     : 'Save Timesheet'}  </Button>
              <label>Send?</label><input type='checkbox' className='bigger' checked={this.state.archive} onChange={(e)=>this.setState({archive:!this.state.archive})}/></>}
              {this.state.createTimesheet && <><Button disabled={this.state.buttonDisabled} icon='add' onClick={this.createTimesheet}>{this.state.buttonDisabled ?    this.getButtonText()   : 'Create Timesheet'} </Button>
              <label>Prefill from last week?</label><input type='checkbox' className='bigger' checked={this.state.prefill} onChange={(e)=>this.setState({prefill:!this.state.prefill})}/></>}
            </GridToolbar>
            <GridColumn headerClassName='gridHeader' field='name' width='325px' title='Description'/>
            <GridColumn headerClassName='gridHeader' field='monday_hours' width='110px' title='Monday' />
            <GridColumn headerClassName='gridHeader' field='tuesday_hours' width='110px' title='Tuesday' />
            <GridColumn headerClassName='gridHeader' field='wednesday_hours' width='110px' title='Wednesday' />
            <GridColumn headerClassName='gridHeader' field='thursday_hours' width='110px' title='Thursday' />
            <GridColumn headerClassName='gridHeader' field='friday_hours' width='110px' title='Friday' />
            <GridColumn headerClassName='gridHeader' field='saturday_hours' width='110px' title='Saturday' />
            <GridColumn headerClassName='gridHeader' field='sunday_hours' width='110px' title='Sunday' />
            <GridColumn headerClassName='gridHeader' width='110px' title='Total' cell={this.totalCell}/>
        </Grid>
        {this.state.loading && <GridLoading />}
      </div>
    );
  }

}

export default Timesheets;