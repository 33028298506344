import React from "react";
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import  './DropdownPopup.css';

const InputPortfolioDropdown = ({ name, label, data, value, valid, _filterChange, _handleChangePortfolio, _itemRender, }) =>
    (
        <div>
            <label>{label}: </label>
            <br></br>
            <MultiSelect
                name={name}
                data={data}
                textField="value"
                style={{minWidth: '200px',maxWidth:'200px'}}
                //textField={"entityCode     |Name of Account or Group truncated if it's too l...|Account"}
                dataItemKey="value"
                allowCustom={true}
                //defaultValue={defaultValue}
                filterable={true}
                header={<span>Code          |Name                              |Type    </span>}
                popupSettings={{width: "450px", className: "ssrsportfolio"}}
                onFilterChange={_filterChange}
                value={value}
                valid={valid}
                onChange={_handleChangePortfolio}
                itemRender={_itemRender}
            />
            <br></br>
        </div>
    );
export default InputPortfolioDropdown;