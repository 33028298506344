import React, { Component } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import { format } from 'date-fns'
import { Document, Page, pdfjs } from 'react-pdf';
import { Grid, Row, Col } from "react-flexbox-grid";
import { LoopCircleLoading } from 'react-loadingg';
import axios from 'axios';
import InputTextField from './components/InputTextField';
import InputDate from './components/InputDate';
import InputBoolean from './components/InputBoolean';
import InputDropdown from './components/InputDropdown';
import InputPortfolioDropdown from './components/InputPortfolioDropdown';
import { parseDate } from '@telerik/kendo-intl';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


class Ssrs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      runReportButtonFlag: false,

      layout: null,
      reports: [],
      report: { name: "Select Report", key: null, adjustableLayout: null },
      entities: [],
      loading: false,
      account: { account: "", displayName: "" },
      numPages: null,
      pageNumber: 1,
      pdfFile: null,
      pdfFlag: false,
      pdfScale: 1.25,
      status: "",
      loaderSpinnerFlag: false,
      checkPdfBlobTimerId: null,//used in componentWillUnmount cleanup
      generateExcelButtonFlag: true,
      generateCsvButtonFlag: true,
      reportParameters: [],
      reportParametersFlag: false,
      parameterList: [],
      defaultList: [],
      singleDateGenericDateToggle: false,
      fromDateGenericDateToggle: false,
      toDateGenericDateToggle: false,
    }

    //this.sendRequest = this.sendRequest.bind(this);
    this.getReportList = this.getReportList.bind(this);
    this.getGenericDates = this.getGenericDates.bind(this);
    this.getAccountList = this.getAccountList.bind(this);
    this.getReportParameters = this.getReportParameters.bind(this);
    this.getReportsDefaults = this.getReportDefaults.bind(this);
    this.getThisDefaultValue = this.getThisDefaultValue.bind(this);
    this.getBlob = this.getBlob.bind(this);
    this.checkBlob = this.checkBlob.bind(this);
    this.deleteBlob = this.deleteBlob.bind(this);
    this.paramTrackingCount = 0;
  }

  useEffect(){
    window.addEventListener('beforeunload', this.setSessionStorage);
    return () => {
      window.removeEventListener('beforeunload', this.setSessionStorage);
    }
  }

  setSessionStorage = () => {

    sessionStorage.setItem('reportGoTo', true)

    if (!sessionStorage.getItem('reportArray')){
      sessionStorage.setItem('reportArray', this.state.report.name)
    }
    else{
      const reportArray = sessionStorage.getItem('reportArray').split(',')
      reportArray.push(this.state.report.name)
      sessionStorage.setItem('reportArray', reportArray.toString())
    }
  }

  consolidateGroups = () => {
    var newListWithConsolidated = []
    var unconsolidatedGroupCount = 0
    var consolidatedGroupCount = 0

    if (this.state.SSRSParam_PortfolioTest) {
      this.state.SSRSParam_PortfolioTest.forEach(entity => {
        if (entity.value.charAt(0) === '@') {
          newListWithConsolidated = newListWithConsolidated.concat({ value: '+' + entity.value, label: entity.label })
          unconsolidatedGroupCount++

        }
        else if (entity.value.includes('+@')) {
          newListWithConsolidated = newListWithConsolidated.concat(entity)
          consolidatedGroupCount++
        }
        else {
          newListWithConsolidated = newListWithConsolidated.concat(entity)
        }

      })
      this.setState({ SSRSParam_PortfolioTest: newListWithConsolidated }, () => {
        if (consolidatedGroupCount > 0 && unconsolidatedGroupCount === 0) {
          newListWithConsolidated = []
          this.state.SSRSParam_PortfolioTest.forEach(entity => {
            if (entity.value.charAt(0) === '+') {
              newListWithConsolidated = newListWithConsolidated.concat({ value: entity.value.replace('+', ''), label: entity.label })
            }
            else {
              newListWithConsolidated = newListWithConsolidated.concat(entity)
            }
          })
          this.setState({ SSRSParam_PortfolioTest: newListWithConsolidated })
        }
      })


    }

    if (this.state.SSRSParam_PortfolioList) {
      this.state.SSRSParam_PortfolioList.forEach(entity => {
        if (entity.value.charAt(0) === '@') {
          newListWithConsolidated = newListWithConsolidated.concat({ value: '+' + entity.value, label: entity.label })
          unconsolidatedGroupCount++
        }
        else if (entity.value.includes('+@')) {
          newListWithConsolidated = newListWithConsolidated.concat(entity)
          consolidatedGroupCount++
        }
        else {
          newListWithConsolidated = newListWithConsolidated.concat(entity)
        }
      })
      this.setState({ SSRSParam_PortfolioList: newListWithConsolidated }, () => {
        if (consolidatedGroupCount > 0 && unconsolidatedGroupCount === 0) {
          newListWithConsolidated = []

          this.state.SSRSParam_PortfolioList.forEach(entity => {
            if (entity.value.charAt(0) === '+') {
              newListWithConsolidated = newListWithConsolidated.concat({ value: entity.value.replace('+', ''), label: entity.label })
            }
            else {
              newListWithConsolidated = newListWithConsolidated.concat(entity)
            }
          })
          this.setState({ SSRSParam_PortfolioList: newListWithConsolidated })
        }
      })
    }

  }




  submitForm = (outputFormat) => {
    var entityList = ''
    if (this.state.SSRSParam_PortfolioTest) {//delete this if block later


      this.state.SSRSParam_PortfolioTest.forEach(entity => {
        entityList = entityList.concat(entity.value + '`')
      })

      entityList = entityList.slice(0, -1);//trims last character which is a comma
    }

    if (this.state.SSRSParam_PortfolioList) {


      this.state.SSRSParam_PortfolioList.forEach(entity => {
        entityList = entityList.concat(entity.value + '`')
      })

      entityList = entityList.slice(0, -1);//trims last character which is a comma
    }
    console.log(entityList)



    if (outputFormat === 'xlsx') {
      this.setState({ generateExcelButtonFlag: false })
    }
    else if (outputFormat === 'csv') {
      this.setState({ generateCsvButtonFlag: false })
    }
    else {
      this.setState({ runReportButtonFlag: !this.state.runReportButtonFlag, pdfFlag: false, pdfFile: null, pageNumber: 1 })
    }

    const { ...inputFields } = this.state
    Object.entries(inputFields).forEach(([key, value]) => {
      if (key.includes("SSRSParam_") && key.includes("_Validate")) {
        this.setState({ [key]: true })
      }
    })

    if (this.validate()) {
      var splitKey;
      var paramString = "";

      var count = 0
      Object.entries(inputFields).forEach(([key, value]) => {
        if (key.includes("SSRSParam_") && value !== null && !key.includes("_Data") && !key.includes("_DataOriginal") && !key.includes("_Validate")) {
          splitKey = key.split('_')
          console.log(key)

          if (value === undefined) {
            value = {}
          }
          console.log(value);

          if (count > 0) {
            paramString += "|"
          }

          //const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

          if (splitKey[1] === "PortfolioTest" || splitKey[1] === "PortfolioList") {
            //paramString += splitKey[1] + "," + value[0].value
            paramString += splitKey[1] + "`" + entityList
            //paramString = paramString.concat({paramName: splitKey[1], paramValue: entityList})
          }
          else if (splitKey[1] === "singleDate" && this.state.singleDateGenericDateToggle) {
            this.state.genericDates.forEach(genericDate => {
              if (genericDate.label === value.value) {
                paramString += splitKey[1] + "`" + format(this.getDate(genericDate.value, true), "MM-dd-yyyy")
                //paramString = paramString.concat({paramName: splitKey[1], paramValue: format(this.getDate(genericDate.value, true), "MM-dd-yyyy")})

              }
            })
          }
          else if (splitKey[1] === "fromDate" && this.state.fromDateGenericDateToggle) {
            this.state.genericDates.forEach(genericDate => {
              if (genericDate.label === value.value) {
                paramString += splitKey[1] + "`" + format(this.getDate(genericDate.value, true), "MM-dd-yyyy")
                //paramString = paramString.concat({paramName: splitKey[1], paramValue: format(this.getDate(genericDate.value, true), "MM-dd-yyyy")})
              }
            })
          }
          else if (splitKey[1] === "toDate" && this.state.toDateGenericDateToggle) {
            this.state.genericDates.forEach(genericDate => {
              if (genericDate.label === value.value) {
                paramString += splitKey[1] + "`" + format(this.getDate(genericDate.value, true), "MM-dd-yyyy")
                //paramString = paramString.concat({paramName: splitKey[1], paramValue: format(this.getDate(genericDate.value, true), "MM-dd-yyyy")})
              }
            })
          }
          else if (value.label) {//checks if this value is from a dropdown list
            paramString += splitKey[1] + "`" + value.value
            //paramString = paramString.concat({paramName: splitKey[1], paramValue: value.value})
          }
          else if (value.constructor.name === "Date") {
            paramString += splitKey[1] + "`" + format(value, "MM-dd-yyyy")
            //paramString = paramString.concat({paramName: splitKey[1], paramValue: format(value, "MM-dd-yyyy")})
          }
          else {
            paramString += splitKey[1] + "`" + value
            //paramString = paramString.concat({paramName: splitKey[1], paramValue: value})
          }
          count++;
        }
      })

      const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

      var layout = null
      if (this.state.SSRSParam_Layout !== null && this.state.SSRSParam_Layout) {
        layout = this.state.SSRSParam_Layout.value
      }

      //f (this.state.layout !== null)
      // layout = this.state.layout.key


      var today = new Date();
      var date = '' + (today.getMonth() + 1) + '' + today.getDate() + '' + today.getFullYear();
      var time = '' + today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
      var currentDateTime = '' + date + '' + time;
      var id = '' + this.props.user.company + "_" +  this.props.user.user + "_" + currentDateTime

      var fileName = this.props.user.company + "_" + this.state.report.name + "_" + currentDateTime + "." + outputFormat

      var payload = {
        id: id,
        paramString: paramString,
        report: this.state.report.name,
        folder: this.state.report.folder,
        database: this.props.user.company,
        fileName: fileName,
        dateTime: currentDateTime,
        outputFormat: outputFormat,
        layout: layout,
        entityList: entityList
      }

      var payloadForBlobMethod = {
        id: id,
        fileName: fileName,
        reportName: this.state.report.name,
        databaseName: this.props.user.company,
        commandDate: currentDateTime
      }

      console.log(payload)


      axios
        .post("api/ssrsRequest", payload, { headers })
        .then((response) => {
          if (response.data.code === 200) {
            this.setState({ status: "", loaderSpinnerFlag: true })

            var count = 0
            var checkPdfBlobTimer = setInterval(
              function () {
                //test = this.checkBlob(fileNameIn)
                this.checkBlob(payloadForBlobMethod, (callback) => {
                  if (callback.errorMessage) {
                    clearInterval(checkPdfBlobTimer)
                    //alert(callback.errorMessage)
                    this.setState({ runReportButtonFlag: true, status: "There was an error creating the report.", loaderSpinnerFlag: false })
                    console.log(callback.errorMessage)
                  }
                  if (callback === true) {//if checkBlob returns true, it means that it was able to find data and the blob is ready
                    clearInterval(checkPdfBlobTimer)//end timer loop
                    this.getBlob(payloadForBlobMethod, outputFormat);
                  }
                  count = count + 1
                  if (count === 10) {
                    this.setState({ status: "" })
                  }
                  if (count > 120) {
                    clearInterval(checkPdfBlobTimer)//end timer loop
                    this.setState({ runReportButtonFlag: true, status: "There was an error creating the report.", loaderSpinnerFlag: false })
                  }
                })

              }
                .bind(this),
              2000
            )

            this.setState({ checkPdfBlobTimerId: checkPdfBlobTimer })
          }
          else {
            alert('An error has occured while sending your request.');
          }
        })
        .catch((e) => {
          console.error(e);
          this.setState({ runReportButtonFlag: !this.state.runReportButtonFlag })
        });

      //e.preventDefault();
    }
    else {
      this.setState({ runReportButtonFlag: true })
    }


  }

  checkParamTracking() {
    var checkTimer = setInterval(
      function () {
        //test = this.checkBlob(fileNameIn)
        if (this.paramTrackingCount === 0){
          this.getParameterDefaults()
          clearInterval(checkTimer)
        }

      }
        .bind(this),
      2000
    )
  }


  _handleChange = e => {
    const t = "SSRSParam_".concat([e.target.props.name])
    this.validate(t)

    this.setState({
      [t]: e.target.value
    })
  }

  _handleChangePortfolio = e => {
    const t = "SSRSParam_".concat([e.target.props.name])
    //this.validate(t)
    var alreadyInListAsConsolidated = false //will be true if you are adding a group and the group is already in the list as consolidated.
    if (e.target.value.length > this.state[t].length && this.state[t].length > 0) {//checks if adding an entity, rather than deleting
      var entityToAdd = e.target.value[e.target.value.length - 1]
      if (entityToAdd.value.includes('@')) {
        this.state[t].forEach(entity => {
          if (entityToAdd.value === entity.value.replace('+', '')) {
            alreadyInListAsConsolidated = true;
          }
        })
      }
    }
    if (!alreadyInListAsConsolidated) {
      this.setState({
        [t]: e.target.value
      })
    }
  }

  _itemRender = (li, itemProps) => {
    const itemChildren = <span>{itemProps.dataItem.label}</span>;

    return React.cloneElement(li, li.props, itemChildren);
  }

  _filterChange = (event) => {
    console.log(event)
    const dataStateToBeFiltered = "SSRSParam_" + event.target.name + "_Data"
    const dataStateContainingOriginalValues = this.state["SSRSParam_" + event.target.name + "_DataOriginal"]
    if (event.target.name === "PortfolioList") {
      event.filter.field = "label"
    }

    this.setState({
      [dataStateToBeFiltered]: this.filterData(event.filter, dataStateContainingOriginalValues)
    });
  }

  _filterChangeGenericDate = (event) => {
    console.log(event)
    event.filter.field = "value"
    const dataStateToBeFiltered = "genericDates_" + event.target.name + "_Data"
    const dataStateContainingOriginalValues = this.state["genericDates_" + event.target.name + "_DataOriginal"]//asdf

    this.setState({
      [dataStateToBeFiltered]: this.filterData(event.filter, dataStateContainingOriginalValues)
    });
  }

  filterData(filter, originalData) {
    //const data = allData.slice();
    return filterBy(originalData, filter);
  }

  componentDidMount() {
    this.getReportList();
    this.getAccountList();
    this.getReportDefaults();
    this.getGenericDates();
    this.useEffect();
  }

  componentWillUnmount() {
    clearTimeout(this.state.checkPdfBlobTimerId)

    window.removeEventListener('beforeunload', this.setSessionStorage);
    sessionStorage.removeItem('reportArray')
    sessionStorage.removeItem('reportGoTo')
  }

  onChangeHandler = (e, type) => {

    this.setState({ [type]: e.target.value });
  }

  onGenericToggleChangeHandler = (value, type) => {
    console.log(value)
    this.setState({ [type]: value })

    if (type === 'singleDateGenericDateToggle') {
      if (value === true) {
        this.setState({ SSRSParam_singleDate: { value: 'bblm', label: 'bblm - Beginning Business Day Last Month' } })
      }
      else {
        this.setState({ SSRSParam_singleDate: this.state.singleDate_OriginalDefault })
        console.log(this.state.singleDate_OriginalDefault)
      }
    }
    else if (type === 'fromDateGenericDateToggle') {
      if (value === true) {
        this.setState({ SSRSParam_fromDate: { value: 'bblm', label: 'bblm - Beginning Business Day Last Month' } })
      }
      else {
        this.setState({ SSRSParam_fromDate: this.state.fromDate_OriginalDefault })
        console.log(this.state.fromDate_OriginalDefault)
      }
    }
    else if (type === 'toDateGenericDateToggle') {
      if (value === true) {
        this.setState({ SSRSParam_toDate: { value: 'bblm', label: 'bblm - Beginning Business Day Last Month' } })
      }
      else {
        this.setState({ SSRSParam_toDate: this.state.toDate_OriginalDefault })
        console.log(this.state.toDate_OriginalDefault)
      }
    }
  }

  changePdfPage = (pageNumber, direction) => {
    var newPage = pageNumber;
    if (direction === 'prev') {
      if (pageNumber === 1) {//if currently viewing the first page...
        newPage = this.state.numPages//...show the last page
      }
      else {
        newPage = pageNumber - 1
      }
    }
    if (direction === 'next') {
      if (pageNumber === this.state.numPages) {//if current page is equal to the amount of pages...
        newPage = 1//...wrap around to first page
      }
      else {
        newPage = pageNumber + 1
      }

    }
    this.setState({ pageNumber: newPage });
  }

  formatDates = (date) => {

    var formattedDate = null

    return formattedDate
  }

  onReportChangeHandler = (e) => {

    const { ...stateObject } = this.state
    this.setState({ runReportButtonFlag: false })

    Object.entries(stateObject).forEach(([key, value]) => {
      if (key.includes("SSRSParam_")) {
        this.setState({ [key]: null })
      }
    })

    this.setState({
      parameterList: [],
      layout: null, layoutFlag: false,
      pdfFile: null, pdfFlag: false, pdfScale: 1.25,
      singleDateGenericDateToggle: false,
      fromDateGenericDateToggle: false,
      toDateGenericDateToggle: false,
    })
    //this.setState({ fromDateFlag: false })
    //this.setState({ toDateFlag: false })
    //this.setState({ singleDateFlag: false })
    //this.setState({ pdfFile: "https://cors-anywhere.herokuapp.com/http://www.pdf995.com/samples/pdf.pdf"})

    //wait for setState to finish before calling getReportParameters
    this.setState({ report: e.target.value }, () => {
      this.getReportParameters()
    });
  }

  getReportList() {
    var reports = [];
    var reportNames = [];
    var splitName;
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var folder = 'ExampleSSRSProject';

    //axios
    //.post('api/ssrsGetReports', payload, { headers })
    axios(`api/ssrsGetReports`, {
      method: 'GET',
      params: { 
        folder: folder, 
        company: this.props.user.company, 
        standardAndCustom: this.props.showStandard && this.props.showCustom,
        standardOnly: this.props.showStandard && !this.props.showCustom,
        customOnly: !this.props.showStandard && this.props.showCustom,
      },
      headers: headers,
    })
      .then(response => {
        var folder
        response.data.data.forEach(reportInResponse => {
          folder = reportInResponse.path.split('/')[1]

          if (reportInResponse.name.includes("_Landscape") || reportInResponse.name.includes("_Portrait")) {
            splitName = reportInResponse.name.split('_')
            if (!reportNames.includes(splitName[0])) {
              reportNames.push(splitName[0]);
              reports.push({ name: splitName[0], key: reportInResponse.key, adjustableLayout: true, folder: folder })
            }
          }
          else {
            reports.push({ name: reportInResponse.name, key: reportInResponse.key, folder: folder })
          }


        })
        this.setState({ reports: reports }, () =>{
          var reportArrayStorage = ""
          var useSessionStorage = false
          if (sessionStorage.getItem('reportArray')){
            reportArrayStorage = sessionStorage.getItem('reportArray')
            useSessionStorage = true
          }
          else{
            reportArrayStorage = localStorage.getItem('reportArray')
          }

          if (reportArrayStorage && reportArrayStorage !== 'Select Report'){
            var reportArray = reportArrayStorage.split(',')
            var loadedReport = ""
            if (reportArray.length > 0){
              loadedReport = reportArray[0]
              reportArray.shift()
              if (useSessionStorage){
                sessionStorage.removeItem('reportGoTo')
                sessionStorage.removeItem('reportArray')
              }
              else{
                reportArray.toString() ? localStorage.setItem('reportArray', reportArray.toString()) : localStorage.removeItem('reportArray')
                localStorage.removeItem('reportGoTo')
              }
              const index = reports.findIndex(item => item.name === loadedReport);
              this.setState({ runReportButtonFlag: false, report: reports[index] }, () => {
                this.getReportParameters();
              });
            }
          }
        })

      })
      .catch(err => {
        console.error(err);
      })
    //return reports;

  }

  getGenericDates() {
    var dates = []

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios(`api/ssrsGetAllGenericDates`, {
      method: 'GET',
      params: { database: this.props.user.company },
      headers: headers,
    })
      .then(response => {
        response.data.data.forEach(dateInResponse => {
          dates.push({ label: dateInResponse.label, value: dateInResponse.value })
        })
        this.setState({ genericDates: dates }, () => console.log(this.state.genericDates))
      })
      .catch(err => {
        console.error(err);
      })

    const genericDates = [
      { value: 'bblm', label: 'bblm - Beginning Business Day Last Month' },
      { value: 'bblq', label: 'bblq - Beginning Business Day Last Quarter' },
      { value: 'bbly', label: 'bbly - Beginning Business Day Last Year' },
      { value: 'bbpm', label: 'bbpm - Beginning Business Day Previous Month' },
      { value: 'bbpq', label: 'bbpq - Beginning Business Day Previous Quarter' },
      { value: 'bbpy', label: 'bbpy - Beginning Business Day Previous Year' },
      { value: 'bbtm', label: 'bbtm - Beginning Business Day This Month' },
      { value: 'bbtq', label: 'bbtq - Beginning Business Day This Quarter' },
      { value: 'bbty', label: 'bbty - Beginning Business Day This Year' },
      { value: 'bbnm', label: 'bbnm - Beginning Business Day Next Month' },
      { value: 'bbnq', label: 'bbnq - Beginning Business Day Next Quarter' },
      { value: 'bbny', label: 'bbny - Beginning Business Day Next Year' },
      { value: 'bdlm', label: 'bdlm - Beginning Day Last Month' },
      { value: 'bdlq', label: 'bdlq - Beginning Day Last Quarter' },
      { value: 'bdly', label: 'bdly - Beginning Day Last Year' },
      { value: 'bdpm', label: 'bdpm - Beginning Day Previous Month' },
      { value: 'bdpq', label: 'bdpq - Beginning Day Previous Quarter' },
      { value: 'bdpy', label: 'bdpy - Beginning Day Previous Year' },
      { value: 'bdtm', label: 'bdtm - Beginning Day This Month' },
      { value: 'bdtq', label: 'bdtq - Beginning Day This Quarter' },
      { value: 'bdty', label: 'bdty - Beginning Day This Year' },
      { value: 'bdnm', label: 'bdnm - Beginning Day Next Month' },
      { value: 'bdnq', label: 'bdnq - Beginning Day Next Quarter' },
      { value: 'bdny', label: 'bdny - Beginning Day Next Year' },
      { value: 'eblm', label: 'eblm - Ending Business Day Last Month' },
      { value: 'eblq', label: 'eblq - Ending Business Day Last Quarter' },
      { value: 'ebly', label: 'ebly - Ending Business Day Last Year' },
      { value: 'ebpm', label: 'ebpm - Ending Business Day Previous Month' },
      { value: 'ebpq', label: 'ebpq - Ending Business Day Previous Quarter' },
      { value: 'ebpy', label: 'ebpy - Ending Business Day Previous Year' },
      { value: 'ebtm', label: 'ebtm - Ending Business Day This Month' },
      { value: 'ebtq', label: 'ebtq - Ending Business Day This Quarter' },
      { value: 'ebty', label: 'ebty - Ending Business Day This Year' },
      { value: 'ebnm', label: 'ebnm - Ending Business Day Next Month' },
      { value: 'ebnq', label: 'ebnq - Ending Business Day Next Quarter' },
      { value: 'ebny', label: 'ebny - Ending Business Day Next Year' },
      { value: 'edlm', label: 'edlm - Ending Day Last Month' },
      { value: 'edlq', label: 'edlq - Ending Day Last Quarter' },
      { value: 'edly', label: 'edly - Ending Day Last Year' },
      { value: 'edpm', label: 'edpm - Ending Day Previous Month' },
      { value: 'edpq', label: 'edpq - Ending Day Previous Quarter' },
      { value: 'edpy', label: 'edpy - Ending Day Previous Year' },
      { value: 'edtm', label: 'edtm - Ending Day This Month' },
      { value: 'edtq', label: 'edtq - Ending Day This Quarter' },
      { value: 'edty', label: 'edty - Ending Day This Year' },
      { value: 'ednm', label: 'ednm - Ending Day Next Month' },
      { value: 'ednq', label: 'ednq - Ending Day Next Quarter' },
      { value: 'edny', label: 'edny - Ending Day Next Year' },
      { value: 'yest', label: 'yest - Yesterday' },
      { value: 'last', label: 'last - Last Business Day' },
      { value: 'prev', label: 'prev - Next To Last Business Day' }
    ]

    this.setState({
      genericDates_singleDate_Data: genericDates,
      genericDates_fromDate_Data: genericDates,
      genericDates_toDate_Data: genericDates,
      genericDates_singleDate_DataOriginal: genericDates,
      genericDates_fromDate_DataOriginal: genericDates,
      genericDates_toDate_DataOriginal: genericDates,
    })


  }

  getAccountList() {
    var entities = [];
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    this.setState({loading: true})

    axios(`api/ssrsGetEntities`, {
      method: 'GET',
      params: { database: this.props.user.company },
      headers: headers
    })
      .then(response => {
        entities = response.data.data
        console.log(response.data.data)
        var splitLine
        var code
        var name
        var type
        var formattedLine
        var newEntities = []
        entities.forEach(line => {
          splitLine = line.label.split('|')
          code = this.getSpacedLine(splitLine[0], 12)
          name = this.getSpacedLine(splitLine[1], 32)
          type = this.getSpacedLine(splitLine[2], 8)

          formattedLine = code + " | " + name + " | " + type
          newEntities.push({ value: line.value, label: formattedLine })
        })
        entities = newEntities
        console.log(entities)
        this.setState({ entities: entities, loading: false })
      })
      .catch(err => {
        console.error(err);
        this.setState({loading: false})
      })
    //return reports;

  }

  getReportDefaults() {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios(`api/ssrsGetReportDefaults`, {
      method: 'GET',
      params: { company: this.props.user.company },
      headers: headers
    })
      .then(response => {
        console.log(response.data.data)
        this.setState({ defaultList: response.data.data })
      })
      .catch(err => {
        console.error(err);
      })
    //return reports;

  }

  getBlob = (payload, outputFormat) => {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var responseType = null
    if (outputFormat === 'pdf') {
      responseType = 'application/pdf'
    }
    if (outputFormat === 'xlsx') {
      responseType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
    if (outputFormat === 'csv') {
      responseType = 'application/csv'
    }



    axios(`api/ssrsGetPdfBlob`, {
      method: 'GET',
      responseType: 'blob', //Force to receive data in a Blob Format
      params: { id: payload.id, databaseName: payload.databaseName },
      headers: headers
    })
      .then(response => {
        console.log(response.data)
        const file = new Blob(
          [response.data],
          { type: responseType });
        const fileURL = URL.createObjectURL(file);//Build a URL from the file
        if (outputFormat === 'pdf') {
          this.setState({
            pdfFile: fileURL, pdfFlag: true, pdfScale: 1.25
          });
        }
        if (outputFormat === 'xlsx') {
          //window.open(fileURL + ".xlsx")
          this.saveExcelFile(file, payload.fileName)
          this.setState({ generateExcelButtonFlag: true })
          //window.location.assign(fileURL)
        }
        if (outputFormat === 'csv') {
          //window.open(fileURL + ".xlsx")
          this.saveCsvFile(file, payload.fileName)
          this.setState({ generateCsvButtonFlag: true })
          //window.location.assign(fileURL)
        }

        this.deleteBlob(payload)
      })
      .catch(error => {
        console.error(error);
      });

    this.setState({ runReportButtonFlag: true, status: "", loaderSpinnerFlag: false })
  }

  deleteBlob = (params) => {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var payload = {
      filename: params.fileName,
      reportName: params.reportName,
      databaseName: params.databaseName,
      id: params.id
    }

    axios
        .post("api/ssrsDeletePdfBlob", payload, { headers })
        .then((response) => {
          
        })
        .catch(error => {
          console.error(error);
        });

    
  }

  saveExcelFile = (blob, filename) => {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0)
    }
  }

  saveCsvFile = (blob, filename) => {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0)
    }
  }


  checkBlob = (payload, callback) => {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios(`api/ssrsGetPdfBlob`, {
      method: 'GET',
      params: { id: payload.id, databaseName: payload.databaseName },
      headers: headers
    })
      .then(response => {

        if (response.data === "no data") {
          callback(false)
        }
        else if (response.data.errorMessage) {
          callback(response.data)
        }
        else {
          callback(true)
        }
      })
      .catch(error => {
        console.error(error);
      });

  }

  getValidValues = (report, parameter, callback) => {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios(`api/ssrsGetParameterValidValuesQuery`, {
      method: 'GET',
      params: { report: report, parameter: parameter, company: this.props.user.company },
      //responseType: 'blob', //Force to receive data in a Blob Format
      headers: headers
    })
      .then(response => {

        if (response.data === "no data") {
          callback(false)
        }
        else {
          callback(response.data)
        }
      })
      .catch(error => {
        console.error(error);
      });

  }

  getDefaultValue = (report, parameter, callback) => {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios(`api/ssrsGetParameterDefaultValueQuery`, {
      method: 'GET',
      params: { report: report, parameter: parameter, company: this.props.user.company },
      //responseType: 'blob', //Force to receive data in a Blob Format
      headers: headers
    })
      .then(response => {

        if (response.data === "no data") {
          callback(false)
        }
        else {
          callback(response.data)
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  getDate(sqlDate, isUTC) {
    var d;
    if (parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX")) {
      d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX")
    }
    else {
      d = parseDate(sqlDate, "MM/dd/yyyy HH:mm:ss")
    }

    if (isUTC) {
      var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
      return utc;
    }

    else
      return d;
  }

  getThisDefaultValue(defaultParameters, paramName) {
    var tempDefaultValue = null

    for (let param of defaultParameters){
      if (param.paramName === paramName){
        tempDefaultValue = param.value
        break
      }
    }



    // if (paramName === 'ReportTitle'
    //   || paramName === 'Layout'
    //   || paramName === 'Disclaimer'
    //   || paramName === 'FeeMethod'
    //   || paramName === 'Periods'
    //   || paramName === 'Period1'
    //   || paramName === 'Period2'
    //   || paramName === 'ReportTitle1'
    //   || paramName === 'ReportTitle2'
    //   || paramName === 'ReportTitle3'
    //   || paramName === 'ReportTitle4'
    //   || paramName.toLowerCase() === 'equityassetclass'
    //   || paramName.toLowerCase() === 'securityclassification'
    //   || paramName.toLowerCase() === 'includeai'
    //   || paramName.toLowerCase() === 'companyname'
    //   || paramName.toLowerCase() === 'includeportfoliocode'
    //   || paramName.toLowerCase() === 'bonddescription'
    //   || paramName.toLowerCase() === 'includesp'
    //   || paramName.toLowerCase() === 'includemoody'
    //   || paramName.toLowerCase() === 'includefitch'
    //   || paramName.toLowerCase() === 'showcash'
    //   || paramName.toLowerCase() === 'showauthorization'
    //   || paramName.toLowerCase() === 'accountnumber'
    //   || paramName.toLowerCase() === 'rounding'
    //   || paramName.toLowerCase() === 'billfor') {
    //   this.state.defaultList.forEach(row => {
    //     if (reportName === row.reportName) {
    //       if (paramName === 'ReportTitle') {
    //         tempDefaultValue = row.reportTitle
    //       }
    //       if (paramName === 'Layout') {
    //         tempDefaultValue = row.layout
    //       }
    //       if (paramName === 'Disclaimer') {
    //         tempDefaultValue = row.disclaimer
    //       }
    //       if (paramName === 'FeeMethod') {
    //         tempDefaultValue = row.feeMethod
    //       }
    //       if (paramName === 'Periods' || paramName === 'Period1') {
    //         tempDefaultValue = row.period1
    //       }
    //       if (paramName === 'Period2') {
    //         tempDefaultValue = row.period2
    //       }
    //       if (paramName === 'ReportTitle1') {
    //         tempDefaultValue = row.reportTitle1
    //       }
    //       if (paramName === 'ReportTitle2') {
    //         tempDefaultValue = row.reportTitle2
    //       }
    //       if (paramName === 'ReportTitle3') {
    //         tempDefaultValue = row.reportTitle3
    //       }
    //       if (paramName === 'ReportTitle4') {
    //         tempDefaultValue = row.reportTitle4
    //       }
    //       if (paramName.toLowerCase() === 'equityassetclass') {
    //         console.log(row)
    //         tempDefaultValue = row.equityAssetClass
    //         console.log(tempDefaultValue)
    //       }
    //       if (paramName.toLowerCase() === 'securityclassification') {
    //         tempDefaultValue = row.securityClassification
    //       }
    //       if (paramName.toLowerCase() === 'includeai') {
    //         tempDefaultValue = row.includeAI
    //       }
    //       if (paramName.toLowerCase() === 'companyname') {
    //         tempDefaultValue = row.companyName
    //       }
    //       if (paramName.toLowerCase() === 'includeportfoliocode') {
    //         tempDefaultValue = row.includePortfolioCode
    //       }
    //       if (paramName.toLowerCase() === 'bonddescription') {
    //         tempDefaultValue = row.bondDescription
    //       }
    //       if (paramName.toLowerCase() === 'includesp') {
    //         tempDefaultValue = row.includeSP
    //       }
    //       if (paramName.toLowerCase() === 'includemoody') {
    //         tempDefaultValue = row.includeMoody
    //       }
    //       if (paramName.toLowerCase() === 'includefitch') {
    //         tempDefaultValue = row.includeFitch
    //       }
    //       if (paramName.toLowerCase() === 'showcash') {
    //         tempDefaultValue = row.showCash
    //       }
    //       if (paramName.toLowerCase() === 'accountnumber') {
    //         tempDefaultValue = row.AccountNumber
    //       }
    //       if (paramName.toLowerCase() === 'showauthorization') {
    //         tempDefaultValue = row.ShowAuthorization
    //       }
    //       if (paramName.toLowerCase() === 'rounding') {
    //         tempDefaultValue = row.Rounding
    //       }
    //       if (paramName.toLowerCase() === 'billfor') {
    //         tempDefaultValue = row.BillFor
    //       }

    //     }
    //   })
    //}

    return tempDefaultValue
  }

  getButtonStatus() {
    if (!this.state.runReportButtonFlag || this.paramTrackingCount !== 0) {
      return true
    }
    else {
      return false
    }

  }

  validate(input) {
    var flag = true;
    var isBlank = false;
    var isIncorrectDate = false;

    const { parameterList } = this.state
    if (input === undefined) {//check every parameter, e.g. for when user clicks Run Report
      parameterList.forEach(form => {
        if (form.allowBlank.toLowerCase() === "false" && (this.state["SSRSParam_" + form.name] === "" || this.state["SSRSParam_" + form.name] === null || this.state["SSRSParam_" + form.name].length === 0)) {

          this.setState({ ["SSRSParam_" + form.name + "_Validate"]: false })
          flag = false
          isBlank = true

        }
        if (form.name.toLowerCase() === "fromdate" && (!this.state.singleDateGenericDateToggle && !this.state.fromDateGenericDateToggle && !this.state.toDateGenericDateToggle)) {
          if (this.getDate(this.state.SSRSParam_fromDate, false).getTime() > this.getDate(this.state.SSRSParam_toDate, false).getTime()) {
            this.setState({ SSRSParam_fromDate_Validate: false, SSRSParam_toDate_Validate: false })
            flag = false
            isIncorrectDate = true
          }


        }
      })

    }
    else {
      if (this.state[input + "_Validate"] === false) {
        this.setState({ [input + "_Validate"]: true })

        if (input.toLowerCase().includes("date")) {
          const { ...inputFields } = this.state
          Object.entries(inputFields).forEach(([key, value]) => {
            if (key.toLowerCase().includes("date_") && key.includes("_Validate") && value !== null) {
              this.setState({ [key]: true })
            }
          })
        }

      }
    }

    if (flag === false) {
      var errorString = ''
      if (isBlank) {
        errorString = errorString.concat('Highlighted fields must not be blank.\r\n')
      }
      if (isIncorrectDate) {
        errorString = errorString.concat('Begin Date must precede End Date.\r\n')
      }
      alert(errorString);
    }

    return flag;

  }




  getReportParameters() {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var report = this.state.report.name;
    var parameters = [];
    var defaultParameters = [];
    this.checkParamTracking();
    //var adjustedDefaultValue = null

    if (this.state.report.adjustableLayout === true) {
      report = report + "_Landscape"
    }

    axios(`api/ssrsGetParameterDefaults`, {
      method: 'GET',
      params: { report: report, includeGlobals: true, company: this.props.user.company },
      headers: headers
    })
      .then(defaultParameterResponse => {

        if (defaultParameterResponse.data.code === 200) {
          defaultParameters = defaultParameterResponse.data.data
          console.log(defaultParameters)


          axios(`api/ssrsGetReportParameters?company=${this.props.user.company}&report=` + report, {
            method: 'GET',
            headers: headers
          })
            .then(response => {
      
              this.setState({ reportParameters: response.data.data })
              //begin loop through response
              //response.data.data.map((individualParameter) => {
              response.data.data.forEach(individualParameter => {
                if (individualParameter.ParamName !== "database") {
                  const t = "SSRSParam_".concat([individualParameter.ParamName])
      
                  var adjustedDefaultValue = null //this.getThisDefaultValue(defaultParameters, individualParameter.ParamName)
                  console.log(individualParameter.ParamName)
                  console.log(adjustedDefaultValue)
      
                  if (individualParameter.ParamName === "PortfolioTest" || individualParameter.ParamName === "PortfolioList") {
                    parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: this.state.entities[0], values: this.state.entities, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                    parameters.sort(this.compareParamOrder)
                    this.setState({
                      [t]: [], [t.concat("_Data")]: this.state.entities, [t.concat("_DataOriginal")]: this.state.entities, [t.concat("_Validate")]: true
                    })
                  }
                  else {
                    if (individualParameter.ParamDataType === "String" && individualParameter.ValidValues !== "") {
                      var values = []
                      var XMLParser = require('react-xml-parser');
                      var xml = new XMLParser().parseFromString(individualParameter.ValidValues);
                      xml.getElementsByTagName('ValidValue').forEach(value => {
                        values.push({ value: value.children[0].value, label: value.children[1].value })
                      })
      
                      if (individualParameter.DefaultValues !== null) {
                        const defaultValue = values.find(item => item.value === individualParameter.DefaultValues);
                        adjustedDefaultValue = values.find(item => item.value === adjustedDefaultValue);
                        console.log(individualParameter.DefaultValues)
                        console.log(adjustedDefaultValue)
                        console.log(defaultValue)
      
                        //if (defaultValue){adjustedDefaultValue = {value: adjustedDefaultValue, label: defaultValue.label}}
      
                        parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: adjustedDefaultValue || defaultValue, values: values, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                        parameters.sort(this.compareParamOrder)
                        this.setState({
                          [t]: adjustedDefaultValue || defaultValue, [t.concat("_Data")]: values, [t.concat("_DataOriginal")]: values, [t.concat("_Validate")]: true
                        })
                      }
                      else {
                        parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: adjustedDefaultValue || values[0], values: values, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                        parameters.sort(this.compareParamOrder)
                        this.setState({
                          [t]: adjustedDefaultValue || values[0], [t.concat("_Data")]: values, [t.concat("_DataOriginal")]: values, [t.concat("_Validate")]: true
                        })
                      }
                    }
                    else if (individualParameter.DynamicValidValues === "True") {
                      this.paramTrackingCount++;
                      this.getValidValues(individualParameter.ReportName, individualParameter.ParamName, (callback) => {
                        if (callback !== false) {
                          var lowerValues = []
                          callback.forEach(obj => {
                            var key, keys = Object.keys(obj);
                            var n = keys.length;
                            var newobj = {}
                            while (n--) {
                              key = keys[n];
                              newobj[key.toLowerCase()] = obj[key];
                            }
                            lowerValues.push(newobj)
                          })
      
                          if (individualParameter.ParamName === "PortfolioTest" || individualParameter.ParamName === "PortfolioList") {
                            var splitLine
                            var code
                            var name
                            var type
                            var formattedLine
                            var newLowerValues = []
                            lowerValues.forEach(line => {
                              splitLine = line.label.split('|')
                              code = this.getSpacedLine(splitLine[0], 12)
                              name = this.getSpacedLine(splitLine[1], 32)
                              type = this.getSpacedLine(splitLine[2], 8)
      
                              formattedLine = code + " | " + name + " | " + type
                              newLowerValues.push({ value: line.value, label: formattedLine })
                            })
                            lowerValues = newLowerValues
      
                          }
      
      
                          if (individualParameter.DefaultValues !== null || individualParameter.ParamName === "equityAssetClass") {
                            const defaultValue = lowerValues.find(item => item.value === individualParameter.DefaultValues);
                            adjustedDefaultValue = lowerValues.find(item => item.value === adjustedDefaultValue);
                            parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: adjustedDefaultValue || defaultValue, values: lowerValues, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                            parameters.sort(this.compareParamOrder)
                            this.setState({
                              [t]: adjustedDefaultValue || defaultValue, [t.concat("_Data")]: lowerValues, [t.concat("_DataOriginal")]: lowerValues, [t.concat("_Validate")]: true
                            })
                          }
                          else if (individualParameter.DefaultValues === null && individualParameter.DynamicDefaultValue === "True") {
                            console.log(individualParameter.ParamName)
                            this.paramTrackingCount++;
                            this.getDefaultValue(individualParameter.ReportName, individualParameter.ParamName, (callback) => {
                              if (callback !== "false") {
                                const lowerDefaultValue = []
                                callback.forEach(obj => {
                                  var key, keys = Object.keys(obj);
                                  var n = keys.length;
                                  var newobj = {}
                                  while (n--) {
                                    key = keys[n];
                                    newobj[key.toLowerCase()] = obj[key];
                                  }
                                  lowerDefaultValue.push(newobj)
                                })
                                console.log(lowerValues)
                                const defaultValue = lowerValues.find(item => item.value === lowerDefaultValue[0].value);
                                console.log(adjustedDefaultValue)
                                adjustedDefaultValue = null //this.getThisDefaultValue(defaultParameters, individualParameter.ParamName)
                                adjustedDefaultValue = lowerValues.find(item => item.label === adjustedDefaultValue);
                                console.log(adjustedDefaultValue)
                                
                                if (individualParameter.ParamName === 'Disclaimer'){
                                  lowerValues.forEach(v => {
                                    v.value = v.label
                                  })
                                  if (adjustedDefaultValue)
                                    adjustedDefaultValue.value = adjustedDefaultValue.label
                                }
                                parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: adjustedDefaultValue || defaultValue, values: lowerValues, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                                parameters.sort(this.compareParamOrder)
                                this.setState({
                                  [t]: adjustedDefaultValue || defaultValue, [t.concat("_Data")]: lowerValues, [t.concat("_DataOriginal")]: lowerValues, [t.concat("_Validate")]: true
                                })
                              }
                              this.paramTrackingCount--
                            })
      
                          }
                          else {
                            parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: lowerValues[0], values: lowerValues, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                            parameters.sort(this.compareParamOrder)
                            if (individualParameter.ParamName === "PortfolioTest" || individualParameter.ParamName === "PortfolioList") {
                              this.setState({
                                [t]: [], [t.concat("_Data")]: lowerValues, [t.concat("_DataOriginal")]: lowerValues, [t.concat("_Validate")]: true
                              })
                            }
                            else {
                              this.setState({
                                [t]: '', [t.concat("_Data")]: lowerValues, [t.concat("_DataOriginal")]: lowerValues, [t.concat("_Validate")]: true
                              })
                            }
                          }
      
                        }
                        this.paramTrackingCount--
                      })
      
                      //parameters.push({ type: individualParameter.ParamDataType, name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: individualParameter.DefaultValues })
                      //this.setState({
                      //  [t]: individualParameter.DefaultValues
                      //})
      
                    }
                    else if (individualParameter.DynamicDefaultValue === "True" && individualParameter.ParamDataType === "DateTime") {
                      this.paramTrackingCount++
                      this.getDefaultValue(individualParameter.ReportName, individualParameter.ParamName, (callback) => {
                        if (callback !== false) {
                          const lowerDefaultValue = []
                          callback.forEach(obj => {
                            var key, keys = Object.keys(obj);
                            var n = keys.length;
                            var newobj = {}
                            while (n--) {
                              key = keys[n];
                              newobj[key.toLowerCase()] = obj[key];
                            }
                            lowerDefaultValue.push(newobj)
                          })
                          var defaultDate = this.getDate(lowerDefaultValue[0].value, true)
                          this.setState({ [individualParameter.ParamName.concat('_OriginalDefault')]: defaultDate })//asdf
                          parameters.push({ type: individualParameter.ParamDataType, name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: defaultDate, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                          parameters.sort(this.compareParamOrder)
                          this.setState({
                            [t]: defaultDate, [t.concat("_Validate")]: true
                          })
      
      
                        }
                        this.paramTrackingCount--
                      })
      
                      //parameters.push({ type: individualParameter.ParamDataType, name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: individualParameter.DefaultValues })
                      //this.setState({
                      //  [t]: individualParameter.DefaultValues
                      //})
                    }
                    else {
                      parameters.push({ type: individualParameter.ParamDataType, name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: adjustedDefaultValue || individualParameter.DefaultValues, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                      parameters.sort(this.compareParamOrder)
                      if (individualParameter.DefaultValues !== null) {
                        this.setState({
                          [t]: adjustedDefaultValue || individualParameter.DefaultValues, [t.concat("_Validate")]: true
                        })
                      }
                      else {
                        this.setState({
                          [t]: '', [t.concat("_Validate")]: true
                        })
                      }
                    }
                  }
      
                }
      
              })
              //end loop through response
      
            })
            .catch(err => {
              console.error(err);
            })
          //return reports;
      
          this.setState({ parameterList: parameters }, () => {
            //this.getParameterDefaults();
            //this.setState({ reportParametersFlag: true, runReportButtonFlag: true })
          });
          if (this.state.report.adjustableLayout === true) {
            //this.setState({ reportParametersFlag: true })
          }

        }
        else {
          alert("Error getting report defaults")
        }
      })
      .catch(error => {
        console.error(error);
      });

    
    

  }

  getParameterDefaults = () => {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var report = this.state.report.name;
    var parameterList = []
    parameterList = parameterList.concat(this.state.parameterList)
    axios(`api/ssrsGetParameterDefaults`, {
      method: 'GET',
      params: { report: report, includeGlobals: true, company: this.props.user.company },
      headers: headers
    })
      .then(response => {

        if (response.data.code === 200) {
          parameterList.forEach(param => {
            response.data.data.forEach(defaultParam => {
              if (param.name === defaultParam.paramName) {
                if (param.type === "Dropdown"){
                  const newDefault = param.values.find(item => item.value === defaultParam.value)
                  param.defaultValue = newDefault
                  this.setState({ ["SSRSParam_".concat([defaultParam.paramName])]: newDefault })
                }
                else if (param.type === "DateTime"){
                  if (defaultParam.value.length === 4) {//if generic date
                    const newDefault = this.state["genericDates_" + defaultParam.paramName + "_Data"].find(item => item.value === defaultParam.value)
                    param.defaultValue = newDefault
                    this.setState({ ["SSRSParam_".concat([defaultParam.paramName])]: newDefault, [defaultParam.paramName + "GenericDateToggle"]: true })
                  }
                  else{
                    const newDefault = this.getDate(defaultParam.value, true)
                    param.defaultValue = newDefault
                    this.setState({ ["SSRSParam_".concat([defaultParam.paramName])]: newDefault })
                  }
                }
                else{
                  param.defaultValue = defaultParam.value
                  this.setState({ ["SSRSParam_".concat([defaultParam.paramName])]: defaultParam.value })
                }
                param.defaultSet = true
              }
            })
          })
          console.log(parameterList)
          this.setState({ parameterList: parameterList, reportParametersFlag: true, runReportButtonFlag: true })
        }
        else {
          alert("Error getting report defaults")
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  getSpacedLine(line, maxLength) {
    var newLine = line
    if (line.length > maxLength) {
      newLine = line.substring(0, maxLength - 3) + '...'
    }
    else if (line.length < maxLength) {
      for (var i = line.length; i < maxLength; i++) {
        newLine = newLine + " "
      }
    }
    return newLine
  }

  compareParamOrder(a, b) {
    if (parseInt(a.sortOrder) < parseInt(b.sortOrder)) {
      return -1;
    }
    if (parseInt(a.sortOrder) > parseInt(b.sortOrder)) {
      return 1;
    }
    return 0;
  }


  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }


  render() {
    const { pageNumber, numPages, parameterList } = this.state;
    var textFieldWidth = '200px'
    console.log(parameterList)
    console.log(this.state.reportParametersFlag)
    console.log(this.state.paramTrackingCount)
    console.log(this.paramTrackingCount)
    return (
      <div>


        <div id="reportSelect" style={{ backgroundColor: "white", borderRadius: '3px' }}>
          <label>Report: </label><DropDownList loading={this.state.loading} data={this.state.reports} textField="name" dataItemKey="key" value={this.state.report} style={{ width: '300px' }} disabled={this.state.reports.length === 0 || this.state.loading} onChange={(e) => this.onReportChangeHandler(e)} />
          <br></br>
          <div style={{ backgroundColor: "white", borderRadius: '3px' }}>
            {this.state.reportParametersFlag && this.paramTrackingCount === 0 &&
              <Grid fluid>
                <Row>
                  {parameterList.map(form => {
                    const t = "SSRSParam_".concat([form.name])
                    if (form.type === "String") {
                      if (form.name === "ReportTitle") {
                        textFieldWidth = '220px'
                      }
                      else {
                        textFieldWidth = '200px'
                      }
                      return (
                        <Col xs={5} sm={4} md={3} lg={2} xl={2} >
                          <br></br>
                          <InputTextField
                            name={form.name}
                            input_type={form.type}
                            label={form.label}
                            key={form.label}
                            value={this.state[t]}
                            defaultValue={form.defaultValue}
                            textFieldWidth={textFieldWidth}
                            valid={this.state[t.concat("_Validate")]}
                            _handleChange={this._handleChange}
                          />
                        </Col>
                      )
                    }
                    if (form.type === "DateTime" && form.name === 'singleDate') {
                      return (

                        <Col xs={5} sm={4} md={3} lg={2} xl={2} >
                          <br></br>
                          {!this.state.singleDateGenericDateToggle &&
                            <div>
                              <InputDate
                                name={form.name}
                                label={form.label}
                                key={form.label}
                                value={this.state[t]}
                                defaultValue={form.defaultValue}
                                valid={this.state[t.concat("_Validate")]}
                                _handleChange={this._handleChange}
                              />
                              <Button onClick={(e) => this.onGenericToggleChangeHandler(true, 'singleDateGenericDateToggle')} icon="list-unordered"></Button>
                            </div>
                          }
                          {this.state.singleDateGenericDateToggle &&
                            <div>
                              <InputDropdown
                                name={form.name}
                                label={form.label}
                                data={this.state.genericDates_singleDate_Data}
                                value={this.state[t]}
                                key={form.label}
                                valid={this.state[t.concat("_Validate")]}
                                _filterChange={this._filterChangeGenericDate}
                                _handleChange={this._handleChange}
                              />
                              <Button onClick={(e) => this.onGenericToggleChangeHandler(false, 'singleDateGenericDateToggle')} icon="calendar"></Button>
                            </div>
                          }
                          <br></br>
                        </Col>

                      )
                    }
                    if (form.type === "DateTime" && form.name === 'fromDate') {
                      return (

                        <Col xs={5} sm={4} md={3} lg={2} xl={2} >
                          <br></br>
                          {!this.state.fromDateGenericDateToggle &&
                            <div>
                              <InputDate
                                name={form.name}
                                label={form.label}
                                key={form.label}
                                value={this.state[t]}
                                defaultValue={form.defaultValue}
                                valid={this.state[t.concat("_Validate")]}
                                _handleChange={this._handleChange}
                              />
                              <Button onClick={(e) => this.onGenericToggleChangeHandler(true, 'fromDateGenericDateToggle')} icon="list-unordered"></Button>
                            </div>
                          }
                          {this.state.fromDateGenericDateToggle &&
                            <div>
                              <InputDropdown
                                name={form.name}
                                label={form.label}
                                data={this.state.genericDates_fromDate_Data}
                                value={this.state[t]}
                                key={form.label}
                                valid={this.state[t.concat("_Validate")]}
                                _filterChange={this._filterChangeGenericDate}
                                _handleChange={this._handleChange}
                              />
                              <Button onClick={(e) => this.onGenericToggleChangeHandler(false, 'fromDateGenericDateToggle')} icon="calendar"></Button>
                            </div>
                          }
                          <br></br>
                        </Col>

                      )
                    }
                    if (form.type === "DateTime" && form.name === 'toDate') {
                      return (

                        <Col xs={5} sm={4} md={3} lg={2} xl={2} >
                          <br></br>
                          {!this.state.toDateGenericDateToggle &&
                            <div>
                              <InputDate
                                name={form.name}
                                label={form.label}
                                key={form.label}
                                value={this.state[t]}
                                defaultValue={form.defaultValue}
                                valid={this.state[t.concat("_Validate")]}
                                _handleChange={this._handleChange}
                              />
                              <Button onClick={(e) => this.onGenericToggleChangeHandler(true, 'toDateGenericDateToggle')} icon="list-unordered"></Button>
                            </div>
                          }
                          {this.state.toDateGenericDateToggle &&
                            <div>
                              <InputDropdown
                                name={form.name}
                                label={form.label}
                                data={this.state.genericDates_toDate_Data}
                                value={this.state[t]}
                                key={form.label}
                                valid={this.state[t.concat("_Validate")]}
                                _filterChange={this._filterChangeGenericDate}
                                _handleChange={this._handleChange}
                              />
                              <Button onClick={(e) => this.onGenericToggleChangeHandler(false, 'toDateGenericDateToggle')} icon="calendar"></Button>
                            </div>
                          }
                          <br></br>
                        </Col>

                      )
                    }
                    if (form.type === "Boolean") {
                      var defaultChecked = ''
                      if (form.defaultValue.toLowerCase() === 'true') {
                        defaultChecked = true
                      }
                      else if (form.defaultValue.toLowerCase() === 'false') {
                        defaultChecked = false
                      }
                      return (

                        <Col xs={5} sm={4} md={3} lg={2} xl={2} >
                          <br></br>
                          <InputBoolean
                            name={form.name}
                            label={form.label}
                            key={form.label}
                            value={this.state[t]}
                            defaultChecked={defaultChecked}
                            valid={this.state[t.concat("_Validate")]}
                            _handleChange={this._handleChange}
                          />
                        </Col>

                      )
                    }
                    if (form.type === "Dropdown" && (form.name === "PortfolioTest" || form.name === "PortfolioList")) {
                      return (

                        <Col xs={5} sm={4} md={3} lg={2} xl={2} >
                          <br></br>
                          <InputPortfolioDropdown
                            name={form.name}
                            label={form.label}
                            data={this.state[t.concat("_Data")]}
                            value={this.state[t]}
                            key={form.label}
                            valid={this.state[t.concat("_Validate")]}
                            _filterChange={this._filterChange}
                            _handleChangePortfolio={this._handleChangePortfolio}
                            _itemRender={this._itemRender}
                          />
                          <Button onClick={(e) => this.consolidateGroups()} >Consolidate</Button>
                        </Col>

                      )
                    }
                    else if (form.type === "Dropdown") {
                      return (

                        <Col xs={5} sm={4} md={3} lg={2} xl={2} >
                          <br></br>
                          <InputDropdown
                            name={form.name}
                            label={form.label}
                            data={this.state[t.concat("_Data")]}
                            value={this.state[t]}
                            key={form.label}
                            valid={this.state[t.concat("_Validate")]}
                            _filterChange={this._filterChange}
                            _handleChange={this._handleChange}
                          />
                        </Col>

                      )
                    } else {
                      return (<></>)
                    }
                  })}
                </Row>
              </Grid>
            }
          </div>
          <br></br>


          <Button onClick={(e) => this.submitForm('pdf')} disabled={this.getButtonStatus()}>Run Report</Button>
          <br></br><br></br>
        </div>


        {
          this.state.loaderSpinnerFlag && <>
            <LoopCircleLoading color={'#bababa'} />
          </>
        }

        <p style={{ maxWidth: '600px' }}>{this.state.status}</p>

        {
          this.state.pdfFlag && <div id="document" style={{ alignIitems: 'center', justifyContent: 'center' }}>
            <Button onClick={() => this.changePdfPage(pageNumber, 'prev')}> Prev </Button>
          | {pageNumber} of {numPages} |
          <Button onClick={() => this.changePdfPage(pageNumber, 'next')}> Next </Button>
            {' '}
            <Button onClick={() => { if (this.state.pdfScale < 2.0) { this.setState({ pdfScale: this.state.pdfScale + 0.25 }) } }}> Zoom in + </Button>
            <Button onClick={() => { if (this.state.pdfScale > 0.75) { this.setState({ pdfScale: this.state.pdfScale - 0.25 }) } }}> Zoom out - </Button>
            {'   '}
            <Button onClick={() => window.open(this.state.pdfFile)}>Download PDF</Button>
            <Button onClick={(e) => this.submitForm('xlsx')} disabled={!this.state.generateExcelButtonFlag}>Download as Excel</Button>
            <Button onClick={(e) => this.submitForm('csv')} disabled={!this.state.generateCsvButtonFlag}>Download as CSV</Button>
            <div style={{ display: 'flex', alignIitems: 'center', justifyContent: 'center', borderStyle: 'solid', backgroundColor: "grey" }}><Document file={this.state.pdfFile} onLoadSuccess={this.onDocumentLoadSuccess}>
              <Page pageNumber={this.state.pageNumber} scale={this.state.pdfScale} />
            </Document>
            </div>

          </div>
        }
      </div >
    );
  }
}

export default Ssrs;