import React, { Component } from "react";
import {Grid, GridColumn, GridToolbar, GridNoRecords} from '@progress/kendo-react-grid';
import { aggregateBy, process } from '@progress/kendo-data-query';
import axios from 'axios';
import GridLoading from "../../Loaders/GridLoading";

class TopTenHoldings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      accountHoldings:[],
      totals:{marketValue:{sum:''}, percentage:{sum:''}},
      portfolio:this.props.portfolio,
      household:this.props.household,
      assetAllocation:this.props.assetAllocation,
      sector:this.props.sector,
      date:this.props.date,
      loading:false,
      dataState:{},
    }

    this.getHoldings = this.getHoldings.bind(this);
    this.getStyleSettings = this.getStyleSettings.bind(this);
  }

  componentDidMount(){
      this.getHoldings()
    
  }

  componentDidUpdate(){
    if(this.props.portfolio!==this.state.portfolio || this.props.assetAllocation!==this.state.assetAllocation
      || this.props.sector!==this.state.sector || this.props.date!==this.state.date){
      this.setState({portfolio:this.props.portfolio, date:this.props.date, assetAllocation:this.props.assetAllocation, sector:this.props.sector}, ()=>{
          this.getHoldings()
      });
    }
  }

  getStyleSettings() {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = ['includeAccruedInterest']

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
    .then(response => {
      this.setState({styleSettings: response.data.styleSettings})
    })
    .catch(err => {
        console.error(err);
    })
  }



  changeDataState = (e)=>{

    
    var newData = this.state.accountHoldings
    if (newData[newData.length - 1].securitySymbol === "TOTALS"){
      newData.pop()
    }
    newData = process(this.state.accountHoldings, e.dataState);

    var totals = aggregateBy(newData.data, [{field:'marketValue', aggregate:'sum'}, {field:'percentage', aggregate:'sum'}]);
    newData.data.push({
      securitySymbol: "TOTALS",
      securityName:'',
      marketValue:totals.marketValue.sum, 
      percentage:totals.percentage.sum
    });

    this.setState({dataState:e.dataState, accountHoldings:newData.data})
  }

  getHoldings(){
    this.setState({loading:true});

    var port = this.state.portfolio.account
    if (this.state.portfolio.consolidated) {
      port = this.props.household
    }

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios.get('api/top10',
      {
        method: 'GET',
        params: {
          company: this.props.user.company,
          port: port,
          consolidated: this.state.portfolio.consolidated,
          date: this.props.date,
          assetAllocation: this.state.assetAllocation,//.replace("&","%26"),
          sector: this.state.sector,//.replace("&","%26")
          includeAccruedInterest: true//this.state.styleSettings ? this.state.styleSettings.includeAccruedInterest : false
        },
        headers: headers,
      })
      .then(response=>{
        
      if(response.data.code===200){
        var resData = response.data.data;

        resData = process(resData, this.state.dataState)

        var totals = aggregateBy(resData.data, [{field:'marketValue', aggregate:'sum'}, {field:'percentage', aggregate:'sum'}]);
        resData.data.push({securitySymbol:'TOTALS', securityName:'', marketValue:totals.marketValue.sum, percentage:totals.percentage.sum});
        if(resData.data.length>0){
          this.setState({accountHoldings:resData.data, loading:false,
          totals:aggregateBy(resData.data, [{field:'marketValue', aggregate:'sum'}, {field:'percentage', aggregate:'sum'}])});
        }
        else{
          this.setState({accountHoldings:[], loading:false, totals:{marketValue:{sum:0}, percentage:{sum:0}}});
        }
      }
    }).catch(err=>{
      this.setState({accountHoldings:[], loading:false, totals:{marketValue:{sum:0}, percentage:{sum:0}}});
      console.log(err);
    });
  }

  formatCurrency(val){
    var formatted = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(val);
    
    if(val<0)
        formatted = '('+formatted+')';

    return formatted;
  }

  formatPercent(val){
    if(val)
      return (val).toFixed(2)+"%";
    else
      return val;
  }

  headerCellRender = (th, props)=>{
    if(th !=null && (props.field === 'marketValue' || props.field==='percentage'))
      return <div {...th.props} style={{justifyContent:'right'}}/>;
    else
      return th;
  }

  cellRender = (tdElement, cellProps)=>{
    if(tdElement!==null){
      var style = {};
      if(cellProps.field==='marketValue' || cellProps.field==='percentage'){
        style = {textAlign: 'right'};
      }
      if(cellProps.dataItem.securitySymbol==='TOTALS' && cellProps.dataItem.securityName===''){
        style = {fontWeight:'bold'};
        if(cellProps.field==='securityName'){
          return(
            <td {...tdElement.props} style={style}>Totals:</td>
          );
        }
        else{
          style = {fontWeight:'bold', textAlign:'right'};
        }
      }
      if(cellProps.field==='percentage'){
        return(
          <td {...tdElement.props} style={style}>{this.formatPercent(cellProps.dataItem[cellProps.field])}</td>
        );
      }
      return(
        <td {...tdElement.props} style={style}/>
      );
    }
    else{
      return tdElement;
    }
}

  render() {

    var tableData = this.state.accountHoldings;
    var showAssetAlloc = this.state.assetAllocation && this.props.styleSettings.acctOverviewClassificationGrouping !== 'sector,securityName' && this.props.styleSettings.acctOverviewClassificationGrouping !== 'sector' && this.props.styleSettings.acctOverviewClassificationGrouping !== 'securityName'
    var showSector = this.state.sector &&  this.props.styleSettings.acctOverviewClassificationGrouping !== 'assetAllocation,securityName' && this.props.styleSettings.acctOverviewClassificationGrouping !== 'securityName'
    var gridToolbarStyle = (showAssetAlloc || showSector) ? {display: 'flex', flexDirection: 'column', padding: '6px'} : {display: 'flex', flexDirection: 'column'}

    return(
        <div className='topTenHolds'>
            {this.props.grid &&
                <Grid 
                  data={this.state.accountHoldings} 
                  className='topTenGrid' 
                  style={{maxWidth:'600px', maxHeight:'76.5vh'}} 
                  cellRender={this.cellRender} 
                  headerCellRender={this.headerCellRender}
                  sortable={true} 
                  resizable={true}
                  onDataStateChange={this.changeDataState}
                  {...this.state.dataState}>
                    <GridToolbar>
                    <div style={gridToolbarStyle}> 
                    <div style={{textAlign:'center'}}><strong>Top 10 Holdings</strong></div>
                      {showAssetAlloc && <div style={{textAlign:'center'}}>Asset Class: {this.state.assetAllocation}</div>} 
                      {showSector && <div style={{textAlign:'center'}}>Sector: {this.state.sector}</div>}
                    </div>
                      
                    </GridToolbar>
                    <GridNoRecords>
                      {this.state.loading && 'Loading...'}
                      {!this.state.loading && 'No records available'}
                    </GridNoRecords>
                    <GridColumn headerClassName='gridHeader' width={275} field="securityName" title="Security Name" />            
                    <GridColumn headerClassName='gridHeader' width={150} field="marketValue" title="Market Value" format='{0:c}'/>
                    <GridColumn headerClassName='gridHeader' width={152} field="percentage" title="% of Total Account"/>
                </Grid>
            }
            {this.state.loading && <GridLoading gridClass='topTenGrid'/>}
            {this.props.table &&
                <>
                <table style={{marginLeft:'auto', marginRight:'0px'}}>
                  <td colSpan='4'>
                    <h2><div>Top 10 Holdings</div>
                    {this.state.assetAllocation && this.props.styleSettings.acctOverviewClassificationGrouping !== 'sector,securityName' && this.props.styleSettings.acctOverviewClassificationGrouping !== 'sector' && this.props.styleSettings.acctOverviewClassificationGrouping !== 'securityName' && <div>Asset Class: {this.state.assetAllocation}</div>} 
                    {this.state.sector && this.props.styleSettings.acctOverviewClassificationGrouping !== 'assetAllocation,securityName' && this.props.styleSettings.acctOverviewClassificationGrouping !== 'securityName' && <div>Sector: {this.state.sector}</div>}</h2>
                  </td>
                  <tbody className='alternateRows'>
                  <tr>
                      <td className='heading leftAlignPad'>Symbol</td>
                      <td className='heading leftAlignPad'>Security Name</td>
                      <td className='heading rightAlignPad'>Market Value</td>
                      <td className='heading rightAlignPad'>% of Assets</td>
                  </tr>
                  {tableData.map((point, i)=>(
                      <tr key={i}>
                      <td className='leftAlignPad'>{point.securitySymbol}</td>
                      <td className='leftAlignPad'>{point.securityName}</td>
                      <td className='rightAlignPad'>{this.formatCurrency(point.marketValue)}</td>
                      <td className='rightAlignPad'>{this.formatPercent(point.percentage)}</td>
                      </tr>
                  ))}
                  <tr>
                    <td className='heading' colSpan='2'>Top 10 Holdings Totals:</td>
                    <td className='heading rightAlignPad'>{this.formatCurrency(this.state.totals.marketValue.sum)}</td>
                    <td className='heading rightAlignPad'>{this.formatPercent(this.state.totals.percentage.sum)}</td>
                  </tr>
                  </tbody>
                </table>
                </>
            }
        </div>
    );
  }



}

export default TopTenHoldings;