import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import {MultiSelect} from '@progress/kendo-react-dropdowns';
import axios from 'axios';

class EditPermissions extends Component {

  constructor(props) {
    super(props);
    this.state = {
        newFolder:'',
        selectedGroups:[],
        groups:[],
        loading: true,
        placeholder: '',
      }

      this.getGroups = this.getGroups.bind(this);
      this.getSelectedGroups = this.getSelectedGroups.bind(this);
      this.updatePermissions = this.updatePermissions.bind(this);
  }

  componentDidMount(){
    if(this.props.open && this.props.type==='shared' && this.props.folder.path.endsWith('/')){
        this.getSelectedGroups(() => {
          this.getGroups();
        });
    }
  }

  resetState(){
    this.setState({
      newFolder:'',
      selectedGroups:[],
    })
  }

  onChange = (e)=>{
    this.setState({ newFolder: e.target.value });
  }

  onGroupsChange = (e) => {
    let newGroups = [...this.state.groups]; // Clone to avoid direct mutation

    if (e.target.value.length > this.state.selectedGroups.length) { // Add operation
        for (let i = 0; i < e.target.value.length; i++) {
            const index = this.state.groups.findIndex(x => x.name === e.target.value[i].name);
            if (index >= 0) { // Remove from groups after adding to selectedGroups
                if (newGroups.length === 1) {
                    newGroups = [];
                } else {
                    newGroups.splice(index, 1);
                }
                break;
            }
        }
    } else { // Remove operation
        for (let i = 0; i < this.state.selectedGroups.length; i++) {
            const index = e.target.value.findIndex(x => x.name === this.state.selectedGroups[i].name);
            if (index === -1) { // Add back to groups after removing from selectedGroups
                newGroups.push(this.state.selectedGroups[i]);
                break;
            }
        }
    }

    this.setState({ selectedGroups: e.target.value, groups: newGroups });
  }

  getGroups(){
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios.get("api/usergroups?company="+this.props.user.company+'&type=nousers', {headers}).then(response => {
      if(response.data.code===200){
        var groups = response.data.groups
        var selectedGroups = [...this.state.selectedGroups]

        for (let i = 0; i <  selectedGroups.length; i++) {
          var index = groups.findIndex(x => x.name === selectedGroups[i].name)
          if (index >= 0) {
            if (groups.length === 1) {
              groups = []
            }
            else{
              groups.splice(index, 1);
            }
          }
        }

        this.setState({groups:groups, loading: false});
      }
        else{
          alert("An error occured while fetching the user groups.");
          this.setState({loading: false});
        }
    }).catch(error => {console.log(error); alert("An error occured while fetching the user groups."); this.setState({loading: false});});
  }

  getSelectedGroups(cb){
    this.setState({loading: true, placeholder: ""})
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios(`api/folderPermissions`, {
      method: 'GET',
      params: { company: this.props.user.company, folder: this.props.folder.path },
      headers: headers
    })
    .then(response => {
      if(response.data.code===200){
        var arr = response.data.groups.slice();
        for(let i=0; i<arr.length; i++){
          arr[i].id = arr[i].userGroup;
        }
        this.setState({selectedGroups:arr, placeholder: "Everyone"}, () => {cb()});
      }
      else{
        alert("An error occured while fetching the folder's user groups.");
        this.setState({placeholder: "Everyone"}, () => {cb()})
      }
    }).catch(error => {alert("An error occured while fetching the folder's user groups."); console.log(error); cb()});
  }

  updatePermissions(callback){
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload ={
        company:this.props.user.company,
        folder:this.props.folder.path,
        groups:this.state.selectedGroups
    }
    axios.post("api/folderPermissions", payload, {headers}).then(response => {
      if(response.data.code===200){
        callback();
      }
      else{
        alert(response.data.errMsg);
        callback("err");
      }
    }).catch(error => {
        console.log(error); 
        alert("An error has occurred while trying to set permissions"); 
        callback("err");});
  }
  


  render() {
      return (
        <Popup closeOnDocumentClick={false} open={this.props.open} onClose={e=>console.log('close')} overlayStyle={{ zIndex: 5 }}  modal>
          {close => (
            <div className="modal">

              <Button className="close" icon="close" onClick={(e)=>{
                this.props.changeOpen('');
                close();}} />


              <div className="header" align="left">
                Update Folder
              </div>

              <div className="content">
                <label>Folder: {this.props.folder.text}</label>
                <br/>
                <br/>
                <br/>
                {this.props.type==='shared' && <><label>Group Permissions: </label>
                  <br/>
                  <MultiSelect
                    data={this.state.groups}
                    placeholder={this.state.placeholder}
                    textField="name"
                    disabled={this.state.loading}
                    defaultValue={this.state.selectedGroups}
                    onChange={this.onGroupsChange}
                  /></>}
              </div>

              <div className="actions">

                <Button
                  className="button"
                  onClick={() => {
                    this.props.changeOpen('')
                    this.resetState();
                    close();
                  }}
                >
                  Cancel
                </Button>

                <Button 
                  className="upload"  
                  onClick={(event)=>{
                    this.updatePermissions((err)=>{
                        if(!err){
                            this.props.changeOpen('');
                            this.resetState();
                            close();
                        }
                    });
                    }}
                >
                      Update Folder
                </Button>

              </div>

            </div>
          )}
        </Popup>

      );
  };
}

export default EditPermissions;