import React, { Component } from "react";
import axios from 'axios';
import { Button } from "@progress/kendo-react-buttons";
import Setting from './Setting';


class SettingsForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            company: this.props.company,
            chartColors: this.props.chartColors,
            updates: [],
            changed: false,
            cornerLogo: null
        }

        this.clearState = this.clearState.bind(this);
        this.createCell = this.createCell.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
    }

    componentDidMount() {

    }

    createCell(cell) {
        if (cell.rowType === 'data') {
            return (
                <div>
                    <Button className='docButton' onClick={(event) => this.addHouseholdMapping(event, cell)}>Add Account Mapping</Button>
                </div>
            );
        }
        else {
            return (
                <></>
            );
        }
    }

    validityTest() {
        var errorString = ''
        for (let style of this.state.updates) {
            if (style.dataType === 'email') {
                if (!emailRegex.test(style.value) && style.value/*allows empty email*/) {
                    errorString = errorString.concat(style.description + ' - Email Address is not in correct format\n\n')
                }
            }
        }

        if (errorString) {
            return 'Please correct issues with the following fields:\n\n' + errorString
        }
        else {
            return false
        }
    }


    _handleChange = e => {
        var newUpdates = this.state.updates

        var value = null
        var availableValues = null

        if (e.target.props.dataType === 'sortable'){
            value = []
            availableValues = []
            
            e.newState.forEach(element => {
                value.push(element.id)
                availableValues.push(element.text)
            })
            value = value.join('|')//create pipe delmited string
            availableValues = availableValues.join('|')
        }

        var index = (newUpdates.findIndex(x => { return x.name === e.target.props.name; }))

        if (index !== -1) {
            newUpdates[index].name = e.target.name || e.target.props.name
            newUpdates[index].description = e.target.props.description
            newUpdates[index].dataType = e.target.props.dataType
            newUpdates[index].value = value || e.target.value
            newUpdates[index].availableValues = availableValues
        }
        else {
            newUpdates.push({ 
                name: e.target.name || e.target.props.name, 
                description: e.target.props.description, 
                value: value || e.target.value, 
                dataType: e.target.props.dataType,
                availableValues: availableValues
            })
        }

        this.setState({
            updates: newUpdates, changed: true
        })

        this.props.changed()
    }

    _handleColorChange = (color, style) => {

        var newUpdates = this.state.updates

        var index = (newUpdates.findIndex(x => { return x.name === style.name; }))

        var newColor = 'rgb(' + color.rgb.r + ',' + color.rgb.g + ',' + color.rgb.b + ',' + color.rgb.a + ')'

        if (index !== -1) {
            newUpdates[index].name = style.name
            newUpdates[index].description = style.description
            newUpdates[index].value = newColor
            newUpdates[index].dataType = style.dataType
        }
        else {
            newUpdates.push({ name: style.name, description: style.description, value: newColor, dataType: style.dataType })
        }

        this.setState({
            updates: newUpdates, changed: true
        })

        this.props.changed()



    }


    _handleChartColorChange = (colorList, style) => {

        var newUpdates = this.state.updates

        var index = (newUpdates.findIndex(x => { return x.name === style.name; }))

        var colorListString = ''
        colorList.forEach(c => {
            colorListString = colorListString.concat('rgb(' + c.color.r + ',' + c.color.g + ',' + c.color.b + ',' + c.color.a + ')<' + (c.mapping || '') + '|')
        })
        colorListString = colorListString.slice(0, -1);//trims last character

        if (index !== -1) {
            newUpdates[index].name = style.name
            newUpdates[index].description = style.description
            newUpdates[index].value = colorListString
            newUpdates[index].dataType = style.dataType
        }
        else {
            newUpdates.push({ name: style.name, description: style.description, value: colorListString, dataType: style.dataType })
        }

        this.setState({
            updates: newUpdates, changed: true
        })
        
        this.props.changed()



    }

    _handleImageChange = e => {
        //check all instances of cornerLogo and cornerPreview
        console.log(e.target.files[0])
        this.setState({ [e.target.name + "Data"]: e.target.files[0], [e.target.name + "Preview"]: URL.createObjectURL(e.target.files[0]) })

        var newUpdates = this.state.updates

        var index = (newUpdates.findIndex(x => { return x.name === e.target.name; }))

        if (index !== -1) {
            newUpdates[index].name = e.target.name
            newUpdates[index].description = ''
            newUpdates[index].dataType = 'image'
            newUpdates[index].imageData = e.target
            newUpdates[index].value = this.props.company + e.target.files[0].name.substring(e.target.files[0].name.indexOf('.'))
        }
        else {
            newUpdates.push({ 
                name: e.target.name, 
                description: '', 
                imageData: e.target,
                value: this.props.company + e.target.files[0].name.substring(e.target.files[0].name.indexOf('.')), 
                dataType: 'image',
            })
        }

        this.setState({
            updates: newUpdates, changed: true
        })

        this.props.changed()
    }

    uploadImage(imageSetting) {
        var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        const formData = new FormData();
        formData.append('company', this.props.company);
        formData.append('name', imageSetting.name + '.png');
        formData.append('template', this.props.templateName);
        formData.append('type', 'customLanding');
        console.log(imageSetting.imageData)
        formData.append('imageData', imageSetting.imageData);
        formData.append('file', imageSetting.imageData);
        formData.append(this.props.company + this.state[imageSetting.name + "Data"].name.substring(this.state[imageSetting.name + "Data"].name.indexOf('.')), this.state[imageSetting.name + "Data"]);
        console.log(this.props.company + this.state[imageSetting.name + "Data"].name.substring(this.state[imageSetting.name + "Data"].name.indexOf('.')))
    
        axios.post('api/homePageImage?company=' + this.props.company, formData, { headers }).then(response => {
          if (response.data.code === 200) {
            //this.props.onCompSettingsChange('cornerLogo', response.data.newlogo);

            // this.props.setStyle(copyStyle);
            // this.props.onCompSettingsChange('cornerLogo', response.data.newlogo);
            // this.props.refresh('style');
            // alert("Style Settings have been updated.");
          }
        }).catch(err => console.log(err));
      }

    saveChanges = e => {

        var validityMessage = this.validityTest()

        if (validityMessage) {
            alert(validityMessage)
        }
        else {
            var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

            var imageUpdates = []

            this.state.updates.forEach(u => {
                //escape single quote
                if (u.dataType === 'text' || u.dataType === 'image'){
                    u.value = u.value.replace(new RegExp("'", "g"), "''")
                }
                //set blanks to 0.0
                if (u.dataType === 'decimal' & u.value === ''){
                    u.value = '0.0'
                }
                if (u.dataType === 'multiselect'){
                    var newValue = ''
                    u.value.forEach(v => {
                        newValue = newValue.concat(v + '|')
                    })
                    u.value = newValue.slice(0, -1);//trims last character which is a pipe
                }
                if (u.dataType === 'image'){
                    imageUpdates.push(u)
                    u.imageData = null
                }
            })

            // imageUpdates.forEach(imageUpdate => {
            //     this.uploadImage(imageUpdate)
            // })

            var payload = {
                company: this.props.company,
                updates: this.state.updates,
                groupName: this.props.templateName,
            }
            console.log(payload)
            axios.post("api/saveHomePageSettings", payload, { headers }).then(response => {
                if (response.data.code === 200) {
                    imageUpdates.forEach(imageUpdate => {
                        this.uploadImage(imageUpdate)
                    })
                    // if (this.state.cornerLogo !== null && this.state.cornerLogo !== undefined){
                    //     this.uploadImage()
                    // }
                    alert('Home Page Settings Saved Successfully');
                    //callback();
                    this.props.saved()
                    this.setState({ changed: false })
                }
                else {
                    alert(response.data.error);
                    //callback("ERROR");
                }
            }).catch(err => {
                alert("An error has occured");
                console.log(err);
                //callback("ERROR");
            });
        }
    }


    clearState() {
        this.setState({
            updates: [],
            cornerLogo: null
        });
    }

    render() {
        return (
            <div>
                <table className="styleTable">
                    <tbody>
                        {this.props.contents.map((setting, i) => {
                            return (
                                <>
                                    {setting.subgroup &&
                                        <div className="styleTableSubgroup">{setting.subgroup}:</div>
                                    }
                                    <Setting
                                        setting={setting}
                                        key={i}
                                        _handleChange={this._handleChange}
                                        _handleColorChange={this._handleColorChange}
                                        _handleChartColorChange={this._handleChartColorChange}
                                        _handleImageChange={this._handleImageChange}
                                        compSettings={this.props.compSettings}
                                        company={this.props.company}
                                        chartColors={this.state.chartColors}
                                    />
                                </>
                            )
                        })}
                    </tbody>
                </table>
                <br />
                <br />
                <Button onClick={this.saveChanges} disabled={!this.state.changed}>Apply Changes</Button>
                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            </div>
        );
    }

}


const emailRegex= RegExp( process.env.REACT_APP_EMAIL_REGEX );


export default SettingsForm;