import React, { Component } from "react";
// import { DropDownList } from '@progress/kendo-react-dropdowns';
// import { Button } from "@progress/kendo-react-buttons";
import axios from 'axios';
import chBlank from './components/images/chBlank.png'
import chDocs from './components/images/chDocs.png'
import chAccessDocs from './components/images/chAccessDocs.png'
import chWeb from './components/images/chWeb.png'
import chMkt from './components/images/chMkt.png'
// import chContact from './components/images/chContact.png'
import chPlaceholder from './components/images/chPlaceholder.png'
import chbg from './components/images/chbg.jpg'
import ReactLoading from 'react-loading';




class Churchill extends Component {

    constructor(props) {
        super(props);
        this.state = {
            associatedHousehold: this.props.CHassociatedHousehold != null ? this.props.CHassociatedHousehold : null,
            csTeamLoaded: false,
            members: []
        }

        this.formatPhoneNumber = this.formatPhoneNumber.bind(this);

    }


    componentDidMount() {
        this.getCsTeamDedicated();
    }

    getCsTeamDedicated() {
        //this.setState({ refreshText: 'Loading...', employeeSelected: false, employeeSelect: '', });
        var members = [];
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        //axios
        //.post('api/ssrsGetReports', payload, { headers })

        axios(`api/CsTeamHousehold`, {
            method: 'GET',
            params: { company: this.props.user.company, user: this.props.user.user },
            headers: headers,
        })
            .then(response => {
                // if (1 === 1) {

                    if (response.data.data != null && response.data.data.length === 1 && this.props.user.role === 'user') {
                        this.setState({ associatedHousehold: {
                            household: response.data.data[0].household, 
                            account: response.data.data[0].account,
                            displayName: response.data.data[0].displayName,
                            accountAnnotation: response.data.data[0].accountAnnotation
                        } })
                        this.props.setCHassociatedHousehold({
                            household: response.data.data[0].household, 
                            account: response.data.data[0].account,
                            displayName: response.data.data[0].displayName,
                            accountAnnotation: response.data.data[0].accountAnnotation
                        });
                    }

                    axios(`api/CsTeamDedicated`, {
                        method: 'GET',
                        params: { company: this.props.user.company, household: (response.data.data != null && response.data.data.length > 0 && response.data.data[0].household != null) ? response.data.data[0].household : '3Sisters85Inc-011_marysue83' },
                        headers: headers,
                    })
                        .then(response => {
                            console.log(response)
                            if (response.data.data.length === 0){
                                this.setState({ members: members, csTeamLoaded: true })
                            }
                            else if (response.data.data.length === 1){
                                this.createTeamMemberObject(response.data.data[0], tempMember0 =>{
                                    members.push(tempMember0)
                                    this.setState({ members: members, csTeamLoaded: true })
                                })
                            }
                            else if (response.data.data.length === 2){
                                this.createTeamMemberObject(response.data.data[0], tempMember0 =>{
                                    members.push(tempMember0)
                                    this.createTeamMemberObject(response.data.data[1], tempMember1 =>{
                                        members.push(tempMember1)
                                        this.setState({ members: members, csTeamLoaded: true })
                                    })
                                })
                            }
                            else if (response.data.data.length === 3){
                                this.createTeamMemberObject(response.data.data[0], tempMember0 =>{
                                    members.push(tempMember0)
                                    this.createTeamMemberObject(response.data.data[1], tempMember1 =>{
                                        members.push(tempMember1)
                                        this.createTeamMemberObject(response.data.data[2], tempMember2 =>{
                                            members.push(tempMember2)
                                            this.setState({ members: members, csTeamLoaded: true })
                                        })
                                    })
                                })
                            }
                            else {//failsafe if length is not 0-3
                                this.setState({ members: members, csTeamLoaded: true })
                            }

                        })
                        .catch(err => {
                            console.error(err);
                            this.setState({ refreshText: '', csTeamLoaded: true });
                        })
                // }
                // else {
                //     this.setState({ refreshText: '', csTeamLoaded: true });
                // }

            })
            .catch(err => {
                console.error(err);
                this.setState({ refreshText: '', csTeamLoaded: true });
            })


    }

    formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          var intlCode = (match[1] ? '+1 ' : '');
          return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
      }

    createTeamMemberObject(memberData, callback) {
        var member = {
            HouseholdID: memberData.HouseholdID,
            Relation: memberData.Relation,
            FullName: memberData.FullName,
            Title: memberData.Title,
            Phone: memberData.Phone,
            Email: memberData.Email,
            PictureName: memberData.PictureName,
        }

        if (memberData.photoUploaded) {
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            axios
                .get("api/download?company=" + this.props.user.company + "&path=csteam/" + memberData.PictureName + "&name=" + memberData.PictureName, { headers })
                .then((response) => {
                    if (response.data) {
                        member.Image = response.data
                    }
                    callback(member)
                })
                .catch((e) => {
                    console.error("ERROR");
                    callback(member)
                });
        }
        else{
            callback(member)
        }
    }



    render() {
        return (
            <div
                style={{
                    backgroundImage: "url(" + chbg + ")", backgroundPosition: 'right',
                    backgroundSize: '92vh',
                    backgroundRepeat: 'no-repeat',
                    height: '92vh',

                }}>

                <div style={{ paddingTop: '3vw' }}></div>
                <label style={{
                    fontSize: '5.0vw', paddingLeft: 60, float: 'left', fontFamily: 'Muli', color: '#6E9DAF'
                }}><label style={{ textDecoration: 'underline' }}>Hello</label>. Welcome to your portal.</label>
                {/* <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /> */}
                <br />
                <div style={{ paddingTop: '7vw' }}></div>
                <div className='customCHButtonGrid'>
                    <table cellPadding="0" cellSpacing="0" style={{ fontFamily: 'Muli', paddingLeft: '6.4vw', float: 'left' }}>
                        <tbody>
                            <tr>
                                <th style={{ padding: 4, color: '#6E9DAF' }}><label style={{}}>Access Your Account Documents</label></th>
                            </tr>
                            <tr>
                                <td style={{ padding: '0.8vw', paddingRight: '3vw', fontSize: '0.8v' }}><div style={{ cursor: 'pointer' }} onClick={this.props.onCHDocClick}><img alt="" style={{ verticalAlign: 'middle' }} src={chDocs} width="50" height="50" /> ACCOUNT DOCUMENTS</div></td>
                            </tr>
                            <tr>
                                {this.props.user.access.indexOf('portfolio') >= 0 && <td style={{ borderBottom: 'solid 2px rgb(160, 160, 160)', padding: '0.8vw', paddingRight: '3vw', fontSize: '0.8v' }}>
                                    <div style={{ cursor: 'pointer' }} onClick={(e) => this.props.onCHAccountClick(e, this.state.associatedHousehold)} ><img alt="" style={{ verticalAlign: 'middle' }} src={chAccessDocs} width="50" height="50" /> ACCESS YOUR ACCOUNTS</div></td>}
                                {this.props.user.access.indexOf('portfolio') < 0 && <td style={{ borderBottom: 'solid 2px rgb(160, 160, 160)', padding: '0.8vw', paddingRight: '3vw' }}><img alt="" style={{ verticalAlign: 'middle' }} src={chBlank} width="50" height="50" /></td>}
                            </tr>
                            {/* <tr>
                                <td style={{ borderBottom: 'solid 2px rgb(160, 160, 160)', padding: '0.8vw', paddingRight: '3vw' }}><img alt="" style={{ verticalAlign: 'middle' }} src={chBlank} width="50" height="50" /></td>
                            </tr> */}
                        </tbody>
                    </table>
                    <table className='customCH' cellPadding="0" cellSpacing="0" style={{ float: 'left' }}>
                        <tbody>
                            <tr>
                                <th style={{ padding: 4, paddingLeft: '2.4vw', color: '#6E9DAF' }}><label style={{}}>Resource Center</label></th>
                            </tr>
                            <tr>
                                <td style={{ padding: '0.8vw', paddingLeft: '2.4vw', fontSize: '0.8v' }}><a target="_blank" rel="noopener noreferrer" style={{ display: 'block', height: '100%', textDecoration: 'none', color: 'black' }} href="https://www.churchillmanagement.com"><img alt="" style={{ verticalAlign: 'middle' }} src={chWeb} width="50" height="50" /> WWW.CHURCHILLMANAGEMENT.COM</a></td>
                            </tr>
                            <tr>
                                <td style={{ borderBottom: 'solid 2px rgb(160, 160, 160)', padding: '0.8vw', paddingLeft: '2.4vw', fontSize: '0.8v' }}><a target="_blank" rel="noopener noreferrer" style={{ display: 'block', height: '100%', textDecoration: 'none', color: 'black' }} href="https://www.churchillmanagement.com/insights/"><img alt="" style={{ verticalAlign: 'middle' }} src={chMkt} width="50" height="50" /> MARKET INSIGHTS</a></td>
                            </tr>
                            {/* <tr>
                                <td style={{ borderBottom: 'solid 2px rgb(160, 160, 160)', padding: '0.8vw', paddingLeft: '2.4vw', fontSize: '0.8vw' }}><a target="_blank" rel="noopener noreferrer" style={{ display: 'block', height: '100%', textDecoration: 'none', color: 'black' }} href="https://www.churchillmanagement.com/contact/"><img alt="" style={{ verticalAlign: 'middle' }} src={chContact} width="50" height="50" /> CONTACT US</a></td>
                            </tr> */}
                        </tbody>
                    </table>
                    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                    {this.state.csTeamLoaded === false && <div style={{ paddingLeft: 400, paddingTop: 50 }}><ReactLoading type='spinningBubbles' color='grey' height={100} width={100} /></div>}
                    {this.state.csTeamLoaded === true &&
                        <div>
                            {this.state.members.length > 0 && <label style={{ color: '#6E9DAF', paddingLeft: '6.4vw', float: 'left' }}>Your Dedicated Client Service Team</label>}
                            <br />
                            <br />
                            <table style={{ float: 'left' }}>
                                <tbody>
                                    <tr>

                                        {this.state.members.map((member, i) => (
                                            <td key={i} style={{ paddingLeft: i === 0 ? '6.4vw' : '3vw', }}><img alt="" style={{}} src={member.Image ? member.Image : chPlaceholder} width="150" height="100" /><br />{member.FullName}<br /><div style={{ fontWeight: 'normal' }}>{member.Title}<br />{this.formatPhoneNumber(member.Phone)}<br /><a href={'mailto:' + member.Email}>{member.Email}</a></div></td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }



                </div>




            </div>
        );
    }

}

export default Churchill;