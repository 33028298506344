import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import {MultiSelect} from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import axios from 'axios';

class AddFolder extends Component {

  constructor(props) {
    super(props);
    this.state = {
        newFolder:'',
        selectedGroups:[],
        groups:[]
      }

      this.getGroups = this.getGroups.bind(this);
  }

  componentDidMount(){
    this.getGroups();
  }

  onChange = (e)=>{
    this.setState({ newFolder: e.target.value });
  }

  onGroupsChange = (e)=>{
    const newGroups = [...this.state.groups]; // Clone to avoid direct mutation

    if (e.target.value.length > this.state.selectedGroups.length) { // Add operation
        for (let i = 0; i < e.target.value.length; i++) {
            (function(i) { // IIFE to safely capture 'i'
                const index = this.state.groups.findIndex(x => x.name === e.target.value[i].name);
                if (index >= 0) { // Remove from groups after adding to selectedGroups
                    newGroups.splice(index, 1);
                    return;
                }
            }).call(this, i);
        }
    } else { // Remove operation
        for (let i = 0; i < this.state.selectedGroups.length; i++) {
            (function(i) { // IIFE to safely capture 'i'
                const index = e.target.value.findIndex(x => x.name === this.state.selectedGroups[i].name);
                if (index === -1) { // Add back to groups after removing from selectedGroups
                    newGroups.push(this.state.selectedGroups[i]);
                    return;
                }
            }).call(this, i);
        }
    }

    this.setState({ selectedGroups: e.target.value, groups: newGroups });
  }

  resetState(){
    console.log("yes")
    this.setState({newFolder:'', selectedGroups:[]})
  }

  getGroups(){
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios.get("api/usergroups?company="+this.props.user.company+"&type=nousers", {headers}).then(response => {
      if(response.data.code===200){
        this.setState({groups:response.data.groups});
      }
      else{
        alert("An error occured while fetching the user groups.");
      }
    }).catch(error => console.log(error));
  }


  render() {
      return (
        <Popup closeOnDocumentClick={false} trigger={this.props.button} onClose={(event) => this.setState({newFolder:''})} overlayStyle={{ zIndex: 5 }}  modal>
          {close => (
            <div className="modal">

              <Button className="close" icon="close" onClick={(e)=>{close(); this.setState({newFolder:''});}} />


              <div className="header" align="left">
                Add Folder
              </div>

              <div className="content">
                <label>New Folder: </label>
                <Input onChange={this.onChange} placeholder='Folder'/>
                <br/>
                <br/>
                <br/>
                {this.props.directory.startsWith('shared/') && <><label>Group Permissions: </label>
                  <MultiSelect
                    data={this.state.groups}
                    placeholder="Everyone"
                    textField="name"
                    onChange={this.onGroupsChange}
                  /></>}
              </div>

              <div className="actions">

                <Button
                  className="button"
                  onClick={() => {
                    close(); this.resetState();
                  }}
                >
                  Cancel
                </Button>

                <Button 
                  className="upload"  
                  onClick={(event)=>{this.props.addFolder(event, this.props.directory, this.state.newFolder, this.state.selectedGroups); close(); this.resetState();}}
                >
                      Add Folder
                </Button>

              </div>

            </div>
          )}
        </Popup>

      );
  };
}

export default AddFolder;