import React, { Component } from "react";
import axios from 'axios';
import {Grid, GridColumn, GridToolbar, GridNoRecords} from '@progress/kendo-react-grid';
import GridLoading from '../../Loaders/GridLoading';
import { process } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Button } from "@progress/kendo-react-buttons";

class GroupAppraisal extends Component {

  constructor(props) {
    super(props);

    this.state = {
        appraisalData:[],
        result:[],
        dataState:{group:[{field:"assetAllocation", aggregates:[]}]},
        aggregates:[],
        dates:[],
        selectedDate:'',
        loading:true,
        portfolio:this.props.portfolio,
        styleSettings: {
          chartShowAsOf: null
        }
    }

    this.getAppraisalData = this.getAppraisalData.bind(this);
    this.getHoldDates = this.getHoldDates.bind(this);
    this.cellRender = this.cellRender.bind(this);
    this.headerCellRender = this.headerCellRender.bind(this);
    this.formatCurrency = this.formatCurrency.bind(this);
  }

  componentDidMount(){
    this.getStyleSettings(() =>{
      this.getHoldDates();
      this.getAppraisalData();
    })
    
  }

  getStyleSettings(cb) {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      "chartShowAsOf"
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
      .then(response => {
        this.setState({ styleSettings: response.data.styleSettings }, () => {
          cb()
        });
      })
      .catch(err => {
        console.error(err);
      })


  }

  getAppraisalData(date){
    this.setState({loading:true});
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var payload = {
      company:this.props.user.company,
      date:date,
      user:this.props.user.user,
      household:this.props.user.defaultAcct.household,
      port:this.state.portfolio.account
    }

    axios.post('api/groupAppraisal', payload, {headers}).then(response=>{
      if(response.data.code===200){
          var aggs = this.props.portfolios.map(x=>({field:x.displayName, aggregate:'sum'}));        
          aggs.push({field:'total', aggregate:'sum'}); 
          var newDataState = {group:[{field:"assetAllocation", aggregates:aggs}]};
          this.setState({appraisalData:response.data.data, loading:false, result:process(response.data.data, newDataState), dataState:newDataState, aggregates:aggs});
        }
        else
          this.setState({appraisalData:[], result:[], loading:false});
    }).catch(err=>{
      this.setState({loading:false});
      console.log(err);
    });
  }

  getHoldDates(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company:this.props.user.company,
      user:this.props.user.user,
      household:this.props.user.defaultAcct.household
    }
    axios.post('api/posDates' , payload, {headers}).then(response=>{
      if(response.data.code===200){
          if(response.data.data.length>0)
            this.setState({dates:response.data.data, selectedDate:response.data.data[0].fDate});
          else
            this.setState({dates:[]});
      }
    }).catch(err=>{
      console.log(err);
    });
  }

  formatCurrency(val){
    var formatted = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(val); 
    return formatted;
  }

  expandChange = (event) => {
    event.dataItem[event.target.props.expandField] = event.value;
    this.forceUpdate();
  }

  changeDataState = (e)=>{
    const groups = e.dataState.group;
    if(groups){
      groups.map(group=>group.aggregates = this.state.aggregates);
    }
    var newData = process(this.state.appraisalData, e.dataState);
    this.setState({dataState:e.dataState, result:newData});
  }

  onGroupExpandClick(e){
    e.dataItem.expanded = !e.dataItem.expanded;
    this.forceUpdate();
  }

  cellRender(tdElement, cellProps) {
    if(cellProps.rowType==='data' && cellProps.field!=='value' && (cellProps.dataItem[cellProps.field]===undefined || cellProps.dataItem[cellProps.field]===0)){
       return(
            <td style={{textAlign:'right'}}>-</td>
        );
    }
    else if (cellProps.rowType === 'groupFooter') {
      if (cellProps.field!=='value' && cellProps.field!=='displayName' && cellProps.field!=='securitySymbol' 
          && cellProps.field!=='securityName' && cellProps.field!=='assetAllocation' && cellProps.field!=='sector'){ 
        
        var val = '';
        if(cellProps.dataItem.aggregates[cellProps.field])
          val = cellProps.dataItem.aggregates[cellProps.field].sum
          
        val = this.formatCurrency(val);

        return (
            <td style={{textAlign:'right'}}>
                <strong>{val}</strong>
            </td>
        );
      }
      else if(cellProps.field==='securitySymbol'){
        return(<></>);
      }
      else if(cellProps.field==='securityName'){
        return (
          <td colSpan='2'>
              <strong>{cellProps.dataItem.value} Totals:</strong>
          </td>
        );
      }
    }
    else if(tdElement!==null){
      var style={};
      if(cellProps.field!=='value' && cellProps.field!=='displayName' && cellProps.field!=='securitySymbol' 
          && cellProps.field!=='securityName' && cellProps.field!=='assetAllocation' && cellProps.field!=='sector')
        style = {textAlign:'right'};

      return(
        <td {...tdElement.props} style={style}/>
      );
    }

    return tdElement;
  }

  headerCellRender(thElement, cellProps) {
    if(thElement!==null){
      var style={};
      if(cellProps.field!=='value' && cellProps.field!=='displayName' && cellProps.field!=='securitySymbol' 
          && cellProps.field!=='securityName' && cellProps.field!=='assetAllocation' && cellProps.field!=='sector')
        style = {justifyContent:'right'};

      return(
        <span {...thElement.props} style={style}/>
      );
    }

    return thElement;

  }

  _export;
  export = () => {
    this._export.save();
  }

  render() {
    
    var ports = [];

    if(Array.isArray(this.props.portfolios))
       ports = this.props.portfolios.slice();

    return(
        <div className='groupAppraisal'>
                  <ExcelExport data={this.state.appraisalData} fileName={'GroupAppraisal.xlsx'} ref={(exporter) => { this._export = exporter; }}>
            <Grid data={this.state.result}
                className='noGroupGridLines'
                cellRender = {this.cellRender}
                headerCellRender = {this.headerCellRender}
                onExpandChange={this.expandChange}
                expandField="expanded"
                groupable={{footer:'always'}}
                resizable={true}
                reorderable={true}
                onDataStateChange={this.changeDataState}
                {...this.state.dataState}>
                 <GridNoRecords>
                  {this.state.loading && 'Loading...'}
                  {!this.state.loading && 'No records available'}
                </GridNoRecords>
                <GridToolbar>
                {this.state.styleSettings.chartShowAsOf && <>
                  <label>As Of: </label><select onChange={(e)=>{this.setState({selectedDate:e.target.value}); this.getAppraisalData(e.target.value)}}>
                      {this.state.dates.map((date, i)=>(
                      <option value={date.fDate}>{date.fDate}</option>
                    ))}
                  </select>
                </>}
                {!this.state.styleSettings.chartShowAsOf && <label>As Of: {this.state.selectedDate}</label>}
                <Button icon="excel" title='Export to Excel' onClick={this.export}>Export</Button>
                </GridToolbar>
                <GridColumn headerClassName='gridHeader' width='95' field="securitySymbol" title="Symbol" />
                <GridColumn headerClassName='gridHeader' width='200' field="securityName" title="Name" />
                <GridColumn headerClassName='gridHeader' width='200' field="assetAllocation" title="Asset Allocation" />
                <GridColumn headerClassName='gridHeader' width='105' field="sector" title="Sector" />
                <GridColumn headerClassName='gridHeader' width='125' field="total" title="Total" format='{0:c}'/>
                {this.props.portfolios && 
                ports.map((port, i)=>(
                    <GridColumn key={i} headerClassName='gridHeader' width='150' field={port.displayName} title={port.displayName} format='{0:c}'/> 
                ))}
            </Grid>
            </ExcelExport>
            {this.state.loading && <GridLoading />}
        </div>
    );
  }


}

export default GroupAppraisal;