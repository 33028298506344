import React, { Component } from "react";
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import axios from 'axios';
import { process } from '@progress/kendo-data-query';
import GridLoading from '../../Loaders/GridLoading';
import { Button } from "@progress/kendo-react-buttons";
import RenameAccount from './RenameAccount';

class AdvisorAccountList extends Component {

  constructor(props) {
    super(props);
    this.state = {
        mappings:[],
        result:[],
        loading:true,
        dataState:{skip:0, take:25},
        styleSettings:{
          advAllUsers: null
        },
    }

    this.getAccountMappings=this.getAccountMappings.bind(this);
    this.viewPortCell=this.viewPortCell.bind(this);
  }

  componentDidMount(){
    this.getStyleSettings(() => {
      this.getAccountMappings();
    });
  }

  getStyleSettings(cb) {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      'advAllUsers'
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
      .then(response => {
        this.setState({ styleSettings: response.data.styleSettings }, () => {
          cb()
        });
      })
      .catch(err => {
        console.error(err);
      })


  }

  getAccountMappings(){
    this.setState({loading:true});
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var advisor = '&advisor='+this.props.user.user;
    if((this.state.styleSettings.advAllUsers || this.props.user.role==='admin') && this.props.user.role !== 'user')
      advisor = '';

    axios.get('api/accountPortList?company='+this.props.user.company+advisor, {headers}).then(response=>{
      var result = process(response.data.mappings, this.state.dataState);
      this.setState({mappings:response.data.mappings, result:result, loading:false});
    }).catch(err=>{
      console.log(err);
      this.setState({mappings:[], loading:false});
    });
  }

  changeDataState = (e)=>{
    var newData = process(this.state.mappings, e.dataState);
    this.setState({dataState:e.dataState, result:newData});
  }

  viewPortCell(cellProps){
    
    if (cellProps.dataItem.email ==='UNMAPPED')
    {
      return(
        <td>
          <Button className='docButton' onClick={(e)=>this.props.previewUnmapped(cellProps.dataItem.account)}>Preview UnMapped</Button>
          {this.props.user.role==='admin' && <RenameAccount refresh={this.getAccountMappings} user={this.props.user} idToken={this.props.idToken} oldPort={cellProps.dataItem.account} button={<Button className='docButton'>Rename Account</Button>}/>}
        </td>
      );
    }
    else
    {
      return(
        <td>
          <Button className='docButton' onClick={(e)=>{this.props.onAccountClick(cellProps)}}>View Account</Button>
          {this.props.user.role==='admin' && <RenameAccount refresh={this.getAccountMappings} user={this.props.user} idToken={this.props.idToken} oldPort={cellProps.dataItem.account} button={<Button className='docButton'>Rename Account</Button>}/>}
        </td>
      );
  
    }

    
  }





  render() {
    return(
      <div className='advAcctMappingList'>
        <Grid data={this.state.result}
              filterable={true}
              sortable={true}
              pageable={{pageSizes:[10,25,50,100,250,500]}}
              onDataStateChange={this.changeDataState}
              {...this.state.dataState}>
            <GridColumn headerClassName='gridHeader' field='email' title='Client' />
            <GridColumn headerClassName='gridHeader' field='account' title='Account' />
            <GridColumn headerClassName='gridHeader' field='displayName' title='Display Name' />
            <GridColumn headerClassName='gridHeader' filterable={false} cell={(props) => this.viewPortCell(props)} />
        </Grid>
        {this.state.loading && <GridLoading />}
      </div>
    );
  }

}

export default AdvisorAccountList;