import React, { Component } from "react";
import {Grid, GridColumn, GridToolbar} from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import axios from 'axios';
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import MapUserHHPopUp from './MapUserHHPopUp';

class HouseholdList extends Component {

    constructor(props) {
    super(props);

    this.state = {
        company:this.props.company,
        households:[],
        householdsAll:[],
        result:[],
        loading:false,
        dataState:{sort:[{field:'household', dir:'asc'}]},
        refreshText:''
      }
      
      this.getHouseholdMappings=this.getHouseholdMappings.bind(this);
      this.clearState=this.clearState.bind(this); 
      this.onCloseModal=this.onCloseModal.bind(this); 
      this.refresh = this.refresh.bind(this);
      this.getHouseholdsAll = this.getHouseholdsAll.bind(this);
  }


  componentDidMount(){
    this.getHouseholdMappings();
    this.getHouseholdsAll();
  }

  
  componentWillUnmount() {
    this.clearState();
  }
  
  refresh(){
    this.getHouseholdMappings();
  }


  getHouseholdsAll(){
    var user = this.props.user;
    const headers = { 'authorization': 'Bearer '.concat(user.idToken) };
    
    axios.get("api/households?company="+this.props.user.company, {headers}).then(response => {
      if(response.data.code===200){
        this.setState({householdsAll:response.data.households});
      }
      else{
        alert("An error occured while fetching the Household list.");
        this.setState({householdsAll:[]});
      }
    }).catch(error => console.log(error));

  }

  getHouseholdMappings(){
    var user = this.props.user;
    const headers = { 'authorization': 'Bearer '.concat(user.idToken) };
    this.setState({loading:true, refreshText:'Loading...'});

    var payload ={
      company:this.props.user.company,
      email:this.props.user.email
    }

    axios.post('api/userGetHouseholds' , payload , {headers}).then(response=>{
      var result = process(response.data.households, this.state.dataState);
      this.setState({households:response.data.households,result:result,  loading:false ,refreshText:''});
    }).catch(err=>{
      console.log(err);
      this.setState({mappings:[], loading:false, refreshText:''});
    });
  }


  clearState(){
    //this.setState({selectedRole:{text:this.props.user.role}, views:this.props.user.access});
    //this.setState({mappings:[]});
  }


  createCell(cell){
    if(cell.rowType==='data'){
      return (
          <div>
          <Button icon="trash" onClick = {(event) => this.deleteHouseholdMapping(event, cell)}>Delete Mapping?</Button>
          </div>

      );
    }
    else{
      return (
        <></>
      );
    }
  }



  deleteHouseholdMapping(e, cell){
    var households = this.state.households.slice();
    var index = households.findIndex(x=>{
      return (x.household===cell.dataItem.household);
    });

    //verify deletion
    const confirm = window.confirm("Are you sure you want to delete this Mapping? (" + cell.dataItem.household + ")" );

    if(confirm===true){
      var user = this.props.user;
      const headers = { 'authorization': 'Bearer '.concat(user.idToken) };
      var payload = {
          household:cell.dataItem.household,
          company:this.props.user.company,
          email:this.props.user.email
        }


      axios.post('api/deleteUserHouseholdMapping', payload, {headers}).then(response=>{
          if(response.data.code!==201){
              alert("Error: Mapping could not be deleted.");
          }
          else{      
            if(index>=0){
                alert('Household/User Mapping deleted successfully');
                this.getHouseholdMappings();
            }

          }
      });
    }
  }

  onCloseModal = () => {
    this.clearState();
  }


  changeDataState = (e)=>{
    var newData = process(this.state.households, e.dataState);
    this.setState({dataState:e.dataState, result:newData});
  }


  render() {
    return(
    
        <Popup closeOnDocumentClick={false} trigger={this.props.button} onClose={(event) => {this.clearState() }} overlayStyle={{ zIndex: 5 }}  modal>
          {close => (
            <div className="modal">
            <Button className="close" icon="close" onClick={(e)=>{close();}} />


            <div className="header" align="left">
                Edit Household Accounts: <strong>{this.props.user.nickname}</strong>
              </div>

            <div className='houseMappingList'>
                <Grid data={this.state.result}
                    sortable={true}
                    onDataStateChange={this.changeDataState}
                    {...this.state.dataState}>

                <GridToolbar>
          <strong>Current Households Mapped</strong>
                
                <Button icon='refresh' title='Refresh' onClick={(e)=>this.getHouseholdMappings()}>{this.state.refreshText}</Button>
                
                <MapUserHHPopUp user={this.props.user} households={this.state.householdsAll} refresh={this.refresh} idToken={this.props.idToken} company={this.props.user.company} email={this.props.user.email} button={<Button className='docButton'>Add Mapping</Button>}/>

                </GridToolbar>
                    <GridColumn headerClassName='gridHeader' width='500' field='household' title='Household'/>
                    <GridColumn headerClassName='gridHeader' width='100'  editable={false} cell={(props) => this.createCell(props)}/>
                </Grid>
            </div>

            
            <div className="actions">              
                <Button
                  className="button"
                  onClick={() => {close();}}>
                  Close
                </Button>

              </div>
        
        
              </div>
          )}
        </Popup>

    );
  }

}


export default HouseholdList;