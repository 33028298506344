import React, { Component } from "react";
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import axios from 'axios';
import { Button } from "@progress/kendo-react-buttons";
import DetailRow from './components/DetailRow';
import AddRoles from './components/AddRoles';
import GridLoading from '../Loaders/GridLoading';
import { process } from '@progress/kendo-data-query';
import { parseDate } from '@telerik/kendo-intl';

const ADJUST_PADDING = 4;
const COLUMN_MIN = 4;

class RoleList extends Component {

    constructor(props) {
        super(props);

        this.minGridWidth = 0;

        var columns = [{ field: 'role', title: 'Role', headerClass: 'gridHeader', minWidth: 150 },
        { field: 'description', title: 'Description', headerClass: 'gridHeader', minWidth: 150 },
        { field: 'active', title: 'Active', filterable: false, cell: (p) => this.createCell(p, 'active'), headerClass: 'gridHeader', minWidth: 125 },
        { field: 'status', title: 'Status', filterable: false, cell: (p) => this.createCell(p, 'status'), headerClass: 'gridHeader', minWidth: 50 },
    ];

        // if (this.props.user.company === 'cssi') {
        //     columns.push({field: 'isGlobal', title: 'Global', filterable: false, cell: (p) => this.createCell(p, 'isGlobal'), headerClass: 'gridHeader', minWidth: 50})
        // } else {
        //     columns.push({field: 'isGlobal', title: 'Inherited', filterable: false, cell: (p) => this.createCell(p, 'inherited'), headerClass: 'gridHeader', minWidth: 50})
        // }

        this.state = {
            roles: [],
            result: [],
            refreshText: '',
            filterable: this.props.compSettings.showFilters,
            columns: columns,
            dataState: { skip: 0, take: 25, sort: [{ field: 'lastLogin', dir: 'desc' }] },
        }
    }

    componentDidMount() {
        this.getRoles(() => {
            this.setMinGridWidth();
        })
    }

    setMinGridWidth = () => {
        this.grid = document.querySelector('.k-grid');
        window.addEventListener('resize', this.handleResize);
        this.minGridWidth = 0;
        this.state.columns.map(item => this.minGridWidth += item.minWidth);
        if (this.state.dataState.group)
            this.minGridWidth += 32 * this.state.dataState.group.length;
        this.setState({
            gridCurrent: this.grid.offsetWidth,
            setMinWidth: this.grid.offsetWidth < this.minGridWidth
        });
    }

    handleResize = () => {
        if (this.grid.offsetWidth < this.minGridWidth && !this.state.setMinWidth) {
            this.setState({
                setMinWidth: true
            });
        } else if (this.grid.offsetWidth > this.minGridWidth) {
            this.setState({
                gridCurrent: this.grid.offsetWidth,
                setMinWidth: false
            });
        }
    }

    setWidth = (minWidth) => {
        let width = this.state.setMinWidth ? minWidth : minWidth + (this.state.gridCurrent - this.minGridWidth) / this.state.columns.length;
        if (width >= COLUMN_MIN)
            width = width - ADJUST_PADDING;
        return width;
    }

    getRoles = (cb) => {
        this.setState({ refreshText: 'Loading...' });

        var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios.get("api/roles?company=" + this.props.company, { headers }).then(response => {
            if (response.data.code === 200) {
                var formattedRoles = this.formatRoleList(response.data.roles);
                this.setState({ roles: formattedRoles, result: process(formattedRoles, this.state.dataState), refreshText: '' }, () => {
                    if (cb)
                        cb();
                });
            }
            else {
                if (cb)
                    cb();
                this.setState({ refreshText: '' });
                alert("An error occured while fetching the roles list.");

            }
        }).catch(error => console.log(error));
    }

    formatRoleList = (roleList) => {
        var listWithRoles;
        listWithRoles = roleList.slice();

        listWithRoles = listWithRoles.filter(role => role.userLevel >= this.props.user.userLevel || role.userLevel == null);

        for (let i = 0; i < listWithRoles.length; i++) {
            listWithRoles[i].company = this.props.company;
            listWithRoles[i].idToken = this.props.idToken;
            listWithRoles[i].refreshUser = this.props.refreshUser;
            listWithRoles[i].changeActivate = this.changeActivate;
            listWithRoles[i].changeRoleData = this.changeRoleData;
            listWithRoles[i].refreshList = this.getRoles;
            listWithRoles[i].loggedInAsAdvisor = this.props.user.role === 'advisor';
            listWithRoles[i].userId = this.props.user.id;
            listWithRoles[i].permissionsArray = this.props.user.permissions;
        }

        return listWithRoles;
    }

    getDate = (sqlDate) => {
        var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
        var utc = null;

        if (d !== null)
            utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes());

        return utc;
    }

    createCell = (cell, type) => {
        if (type === 'active') {
            if (cell.dataItem.active === true) {
                return (<td>Active</td>);
            }
            else {
                return (<td>Inactive</td>);
            }
        } else if (type === 'status') {
            if (cell.dataItem.isOverride) {
                return (<td>Override</td>);
            }
            else if (cell.dataItem.isGlobal) {
                return (<td>CSSI</td>);
            } else {
                return (<td></td>)
            }
        }
    }

    changeActivate = (guid, active) => {
        var arr = this.state.roles.slice();

        var indexOfRole = arr.findIndex((item) => {
            return item.guid === guid;
        });

        if (indexOfRole > -1) {
            arr[indexOfRole].active = active;
        }
        this.setState({ roles: arr });
    }

    changeRoleData = (email, newRole, newViews, approved) => {
        var arr = this.state.roles.slice();

        this.setState({ roles: arr });
    }

    expandChange = (event) => {
        const data = this.state.result.data.map(item => 
          item.guid === event.dataItem.guid
              ? {
                   ...item, 
                   expanded: !item.expanded,
                  } 
              : item
      );
        this.setState((prevState) => {
          let obj = {...prevState};
          obj.result.data = data;
          return obj;
        })
      };

    _export;

    export = () => {
        this._export.save();
    }

    changeDataState = (e) => {
        var newData = process(this.state.roles, e.dataState);
        this.setState({ dataState: e.dataState, result: newData });
    }

    render() {
        return (
            <div>
                {!this.state.groups && !this.state.logs && (
                    <div className="roleList">
                        <Grid data={this.state.result}
                            onRowClick={this.expandChange}
                            detail={DetailRow}
                            resizable={true}
                            reorderable={true}
                            pageable={{ pageSizes: [10, 25, 50, 100, 250, 500] }}
                            sortable={true}
                            onDataStateChange={this.changeDataState}
                            expandField="expanded"
                            onExpandChange={this.expandChange}
                            filterable={this.state.filterable}
                            {...this.state.dataState}>
                            <GridToolbar>
                                <Button icon='refresh' title='Refresh' onClick={(e) => this.getRoles(() => this.setMinGridWidth())}>{this.state.refreshText}</Button>
                                <Button icon='filter' title='Filter' onClick={(e) => this.setState({ filterable: !this.state.filterable })}>Filter</Button>
                                <AddRoles compSettings={this.props.compSettings} company={this.props.company} role={this.props.role} idToken={this.props.idToken} refreshRoles={this.getRoles}
                                    button={<Button icon="plus"> Create Role</Button>} newOrder={(1 + this.state.roles.length) * 10} />
                            </GridToolbar>
                            {
                                this.state.columns.map((column, key) => {
                                    return <GridColumn field={column.field} title={column.title} key={key} format={column.format} cell={column.cell} filterable={column.filterable} filter={column.filter} headerClassName={column.headerClass} width={this.setWidth(column.minWidth)} />
                                })
                            }
                        </Grid>
                        {this.state.refreshText && <GridLoading gridClass='roleList' />}
                    </div>)
                }
            </div>
        );
    }

}

export default RoleList;