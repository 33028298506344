
import React from 'react';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { Button } from "@progress/kendo-react-buttons";
import axios from 'axios';
import { withState } from './with-state';
import GridLoading from '../Loaders/GridLoading';
import ReportListPopup from './components/ReportListPopup.js';
import createFilterDropDownCell from './components/FilterDropDownCell';

const StatefulGrid = withState(Grid);

const cloneProduct = (product) => ({ ...product });

class ReportLanding extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //data: sampleProducts.map(cloneProduct),
            data: [],
            editField: undefined,
            reportSelected: false,
            reportSelect: '',
            changes: false,
            reportsLoading: true,

            disclaimersList: [],
            assetClassList: [],

            gridFilter: undefined,
            filterable: false,
            reportStyles: [],
            sort: [{ field: 'SortOrder', dir: 'asc' }]
        };

        //this.renderers = new Renderers(this.enterEdit, this.exitEdit, 'inEdit');
        this.getReportList = this.getReportList.bind(this);
        this.getDisclaimers = this.getDisclaimers.bind(this);
        this.getAssetClassDefaults = this.getAssetClassDefaults.bind(this);
    }

    componentDidMount() {
        this.getReportList();
        this.getDisclaimers()
        this.getAssetClassDefaults();

    }

    selectionChange(event, gFiles) {
        gFiles.forEach(item => item.selected = false);
        event.dataItem.selected = !event.dataItem.selected;
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ reportSelect: selectedFiles[0] });
        if (selectedFiles.length > 0) {
            this.setState({ reportSelected: true });
        }
        else {
            this.setState({ reportSelected: false });
        }
        this.forceUpdate();
    }

    headerSelectionChange(event, gFiles) {
        const checked = event.syntheticEvent.target.checked;
        gFiles.forEach(item => item.selected = checked);
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ reportSelect: selectedFiles[0] });
        if (selectedFiles.length > 0) {
            this.setState({ reportSelected: true });
        }
        else {
            this.setState({ reportSelected: false });
        }
        this.forceUpdate();
    }



    getDisclaimers() {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var disclaimers = []

        //axios
        //.post('api/ssrsGetReports', payload, { headers })
        axios(`api/ssrsGetDisclaimers`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers,
        })
            .then(response => {

                response.data.data.forEach(disclaimerInResponse => {
                    disclaimers.push(disclaimerInResponse.DisclaimerCode)
                })
                this.setState({ disclaimersList: disclaimers })
            })
            .catch(err => {
                console.error(err);
            })
    }

    getAssetClassDefaults() {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios(`api/ssrsAssetClassDefaults`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers,
        })
            .then(response => {
                var assetClasses = []

                response.data.data.forEach(assetClassInResponse => {
                    //assetClasses.push(assetClassInResponse.AssetClassCode)
                    assetClasses.push(assetClassInResponse.label)
                })
                //this.setState({ assetClassList: assetClasses})
                this.setState({ assetClassList: assetClasses })
            })
            .catch(err => {
                console.error(err);
            })
        //return reports;

    }

    getReportList() {
        this.setState({ refreshText: 'Loading...', reportSelected: false, reportSelect: '', });
        var reports = [];
        var reportStyles = [];
        var reportStylesUnique = [];

        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        //axios
        //.post('api/ssrsGetReports', payload, { headers })
        axios(`api/ssrsGetStandardReportList`, {
            method: 'GET',
            params: { database: this.props.user.company },
            headers: headers,
        })
            .then(response => {


                response.data.data.forEach(reportInResponse => {
                    //if (reportInResponse.Path.includes('/ExampleSSRSProject')) {
                    //    standardOrCustom = "Standard Report"
                    //}
                    //else {
                    //    standardOrCustom = "Custom Report"
                    //}
                    //
                    //var name = reportInResponse.name
                    //if (reportInResponse.name.includes("_Landscape") || reportInResponse.name.includes("_Portrait")) {
                    //    splitName = reportInResponse.name.split('_')
                    //    if (!reportNames.includes(splitName[0])) {
                    //        reportNames.push(splitName[0]);
                    //        name = splitName[0]
                    //        //reports.push({ name: splitName[0], key: reportInResponse.key, adjustableLayout: true, type: standardOrCustom })
                    //    }
                    //}
                    //else {
                    //    name = reportInResponse.name
                    //    //reports.push({ name: name, key: reportInResponse.key, type: standardOrCustom })
                    //}
                    //reports.push({ name: name, key: reportInResponse.key, type: standardOrCustom })

                    if(reportStylesUnique.indexOf(reportInResponse.reportStyle) === -1) {
                        reportStyles.push({text: reportInResponse.reportStyle});
                        reportStylesUnique.push(reportInResponse.reportStyle);
                    }

                    reports.push({
                        reportName: reportInResponse.reportName,
                        reportStyle: reportInResponse.reportStyle,
                        description: reportInResponse.description,
                    })


                })
                //var sort=[{ field: 'type', dir: 'asc' }, { field: 'reportName', dir: 'asc' }]
                this.setState({ data: reports.map(cloneProduct), refreshText: '', reportStyles: reportStyles})

            })
            .catch(err => {
                console.error(err);
                this.setState({ refreshText: '' });
            })
    }



    saveChanges = () => {
        this.state.reports.splice(0, this.state.reports.length, ...this.state.data);
        this.setState({
            editField: undefined,
            changes: false
        });
    }

    openPopup = () => {
        this.state.reports.splice(0, this.state.reports.length, ...this.state.data);
        this.setState({
            editField: undefined,
            changes: false
        });
    }

    cancelChanges = () => {
        this.setState({
            data: this.state.reports.map(cloneProduct),
            changes: false
        });
    }

    itemChange = (event) => {
        event.dataItem[event.field] = event.value;
        this.setState({
            changes: true
        });
    }

    onFilterChange = (e) => {
        this.setState({ gridFilter: e.filter });
    }

    RunCell = props => (
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px"
          }}>
              <Button >
                  Run
              </Button>
          </div>
        
    );

    MagnifyCell = props => (
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px"
          }}>
              <Button icon='zoom'/>
          </div>
        
    );

    render() {
        return (
            <div className="userList">
                <StatefulGrid
                    //style={{ height: '420px' }}
                    selectedField='selected'
                    onSelectionChange={(e) => this.selectionChange(e, this.state.data)}
                    onRowClick={(e) => this.selectionChange(e, this.state.data)}
                    onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.data)}
                    data={this.state.data}
                    rowHeight={50}
                    //onItemChange={this.itemChange}
                    filterable={true}
                    //resizable
                    sortable={{
                        mode: 'multiple'
                    }}
                    sort={this.state.sort}
                    //cellRender={this.renderers.cellRender}
                    //rowRender={this.renderers.rowRender}

                    editField="inEdit"
                >
                    <GridToolbar>
                        <ReportListPopup closeRefresh={this.getReportList} idToken={this.props.idToken} company={this.props.company} user={this.props.user} uploadUser={this.props.uploadUser} report={this.state.reportSelect} disclaimers={this.state.disclaimersList} equityAssetClass={this.state.assetClassList} loading={this.state.editScreenLoading} /*onReportListChange={this.onReportListChange}*/ button={<Button icon='edit' disabled={!this.state.reportSelected} className="docButton">Edit Default Values</Button>} />
                    </GridToolbar>
                    <GridColumn filterable={false} width='60px' cell={this.RunCell}  />
                    <GridColumn field="reportStyle" title="Report Style" width="150px" filterable={true} filterCell={createFilterDropDownCell(this.state.reportStyles, '')} />
                    <GridColumn field="reportName" title="Report Name" width="250px" filterable={false} />
                    <GridColumn field="description" title="Report Title"  filterable={false} />
                    <GridColumn filterable={false} width='60px' cell={this.MagnifyCell}  />
                </StatefulGrid>
                {this.state.refreshText && <GridLoading gridClass='packageReview' />}
            </div>
        );
    }

}

//ReactDOM.render(
//    <App />,
//    document.querySelector('my-app')
//);

export default ReportLanding