import React, { Component } from "react";
import {Grid, GridColumn, GridToolbar, GridNoRecords} from '@progress/kendo-react-grid';
import axios from 'axios';
import { process } from '@progress/kendo-data-query';
import GridLoading from '../../Loaders/GridLoading';
import { Input } from '@progress/kendo-react-inputs';
import HouseholdDetail from './HouseholdDetail';
import { Button } from '@progress/kendo-react-buttons';
import * as utils from '../../utilities'

class HouseholdList extends Component {

  constructor(props) {
    super(props);

    var pageSizes = [10,25,50,100,250,500];

    var defaultPerPage = parseInt(500);
    defaultPerPage = parseInt(this.props.styleSettings.hhPerPage);

    if(pageSizes.indexOf(defaultPerPage)<0){
      var i=0;
      while(i<pageSizes.length && defaultPerPage>pageSizes[i]){
        i++;
      }
      pageSizes.splice(i,0,defaultPerPage);
    } 

    this.state = {
        data:[],
        groupedData:[],
        result:[],
        searchTerm:'',
        loading:true,
        pageSizes:pageSizes,
        asOf:'',
        dataState:{skip:0, take:defaultPerPage, group:undefined, filter:undefined, sort:undefined},
        groupDataState:{group:[{field:"household"}]},
        groupDataStateWithMV:{group:[{field:"household", aggregates:[{field:'marketValue', aggregate:'sum'}]}]},
        styleSettings:{
          columnLabelHouseholdColumn: ' ',
          // advAllUsers: null,
        },
        showHouseholdName: null,
        householdListShowMV: null,
        householdListSearchCapacity: 2500
    }

    this.getHouseholds=this.getHouseholds.bind(this);
  }

  componentDidMount(){
    this.getShowHouseholdNameSetting()
  }

  componentDidUpdate(){
  }


  getShowHouseholdNameSetting() {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios(`api/superAdminSettings`, {
        method: 'GET',
        params: { company: this.props.user.company },
        headers: headers
    })
        .then(response => {
            let obj = {...this.state};
            response.data.data.forEach(setting => {
                if (setting.settingName === 'showHouseholdName'){
                    obj.showHouseholdName = setting.settingValue.toLowerCase() === 'true' ? true : false;
                }
                if (setting.settingName === 'householdListShowMV'){
                    obj.householdListShowMV = setting.settingValue.toLowerCase() === 'true' ? true : false;
                }
                if (setting.settingName === 'householdListSearchCapacity'){
                    obj.householdListSearchCapacity = parseInt(setting.settingValue);
                }
                
            })
            this.setState(obj,() => {
                this.getStyleSettings(() => {
                    this.getHouseholds();
                })
            })

        })
        .catch(err => console.log(err));
}

  getStyleSettings(cb) {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      'columnLabelHouseholdColumn',
      'householdListDisplayCloseDate',
      // 'advAllUsers',
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
      .then(response => {
         

        this.setState({ styleSettings: response.data.styleSettings }, () => {
          cb()
        });
      })
      .catch(err => {
        console.error(err);
      })


  }

  getHouseholds(){
    this.setState({loading:true});
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var advisor = '&advisor='+this.props.user.user;
    var includeMV = '&includeMV=0'
    if (this.state.householdListShowMV){
      includeMV = '&includeMV=1'
    }
    var includeHouseholdName = '&includeHouseholdName=0'
    if (this.state.showHouseholdName && utils.isUser(this.props.user)){
      includeHouseholdName = '&includeHouseholdName=1'
    }
    
    // if((this.state.styleSettings.advAllUsers || this.props.user.role==='admin') && this.props.user.role !== 'user')
    if(utils.checkPermission(this.props.user,"Access All Households"))
      advisor = '';

    axios.get('api/householdInfo?company='+this.props.user.company+advisor+includeMV+includeHouseholdName, {headers}).then(response=>{
      var date = '';

      for(let x=0; x<response.data.data.length; x++){
        if(response.data.data[x].date!==null && date==='')
          date = response.data.data[x].date;
        if (response.data.data[x].account!= null && response.data.data[x].account !== '' && (this.props.user.role === 'advisor' || this.props.user.role === 'admin')) response.data.data[x].displayName += (' - ' +response.data.data[x].account)
        response.data.data[x].onAcctClick=this.props.onAccountClick;
      }

      var result = null
      if (this.state.householdListShowMV){
        result = process(response.data.data, this.state.groupDataStateWithMV);
      }
      else{
        result = process(response.data.data, this.state.groupDataState);
      }

      var groupedData = [];
      var total;
      var householdName;
      var closedCount = 0;
      var highestCloseDate = null;
      var closed = false;

      for(let i=0; i<result.data.length; i++){
        total = null;
        householdName = null
        closedCount = 0
        highestCloseDate = null;
        closed = false;

        for(let k=0; k<1; k++){
          householdName = result.data[i].items[k].householdName;
        }

        if (this.state.householdListShowMV){
          for(let k=0; k<result.data[i].items.length; k++){
            if(result.data[i].items[k].marketValue)
              total = total + result.data[i].items[k].marketValue;
            if(result.data[i].items[k].closeDate){
              if (this.state.styleSettings.householdListDisplayCloseDate){
                closedCount++;
                if (highestCloseDate){
                  if (new Date(highestCloseDate) < new Date(result.data[i].items[k].closeDate))
                    highestCloseDate = result.data[i].items[k].closeDate
                }
                else{
                  highestCloseDate = result.data[i].items[k].closeDate
                }
              }
              else {
                result.data[i].items[k].closeDate = null
              }
            }
          }
        }
        result.data[i].items = result.data[i].items.map(item => {
            let retObj = {...item};
            retObj.displayName = retObj.displayName.replace(' - '+retObj.account,'');
            return retObj;
        })

        if (this.state.styleSettings.householdListDisplayCloseDate){
          if (closedCount === result.data[i].items.length){
            total=highestCloseDate;
            closed=true;
          }
        }
        groupedData.push({household:result.data[i].value, householdName: householdName, houseMarketValue:total, closed: closed, details:result.data[i].items, expanded:false, date:date, includeMV: this.state.householdListShowMV});
      }

      result = process(groupedData, this.state.dataState);
      //result = {data:groupedData, total:groupedData.length};
      result = this.addPropertyToItems(result);
      this.setState({data:response.data.data, result:result, groupedData:groupedData, loading:false, asOf:date});
    }).catch(err=>{
      console.log(err);
      this.setState({data:[], loading:false});
    });
  }

  changeDataState = (e)=>{
    var newData = process(this.state.groupedData, e.dataState);
    newData = this.addPropertyToItems(newData);
    this.setState({dataState:e.dataState, result:newData});
  }

  // expandChange = (event) => {
  //   event.dataItem[event.target.props.expandField] = event.value;
  //   this.forceUpdate();
  // }

  onChangeFilter = (e) =>{
    this.setState({
        searchTerm: e != null ? e.target.value : this.state.searchTerm
    },() => {
    if (this.state.data.length < this.state.householdListSearchCapacity || e == null || e.target.value === '') {

        var filter = {
            logic:"or", 
            filters:[
                {field:"household", operator:"contains", value:this.state.searchTerm},
                {field:"account", operator:"contains", value:this.state.searchTerm},
                {field:"displayName", operator:"contains", value:this.state.searchTerm},
            ],
            group:"household"
        };
        var dataState = this.state.dataState;
        dataState.filter = filter;
        // var result = process(this.state.groupedData, dataState);

        // this replaces the kendo process method to mutate the groupedData into a new result
        // note that it is not as extensive as the process method, and assumes the field operator is always "contains" and logic is "or"
        var result = JSON.parse(JSON.stringify(this.state.groupedData)); // equates to a deep object copy
        result = result.filter(row => {
            let rowBool = false;
            // if we find any of the search filter columns in the top level of the row, make sure it's included in the final result
            dataState['filter']['filters'].forEach(filter => {
                if (row[filter.field] != null && String(row[filter.field]).toLowerCase().indexOf(String(this.state.searchTerm).toLowerCase()) > -1) {
                    rowBool = true;
                }
            })
            // if the top level is included, we'll include all the detail rows; otherwise, we'll filter those down to our search params
            let detailsRows = (rowBool) ? row.details : [...row.details].filter(detailRow => {
                // loop through all the detail rows, if we find any of the search filter columns at this level, make sure we show them in the final result
                let rowDetailBool = false;
                dataState['filter']['filters'].forEach(filter => {
                    if (detailRow[filter.field] != null && String(detailRow[filter.field]).toLowerCase().indexOf(String(this.state.searchTerm).toLowerCase()) > -1) {
                        rowDetailBool = true;
                    }
                })
                return rowDetailBool;
            });
            detailsRows = detailsRows.map(row => { return {...row,onAcctClick: this.props.onAccountClick}});
            // if the top level was not included but detail rows were, expand the grid automatically
            if (!rowBool && detailsRows.length > 0) {
                rowBool = true;
                row.expanded = true;
            }
            // replace the detail rows with our filtered results (if applicable)
            row.details = detailsRows;
            
            return rowBool;
        })
        this.setState({result:{data: result, total: result.length}, dataState:dataState, loading: false});
    }
    })
  }

  CellRender = (tdElement, cellProps) => {
    var val;
    var style={cursor:'pointer'};

    if(tdElement!==null){
      if(cellProps.field==='houseMarketValue'){
          style={cursor:'pointer', textAlign:'right'};
          val = cellProps.dataItem[cellProps.field];
          if(val===null)
            val = '-';
          else if(isNaN(val)){
            if (cellProps.dataItem['closed']){
              val = 'closed ' + val
            }
          }
          else
            val = this.formatCurrency(val);

        return(<td {...tdElement.props} style={style}>{val}</td>);
      }

      return(
        <td {...tdElement.props} style={style}/>
      );
    }

    return tdElement;
  }

  headerCellRender = (thElement, cellProps) => {
    if(thElement!==null){
      var style;

      if(cellProps.field==='houseMarketValue'){
        style={justifyContent:'right'};
        return(<div {...thElement.props} style={style}>
          <span className="k-link !k-cursor-default">
            <span className="k-column-title" style={style}>
              Market Value As Of: {this.state.asOf}
            </span>
          </span>
        </div>)
      }

      return(
        <div {...thElement.props} style={style}/>
      );
    }

    return thElement;
  }

  formatCurrency = (val) =>{
    var formatted = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(val);
    return formatted;
  }

  expandChange = (event) => {
    const expandField = event.target.props.expandField;
    const itemId = event.dataItem.id;

    const updateItemsRecursive = (items) => {
      return items.map(item => {
        let updatedItem = item;
        if (item.id === itemId) {
          updatedItem = {
            ...item,
            [expandField]: !item[expandField],
          };
        }
        if (Array.isArray(item.items)) updatedItem.items = updateItemsRecursive(item.items);
        return updatedItem;
      });
    };

    const updatedData = updateItemsRecursive(this.state.result.data);

    this.setState((prevState) => ({
      ...prevState,
      result: {
        ...prevState.result,
        data: updatedData
      }
    }));
  }

  addPropertyToItems = (obj) => {
    // Usage: (call in both initial data set and prior to any datagrid result state change)
    // result = this.addPropertyToItems(result);

    const addPropertyToNestedItems = (item) => {
      item.id = Math.random();
      item.expanded = false;

      // Recursively call this function for each item in the 'items' array
      if (Array.isArray(item.items)) item.items.forEach(subItem => addPropertyToNestedItems(subItem));
      return item;
    }

    // Traverse the array and add properties
    if (Array.isArray(obj.data)) obj.data.forEach(item => addPropertyToNestedItems(item));
    return obj;
  }

  render() {
    return(
      <div className='householdList'>   
        <Grid data={this.state.result}
            pageable={{pageSizes:this.state.pageSizes}}
            expandField='expanded'
            onExpandChange={this.expandChange}
            onDataStateChange={this.changeDataState}
            detail={HouseholdDetail}
            cellRender={this.CellRender}
            headerCellRender={this.headerCellRender}
            onRowClick={(e) => this.props.onAccountClick(e)}
            {...this.state.dataState}>
            <GridNoRecords>
              {this.state.loading && 'Loading...'}
              {!this.state.loading && 'No records available'}
            </GridNoRecords>
            {this.state.data.length > 0 && <GridToolbar>
              <div style={{width: '100%'}}>
                <label>Search: </label><Input style={{width:'25%'}} value={this.state.searchTerm} onChange={(e)=> this.onChangeFilter(e)}/>
                {this.state.data.length >= this.state.householdListSearchCapacity && <>&nbsp;<Button title='Search' disabled={this.state.loading || this.state.searchTerm.length <= 2} onClick={() => this.setState({loading: true},()=>this.onChangeFilter())}>Search</Button></>}
                {this.state.searchTerm !== '' && <>&nbsp;<Button title='Clear' onClick={()=> this.onChangeFilter({target: {value: ''}})}>Clear</Button></>}
              </div>
            </GridToolbar>}
            {(!this.state.showHouseholdName || (this.state.showHouseholdName && !utils.isUser(this.props.user))) && <GridColumn headerClassName='gridHeader' field='household' title={this.state.styleSettings.columnLabelHouseholdColumn} />} 
            {(this.state.showHouseholdName && utils.isUser(this.props.user)) && <GridColumn headerClassName='gridHeader' field='householdName' title={this.state.styleSettings.columnLabelHouseholdColumn} />}
            {this.state.householdListShowMV && <GridColumn headerClassName='gridHeader' field='houseMarketValue' title='Market Value'/>}
        </Grid>
        {this.state.loading && <GridLoading />}
      </div>
    );
  }

}

export default HouseholdList;