import React from 'react';
import axios from 'axios';
import {
  Chart, ChartTooltip, ChartSeries,
  ChartSeriesItem, ChartCategoryAxis,
  ChartCategoryAxisItem, ChartValueAxis, ChartValueAxisItem, ChartLegend
} from '@progress/kendo-react-charts';
import { formatNumber } from '@telerik/kendo-intl';
import loading from '../../Callback/loading.svg';

class PerformanceOverview extends React.Component {

  constructor(props) {
    super(props);

    const valObj = this.getValues(this.props.width);

    this.state = {
      data: [],
      labels: [],
      loading: true,
      dates: [],
      selectedDate: '',
      col1: valObj.col1,
      col2: valObj.col2,
      perfType: 'net',
      graphHeight: valObj.graphHeight,
      dataState: {
        group: [{ field: 'tranCode', aggregates: [{ field: 'tradeAmount', aggregate: 'sum' }, { field: 'tranName', aggregate: 'max' }] }],
        sort: [{ field: 'tradeDate', dir: 'desc' }]
      },
      styleSettings: null,
      date: this.props.date,
      min: 0,
      irr: false,
      fontFamily: null,
      chartFontFamily: null
    };

    this.options = {
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            padding: 2
          }
        },
        tooltip: {
          // Customize tooltip (function format may differ from Kendo's)
        }
      },
      scales: {
        y: {
          // title: {
          //   display: true,
          //   text: this.state.returnText
          // },
          min: this.state.min
        },
        x: {
          // Configure if needed
        }
      },
      maintainAspectRatio: false
    };

    this.maxResults = 9;

  }

  getValues = (width) => {
    let col1 = 0, col2 = 0, graphHeight = 0;
    switch (width) {
      case 4:
        col1 = 12; col2 = 12; graphHeight = 350;
        break;
      case 6:
        col1 = 12; col2 = 12; graphHeight = 350;
        break;
      case 12:
        col1 = 12; col2 = 12; graphHeight = 350;
        break;
      default:
    }
    return { col1: col1, col2: col2, graphHeight: graphHeight }
  }

  componentDidMount() {
    this.getStyleSettings(() => {
      this.props.callback((width) => {
        const CBvalObj = this.getValues(width);
        this.setState({
          graphHeight: CBvalObj.graphHeight,
          col1: CBvalObj.col1,
          col2: CBvalObj.col2
        })
      });
      if (this.props.onWidgetLoad) {
        this.props.onWidgetLoad();
        this.props.onAddCallback((result, err) => {
          this.setState({ loading: true }, () => {
            this.onSubmit();
          })
        })
      }
    });
  }

  // componentDidUpdate() {
  //   if (this.props.portfolio !== this.state.portfolio || this.props.date !== this.state.date || this.props.perfType !== this.state.perfType || this.props.returnType !== this.state.returnType) {

  //     var returnText = "Time Weighted Return";
  //     if (this.props.returnType === 'irr')
  //       returnText = "IRR";
  //     this.setState({ portfolio: this.props.portfolio, date: this.props.date, /*perfType: this.props.perfType,*/ returnType: this.props.returnType, returnText: returnText }, () => {
  //       // this.getTWRs()
  //     });
  //   }
  // }

  getStyleSettings(cb) {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      'chartDefaultFees',
      'chartColors',
      'pieChartColors',
      'perfHistIrr',
      'themeFontFamily',
      'themeChartFont'
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
      .then(response => {
        // var CompLayoutText = "Grid Layout";
        // if (response.data.styleSettings.docGrid)
        //   CompLayoutText = "Folder Layout";

        var chartColors = []

        if (response.data.styleSettings.chartColors) {
          response.data.styleSettings.chartColors.split('|').forEach((colorLabelPair, i) => {
            var c = colorLabelPair.split('<')
            chartColors.push({ color: c[0], type: null, mapping: null })
          });
        }

        if (response.data.styleSettings.pieChartColors) {
          response.data.styleSettings.pieChartColors.split('|').forEach((colorLabelPair, i) => {
            var c = colorLabelPair.split('<')
            chartColors.push({ color: c[0], type: 'pie', mapping: c[1] })
          });
        }
        this.setState({
          styleSettings: response.data.styleSettings,
          perfType: (response.data.styleSettings.chartDefaultFees === 'Gross' ? 'gross' : 'net'),
          colors: chartColors,
          irr: response.data.styleSettings.perfHistIrr,
          fontFamily: response.data.styleSettings.themeFontFamily,
          chartFontFamily: response.data.styleSettings.themeChartFont
        }, () => {
          cb()
        });
      })
      .catch(err => {
        console.error(err);
      })
  }

  renderTooltip = ({ point }) => {
    if (point !== undefined) {
      return (
        <div><strong>{point.dataItem.accountPortfolio}</strong><div>{formatNumber(point.value, "0.00 '%'")}</div></div>
      );
    }
    else {
      return (<div></div>);
    }
  }

  onSubmit = () => {
    // logic to get data from APIs
    this.getPerfDates(() => {
      this.getTWRs();
    });
  }

  getPerfDates(cb) {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company: this.props.user.company,
      email: this.props.user.user,
      port: null, // this.props.selectedHousehold != null && this.props.selectedHousehold.details != null && this.props.selectedHousehold.details.length > 0 ? this.props.selectedHousehold.details[0].account : null,
      household: this.props.selectedHousehold != null && this.props.selectedHousehold.details != null && this.props.selectedHousehold.details.length > 0 ? this.props.selectedHousehold.details[0].household : null,
      perfType: this.state.perfType
    }
    axios.post('api/perfDates', payload, { headers }).then(response => {
      if (response.data.code === 200) {
        if (response.data.data.length > 0) {
          var selectedDate = /*this.state.selectedDate != null && this.state.selectedDate !== '' ? this.state.selectedDate :*/ response.data.data[0].fDate
          this.setState({ dates: response.data.data, selectedDate: selectedDate },
            () => {
              if (cb != null) cb();
            });
        }
        else
          this.setState({ dates: [] },
            () => {
              if (cb != null) cb();
            });
      }
    }).catch(err => {
      console.log(err);
    });
  }

  getTWRs() {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var unmapped = '';

    if (this.props.user.unmapped)
      unmapped = 'true';

    var payload = {
      company: this.props.user.company,
      port: this.props.selectedHousehold != null && this.props.selectedHousehold.details != null && this.props.selectedHousehold.details.length > 0 ? this.props.selectedHousehold.details[0].account : null,
      household: this.props.selectedHousehold != null && this.props.selectedHousehold.details != null && this.props.selectedHousehold.details.length > 0 ? this.props.selectedHousehold.details[0].household : null,
      date: this.state.dates.length > 0 ? this.state.dates[0].date : '',
      user: this.props.user.user,
      perfType: this.state.perfType,
      irr: this.state.irr,
      unmapped: unmapped
    }

    var periodsWithData = []

    axios.post('api/perfOverviewWidget', payload, { headers }).then(response => {
      if (response.data.code === 200) {
        var perfData = response.data.data;
        var min = 0;
        for (let i = 0; i < perfData.length; i++) {
          var acctData = perfData[i];

          //   acctData = acctData.filter(x => (x.category !== 'MTD'));
          //   acctData = acctData.filter(x => (x.category !== 'QTD'));
          acctData = acctData.filter(x => (x.category !== 'Prev Qtr'));
          acctData = acctData.filter(x => (x.category !== '3 Month'));
          acctData = acctData.filter(x => (x.category !== '6 Month'));
          acctData = acctData.filter(x => (x.category !== 'YTD'));
          acctData = acctData.filter(x => (x.category !== 'Prev Year'));
          acctData = acctData.filter(x => (x.category !== '1 Year'));
          acctData = acctData.filter(x => (x.category !== '3 Years'));
          acctData = acctData.filter(x => (x.category !== '5 Years'));
          acctData = acctData.filter(x => (x.category !== '7 Years'));
          acctData = acctData.filter(x => (x.category !== '10 Years'));
          acctData = acctData.filter(x => (x.category !== '15 Years'));
          acctData = acctData.filter(x => (x.category !== '20 Years'));
          acctData = acctData.filter(x => (x.category !== '25 Years'));
          //   acctData = acctData.filter(x => (x.category !== 'ITD'));

          // acctData = acctData.filter(x=>(x.value!==null && x.value!==undefined && x.value!==0));

          //filter out any periods in benchmarks that aren't in account
          if (acctData.length > 0 && acctData[0].indexName !== null && acctData[0].indexName !== undefined) {
            // acctData = acctData.filter(
            //   function (element) {
            //     var test = this.findIndex(a => {
            //       return a.category === element.category;
            //     });
            //     return test >= 0;
            //   }, perfData[0]);
          }

          for (let k = 0; k < acctData.length; k++) {
            if (acctData[k].value < min)
              min = acctData[k].value;
          }

          perfData[i] = acctData;
        }

        //get list of periods with data across all accounts (preserves period order for consolidated view)
        for (let i = 0; i < perfData.length; i++) {
          for (let j = 0; j < perfData[i].length; j++) {
            if (perfData[i][j].value !== null && perfData[i][j].value !== undefined && perfData[i][j].value !== 0) {
              periodsWithData.push(perfData[i][j].category)
            }
          }
        }

        //filter out periods that don't have data
        for (let i = 0; i < perfData.length; i++) {
          perfData[i] = perfData[i].filter(x => (periodsWithData.includes(x.category)));
        }

        //remove any benchmarks that no longer have periods
        for (let i = perfData.length - 1; i >= 0; i--) {
          if (perfData[i].length === 0)
            perfData.splice(i, 1);
        }

        this.setState({ data: perfData, loading: false, min: min });
      }
      else {
        this.setState({ loading: false });
        alert('Could not retrieve performance data - Chart. ');
      }
    }).catch(err => {
      this.setState({ loading: false });
      console.log(err);
    });
  }

  render() {
    var series = [...this.state.data].slice(0, this.maxResults);
    const legendHeight = 25;

    // if (this.state.data.length >= 10 && this.state.portfolio.consolidated) {
    //   return (<></>)
    // }
    return (<div>
      <div className="widget">
        <div className={'widgetWidth' + this.state.col2}>
          {this.state.loading && <img className="loadingIcon" src={loading} alt="loading" />}
          {!this.state.loading ?
            <>
              {this.state.data.length > 0 ?
                <>
                  <div className='perfOverviewSnapshotHeader' ><label>{`As of ${new Date(this.state.selectedDate).toLocaleDateString("en-US")}` + (series.length < this.state.data.length ? ` (Showing ${this.maxResults} of ${this.state.data.length} accounts)` : '')}</label></div>
                  <div style={{ height: this.state.graphHeight + 'px' }}>
                    <Chart
                      transitions={{ series: false }}
                      pannable={false}
                      zoomable={false}
                      style={{ width: '100%', height: (this.state.graphHeight - 30 /*- (legendHeight * series.length)*/) + 'px' }}
                      onScroll={(e) => {
                        if (e.target.scrollTop === 0 || e.target.scrollTop === e.target.scrollHeight) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <ChartLegend visible={false} position="right" orientation="vertical" height={legendHeight * series.length} labels={{ margin: { right: 10 }, padding: 2, font: `bold 12px/12px "${this.state.chartFontFamily}", sans-serif !important;` }} />
                      <ChartTooltip render={this.renderTooltip} />
                      <ChartCategoryAxis>
                        <ChartCategoryAxisItem name='labels' line={{ visible: false }} />
                        <ChartCategoryAxisItem name='series' line={{ visible: false }} />
                      </ChartCategoryAxis>
                      <ChartValueAxis>
                        <ChartValueAxisItem title={{ text: this.state.returnText, rotation: 270 }} axisCrossingValue={this.state.min} />
                      </ChartValueAxis>
                      <ChartSeries>
                        {series.map((item, key) => (
                          <ChartSeriesItem f key={key} name={item[0].accountPortfolio} categoryAxis='series' categoryField='category' type='column' field='value' color={(this.state.colors.length > key && this.state.colors[key].color) || ''} data={item} />
                        ))}
                      </ChartSeries>
                    </Chart>
                  </div>
                </>
                : <div className='widgetNoData'>No data available</div>}
            </>
            : <div className='widgetNoData'></div>}
        </div>
      </div>
    </div>
    );
  }
}

export default PerformanceOverview;