
import React, { Component } from 'react';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import axios from 'axios';
import { withState } from './with-state';
import GridLoading from '../../Loaders/GridLoading';
import { Upload } from '@progress/kendo-react-upload';
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import bgMessages from './bg.json';
loadMessages(bgMessages, 'bg-BG');
//import createFilterDropDownCell from './components/FilterDropDownCell';

const StatefulGrid = withState(Grid);

const cloneProduct = (product) => ({ ...product });

class ClientServiceTeamMapping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //data: sampleProducts.map(cloneProduct),
            data: [],
            dataState: { skip: 0, take: 25 },
            editField: undefined,
            employeeSelected: false,
            employeeSelect: '',
            files: [],
            gridFilter: undefined,
            filterable: false,
            //sort: [{ field: 'SortOrder', dir: 'asc' }]
        };

        this.getCsTeamList = this.getCsTeamList.bind(this);
    }

    componentDidMount() {
        this.getCsTeamList();
    }

    changeDataState = (e) => {
        var newData = process(this.state.data, e.dataState);
        this.setState({ dataState: e.dataState, data: newData });
    }

    selectionChange(event, gFiles) {
        gFiles.forEach(item => item.selected = false);
        event.dataItem.selected = !event.dataItem.selected;
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ employeeSelect: selectedFiles[0] });
        if (selectedFiles.length > 0) {
            this.setState({ employeeSelected: true });
        }
        else {
            this.setState({ employeeSelected: false });
        }
        this.forceUpdate();
    }

    headerSelectionChange(event, gFiles) {
        gFiles.forEach(item => item.selected = false);
        this.setState({employeeSelected: false, employeeSelect: ''})
        this.forceUpdate();
    }


    getCsTeamList() {
        this.setState({ refreshText: 'Loading...', employeeSelected: false, employeeSelect: '', });
        var members = [];
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        //axios
        //.post('api/ssrsGetReports', payload, { headers })
        axios(`api/CsTeamMembers`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers,
        })
            .then(response => {


                response.data.data.forEach(teamMemberinResponse => {


                    members.push({
                        HouseholdID: teamMemberinResponse.HouseholdID,
                        Relation: teamMemberinResponse.Relation,
                        FullName: teamMemberinResponse.FullName,
                        Title: teamMemberinResponse.Title,
                        Phone: teamMemberinResponse.Phone,
                        Email: teamMemberinResponse.Email,
                        PictureName: teamMemberinResponse.PictureName,
                        PhotoUploaded: teamMemberinResponse.Uploaded
                    })

                })
                //var sort=[{ field: 'type', dir: 'asc' }, { field: 'reportName', dir: 'asc' }]
                this.setState({ data: members.map(cloneProduct), members: members, refreshText: '' })

            })
            .catch(err => {
                console.error(err);
                this.setState({ refreshText: '' });
            })
    }

    onAdd = (e) => {
        if (e.newState[0].extension.toLowerCase() !== '.png' && e.newState[0].extension.toLowerCase() !== '.jpg' && e.newState[0].extension.toLowerCase() !== '.jpeg'){
            alert('File must be in .jpg or .png format')
        }
        else if(e.newState[0].size > 3000000 ){
            alert('Filesize must cannot be greater than 3 megabytes')
        }
    }

    onRemove = (e) => {
        this.setState({ files: e.newState });
    }

    onBeforeUpload = (e) => {
        e.additionalData.company = this.props.user.company;
        e.additionalData.name = this.state.employeeSelect.PictureName
        e.additionalData.type = 'csteam'
    }

    onStatusChange = (e) => {
        if (e.response.response.code === 200) {
            alert("Photo Uploaded")
            this.getCsTeamList();
        }
        else {
            alert("Unable to save photo")
        }
    }

    render() {
        return (
            <div className="customMapping">
                <StatefulGrid
                    selectedField='selected'
                    onSelectionChange={(e) => this.selectionChange(e, this.state.data)}
                    onDataStateChange={this.changeDataState}
                    onRowClick={(e) => this.selectionChange(e, this.state.data)}
                    onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.data)}
                    data={this.state.data}
                    rowHeight={50}
                    filterable={true}
                    resizable
                    sortable={{
                        mode: 'multiple'
                    }}
                    sort={this.state.sort}
                    editField="inEdit"
                >
                    <GridToolbar>
                        <div style={{display: 'block', width: '100%'}}>
                        <LocalizationProvider language="bg-BG">
                            <IntlProvider locale="bg" >
                                <Upload autoUpload={true}
                                    disabled={!this.state.employeeSelected}
                                    batch={false}
                                    multiple={false}
                                    restrictions={{
                                        allowedExtensions: [".png",".jpg",".jpeg"],
                                        maxFileSize: 3000000,
                                    }}
                                    // files={this.state.files}
                                    // listItemUI={this.renderFile}
                                    onAdd={this.onAdd}
                                    // onRemove={this.onRemove}
                                    onStatusChange={this.onStatusChange}
                                    onBeforeUpload={this.onBeforeUpload}
                                    saveUrl={'api/CsTeamMembers?company='+ this.props.user.company}
                                    saveHeaders={{ 'authorization': 'Bearer '.concat(this.props.idToken) }} />
                            </IntlProvider>
                        </LocalizationProvider>
                        </div>
                        <div><label style={{fontSize:'80%', fontStyle:'italic'}}>*for best performance, we recommend images under 200kb in size</label></div>
                    </GridToolbar>
                    <GridColumn filterable={false} width='50px' field="selected" headerClassName='gridHeader' headerSelectionValue={this.state.data.findIndex(dataItem => dataItem.selected === false) === -1} />
                    <GridColumn field="FullName" title="Full Name" filterable={false} />
                    <GridColumn field="Email" title="Email" filterable={false} />
                    <GridColumn field="PictureName" title="Photo Name" filterable={false} />
                    <GridColumn field="PhotoUploaded" title="Photo Uploaded?" width='150px' filterable={false} />
                </StatefulGrid>
                {this.state.refreshText && <GridLoading gridClass='packageReview' />}
            </div>
        );
    }

}


export default ClientServiceTeamMapping