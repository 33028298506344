import React, { Component } from "react";
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import axios from 'axios';
import { Button } from "@progress/kendo-react-buttons";
import DetailRow from './components/DetailRow';
import AddPermissions from './components/AddPermissions';
import GridLoading from '../Loaders/GridLoading';
import { process } from '@progress/kendo-data-query';
import { parseDate } from '@telerik/kendo-intl';
import PermissionWrapper from "../PermissionWrapper/PermissionWrapper";

const ADJUST_PADDING = 4;
const COLUMN_MIN = 4;

class PermissionList extends Component {

    constructor(props) {
        super(props);

        this.minGridWidth = 0;

        var columns = [{ field: 'permission', title: 'Permission', headerClass: 'gridHeader', minWidth: 150 },
        { field: 'description', title: 'Description', headerClass: 'gridHeader', minWidth: 150 },
        { field: 'api', title: 'API', headerClass: 'gridHeader', minWidth: 150 },
        { field: 'method', title: 'Method', headerClass: 'gridHeader', minWidth: 60 },
        { field: 'active', title: 'Active', filterable: false, cell: (p) => this.createCell(p, 'active'), headerClass: 'gridHeader', minWidth: 125 },
        { field: 'status', title: 'Status', filterable: false, cell: (p) => this.createCell(p, 'status'), headerClass: 'gridHeader', minWidth: 50 }];

        // if (this.props.user.company === 'cssi') { 
        //     columns.push({field: 'isGlobal', title: 'Global', filterable: false, cell: (p) => this.createCell(p, 'isGlobal'), headerClass: 'gridHeader', minWidth: 50})
        // } else {
        //     columns.push({field: 'isGlobal', title: 'Inherited', filterable: false, cell: (p) => this.createCell(p, 'inherited'), headerClass: 'gridHeader', minWidth: 50})
        // }            

        this.state = {
            permissions: [],
            result: [],
            refreshText: '',
            filterable: this.props.compSettings.showFilters,
            columns: columns,
            dataState: { skip: 0, take: 100, sort: [{ field: 'lastLogin', dir: 'desc' }] },
        }
    }

    componentDidMount() {
        this.getSuperAdminSettings(() =>{
            this.getPermissions(() => {
                this.setMinGridWidth();
            });
        })
    }

    setMinGridWidth = () => {
        this.grid = document.querySelector('.k-grid');
        window.addEventListener('resize', this.handleResize);
        this.minGridWidth = 0;
        this.state.columns.map(item => this.minGridWidth += item.minWidth);
        if (this.state.dataState.group)
            this.minGridWidth += 32 * this.state.dataState.group.length;
        this.setState({
            gridCurrent: this.grid.offsetWidth,
            setMinWidth: this.grid.offsetWidth < this.minGridWidth
        });
    }

    handleResize = () => {
        if (this.grid.offsetWidth < this.minGridWidth && !this.state.setMinWidth) {
            this.setState({
                setMinWidth: true
            });
        } else if (this.grid.offsetWidth > this.minGridWidth) {
            this.setState({
                gridCurrent: this.grid.offsetWidth,
                setMinWidth: false
            });
        }
    }

    setWidth = (minWidth) => {
        let width = this.state.setMinWidth ? minWidth : minWidth + (this.state.gridCurrent - this.minGridWidth) / this.state.columns.length;
        if (width >= COLUMN_MIN)
            width = width - ADJUST_PADDING;
        return width;
    }

    getSuperAdminSettings(cb) {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        axios(`api/superAdminSettings`, {
          method: 'GET',
          params: { company: this.props.user.company },
          headers: headers
        })
          .then(response => {
            response.data.data.forEach(setting => {
              if (setting.settingName === 'customLanding') {
                this.setState({ customLanding: setting.settingValue })
                cb()
              }
            })
    
          })
          .catch(err => {console.log(err); cb()});
      }

    getPermissions = (cb) => {
        this.setState({ refreshText: 'Loading...' });

        var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios.get("api/permissions?company=" + this.props.company, { headers }).then(response => {
            if (response.data.code === 200) {
                var formattedPermissions = this.formatPermissionList(response.data.permissions);
                this.setState({ permissions: formattedPermissions, result: this.addPropertyToItems(process(formattedPermissions, this.state.dataState)), refreshText: '' }, () => {
                    if (cb)
                        cb();
                });
            }
            else {
                if (cb)
                    cb();
                this.setState({ refreshText: '' });
                alert("An error occured while fetching the permissions list.");

            }
        }).catch(error => console.log(error));
    }

    formatPermissionList = (permissionList) => {
        var listWithPermissions;
        listWithPermissions = permissionList.slice();

        listWithPermissions = listWithPermissions.filter(permission => permission.permission.indexOf('___') === -1)

        //remove home page settings if custom landing is not activated
        //always show for cssi though, since that is global
        listWithPermissions = listWithPermissions.filter(permission => (
            permission.permission.indexOf('Home Page Settings') === -1 ||
            (this.state.customLanding && this.state.customLanding !== 'None' && this.state.customLanding !== 'Churchill' && this.state.customLanding !== 'Snapshot') ||
            (this.props.user.company === 'cssi')
        ))

        for (let i = 0; i < listWithPermissions.length; i++) {
            listWithPermissions[i].company = this.props.company;
            listWithPermissions[i].idToken = this.props.idToken;
            listWithPermissions[i].changeActivate = this.changeActivate;
            listWithPermissions[i].changePermissionData = this.changePermissionData;
            listWithPermissions[i].refreshList = this.getPermissions;
            listWithPermissions[i].refreshUser = this.props.refreshUser;
            listWithPermissions[i].loggedInAsAdvisor = this.props.user.permission === 'advisor';
            listWithPermissions[i].userLevel = this.props.user.userLevel;
            listWithPermissions[i].permissionsArray = this.props.user.permissions;
        }

        return listWithPermissions;
    }

    getDate = (sqlDate) => {
        var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
        var utc = null;

        if (d !== null)
            utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes());

        return utc;
    }

    createCell = (cell, type) => {
        if (type === 'active') {
            if (cell.dataItem.active === true) {
                return (<td>Active</td>);
            }
            else {
                return (<td>Inactive</td>);
            }
        } else if (type === 'status') {
            if (cell.dataItem.isOverride) {
                return (<td>Override</td>);
            }
            else if (cell.dataItem.isGlobal) {
                return (<td>CSSI</td>);
            } else {
                return (<td></td>)
            }
        }
    }

    changeActivate = (guid, active) => {
        var arr = this.state.permissions.slice();

        var indexOfPermission = arr.findIndex((item) => {
            return item.guid === guid;
        });

        if (indexOfPermission > -1) {
            arr[indexOfPermission].active = active;
        }
        this.setState({ permissions: arr });
    }

    changePermissionData = (email, newPermission, newViews, approved) => {
        var arr = this.state.permissions.slice();

        this.setState({ permissions: arr });
    }

    // expandChange = (event) => {
    //     event.dataItem.expanded = !event.dataItem.expanded;
    //     this.forceUpdate();
    // }

    expandChange = (event) => {
        const expandField = event.target.props.expandField;
        const itemId = event.dataItem.id;
    
        const updateItemsRecursive = (items) => {
          return items.map(item => {
            let updatedItem = item;
            if (item.id === itemId) {
              updatedItem = {
                ...item,
                [expandField]: !item[expandField],
              };
            }
            if (Array.isArray(item.items)) updatedItem.items = updateItemsRecursive(item.items);
            return updatedItem;
          });
        };
    
        const updatedData = updateItemsRecursive(this.state.result.data);
    
        this.setState((prevState) => ({
          ...prevState,
          result: {
            ...prevState.result,
            data: updatedData
          }
        }));
      }
    
      addPropertyToItems = (obj) => {
        // Usage: (call in both initial data set and prior to any datagrid result state change)
        // result = this.addPropertyToItems(result);
    
        const addPropertyToNestedItems = (item) => {
          item.id = Math.random();
          item.expanded = false;
    
          // Recursively call this function for each item in the 'items' array
          if (Array.isArray(item.items)) item.items.forEach(subItem => addPropertyToNestedItems(subItem));
          return item;
        }
    
        // Traverse the array and add properties
        if (Array.isArray(obj.data)) obj.data.forEach(item => addPropertyToNestedItems(item));
        return obj;
      }

    _export;

    export = () => {
        this._export.save();
    }

    changeDataState = (e) => {
        var newData = process(this.state.permissions, e.dataState);
        this.setState({ dataState: e.dataState, result: this.addPropertyToItems(newData) });
    }

    render() {
        return (
            <PermissionWrapper hidden={false} {...this.props} {...this.state} allowedPermission="View Permissions">
            <div>
                {!this.state.groups && !this.state.logs && (
                    <div className="permissionList">
                        <Grid data={this.state.result}
                            onRowClick={this.expandChange}
                            detail={DetailRow}
                            resizable={true}
                            reorderable={true}
                            pageable={{ pageSizes: [10, 25, 50, 100, 250, 500] }}
                            sortable={true}
                            onDataStateChange={this.changeDataState}
                            expandField="expanded"
                            onExpandChange={this.expandChange}
                            filterable={this.state.filterable}
                            {...this.state.dataState}>
                            <GridToolbar>
                                <Button icon='refresh' title='Refresh' onClick={(e) => this.getPermissions(() => this.setMinGridWidth())}>{this.state.refreshText}</Button>
                                <Button icon='filter' title='Filter' onClick={(e) => this.setState({ filterable: !this.state.filterable })}>Filter</Button>
                                <PermissionWrapper hidden={true} {...this.props} {...this.state} allowedPermission="Create Permission">
                                <AddPermissions compSettings={this.props.compSettings} company={this.props.company} permission={this.props.permission} idToken={this.props.idToken} refreshPermissions={this.getPermissions}
                                    button={<Button icon="plus">Create Permission</Button>} newOrder={(1 + this.state.permissions.length) * 10} />
                                </PermissionWrapper>
                            </GridToolbar>
                            {
                                this.state.columns.map((column, key) => {
                                    return <GridColumn field={column.field} title={column.title} key={key} format={column.format} cell={column.cell} filterable={column.filterable} filter={column.filter} headerClassName={column.headerClass} width={this.setWidth(column.minWidth)} />
                                })
                            }
                        </Grid>
                        {this.state.refreshText && <GridLoading gridClass='permissionList' />}
                    </div>)
                }
            </div>
            </PermissionWrapper>
        );
    }

}

export default PermissionList;