import React, { Component } from "react";
// import { DropDownList } from '@progress/kendo-react-dropdowns';
// import { Button } from "@progress/kendo-react-buttons";
// import { Input } from "@progress/kendo-react-inputs";
import axios from 'axios';

class ReportLogs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reportLogs: "",
        }
    }

    componentDidMount = () => {
        this.getReportLogs();
    }

    getReportLogs = () => {
        //this.setState({reportLogs: "test\r\ntest\r\ntesttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttt\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\ntest"})
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        axios(`api/reportLogs`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers
        })
            .then(response => {
                var errorString = response.data.data
                if (!errorString){
                    errorString = "Error log is empty"
                }
                this.setState({reportLogs: errorString})
            })
            .catch(err => console.log(err));
    }



//value={this.state.DisclaimerTextWithNewline || this.getEditField('DisclaimerTextWithNewline')} onChange={(e)=>this.onFieldChange(e, 'DisclaimerTextWithNewline')}
    render = () => {
        return (
            <div className="reportLogs">
                <textarea id="reportLogsText" name="reportLogsText" className="reportLogsTextarea" value={this.state.reportLogs} placeholder={"Loading report logs..."} readOnly={true}/>
            </div>
        );
    }

}

export default ReportLogs;