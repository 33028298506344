import React, { Component } from "react";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import CreateExports from './CreateExports';
import ReviewExports from "./ReviewExports";

class DataExports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabSelected: props.user.dataExportsTab || 0,
            // styleSettings: props.styleSettings,
        }
    }

    componentDidMount() {
        this.props.resetDataExportsTab()
    }

    handleSelect = (e) => {
        this.setState({ tabSelected: e.selected });;
    }

    render() {
        return (
            <div className="dataExports">
                <TabStrip selected={this.state.tabSelected} onSelect={this.handleSelect}>
                    <TabStripTab title={'Create Exports'}>
                        <CreateExports idToken={this.props.idToken} user={this.props.user} onDataExportRun={this.props.onDataExportRun} />
                    </TabStripTab>
                    <TabStripTab title={'Review Exports'}>
                        <ReviewExports idToken={this.props.idToken} user={this.props.user} styleSettings={this.props.styleSettings} />
                    </TabStripTab>
                </TabStrip>

            </div>

        )
    }

}

export default DataExports;