import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';

class EditHousehold extends Component {

  constructor(props) {
    super(props);

    this.state = {
      household: this.props.household,
      householdName: this.props.householdName,
      AggAccountCode: this.props.AggAccountCode,
      existingHH: this.props.existingHH,
      company: this.props.company
    }
    this.clearState = this.clearState.bind(this);
  }


  clearState() {
    //this.setState({selectedRole:{text:this.props.user.role}, views:this.props.user.access});
  }

  onHouseholdChange = (e) => {
    this.setState({ household: e.target.value });
  }
  onHouseholdNameChange = (e) => {
    this.setState({ householdName: e.target.value });
  }
  onAggAccountCodeChange = (e) => {
    this.setState({ AggAccountCode: e.target.value });
  }


  renameHousehold(e) {

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    //ask for new name
    var newHousehold = prompt("Enter new Household code.");


    if (newHousehold == null) {
      return;
    }
    //check if anything to rename to
    if (newHousehold === '') {
      return;
    }

    //scrub the new HH code
    newHousehold = newHousehold.replace('/', '');




    //check if name already exists
    if (this.props.checkHHExists(newHousehold)) {
      alert("A Household named (" + newHousehold + ") already exists.");
      return;
    }

    //verify they want to rename
    if (window.confirm("Confirm renaming (" + this.state.household + ") to (" + newHousehold + ")?")) {
      //newHousehold = newHousehold;


      //move S3 files and update data base
      var payload = {
        household: this.state.household,
        newHouseholdName: newHousehold,
        company: this.state.company
      }

      axios.post('api/renameHousehold', payload, { headers }).then(response => {
        if (response.data.code === 201) {

          //change the household state so other fields can be updated
          this.setState({ household: newHousehold });
          document.getElementById("householdInputCode").value = newHousehold;


          //notify user rename is finished
          alert("The Household has been renamed. All Mappings and Document Vault entries have been updated.");


        }
        else {
          alert("Error: Household could not be renamed.");
        }
      });

    }

  }


  updateHousehold(e) {

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var payload = {
      household: this.state.household,
      householdName: this.state.householdName,
      AggAccountCode: this.state.AggAccountCode,
      company: this.state.company
    }

    axios.post('api/updateHousehold', payload, { headers }).then(response => {
      if (response.data.code === 201) {
        //user.refreshList();
        alert("Household has been saved.");
      }
      else if (response.data.code === 202) {
        //user.refreshList();
      }
      else {
        alert("Error: Household could not be updated.");
      }
      this.setState({
        household: this.props.household,
        householdName: this.props.householdName,
        AggAccountCode: this.props.AggAccountCode,
        existingHH: this.props.existingHH,
        company: this.props.company
      })
    });
  }

  render() {
    return (
      <Popup closeOnDocumentClick={false} trigger={this.props.button} onClose={(event) => { this.clearState() }} overlayStyle={{ zIndex: 5 }} modal>
        {close => (
          <div className="modal">

            <Button className="close" icon="close" onClick={(e) => { this.props.refreshHH(); close(); }} />


            <div className="header" align="left">
              {this.props.isAdd ? 'Add' : 'Edit'} Household
              </div>

            <div className="content">
              <div style={{ marginTop: '10px' }}>
                <label>Household: </label><input id="householdInputCode" disabled={this.state.existingHH} defaultValue={this.state.household} onChange={this.onHouseholdChange} />
                <label>   </label>


                {this.state.existingHH &&
                  <Button
                    className="button"
                    onClick={(event) => { this.renameHousehold(event); }}>
                    Rename
                  </Button>
                }


              </div>
              <div style={{ marginTop: '10px' }}><label>Household Name: </label><input defaultValue={this.state.householdName} onChange={this.onHouseholdNameChange} /></div>
              <div style={{ marginTop: '10px' }}><label>Agg. Account Code: </label><input defaultValue={this.state.AggAccountCode} onChange={this.onAggAccountCodeChange} /></div>
            </div>

            <div className="actions">
              <Button
                className="button"
                onClick={() => { this.props.refreshHH(); close(); }}>
                Cancel
                </Button>


              <Button
                className="upload"
                onClick={(event) => { this.updateHousehold(event); this.props.refreshHH(); close(); }}>
                Update
                </Button>
            </div>

          </div>
        )}
      </Popup>

    );
  };
}

export default EditHousehold;