import React, { Component } from "react";
import ReactDragListView from 'react-drag-listview';
import SnapshotWidget from "./components/SnapshotWidget";
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import axios from 'axios';
import * as utils from '../utilities';
import { process } from '@progress/kendo-data-query';
import './Snapshot.css';

// import axios from 'axios';

class Snapshot extends Component {

  constructor(props) {
    super(props);

    // var defaultPerPage = parseInt(500);

    this.state = {
      snapshots: [],
      editActive: false,
      households: [],
      selectedHousehold: '',
      householdListShowMV: null,
      loadedWidgetsCount: 0,
      totalWidgets: 0,
      householdDataLoaded: false,
      dataState: { skip: 0, take: 100000, group: undefined, filter: undefined, sort: undefined },
      groupDataState: { group: [{ field: "household" }] },
      groupDataStateWithMV: { group: [{ field: "household", aggregates: [{ field: 'marketValue', aggregate: 'sum' }] }] },
      data: [],
      groupedData: [],
      loading: false,
      asOf: '',
      // dataState: [],
    };

    this.callbackArray = [];
  }

  componentDidMount() {
    this.getSnapshots(() => {
      this.getHouseholds();
    });   
  }

  onWidgetLoad = () => {
    this.setState((prevState) => {
      const newCount = prevState.loadedWidgetsCount + 1;
      return { loadedWidgetsCount: newCount };
    }, this.checkIfAllLoaded);
  }
  
  checkIfAllLoaded = () => {
    const { loadedWidgetsCount, totalWidgets, householdDataLoaded } = this.state;
    if (loadedWidgetsCount === totalWidgets && householdDataLoaded) {
      // All widgets and household data are loaded, trigger data loading in widgets
      this.callbackArray.forEach(cb => cb());
    }
  }

  getHouseholds = () => {
    var advisor = '&advisor=' + this.props.user.user;
    var includeMV = '&includeMV=0'
    if (this.state.householdListShowMV) {
      includeMV = '&includeMV=1'
    }

    // if((this.state.styleSettings.advAllUsers || this.props.user.role==='admin') && this.props.user.role !== 'user')
    if (utils.checkPermission(this.props.user, "Access All Households"))
      advisor = '';

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios.get('api/householdInfo?company=' + this.props.user.company + advisor + includeMV, { headers }).then(response => {
      var date = '';

      for (let x = 0; x < response.data.data.length; x++) {
        if (response.data.data[x].date !== null && date === '')
          date = response.data.data[x].date;
        // if (response.data.data[x].account != null && response.data.data[x].account !== '' && (this.props.user.role === 'advisor' || this.props.user.role === 'admin')) response.data.data[x].displayName += (' - ' + response.data.data[x].account)
        // response.data.data[x].onAcctClick = this.props.onAccountClick;
      }

      var result = null;
      if (this.state.householdListShowMV) {
        result = process(response.data.data, this.state.groupDataStateWithMV);
      }
      else {
        result = process(response.data.data, this.state.groupDataState);
      }

      var groupedData = [];
      var total;
      var householdName;

      for (let i = 0; i < result.data.length; i++) {
        total = null;
        householdName = null

        for (let k = 0; k < 1; k++) {
          householdName = result.data[i].items[k].householdName;
        }

        if (this.state.householdListShowMV) {
          for (let k = 0; k < result.data[i].items.length; k++) {
            if (result.data[i].items[k].marketValue)
              total = total + result.data[i].items[k].marketValue;
          }
        }
        result.data[i].items = result.data[i].items.map(item => {
          let retObj = { ...item };
          retObj.displayName = retObj.displayName.replace(' - ' + retObj.account, '');
          return retObj;
        })

        groupedData.push({ household: result.data[i].value, householdName: householdName, houseMarketValue: total, details: result.data[i].items, date: date, includeMV: this.state.householdListShowMV });
      }
      console.log('grouped', groupedData)
      // result = process(groupedData, this.state.dataState);
      //result = {data:groupedData, total:groupedData.length};
      this.setState({
        data: response.data.data,
        result: result, groupedData: groupedData,
        loading: false,
        asOf: date,
        selectedHousehold: groupedData[0],
        householdDataLoaded: true
      }, () => {
        this.checkIfAllLoaded();
      });
    }).catch(err => {
      console.log(err);
      this.setState({ data: [], loading: false });
    });

  }

  onAddCallback = (cb) => {
    this.callbackArray.push(cb);
  }

  onUserChange = (e) => {
    this.setState({ selectedHousehold: e.target.value }, () => {
      this.callbackArray.forEach(cb => {
        cb();
      })
    });
  }

  getSnapshots = (cb) => {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios.get("api/snapshots?email=" + this.props.user.user + "&company=" + this.props.user.company, { headers }).then(response => {
      if (response.data.code === 200) {
        this.setState({
          snapshots: response.data.data,
          totalWidgets: response.data.data.length,
          refreshText: ''
        }, () => {
          if (cb)
            cb();
        });
      } else {
        if (cb)
          cb();
        this.setState({ refreshText: '' });
        alert("An error occured while fetching the snapshots.");
      }
    }).catch(error => console.log(error));
  }

  handleChange = (widgetId, key, val) => {
    this.setState((prevState) => {
      let sel = prevState;
      const index = sel.snapshots.findIndex(item => item.widgetId === parseInt(widgetId));
      if (sel.snapshots[index].userConfiguration == null) sel.snapshots[index].userConfiguration = {};
      if (sel.snapshots[index].userConfiguration[key] == null) sel.snapshots[index].userConfiguration[key] = null;
      sel.snapshots[index].userConfiguration[key] = val;
      return sel;
    }, () => this.reorder())
  }

  handleClick = (visualizationName) => {
    this.props.onSnapshotBodyClick(this.state.selectedHousehold, visualizationName);
  }

  reorder = (data = [...this.state.snapshots]) => {
    let dbSnapshots = [];
    let counter = 0;
    data = data.map((snapshot) => {
      let newOrder = null;
      if (dbSnapshots.findIndex(item => item.widgetId === snapshot.widgetId) === -1) {
        dbSnapshots.push({ widgetId: snapshot.widgetId })
        newOrder = (snapshot.userActive ? ((counter + 1) * 10) : null);
        if (snapshot.userActive) counter++;
      }
      return { ...snapshot, userOrder: newOrder }
    })

    this.setState({
      snapshots: data
    }, () => {
      var headers = {
        'Authorization': 'Bearer '.concat(this.props.idToken),
      };
      var payload = {
        snapshots: data,
        email: this.props.user.user,
        company: this.props.user.company
      };

      axios.post('api/updateSnapshots', payload, { headers }).then(response => {
        if (response.data.code !== 200) {
          alert("Unable to update snapshot.");
        }
      }).catch(err => {
        alert("An error occured when updating the snapshot.");
        console.log(err)
      });

    });
  }

  render() {
    const dragProps = {
      onDragEnd: (fromIndex, toIndex) => {
        // console.log(fromIndex, toIndex)
        const data = [...this.state.snapshots];
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        this.reorder(data);
      },
      nodeSelector: 'div.widget',
      handleSelector: 'div.widgetHeader',
      className: 'w-100'
    };
    // implement method
    return (
      <>
      {this.props.type === "lite" && /* <div className="snapshotRow">*/
          <div className="liteSnapshotHouseholdDropDown" style={{height: 'auto', paddingBottom: '20px', paddingTop:(this.props.styleSettings.themeFontFamily === 'Poppins' || this.props.styleSettings.themeFontFamily === 'OpenSans' ) ? 0 : ''}}>
            Select your account:
            <DropDownList
              data={this.state.groupedData}
              textField="household"
              dataItemKey="household"
              onChange={this.onUserChange}
              value={this.state.selectedHousehold}
            />
          {/* </div> */}
        </div>}
        <div className={this.props.type === "full" ? '' : (this.props.styleSettings.themeFontFamily === 'Poppins' || this.props.styleSettings.themeFontFamily === 'OpenSans' ? 'liteSnapshot' : 'liteSnapshot')}>
        {this.props.type === "full" &&/* <div className="snapshotRow">*/
          <div className="snapshotColumn snapshotHouseholdDropDown">
            Select your account:
            <DropDownList
              style={{ width: '75%' }}
              data={this.state.groupedData}
              textField="household"
              dataItemKey="household"
              onChange={this.onUserChange}
              value={this.state.selectedHousehold}
            />
          {/* </div> */}
        </div>}
        <div className={"snapshotColumn snapshotColumn" + this.state.editActive ? 8 : 12}>
          <ReactDragListView.DragColumn {...dragProps}>
            <div className="snapshotRow">
              {this.state.snapshots.sort((a, b) => a.userOrder - b.userOrder).map((item) => (
                <SnapshotWidget
                  {...this.props}
                  key={item.widgetId}
                  id={item.widgetId}
                  name={item.name}
                  types={item.types}
                  widget={item}
                  _handleClick={this.handleClick}
                  widths={(item.configuration != null && item.configuration.widths != null && item.configuration.widths.length > 0) ? item.configuration.widths : [4]}
                  width={this.props.type === 'lite' ? 6 : ((item.userConfiguration != null && item.userConfiguration.width != null && item.userConfiguration.width > 0) ? item.userConfiguration.width :
                    ((item.configuration != null && item.configuration.defaultWidth != null && item.configuration.defaultWidth > 0) ? item.configuration.defaultWidth : 4))}
                  active={item.userActive}
                  handleChange={this.handleChange}
                  selectedHousehold={this.state.selectedHousehold}
                  onAddCallback={this.onAddCallback}
                  onWidgetLoad={this.onWidgetLoad}
                />
              ))}
            </div>
          </ReactDragListView.DragColumn>
        </div>
        <div className="snapshotRow">
          <div className="snapshotColumn text-right">{!this.state.editActive ? <Button onClick={() => this.setState({ editActive: true })} className="">Customize Snapshot</Button> : <></>}</div>
        </div>
        <div className="snapshotRow">
          {this.state.editActive ?
            <div className="snapshotRow">
              <ul className="snapshotList">
                {[...this.state.snapshots].sort((a, b) => a.order - b.order).map(item => (
                  <li key={item.widgetId}>
                    <label>
                      <input
                        type="checkbox"
                        checked={item.userActive}
                        name={"widget_" + item.widgetId}
                        onChange={(e) => {
                          e.persist();
                          this.setState((prevState) => {
                            let sel = prevState;
                            sel.snapshots.filter(item => ("widget_" + item.widgetId) === (e.target.name))[0].userActive = !sel.snapshots.filter(item => ("widget_" + item.widgetId) === (e.target.name))[0].userActive
                            return sel;
                          }, () => this.reorder())
                        }}
                      />
                      {item.name}
                    </label>
                  </li>
                ))}
                <li><Button onClick={() => this.setState({ editActive: false })} className="">Done</Button></li>
              </ul>
            </div>
            : <></>}
        </div>
      </div>
      </>
    );

  }

}

export default Snapshot;