import React from "react";
import { Grid, GridColumn, GridDetailRow, GridNoRecords } from '@progress/kendo-react-grid';
import GridLoading from '../Loaders/GridLoading';
import axios from 'axios';

class SsrsPackagerDetail extends GridDetailRow {

    constructor(props) {
        super(props);

        this.state = {
            entityColumn: "Reporting Entity"
        }
    }

    componentDidMount() {
        this.getPackageReports();
    }

    getPackageReports() {
        this.setState({ loading: true });
        var reports = [];
        const headers = { 'authorization': 'Bearer '.concat(this.props.dataItem.idToken) };
    

        this.setState({ reportList: [] }, () => {
            axios(`api/ssrsGetPackageReports`, {
                method: 'GET',
                params: { database: this.props.dataItem.database, packageName: this.props.dataItem.Key },
                headers: headers
            })
                .then(response => {
                    var entityColumn = "Reporting Entity"
                    reports = response.data.data
                    var reportList = []
                    reports.sort((a, b) => a.reportOrder - b.reportOrder)
                    reports.forEach(reportInResponse => {
                        if (reportInResponse.contentType === 'Advanced Group Report' || reportInResponse.contentType === 'Advanced Group PDF') {entityColumn = "Group Behavior"}
                        if (reportInResponse.hierarchy === 'Multi Level'){
                            reportList = reportList.concat({ reportName: reportInResponse.reportName, contentType: reportInResponse.contentType, reportOrder: reportInResponse.reportOrder, reportingGroup: reportInResponse.hierarchy + ' - ' + reportInResponse.reportingGroup, reportDate: reportInResponse.reportDate, orientation: reportInResponse.orientation, paramString: reportInResponse.paramString })
                        }
                        else{
                            reportList = reportList.concat({ reportName: reportInResponse.reportName, contentType: reportInResponse.contentType, reportOrder: reportInResponse.reportOrder, reportingGroup: reportInResponse.reportingGroup, reportDate: reportInResponse.reportDate, orientation: reportInResponse.orientation, paramString: reportInResponse.paramString })
                        }
                    })
                    this.setState({
                        reportList: reportList
                    })  
                    //this.setState({ accounts: packages })
                    this.setState({ loading: false, entityColumn: entityColumn});
                })
                .catch(err => {
                    console.error(err);
                    this.setState({ loading: false });
                })
            //return reports;
        })
    }

    formatCurrency = (val) => {
        var formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val);
        return formatted;
    }

    headerCellRender = (thElement, cellProps) => {
        if (thElement !== null) {
            var style = { cursor: 'default' };

            if (cellProps.field === 'marketValue') {
                style = { cursor: 'pointer', textAlign: 'right' };
                return (<div {...thElement.props} style={style}>Market Value As Of: {this.props.dataItem.date}</div>)
            }

            return (
                <div {...thElement.props} style={style} />
            );
        }

        return thElement;
    }

    CellRender = (tdElement, cellProps) => {
        var val;
        var style;

        if (tdElement !== null) {
            style = { cursor: 'pointer' };

            if (cellProps.field === 'marketValue') {
                style = { cursor: 'pointer', textAlign: 'right' };
                val = cellProps.dataItem[cellProps.field];
                if (val === null)
                    val = '-';
                else
                    val = this.formatCurrency(val);

                return (<td {...tdElement.props} style={style}>{val}</td>);
            }

            return (
                <td {...tdElement.props} style={style} />
            );
        }

        return tdElement;
    }

    render() {
        const data = this.state.reportList;

        return (
            <div>
                <Grid data={data}
                    style={{ height: '100%', maxHeight: '30vh' }}
                    headerCellRender={this.headerCellRender}
                    cellRender={this.CellRender}
                >
                    <GridNoRecords>
                        {this.state.loading && 'Loading...'}
                        {!this.state.loading && 'No records available'}
                    </GridNoRecords>
                    <GridColumn headerClassName='gridHeader' field='reportName' title='Name' />
                    <GridColumn headerClassName='gridHeader' field='contentType' title='Content Type' />
                    <GridColumn headerClassName='gridHeader' field='reportOrder' title='Order' width='70px' />
                    <GridColumn headerClassName='gridHeader' field='reportingGroup' title={this.state.entityColumn} />
                    <GridColumn headerClassName='gridHeader' field='reportDate' title='Date' />
                    <GridColumn headerClassName='gridHeader' field='orientation' title='Orientation' />
                </Grid>
                {this.state.loading && <GridLoading />}
            </div>
        );
    }

}
export default SsrsPackagerDetail;

