import React, { Component } from "react";
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import axios from 'axios';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { process } from '@progress/kendo-data-query';
import { DropDownFilterCell } from "./DropDownFilterCell";
import GridLoading from '../../Loaders/GridLoading';
import InviteUser from '../../InviteUser/InviteUser';

const yesNoFilterCell = (props) => (
  <DropDownFilterCell {...props} fieldType="yesNo" data={["(All)", "Yes", "No"]} />
);
class UnmappedUsers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      result: { data: [], total: 0 },
      resultExport: { data: [], total: 0 },
      dataState: { skip: 0, take: 150, filter: { filters: [{field: 'ignoreUser', operator: 'eq', value: false}], logic: 'and'} },
      loading: false,
      refreshText: '',
      unmappedUsers: [],
      selectedUsers: [],
      roles: [],
    }
  }

  componentDidMount() {
    this.getUnmappedUsers();
  }

  getUnmappedUsers = () => {
    this.setState({ 
      refreshText: 'Loading...', 
      selectedUsers:[] 
    });

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios(`api/unmappedUsers`, {
      method: 'GET',
      params: { company: this.props.user.company, },
      headers: headers
    })
      .then(response => {
        if (response.data.code === 200) {
          let roles = response.data.roles
            .sort((a,b) => (a.userLevel - b.userLevel))
            .map(item => {return ({id: item.guid, text: item.role})})


          this.setState({
            unmappedUsers: response.data.unmapped,
            roles: roles,
            resultExport: process(response.data.unmapped, { ...this.state.dataState, skip: 0, take: response.data.unmapped.length }),
            result: process(response.data.unmapped, this.state.dataState),
            refreshText: 'Refresh'
          })
        }

      })
      .catch(err => {
        console.log(err)
        this.setState({ refreshText: 'Refresh' });

      });
  }

  changeMFA = (clear) => {
    if (clear){//"clear" will be true if being called from clearState() in InviteForm.js
      this.state.result.data.forEach(i => {
        i.useMFA = false //since we are running clearState() we always want to set useMFA to false
      })
    }
    else{
      this.state.result.data.forEach(i => {
        i.useMFA = !i.useMFA
      })
    }
  }

  changeDataState = (e) => {
    var data = { ...e.dataState }
    if (data.filter != null && data.filter.filters != null) {
      data.filter.filters.forEach(item => {
        if (item.field === 'ignoreUser' && item.value === 'Yes') { item.value = true; }
        if (item.field === 'ignoreUser' && item.value === 'No') { item.value = false; }
      })
      data.filter.filters = data.filter.filters.filter(item => item.value !== '(All)');
    }
    this.setState({
      dataState: e.dataState,
      resultExport: process(this.state.unmappedUsers, { ...data, skip: 0, take: this.state.unmappedUsers.length }),
      result: process(this.state.unmappedUsers, data)
    });
  }

  excludeUser = (email, name, ignoreUser) => {
    var conf = "";

    if (ignoreUser === true) {
      conf = window.confirm("Are you sure you want to include " + name + "?");
    }
    else {
      conf = window.confirm("Are you sure you want to exclude " + name + "?");
    }

    if (conf) {
      var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

      var payload = {
        company: this.props.user.company,
        email: email,
      }

      axios.post("api/excludeUnmappedUser", payload, { headers }).then(response => {
        if (response.data.code === 200) {
          this.getUnmappedUsers();
        }
        else {
          alert("An error has occurred. Please try again.");
        }
      }).catch(err => console.log(err));
    }
  }

  includeSelectedUsers = () => {
    var conf = "";
    conf = window.confirm("Are you sure you want to include " + this.state.selectedUsers.length + (this.state.selectedUsers.length > 1 ? " users?" : " user?"));

    if (conf) {
      var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

      var payload = {
        company: this.props.user.company,
        usersToInclude: this.state.selectedUsers,
      }

      axios.post("api/includeSelectedUnmappedUsers", payload, { headers }).then(response => {
        if (response.data.code === 200) {
          this.getUnmappedUsers();
        }
        else {
          alert("An error has occurred. Please try again.");
        }
      }).catch(err => console.log(err));
    }
  }

  deleteSelectedUsers = () => {
    let confirm = prompt(`Are you sure you want to delete ${this.state.selectedUsers.length} user${this.state.selectedUsers.length !== 1 ? 's' : ''} from the table?\n\nIf so, enter "DELETE" below`, "");

    if (confirm && confirm.toLowerCase() === "delete") {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        var payload = {
          company: this.props.user.company,
          usersToDelete: this.state.selectedUsers.join(",")
        }
    
    
        axios.post('api/deleteUnmappedUsers', payload, { headers }).then(response => {
            if (response.data.code === 200){
                alert(`${this.state.selectedUsers.length} unmapped users successfully deleted from the table`)
                this.getUnmappedUsers();
            }
            else{
                alert("Error deleting unmapped users")
            }
        }).catch(err => {
          console.log(err);
          alert("Error deleting unmapped users")
        });
    }
    else if (confirm === ""){
        alert("\"DELETE\" was not entered. Unmapped Users were not deleted")
    }
    else if (confirm === null){
        //nothing
    }
    else{
        alert("\"DELETE\" was not entered. Unmapped Users were not deleted")
    }

}

  excludeSelectedUsers = () => {
    var conf = "";
    conf = window.confirm("Are you sure you want to exclude " + this.state.selectedUsers.length + (this.state.selectedUsers.length > 1 ? " users?" : " user?"));

    if (conf) {
      var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

      var payload = {
        company: this.props.user.company,
        usersToExclude: this.state.selectedUsers,
      }

      axios.post("api/excludeSelectedUnmappedUsers", payload, { headers }).then(response => {
        if (response.data.code === 200) {
          this.getUnmappedUsers();
        }
        else {
          alert("An error has occurred. Please try again.");
        }
      }).catch(err => console.log(err));
    }
  }

  selectionChange = (event, gFiles) => {
    event.dataItem.selected = !event.dataItem.selected;
    var selectedUsers = [];
    for (let i = 0; i < gFiles.data.length; i++) {
      if (gFiles.data[i].selected) {
        selectedUsers.push(gFiles.data[i].email);
      }
    }
    this.setState({ selectedUsers: selectedUsers });
    this.forceUpdate();
  }

  headerSelectionChange = (event, gFiles) => {
    const checked = event.syntheticEvent.target.checked;
    gFiles.data.forEach(item => item.selected = checked);
    var selectedUsers = [];
    for (let i = 0; i < gFiles.data.length; i++) {
      if (gFiles.data[i].selected) {
        selectedUsers.push(gFiles.data[i].email);
      }
    }
    this.setState({ selectedUsers: selectedUsers });
    this.forceUpdate();
  }

  _export;
  export = () => {
    this._export.save();
  }

  render() {
    return (
      <div>

        <div className='unmappedUserMappingList'>
          <br />
          <strong style={{ color: 'red' }} >  Unmapped Users:{this.state.unmappedUsers.length}</strong>
          <strong>  Excluded Users:{this.state.unmappedUsers.filter(item => item.ignoreUser).length}</strong>
          <ExcelExport data={this.state.resultExport.data != null && this.state.resultExport.data.length > 0 ? JSON.parse(JSON.stringify(this.state.resultExport.data)).map(item => {
            item.ignoreUser = (item.ignoreUser ? 'Yes' : 'No');
            return item;
          }) : []} fileName={'Unmapped_Users_Export.xlsx'} ref={(exporter) => { this._export = exporter; }}>
            <ExcelExportColumn field='email' title='Email' width={300} />
            <ExcelExportColumn field='name' title='Name' width={200} />
            <ExcelExportColumn field='ignoreUser' title='Exclude' width={100} />
          </ExcelExport>
          <Grid data={this.state.result}
            selectedField='selected'
            onRowClick={(e) => this.selectionChange(e, this.state.result)}
            onSelectionChange={(e) => this.selectionChange(e, this.state.result)}
            onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.result)}
            sortable={true}
            filterable={true}
            pageable={true}
            onDataStateChange={this.changeDataState}
            {...this.state.dataState}
          >


            <GridToolbar>
              <div>
                <Button icon='refresh' title='Refresh' onClick={(e) => this.getUnmappedUsers()}>{this.state.refreshText}</Button>
                <Button icon="excel" title='Export to Excel' onClick={this.export}>Export</Button>
                <Button icon='delete' title='Delete' disabled={this.state.selectedUsers.length === 0} onClick={(e) => this.deleteSelectedUsers()}>Delete Selected Users</Button>
                <Button icon='plus' title='Include' disabled={this.state.selectedUsers.length === 0} onClick={(e) => this.includeSelectedUsers()}>Include Selected Users</Button>
                <Button icon='minus' title='Exclude' disabled={this.state.selectedUsers.length === 0} onClick={(e) => this.excludeSelectedUsers()}>Exclude Selected Users</Button>
                {/* <strong> Hide Excluded:</strong><input type="checkbox" checked={this.state.excludeExcluded} onChange={this.onClick_excludeExcluded} /> */}
                <InviteUser unmappedUsers={true}
                  unmappedUserList={this.state.result.data.filter(item => !item.ignoreUser)}
                  unmappedChangeMFA={this.changeMFA}
                  compSettings={this.props.compSettings}
                  company={this.props.user.company}
                  role={this.props.user.role}
                  roles={this.state.roles}
                  user={this.props.user}
                  idToken={this.props.idToken}
                  icon="upload"
                  refreshUsers={this.getUnmappedUsers} />
              </div>

            </GridToolbar>
            <GridColumn width='50px' field="selected" filterable={false} headerClassName='gridHeader'
              headerSelectionValue={this.state.unmappedUsers.findIndex(dataItem => dataItem.selected === false) === -1}
            />
            <GridColumn headerClassName='gridHeader' field='email' title='Email' />
            <GridColumn headerClassName='gridHeader' field='name' title='Name' />
            <GridColumn headerClassName='gridHeader' field='ignoreUser' title='Exclude' filter="boolean" filterCell={yesNoFilterCell} cell={(cell) => cell.dataItem.ignoreUser ? <td>Yes</td> : <td>No</td>} />
            <GridColumn headerClassName='gridHeader' filterCell={(props) => { return (<></>) }}
              cell={(cell) => !cell.dataItem.ignoreUser ?
                <td><div><Button className='docButton' icon='minus' onClick={(e) => this.excludeUser(cell.dataItem.email, cell.dataItem.name, cell.dataItem.ignoreUser)}>Exclude</Button></div></td> :
                <td><div><Button className='docButton' icon='plus' onClick={(e) => this.excludeUser(cell.dataItem.email, cell.dataItem.name, cell.dataItem.ignoreUser)}>Include</Button></div></td>} />
          </Grid>
          {this.state.loading && <GridLoading />}
        </div>
      </div>
    );
  }

}

export default UnmappedUsers;