import React, { Component } from "react";
import { ComboBox,DropDownList } from '@progress/kendo-react-dropdowns';
import axios from 'axios';
import { filterBy, process } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { parseDate } from '@telerik/kendo-intl';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import GridLoading from '../../Loaders/GridLoading';
import StagedEmailRequest from './StagedEmailRequest';

class StagedEmailSets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //document list state items
            fileList: [],
            result: [],
            dataState: { skip: 0, take: 25, sort: [{ field: 'fileLocation', dir: 'asc' }, { field: 'displayName', dir: 'asc' }] },
            loading: false,

            //bulk key dropdown state items
            selectedBulkKey: "",
            bulkKeys: [],
            bulkKeysResult: [],
            //fields fed from the bulk key
            fromDate: '',
            toDate: '',
            locationString:'',
            usersString:'',
            foldersString:'',


            //for table filtering
            excludeUsersNonNotify:false,
            excludeExcludedUsers:false,
            userMappingMode:{value:"2", desc:'Mapped Only'},//1 = all, 2 = only mapped, 3 = only unmapped
            userMappingModeOptions: [],
            //userMappingModeValues: [],

            //document count state items
            TotalDocuments: 0,
            UnmappedDocuments: 0,
            DistinctEmailAddrs: 0,


        }

        this.loadDocumentList = this.loadDocumentList.bind(this);
        this.getBulkKeyList = this.getBulkKeyList.bind(this);
        this.clearState = this.clearState.bind(this);
        this.excludeEmail = this.excludeEmail.bind(this);
        this.getDate = this.getDate.bind(this)
    }

    componentDidMount() {
        this.state.userMappingModeOptions.push({value:"1", desc:'All'});
        this.state.userMappingModeOptions.push({value:"2", desc:'Mapped Only'});
        this.state.userMappingModeOptions.push({value:"3", desc:'Unmapped Only'});


        this.getBulkKeyList();


    }

    getDate(sqlDate, isUTC){
      var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
      if(d){
        var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
        if(isUTC)
          return utc;
        else
          return d;
      }
      else
        return null;
    }

    clearState() {
        this.setState({

            files: [],
            previewMappings: []

        });
    };



    //get the bulk key list
    getBulkKeyList() {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var payload = {
            company: this.props.user.company
        }
        axios.post('api/emailBulkKeysGet', payload, { headers }).then(response => {
            if (response.data.code === 200) {

                if (response.data.bulkKeys.length > 0)
                    this.setState({ bulkKeys: response.data.bulkKeys, bulkKeysResult: response.data.bulkKeys, selectedBulkKey: [] });
                else
                    this.setState({ bulkKeys: [] });
            }
        }).catch(err => {
            console.log(err);
        });
    }










    onBulkKeyChange = (e) => {
        if (!this.isEmpty(e.target.value)) {
            this.setState({ selectedBulkKey: e.target.value }, () => {
                this.loadDocumentList();
            });
        }

    }

    loadDocumentList() {

        if (this.state.selectedBulkKey !== undefined) {

            //set some of the fields from the selected bulk key
            this.setState({ 
                fromDate: getFormattedDate(this.state.selectedBulkKey.fromDate )
                ,toDate: getFormattedDate( this.state.selectedBulkKey.toDate )
                ,locationString:this.state.selectedBulkKey.locationString 
                ,usersString:this.state.selectedBulkKey.usersString 
                ,foldersString:this.state.selectedBulkKey.foldersString ,
                loading: true, fileList: [], result: [] 
            });


            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            var payload = {
                company: this.props.user.company,
                bulkEmailKey: this.state.selectedBulkKey.bulkEmailKey,
                //get extra filter options
                excludeUsersNonNotify:this.state.excludeUsersNonNotify,
                excludeExcludedUsers:this.state.excludeExcludedUsers,
                userMappingMode:this.state.userMappingMode.value
                
            }

            axios.post('api/fileUploadedListGet?company='+this.props.user.company, payload, { headers }).then(response => {
                if (response.data.code === 200) {

                    var fileList = []
                    for (let row of response.data.fileList){
                      row.uploadDateObject = this.getDate(row.uploadDate)
                      fileList.push(row)
                    }

                    var result = process(fileList, this.state.dataState);

                    this.setState({
                        fileList: fileList
                        , result: result
                        , loading: false
                        , TotalDocuments: response.data.TotalDocuments
                        , UnmappedDocuments: response.data.UnmappedDocuments
                        , DistinctEmailAddrs: response.data.DistinctEmailAddrs
                    });
                }
            }).catch(err => {
                console.log(err);
            });
        }

    }



    //check if an object is empty
    isEmpty = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    };


    changeDataState = (e) => {
        var newData = process(this.state.fileList, e.dataState);
        this.setState({ dataState: e.dataState, result: newData });
      }
    

      onFieldChange(e, field) {
        this.setState({ [field]: e.target.value }, () => {
            this.loadDocumentList();
        });        
      }

      onCheckboxFieldChange(e, field) {
        this.setState({ [field]:e.value }, () => {
            this.loadDocumentList();
        });        
      }

      onClick_excludeUsersNonNotify = () => {
        this.setState({excludeUsersNonNotify:!this.state.excludeUsersNonNotify}, () => {
            this.loadDocumentList();
        });        
      };
      onClick_excludeExcludedUsers = () => {
        this.setState({excludeExcludedUsers:!this.state.excludeExcludedUsers}, () => {
            this.loadDocumentList();
        });        
      };


      createMapCell(cell){
        return(
          <td>
            {cell.dataItem.excludedEmailAddress===false   && <Button className='docButton' icon='delete' onClick={(e)=>this.excludeEmail(cell.dataItem.BulkEmailKey, cell.dataItem.EndUserEmail, cell.dataItem.excludedEmailAddress, cell.dataItem.EndUserName)}>Exclude User</Button> }
    
            {cell.dataItem.excludedEmailAddress===true   && <Button className='docButton' icon='delete' onClick={(e)=>this.excludeEmail(cell.dataItem.BulkEmailKey, cell.dataItem.EndUserEmail, cell.dataItem.excludedEmailAddress, cell.dataItem.EndUserName)}>Include User</Button> }          </td>
        );
      }


      excludeEmail(BulkEmailKey,EndUserEmail,excludedEmailAddress,EndUserName ){
        var conf="";
        
        if(excludedEmailAddress===true)
        {
            conf =  window.confirm("Are you sure you want to include "+EndUserName + "?");
        }
        else
        {
            conf =  window.confirm("Are you sure you want to exclude "+EndUserName + "?");
        }
    
    
        if(conf){
          var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    
          var payload ={
            company: this.props.user.company,
            bulkKey: BulkEmailKey,
            EndUserEmail:EndUserEmail
          }
    
          axios.post("api/excludeStagedEmail", payload, {headers}).then(response=>{
            if(response.data.code===200){
              this.loadDocumentList();
            }
            else{
              alert("An error has occurred. Please try again.");
            }
          }).catch(err=>console.log(err));
        }
      }
    



    render() {
        return (
            <div>

<ExcelExport data={this.state.fileList} fileName={'BulkDocumentEmailList_Export.xlsx'} ref={(exporter) => { this._export = exporter; }}>
            <ExcelExportColumn field='fileLocation' title='Document Vault Location' />
            <ExcelExportColumn field='displayName' title='File Name' />
            <ExcelExportColumn field='EndUserName' title='End User' />
            <ExcelExportColumn field='EndUserEmail' title='End User Email' />
            <ExcelExportColumn field='uploadedBy' title='Uploaded By' />
            <ExcelExportColumn field='uploadDate' title='Uploaded Date' />
            <ExcelExportColumn field='excludedEmailAddress' title='Excluded User' />
          </ExcelExport>


          <table className="styleTable">
            <tbody>
              <tr>
                <td>

                <label className='bgText'>  Email Set: </label>
                <ComboBox
                    style={{ width: '30vw' }}
                    data={this.state.bulkKeysResult}
                    textField='bulkEmailKey'
                    onChange={this.onBulkKeyChange}
                    value={this.state.selectedBulkKey}
                    filterable={true}
                    allowCustom={false}

                    onFilterChange={(e) => {
                        this.setState({ bulkKeysResult: filterBy(this.state.bulkKeys, e.filter) });
                    }}
                />

                <label style={{ paddingLeft: '5px' }}><strong> Mapped: </strong></label>
                  <DropDownList
                    data={this.state.userMappingModeOptions}
                    textField="desc"
                    dataItemKey="value"
                    onChange={(e) => this.onFieldChange(e, 'userMappingMode')}
                    value={this.state.userMappingMode}
                  />

            {/* <label style={{ paddingLeft: '5px' }}><strong> Hide Users with Excluded Notifications:</strong><input type="checkbox" checked={this.state.excludeUsersNonNotify} onChange={this.onClick_excludeUsersNonNotify}/></label> */}
            <label style={{ paddingLeft: '5px' }}><strong> Hide Excluded Users:</strong><input type="checkbox" checked={this.state.excludeExcludedUsers} onChange={this.onClick_excludeExcludedUsers}/></label>




</td>
</tr>
<tr>
<td>

                <label style={{ paddingLeft: '10px' }}><strong>Upload From: </strong> {this.state.fromDate}</label> 
                <label style={{ paddingLeft: '5px' }}><strong>Upload To: </strong> {this.state.toDate}</label>
                <label style={{ paddingLeft: '5px' }}><strong>File Locations: </strong> {this.state.locationString}</label>
                <label style={{ paddingLeft: '5px' }}><strong>Vault Folders: </strong> {this.state.foldersString} </label>
                <label style={{ paddingLeft: '5px' }}><strong>Upload Users: </strong> {this.state.usersString} </label>

</td>
</tr>
</tbody>
</table>

<div className='houseMappingList'>
            <Grid data={this.state.result}
              sortable={true}
              filterable={true}
              pageable={true}
              style={{ height: '500px' }}
              onDataStateChange={this.changeDataState}
              {...this.state.dataState}
            >


              <GridToolbar>

                <div>

                  <Button icon="excel" title='Export to Excel' onClick={this.export}>Export</Button>

                  {this.state.DistinctEmailAddrs !== 0 &&
                    <StagedEmailRequest  DistinctEmailAddrs={this.state.DistinctEmailAddrs}  bulkKey={this.state.selectedBulkKey.bulkEmailKey}  idToken={this.props.idToken} company={this.props.user.company} user={this.props.user.user} button={<Button className="docButton" icon="upload">Request Email Notifications</Button>} /> 
                  }


                  <strong>Total Documents: {this.state.TotalDocuments}</strong>
                  {this.state.UnmappedDocuments !== 0 && <strong style={{ color: 'red' }} >&nbsp;&nbsp;Unmapped Documents: {this.state.UnmappedDocuments}</strong>}
                  <strong>&nbsp;&nbsp;Distinct Email Addrs: {this.state.DistinctEmailAddrs}</strong>
                </div>



              </GridToolbar>
              <GridColumn field='fileLocation' title='File Location' />
              <GridColumn field='displayName' title='File Name' />
              <GridColumn field='EndUserName' title='End User to Notify' />
              <GridColumn field='uploadDateObject' title='Uploaded Date'  format="{0:g}"/>
              <GridColumn field='excludedEmailAddress' title='Exclude User' />
              <GridColumn headerClassName='gridHeader' filterCell={(props)=>{return(<></>)}} cell={(props) => this.createMapCell(props)} />

            </Grid>
            {this.state.loading && <GridLoading />}
          </div>


            </div>
        );
    }

    _export;
    export = () => {
      this._export.save();
    }

}

function getFormattedDate(date) {
    var myDate = new Date(date);

    var year = myDate.getFullYear();
    var month = (1 + myDate.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
    var day = myDate.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    return month + '/' + day + '/' + year;

    
  }


export default StagedEmailSets;